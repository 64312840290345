@import '../../../styles/utils/variables';

.container {
  margin: 0 auto;

  @media @viewport-mobile {
    margin: 0 20px;
    padding: 0;
  }
}
