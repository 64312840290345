@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.ad-landing-page {
  max-height: 600px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });
  }
  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    position: fixed;
    width: 450px;
    border-radius: 16px;
    z-index: 1000;

    @media @viewport-mobile {
      width: 100%;
    }

    .with-theme({
      background: @vars[white-to-neutral-dark-50]
    });

    &-sup {
      padding: 16px 16px 0;
      font-weight: 400;
      font-size: 16px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    &-sub {
      padding: 0 16px 16px;
      font-weight: 300;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }

    &-bar {
      height: 40px;
      padding: 0px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .with-theme({
        background-color: @vars[neutral-50-to-neutral-dark-100];
        border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      span {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #434C69;

        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        svg {
          margin-left: 4px;
        }
      }
    }
  }

  &-list {
    margin-top: 120px;

    &-item {
      display: flex;
      justify-content: space-between;
      padding: 14px 0px 14px 24px;

      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];

        &:last-child {
          border: none;
        }
      });

      .link {
        svg {
          margin-right: 8px;
        }
        .url {
          font-size: 14px;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .divider {
        .with-theme({
          background-color: @vars[neutral-200-to-neutral-dark-200];
          border-color: @vars[neutral-200-to-neutral-dark-200];
        });
      }
      .amount {
        width: 105px;
        display: flex;
        align-items: center;
        justify-content: center;

        .with-theme({
          color: @vars[neutral-800-to-200];
        });
      }
    }
  }
}
