@import "../../styles/utils/variables";

.step-title {
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;

  @media @viewport-mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: @Neutral800;
    letter-spacing: -0.5px;
  }

  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: @Neutral600;
    letter-spacing: -0.3px;
  }
}
