@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.university-course-chapter-card {

  &-wrapper {
    width: 100%;
    padding: 12px 16px;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      padding: 16px;
    }

    &:last-of-type {
      padding-bottom: 0 !important;
      border-bottom: none !important;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @viewport-mobile {
      align-items: flex-start;
    }

    &--locked {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 8px;
      gap: 4px;

      svg {
        min-width: 20px;
      }

      @media @viewport-mobile {
        gap: 8px;
        align-items: flex-start;
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: @orangePrimary;
      }
    }
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 16px;
    width: calc(100% - 60px);

    @media @viewport-mobile {
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
    }

    .text {
      width: 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;

      @media @viewport-mobile {
        width: 100%;
      }
    }

    .title {
      white-space: nowrap;
      margin-bottom: 0 !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];

        &--locked {
          color: @vars[neutral-400-to-neutral-dark-400];
        }
      })
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];

        &--locked {
          color: @vars[neutral-400-to-neutral-dark-400];
        }
      })
    }

    .stat {
      display: flex;
      align-items: center;
      gap: 8px;

      &:not(.completed) {
        svg path {
          .with-theme({
            stroke: @vars[neutral-400-to-neutral-dark-400];
          })
        }
      }

      &--locked span {
        .with-theme({
          color: @vars[neutral-400-to-neutral-dark-400] !important;
        });
      }

      span {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      &.completed:not(&--locked) span {
        color: @greenPrimary !important;
      }
    }
  }

  &-button {
    margin-left: auto;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    @media @viewport-mobile {
      transform: translate(10px, -10px);
    }

    &:not(&--locked) svg path {
      .with-theme({
        fill: @vars[neutral-600-to-neutral-dark-500];
      });
    }

    &--locked {
      padding: 0;
      cursor: not-allowed;
    }
  }
}
