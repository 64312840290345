@import '../../../styles/theme/theme';

.upcoming-drop-page{
  padding: 64px 0;
  max-width: 1100px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin: 0 auto!important;


  > .card-wrapper{
    width: 512px;
    margin: 0 8px 16px;
  }

  > .empty-row{
    width: 100%;
    height: 48px;
  }

  @media @viewport-mobile {
    padding: 20px;

    > .card-wrapper{
      width: 335px;
    }

    > .empty-row{
      height: 80px;
    }
  }

  @media only screen and (max-width: 400px) {
    > .card-wrapper{
      margin: 0 0 16px;
    }
  }
}

.ant-modal.custom-modal.upcoming-drop{
  .ant-modal-header{
    @media @viewport-mobile {
      padding: 8px 0;
      margin-bottom: 0;
    }
    .ant-modal-title{
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }
  }
  .ant-modal-content {
    .ant-modal-header{
      padding: 16px 16px 0 !important;
    }
    .modal-sub-title{
      padding: 16px 16px 16px !important;
    }
    .modal-footer-sub-btn-wrapper{
      padding: 0 16px 16px !important;
    }
    .ant-modal-close{
      top: 18px;
    }
    .ant-modal-body {
      padding: 16px 24px 24px;

      .ant-spin-container{
        p{
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          letter-spacing: -0.3px;
          max-width: 374px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }
      }
      .switch-subscription-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        position: relative;
        @media @viewport-mobile {
          flex-direction: column;
          gap: 16px;
        }

        .final-confirmation-icon {
          position: absolute;
          z-index: 100;
          left: 50%;
          top: 34px;
          transform: translate(-50%, 0);
          display: flex;
          padding: 8px;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          background: @Neutral800;
          .with-theme({
            box-shadow: @vars[box-shadow-object-middle];
          });

          @media @viewport-mobile {
            top: 114px;
          }
        }

        & > .ant-spin-nested-loading {
          width: 197px;
          overflow: visible;

          @media @viewport-mobile {
            width: 100%;
          }
        }

        .plan-card-upcoming{
          width: 197px;
          margin: 0;

          @media @viewport-mobile {
            width: 100%;
          }
        }
      }
    }
    .ant-modal-footer{
      .modal-footer-sub-btn-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        @media @viewport-mobile {
          width: 100%;
          justify-content: center;
        }
        .ant-btn.btn-primary{
          padding: 0 12px;
          width: auto;
          height: 40px;
          border-radius: 10px !important;
          color: @Neutral800;
          box-shadow: 0px 4px 4px 0px rgba(21, 30, 58, 0.04);
          box-shadow: 0px 0px 0px 1px rgba(197, 204, 227, 0.45);
          box-shadow: 0px -1px 2px 0px rgba(242, 243, 248, 0.48) inset;
          border-color: @Neutral200 !important;
          &:hover {
            background: @Neutral50;
          }
          @media @viewport-mobile {
            width: 100%;
          }
        }
        .ant-btn.btn-primary.red{
          padding: 0 12px;
          width: auto;
          height: 40px;
          border-radius: 10px !important;
          border-color: @redPrimary !important;
          color: @redPrimary !important;
          background: #FFFFFF !important;
          box-shadow: 0px 4px 4px 0px rgba(215, 19, 19, 0.04);
          box-shadow: 0px 0px 0px 1px rgba(215, 19, 19, 0.45);
          box-shadow: 0px -1px 2px 0px rgba(242, 243, 248, 0.48) inset;
          &:hover,:focus{
            background:#FFFAFA !important;
          }
          @media @viewport-mobile {
            width: 100%;
          }
        }
      }
      .double-btn-wrapper{
        .double-btn-block{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 16px 16px;
          .ant-btn.btn-primary{
            color: @Neutral800 !important;
            border-radius: 10px !important;
            box-shadow: 0px 4px 4px 0px rgba(21, 30, 58, 0.04);
            box-shadow: 0px 0px 0px 1px rgba(197, 204, 227, 0.45);
            box-shadow: 0px -1px 2px 0px rgba(242, 243, 248, 0.48) inset;
            border-color: @Neutral200 !important;
            &:hover {
              background: @Neutral50;
            }
            @media @viewport-mobile {
              width: 100%;
            }
          }
          .ant-btn.btn-primary.red{
            border-radius: 10px !important;
            border-color: @redPrimary !important;
            color: @redPrimary  !important;;
            background: #FFFFFF !important;
            box-shadow: 0px 4px 4px 0px rgba(215, 19, 19, 0.04);
            box-shadow: 0px 0px 0px 1px rgba(215, 19, 19, 0.45);
            box-shadow: 0px -1px 2px 0px rgba(242, 243, 248, 0.48) inset;
            &:hover{
              background:#FFFAFA !important;
            }
            @media @viewport-mobile {
              width: 100%;
            }
          }
        }
      }
    }
  }


  @media @viewport-mobile {
    .ant-modal-content{
      .ant-modal-body {
        padding: 0 20px;
        overflow-y: auto !important;

        .ant-divider{
          display: none;
        }
        &:before{
          display: block;
          content: '';
          position: sticky;
          z-index: 3;
          top: 0;
          width: 100%;
          height: 24px;
        }

        &:after{
          display: none;
        }
      }
    }
  }

  &.small-top{
    @media @viewport-mobile {
      .ant-modal-content{
        .ant-modal-body {
          &:before{
            height: 8px;
          }
        }
      }
    }
  }

  &.without-footer{
    .ant-modal-content{
      .ant-modal {

        &-body{
          @media @viewport-mobile {
            max-height: calc(100dvh - 56px);
          }
        }

        &-footer{
          display: none;
        }
      }
    }
  }
}

.ant-btn.rsb-container.with-animation-hover.btn {
  height: 40px;
  outline: none;
  border: none;

  &:hover {
    color: @white !important;
    background: @Neutral50;
  }

  &.red:hover {
    background: @redDepressed;
  }
}

.modal-footer-sub-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .modal-footer-btn {
    height: 48px;
    width: 224px;

    &.wide {
      width: 100%;
    }

    &.red {
      border-color: @redPrimary !important;
      margin-left: unset !important;

      &:hover {
        border-color: @redDepressed !important;
      }
    }

    &:not(.border-only) {
      border-color: @bluePrimary;

      &:hover, &:focus, &:active {
        color: @white;
        background: @blueDepressed;
      }
    }
  }
}


.ant-modal.custom-modal.upgrade-plan,
.ant-modal.custom-modal.downgrade-plan{
  .ant-modal-content{
    .ant-modal-footer{
      .modal-footer-sub-btn-wrapper{
        .ant-btn.btn-primary.red{
            padding: 0 12px;
            width: auto;
            height: 40px;
            border-radius: 10px !important;
            color: @white !important;
            background: @bluePrimary !important;
            .with-theme({
              box-shadow: @vars[box-shadow-btn-primary];
            });

            &:hover, &:focus {
              background: @blueDepressed !important;
            }
            @media @viewport-mobile {
              width: 100%;
            }
          }
      }
    }
  }
  .ant-btn.btn-primary{
    padding: 0 12px;
    width: auto;
    height: 40px;
    border-radius: 10px !important;
    border: none !important;
    .with-theme({
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark-50] !important;
      box-shadow: @vars[box-shadow-btn-secondary] !important;

      &:hover, &:focus {
        background: @vars[neutral-50-to-neutral-dark-100] !important;
      }
    });

    @media @viewport-mobile {
      width: 100%;
    }
  }
}


