@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.collections-page {
  padding: 0 !important;
  overflow: unset!important;
  overflow-x: visible!important;
  max-height: 100dvh;
  overscroll-behavior: contain;

  .skeleton.link {
    display: block;
  }

  .title-wrapper-icon {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #661AC6 !important;
    box-shadow: 0px 20px 20px -10px rgba(70, 13, 144, 0.04), 0px 10px 10px -5px rgba(70, 13, 144, 0.04), 0px 5px 5px -2.5px rgba(70, 13, 144, 0.04), 0px 2.5px 2.5px -1.25px rgba(70, 13, 144, 0.04), 0px 0.833px 0.833px -0.417px rgba(70, 13, 144, 0.04), 0px 0px 0px 0.833px rgba(70, 13, 144, 0.04) !important;

    svg {
      margin-top: 5px;
      margin-left: 2px;
    }
  }

  &-options-wrapper {
    padding: 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      padding: 16px;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }

    .search {

      &_wrapper {
        margin-bottom: 0;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        @media @viewport-mobile {
          width: 100%;
        }
      }

      &-input {
        width: 400px;
        margin-right: 0;

        @media @viewport-mobile {
          width: calc(100% - 82px);
        }
      }

      &-submit {
        transition: all .3s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none !important;
        height: 40px;
        padding: 6px 12px;
        border-radius: 10px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
          background: @vars[white-to-neutral-dark-50];
          box-shadow: @vars[box-shadow-btn-secondary];

          &:hover, &:focus {
            background: @vars[neutral-50-to-neutral-dark-100];
          }

          &.disabled {
            cursor: not-allowed;
            background: @vars[neutral-100-to-neutral-dark-100];
          }
        })
      }
    }

    .create-collection {
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      cursor: pointer;
      transition: all .3s ease;
      background: @bluePrimary;
      border: none !important;
      outline: none !important;
      color: @white !important;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-primary];
      });

      @media @viewport-mobile {
        width: 100%;
        justify-content: center;
      }

      &:hover, &:focus {
        background: @blueDepressed;
      }
    }

  }

  .products-overview_wrapper {
    height: calc(100dvh - 93px - 89px);
  }
}
