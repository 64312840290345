@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixin';
@import '../../../styles/base/animation';
@import '../../../styles/theme/theme';

section.ant-layout:has(.login-content) {
  height: 100dvh;
}

.left {
  text-align: left;
}

.login-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 25px 0;
  font-family: "Inter", sans-serif;
  background: @white;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.success {
    align-items: center;
    justify-content: center;

    .content-title {
      padding-top: 24px;
      width: 450px;

      > h2 {
        text-align: center;
        margin-bottom: 12px;
        background: @white;
      }

      > div {
        white-space: pre-wrap;
        font-size: 16px;
      }

      &.animation {
        h2 {
          font-size: 32px;
          line-height: 48px;
          letter-spacing: -0.69px;
          color: @blue500;
          background: @white;
        }
      }
    }

    .page-content {
      height: auto;
      display: flex;
      flex-direction: column;
      min-height: 240px;

      > img,
      video {
        max-height: 468px;
        height: 57vh;
        max-width: 100%;
        margin: 62px auto;
      }

      video {
        @media @viewport-mobile {
          height: 37vh;
        }
      }

      .ant-btn {
        width: 390px;
        min-height: 48px;
      }
    }

    &_first-time {
      background: @white;
      .content-title {
        @media @viewport-mobile {
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        > h2 {
          text-align: center;
          margin-bottom: 12px;
          background: @white;
        }

        > div{
          font-size: 16px;
          white-space: pre-wrap;
          line-height: 24px;
          text-align: center;
          @media @viewport-mobile {
            margin-bottom: 8px;
            padding: 0;
            width: 270px;
            white-space: pre-wrap;
          }
        }
      }

      .page-content {
        > img,
        video {
          margin: auto;
        }

        .ant-btn {
          width: 390px;
          margin: 0 auto;
        }
      }
    }
  }

  .login-logo {
    width: 36px;
    height: 36px;

    @media @viewport-mobile {
      width: 32px;
      height: 32px;
    }
  }


  .login-card-wrapper {
    margin-top: 100px;

    &:has(.resend-code) {
    }

    @media (max-height: 730px) {
      margin-top: 50px;
    }

    @media @viewport-mobile {
      margin-top: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .login-card {
      width: 400px;
      padding: 24px;
      border-radius: 24px;
      background: @white;
      box-shadow: 0px 24px 24px -12px rgba(34, 90, 234, 0.04), 0px 12px 12px -6px rgba(34, 90, 234, 0.04), 0px 6px 6px -3px rgba(34, 90, 234, 0.04), 0px 3px 3px -1.5px rgba(34, 90, 234, 0.04), 0px 1px 1px -0.5px rgba(34, 90, 234, 0.04), 0px 0px 0px 1px rgba(34, 90, 234, 0.04);

      @media @viewport-mobile {
        padding: 16px;
        width: calc(100% - 32px);
        max-width: 400px;
      }

      .ant-card-body {
        padding: 0;
        border-radius: 0;
      }

      .code-block {
        margin: 24px auto;
        width: 100% !important;

        > div {
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: space-between;

          input {
            border-radius: 10px;
            border: 1px solid @Neutral200;
            background: @white;
            display: flex;
            width: 40px;
            height: 40px;
            padding: 6px 12px;
            justify-content: space-between;
            align-items: center;
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: @Neutral800;
            caret-color: @Neutral800;

            &.error {
              border: 1px solid @redPrimary;
            }

            &:focus, &:active {
              border: 1px solid @bluePrimary;
            }
          }
        }
      }

      .error-text {

        &.error-text-restore {
          position: relative;
          top: -8px;
        }
        span {
          color: @redPrimary;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        &.red {
          span {
            color: @redPrimary;
          }
        }

        &.yellow {
          span {
            color: @yellowPrimary;
          }
        }

        &.green {
          span {
            color: @greenPrimary;
          }
        }
      }

      .resend-code {
        text-align: center;
        margin-top: 18px;

        .resend-timer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .ant-statistic {
            margin-right: 5px;
          }

          .ant-statistic-content {
            display: flex;
            align-items: center;
          }

          span {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: @Neutral400;
          }
        }

        .text {
          color: @bluePrimary;
          cursor: pointer;
        }
      }

      .verify-btn-block {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        button {
          transition: all .3s ease;
          cursor: pointer;
          min-height: 40px;
          width: 100%;
          margin: 0;
          border-radius: 10px;
          background: @bluePrimary;
          box-shadow: 0px -1px 2px 0px rgba(20, 72, 203, 0.48) inset, 0px 0px 0px 1px rgba(34, 90, 234, 0.16), 0px 8px 16px -8px rgba(34, 90, 234, 0.64), 0px 4px 4px 0px rgba(0, 0, 0, 0.06);

          &:hover, &:focus, &:active {
            background: @blueDepressed;
          }

          &[disabled] {
            background: @Neutral100 !important;
            color: @Neutral300 !important;
            box-shadow: none !important;
            cursor: not-allowed;
          }
        }

        .gmail {
          border: none;
          cursor: pointer;
          width: 100%;
          margin: 0;
          height: 40px;
          border-radius: 10px;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @Neutral800;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          background: @white;
          transition: all .3s ease;
          box-shadow: 0px -1px 2px 0px rgba(242, 243, 248, 0.48) inset, 0px 0px 0px 1px rgba(197, 204, 227, 0.45), 0px 4px 4px 0px rgba(21, 30, 58, 0.04);

          &:hover, &:focus, &:active {
            background: @Neutral50;
          }
        }
      }

      &-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: @Neutral800;

        @media @viewport-mobile {
          font-size: 16px;
          line-height: 20px;
        }
      }

      &-subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: @Neutral600;
        margin-top: 8px;
        letter-spacing: -0.3px;
      }

      .social-btn-block-login {
        display: flex;
        margin-top: 24px;
        align-items: center;
        gap: 12px;

        @media @viewport-mobile {
          margin-top: 16px;
        }

        .social-btn-login {
          transition: all .3s ease;
          flex: 1;
          padding: 6px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @Neutral800;
          cursor: pointer;
          border-radius: 8px;
          border: none;
          height: 36px;
          background: @white;
          box-shadow: 0px -1px 2px 0px rgba(242, 243, 248, 0.48) inset, 0px 0px 0px 1px rgba(197, 204, 227, 0.45), 0px 4px 4px 0px rgba(21, 30, 58, 0.04);

          &:hover, &:focus, &:active {
            background: @Neutral50;
          }

          &[disabled] {
            background: @Neutral100 !important;
            color: @Neutral300 !important;
            box-shadow: none !important;
            cursor: not-allowed;
          }

          &--apple {
            span {
              margin-top: 2px;
            }
          }

          .social-btn-login-icon {
            width: 20px;
            height: 20px;
          }
        }
      }

      .login-card-divider {
        margin: 24px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        @media @viewport-mobile {
          margin: 16px auto;
        }

        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: @Neutral600;
        }

        div {
          background: @Neutral200;
          height: 1px;
          flex: 1;
        }
      }

      .login-form {

        &.restore {
          &:has(.restore-password-input + .error-text:not(.error-text_empty)) {
            .login-form-submit {
              margin-top: 8px;
            }
          }
        }

        &:has(.login-form-password + .field-helper) {
          .login-form-submit {
            margin-top: 8px;
          }
        }

        .field-helper {
          position: relative;
          top: -8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
        }

        input {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          height: 40px;
          padding: 6px 12px;
          border-radius: 10px;
          border: 1px solid @Neutral200;
          background: @white;
          caret-color: @Neutral800 !important;
          box-shadow: none;
          transition: all .3s ease;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 50px @white inset !important;
          }

          &:hover, &:focus, &:active {
            border: 1px solid @bluePrimary;
          }

          &.ant-input-status-error {
            border: 1px solid @redPrimary;
          }
        }

        .ant-input-affix-wrapper {
          padding: 0;
          border-radius: 10px;
          border: 1px solid @Neutral200;
          box-shadow: none;
          transition: all .3s ease;

          &:hover, &:focus, &:active, &:focus-within {
            border: 1px solid @bluePrimary;
          }

          &-status-error {
            border: 1px solid @redPrimary;
          }

          .ant-input-suffix {
            padding: 6px 12px 6px 0;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          input {
            border-radius: 10px 0 0 10px;
            border: none;
          }
        }

        &-submit {
          transition: background .3s ease;
          cursor: pointer;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          border: none;
          color: @white !important;
          border-radius: 10px;
          background: @bluePrimary;
          box-shadow: 0px -1px 2px 0px rgba(20, 72, 203, 0.48) inset, 0px 0px 0px 1px rgba(34, 90, 234, 0.16), 0px 8px 16px -8px rgba(34, 90, 234, 0.64), 0px 4px 4px 0px rgba(0, 0, 0, 0.06);

          &:hover, &:focus, &:active {
            background: @blueDepressed;
          }

          &[disabled] {
            background: @Neutral100 !important;
            color: @Neutral300 !important;
            box-shadow: none !important;
            cursor: not-allowed;
          }

          @media @viewport-mobile {
            margin-top: 16px;
          }
        }
      }
    }
  }

  .terms {
    margin-top: 24px;
    text-align: center;
    width: 400px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: @Neutral400;

    @media @viewport-mobile {
      width: calc(100% - 32px);
      max-width: 400px;
    }

    a {
      margin: 0 3px;
      text-decoration: underline;
      color: @Neutral400;
      cursor: pointer;
      transition: all .3s ease;

      &:hover, &:focus, &:active {
        color: @bluePrimary;
      }
    }
  }

  .forgot-password {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: @bluePrimary;

    @media @viewport-mobile {
      margin-top: 16px;
    }
  }

  .create-account {
    margin-top: auto;
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: @Neutral600;

    a {
      color: @bluePrimary;
      transition: all .3s ease;
      cursor: pointer;

      &:hover, &:focus, &:active {
        color: @blueDepressed;
      }
    }

    &:before {
      position: absolute;
      content: '';
      top: -19px;
      left: -25px;
      width: 276px;
      height: 1px;
      background: @Neutral200;
    }
  }

  .remember-password {
    margin-top: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: @Neutral600;

    a {
      color: @bluePrimary;
      transition: all .3s ease;

      &:hover, &:focus, &:active {
        color: @blueDepressed;
      }
    }
  }
}
