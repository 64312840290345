@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';
@import '../../../styles/utils/variables';

.droptimes-admin-page {
  padding-top: 24px;
  padding-bottom: 65px;

  .drop-timer-list {
    max-width: 1040px;
    margin: 0 auto;
    .drop-card {
      width: 512px;
      height: 279px;
      border: 1px solid #f2f3f8;
      border-radius: @border-radius;
      display: flex;
      overflow: hidden;
      .drop-card-timer {
        padding: 24px;
        width: 303px;
        .drop-card-title {
          font-size: 26px;
          line-height: 40px;
          color: #151E3A;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
        }
        .timer-block-next {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
          margin-bottom: 64px;
          > div {
            > div:first-child {
              font-family: 'Inter', sans-serif;
              font-weight: 500;
              margin-bottom: 4px;
              font-size: 14px;
              line-height: 20px;
              color: @Neutral500;
            }
            > div:last-child {
              font-family: 'Inter', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              color: @Neutral800;
            }
          }
        }
        .timer-block {
          margin-top: auto;
          margin-bottom: 0;
          width: 100%;
          .timer-animation {
            width: 100%;
            height: auto;
            display: block;
            margin-top: 0;
            .timer-animation--clock {
              width: 100%;
              position: relative;
              overflow: hidden;
              display: flex;
              justify-content: space-between;
              &.random {
              }
            }
          }
        }
        .drop-timer-wrapper.disabled {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .drop-timer-box {
            .ant-statistic {
              border: 1px solid;
              background: @Neutral100;
              border-color: @Neutral300;
              width: 58px;
              height: 64px;
              border-radius: @border-radius;
              .ant-statistic-title {
                color: @Neutral300;
                margin-top: 7px;
                margin-bottom: 3px;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
              }
              .ant-statistic-content {
                color: #C5CCE3;
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 22px;
                line-height: 32px;
                text-align: center;
                margin-bottom: 4px;
                .ant-statistic-content-value {
                  color: #C5CCE3;
                  font-family: 'Inter', sans-serif;
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 32px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .drop-card-category {
        width: 206px;
        min-width: 206px;
        margin-left: auto;
        padding: 24px;
        .drop-card-image {
          background-color: @white;
          width: 158px;
          height: 158px;
          border-radius: 50%;
          border: 1px solid #f2f3f8;
          padding: 31px;
          img {
            width: 100%;
          }
        }
        .drop-card-button {
          margin: 24px auto 0;
          padding: 16px;
          text-align: center;
          width: 159px;
          height: 48px;
          border: 1px solid #f2f3f8;
          border-radius: @border-radius;
          background-color: @white;
          font-size: 14px;
          line-height: 16px;
          color: #151E3A;
          display: block;
        }
      }
      &.random:not(.empty) {
        .drop-card-category {
          background-color: @blue100;
          .drop-card-button {
            overflow: hidden;
            border-color: transparent;
            background-image: url('../../Images/gradient_button.png');
            background-size: inherit;
            background-repeat: no-repeat;
            background-position: center;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: @border-radius;
            span {
              display: block;
              width: 157px;
              height: 46px;
              line-height: 46px;
              background-color: @white;
            }
          }
        }
      }
      &.theme-red:not(.empty) {
        .drop-card-category {
          background-color:@red100;
          .drop-card-button {
            border-color:  @red500;
          }
        }
      }
      &.theme-yellow:not(.empty) {
        .drop-card-category {
          background-color: @yellow100;
          .drop-card-button {
            border-color: @yellow500;
          }
        }
      }
      &.theme-pink:not(.empty) {
        .drop-card-category {
          background-color: @pink100;
          .drop-card-button {
            border-color: @pink500;
          }
        }
      }
      &.theme-purple:not(.empty) {
        .drop-card-category {
          background-color: @purple100;
          .drop-card-button {
            border-color: @purple500;
          }
        }
      }
      &.theme-green:not(.empty) {
        .drop-card-category {
          background-color: @green100;
          .drop-card-button {
            border-color: @green500;
          }
        }
      }
      &.theme-brown:not(.empty) {
        .drop-card-category {
          background-color: @brown100;
          .drop-card-button {
            border-color: @brown500;
          }
        }
      }
      &.empty {
        .drop-card-timer {
          .drop-card-title {
            color: @Neutral300;
          }
        }
        .drop-card-category {
          background-color: @Neutral100;
          .drop-card-button {
            color: @Neutral300;
          }
        }
      }
    }
  }
}
.drop-time-modal {
  
  .ant-modal-content {
    padding: 20px !important;
  }
  &.timer {
    position: relative;
    &::before {
      position: absolute;
      top: 185px;
      left: 0;
      content: '';
      height: 1px;
      width: 100%;
      background-color: @Neutral100;
      z-index: 5;
    }
    &::after {
      position: absolute;
      bottom: 75px;
      left: 0;
      content: '';
      height: 1px;
      width: 100%;
      background-color: @Neutral100;
      z-index: 5;
    }
  }

  .ant-modal-body {
    padding-top: 24px !important;
  }
  .drop-modal-wrapper {
    display: flex;
    flex-direction: column;

    .drop-modal-info {
      display: flex;
      padding-bottom: 20px;
      .drop-modal-image {
        width: 96px;
        height: 96px;
        margin-right: 24px;
        img {
          width: 100%;
        }
      }
      .modal-title-wrapper {
        .modal-title {
          font-size: 18px;
          line-height: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          margin-bottom: 16px;
          &.random {
            color: @blue500;
          }
          &.theme-red  {
            color: @red500;
          }
          &.theme-yellow {
            color: @yellow500;
          }
          &.theme-pink {
            color: @pink500;
          }
          &.theme-green {
            color: @green500;
          }
          &.theme-brown {
            color: @brown500;
          }
          &.theme-purple {
            color: @purple500;
          }
        }
        .timer-block-next {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 300px;
          > div {
            &:first-child {
              margin-right: 32px;
            }
            > div:first-child {
              font-family: 'Inter', sans-serif;
              font-weight: 500;
              margin-bottom: 12px;
              font-size: 14px;
              line-height: 20px;
              color: @Neutral500;
            }
            > div:last-child {
              font-family: 'Inter', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              color: @Neutral800;
            }
          }
        }
      }
    }
    .drop-modal-timer {
      padding-top: 24px;
      padding-bottom: 14px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .timer-col {
        width: 97px;
        &:first-child {
          width: 124px;
        }
        &:last-child {
          width: 98px;
        }
        .timer-text {
          font-size: 14px;
          line-height: 20px;
          color: #6e7dae;
          margin-bottom: 4px;
        }
        .timer-count {
          &.ant-input-number-focused {
            box-shadow: none;
            border-color: @blue500;
          }
          &:focus {
            box-shadow: none;
          }
          display: inline-block;
          width: 100%;
          border: 1px solid #f2f3f8;
          height: 48px;
          border-radius: @border-radius;
          .ant-input-number-handler-wrap {
            opacity: 1 !important;
            width: 32px;
            height: 70% !important;
            top: 7px !important;
            border-left: 1px solid #f2f3f8;
            padding-left: 6px;
            padding-right: 6px;

            .ant-input-number-handler-down {
              border-top: none;
            }
            .ant-input-number-handler-up {
              border-bottom: 1px solid #f2f3f8;
              .anticon {
                margin-top: -7px;
              }
            }

            .ant-input-number-handler-up,
            .ant-input-number-handler-down {
              height: 50% !important;
              border-left: none;

              .anticon {
                width: 12px;

                svg {
                  fill: @Neutral500 !important;
                }
              }
              &:active {
                background-color: transparent;
              }
              &:hover {
                height: 50% !important;
                .anticon {
                  svg {
                    fill: @blue500 !important;
                  }
                }
              }
            }
          }
          .ant-input-number-input-wrap {
            line-height: 48px;
            display: inline-block;
            .ant-input-number-input {
              color: @Neutral800;
              height: 48px;
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    .double-btn-wrapper {
      .double-btn-block {
        .btn-primary:not(.border-only) {
          margin-left: 14px;
        }
        button[disabled] {
          font-size: 14px;
          color: @Neutral300;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
        }
      }
    }
  }
}
