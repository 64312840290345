@import '../../../styles/theme/theme';
@import "../../../styles/utils/variables";

.plan-card-onboarding {
  font-family: "Inter",sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  border: 1px solid @Neutral200;
  padding: 24px;
  width: calc((100% - 64px) / 3);
  height: 682px;

  .with-theme({
    background: @vars[white-to-neutral-dark-50];
    border: 1px solid @vars[neutral-200-to-neutral-dark-50] !important;
  });

  @media @viewport-mobile {
    width: 100%;
    height: unset;
    padding: 16px;
  }

  &-img {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid @Neutral900;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), @Neutral800;
    box-shadow: 0px 16px 16px -8px rgba(21, 30, 58, 0.04), 0px 8px 8px -4px rgba(21, 30, 58, 0.04), 0px 4px 4px -2px rgba(21, 30, 58, 0.04), 0px 2px 2px -1px rgba(21, 30, 58, 0.04), 0px 0.667px 0.667px -0.333px rgba(21, 30, 58, 0.04), 0px 0px 0px 0.667px rgba(21, 30, 58, 0.04);

    &-wrap {
      display: flex;
      width: 48px;
      height: 48px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
      });
    }
  }

  &-title-wrap {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .plan-card-onboarding-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: @Neutral800;
      letter-spacing: -0.5px;
      text-transform: capitalize;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    .plan-card-onboarding-subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }
  &-btn-wrapper{
    position: relative;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &-price-wrap {
    display: flex;
    align-items: center;
    padding-bottom: 22px;

    .plan-card-onboarding-price {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -1.2px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
      margin-left: 8px;
    }

    div:not(.ant-divider) {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      color:@Neutral400;
      margin-top: -2px;
    }
  }

  .btn-primary {
    width: 100%;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 6px 12px !important;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    border: none !important;
    .with-theme({
      background: @vars[white-to-neutral-dark-100];
      box-shadow: @vars[box-shadow-btn-secondary];
      color: @vars[neutral-800-to-100] !important;

      &:hover, &:focus, &:active {
        background: @vars[neutral-50-to-neutral-dark-100];
        color: @vars[neutral-800-to-100];
      }
    });

    &.disabled{
      cursor: not-allowed!important;
      pointer-events: none;
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100] !important;
        color: @vars[neutral-300-to-neutral-dark-300] !important;
        box-shadow: none !important;
      });
    }
    &.border-only.three-dots{
      min-width: unset;
        .with-theme({
          background: @vars[white-to-neutral-dark-50] !important;
          box-shadow: @vars[box-shadow-btn-secondary] !important;
        });
    }

    &.skip-trial{
      background: @bluePrimary !important;
      box-shadow: 0px -1px 2px 0px rgba(20, 72, 203, 0.48) inset, 0px 0px 0px 1px rgba(34, 90, 234, 0.16), 0px 8px 16px -8px rgba(34, 90, 234, 0.64), 0px 4px 4px 0px rgba(0, 0, 0, 0.06);      color:@white !important;

      &:hover, &:focus, &:active {
        background: @blueDepressed !important;
      }
    }
  }

  &-description {
    margin-top: 24px;
    @media @viewport-mobile {
      width: 100%;
    }

    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      margin-bottom: 0;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500] !important;
      });
    }

    .info-wrp {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid @Neutral100 !important;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-100-to-neutral-dark-100]!important;
        &:last-of-type {
          border-bottom: none !important;
        }
      });

      &:last-child {
        border-bottom: none !important;
      }

      svg {
        margin-right: 6px;
      }

      .info-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: -0.3px;
        white-space: nowrap;
          @media @viewport-mobile {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 120px;
          }
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });

        &.value {
          text-decoration: underline;
          text-decoration-style: dashed;
          text-decoration-color: @Neutral600;
          font-weight: 500;
          margin-right: 6px;
          color:  @Neutral800;
          @media @viewport-mobile {
            overflow: unset;
            text-overflow: unset;
            max-width: 170px;
          }
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }
      }
    }
  }
  .btn-primary{
    &.border-only{
      .with-theme({
      &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      });
      &.scheduled{
        color: @orangePrimary !important;
        box-shadow: unset !important;
        .with-theme({
          background: @vars[white-to-neutral-dark-50] !important;
          border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
        });

        span{
          display:flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }


  &.smallHeight{
    padding: 0;
    border: unset !important;
    width: auto;
    min-width: 180px;
    height: auto;
    align-items: flex-start;
    background: transparent;

      .plan-card-onboarding-title {
        font-size: 16px;
      }
      .plan-card-onboarding-price-wrap{
        display: flex;
        align-items: center;
        gap: 4px;
        .plan-card-onboarding-price {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }
        span{
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.3px;
          margin-left: unset;
        }
      }
      .plan-card-onboarding-type{
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.3px;
        margin-left: unset;
        padding-bottom: 22px;
        margin-top: -22px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }
    }
  &.trending {
    border: 1px solid rgba(34, 90, 234, 0.55);

    .plan-card-onboarding-img {
      border-radius: 8px;
      border: 1px solid @blueDepressed;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), @bluePrimary;
      box-shadow: 0px 16px 16px -8px rgba(7, 47, 148, 0.04), 0px 8px 8px -4px rgba(7, 47, 148, 0.04), 0px 4px 4px -2px rgba(7, 47, 148, 0.04), 0px 2px 2px -1px rgba(7, 47, 148, 0.04), 0px 0.667px 0.667px -0.333px rgba(70, 13, 144, 0.04), 0px 0px 0px 0.667px rgba(7, 47, 148, 0.04);

      &-wrap {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        .with-theme({
          background: @vars[white-to-neutral-dark-50];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
        });
      }
    }

    .btn-primary {
      background: @bluePrimary !important;
      box-shadow: 0px -1px 2px 0px rgba(20, 72, 203, 0.48) inset, 0px 0px 0px 1px rgba(34, 90, 234, 0.16), 0px 8px 16px -8px rgba(34, 90, 234, 0.64), 0px 4px 4px 0px rgba(0, 0, 0, 0.06);      color:@white !important;

      &:hover, &:focus, &:active {
        background: @blueDepressed !important;
      }
    }

    .btn-primary.border-only{
      &.disabled{
        color: @Neutral500 !important;
        cursor: not-allowed!important;
        pointer-events: none;
        .with-theme({
          background: @vars[neutral-100-to-800] !important;
          border: 1px solid @vars[neutral-100-to-800] !important;
          box-shadow: unset !important;
        });
      }
    }

    .trending-tag {
      position: absolute;
      top: 16px;
      right: 16px;
      padding: 4px 6px;
      border-radius: @border-radius;
      background: @blueLight;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;

      .with-theme({
        color: @vars[blue-depressed-to-blue-primary];
        background: @vars[blue-light-to-blue-dark];
      });

    }

    .plan-card-onboarding-description {

      .info-wrp {

        svg {

          path {
            stroke: @bluePrimary;
          }
        }
      }
    }
  }
}
