@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.cascading-wrapper {
  font-family: 'Inter', sans-serif;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  height: 40px;

  @media @viewport-mobile {
    width: 100%;
  }

  .with-theme({
    background-color: @vars[white-to-neutral-dark-50];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    box-shadow: @vars[box-shadow-btn-secondary-without-border];
  });

  &.disabled {
    cursor: not-allowed;

    .cascading-dropdown-value-selected {
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });
    }

    .with-theme({
      background: @vars[neutral-50-to-neutral-dark-100];
    });
  }

  & * {
    transition: all 0.3s ease;
  }
  
  &:has(.ant-dropdown-open) {
    .with-theme({
      box-shadow: @vars[box-shadow-btn-secondary-without-border];
      background: @vars[neutral-50-to-neutral-dark-100];
    });
  }

  .cascading-dropdown {
    max-width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    
    &.presents {
      margin-right: 12px;
    }

    &-wrapper {
      z-index: 1100 !important;
      box-shadow: 0 8px 40px 0 rgba(112, 123, 160, 0.1);
      border-radius: 10px;

      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background-color: @vars[white-to-neutral-dark-50];
      });

      @media @viewport-mobile {
        height: 216px !important;
      }
    }

    &-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: capitalize;
      position: relative;

      &-checked {
        background: @bluePrimary;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        right: -40px;
      }

      &-selected {
        display: flex;
        align-items: center;
        padding: 4px 6px;
        border-radius: 4px;
        margin-left: 8px;

        svg {
          margin-right: 4px;
          width: 20px;
          width: 20px;
        }

        .with-theme({
          background-color: @vars[neutral-100-to-neutral-dark-200];
        });
      }

      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }

    &-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-wrapper {
        padding: 8px;
        height: 100%;
        width: 215px;

        &.presets-menu-wrapper {
          width: 268px;
        }
      }

      &-item {
        width: 100%;
        height: 40px;
        border-radius: @border-radius;
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 12px;
        margin-bottom: 4px;

        &.disabled {
          cursor: not-allowed;

          .with-theme({
            background: @vars[neutral-100-to-neutral-dark-100] !important;
            span {
              color: @vars[neutral-300-to-neutral-dark-300] !important;
            }
            svg path {
              stroke: @vars[neutral-300-to-neutral-dark-300];
            }
          })
          // .with-theme({
          //   color: @vars[neutral-200-to-800];
          // });
        }

        span {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          gap: 6px;

          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }

        &--advanced {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          background: transparent;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 12px;
          position: relative;
          margin-bottom: 4px;

          &:has(.ant-dropdown-open-inner) {
            .with-theme({
              background-color: @vars[neutral-100-to-neutral-dark-200];
            });

            .cascading-dropdown-inner > svg {
              transform: rotateZ(90deg); 
            }
          }

          .cascading-dropdown-inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > svg {
              transform: rotateZ(-90deg);

              path {
                .with-theme({
                  stroke: @vars[neutral-500-to-neutral-dark-500];
                })
              }
            }

            span {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;

              .with-theme({
                color: @vars[neutral-800-to-200];
              });
            }
          }

          &:hover {
            .with-theme({
              border-color: @bluePrimary;
            });
          }

          .cascading-dropdown-wrapper-inner {
            width: 165px !important;
            height: 318px !important;
            border-radius: 10px;
            box-shadow: 0 8px 40px 0 rgba(112, 123, 160, 0.1);
            left: (calc(-100% + 10px)) !important;
            top: -9px !important;
            
            .with-theme({
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
              background-color: @vars[white-to-neutral-dark-50];
            });

            .cascading-dropdown-menu-wrapper-inner {
              padding: 8px;
              height: 100%;

              .cascading-dropdown-menu-inner {
                display: flex;
                flex-direction: column;
                
                .cascading-dropdown-menu-item-inner {
                  text-transform: capitalize;
                  gap: 4px;
                }
              }
            }
          }
        }

        &:not(.cascading-dropdown-menu-item--active):hover {
          .with-theme({
            background-color: @vars[neutral-100-to-neutral-dark-200];
          });
        }

        &--active {
          .with-theme({
            background-color: @vars[neutral-100-to-neutral-dark-200];
          });
        }
      }
    }
  }
}
