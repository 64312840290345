@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.store-tracker-app-card {
  position: relative;
  width: calc((100% - 48px) / 4);
  max-width: 264px;
  height: 188px;
  border-radius: 16px;
  padding: 16px;
  .with-theme({
    background: @vars[white-to-neutral-dark-50];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    width: 100%;
    min-width: 100%;
    max-width: unset;
    scroll-snap-align: start;
  }

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .image {
      width: 40px;
      height: 40px;
      border-radius: 8px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
      }
    }

    .store-tracker-app-card-btn {
      position: relative;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 6px 8px;
      cursor: pointer;
      transition: all .3s ease;
      border: none !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100] !important;
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      });
    }
  }

  &-title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    text-align: left;
    .with-theme({
      color: @vars[neutral-800-to-100] !important;
    });
  }

  &-description {
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }

  &-footer {
    position: absolute;
    left: 16px;
    bottom: 16px;
    display: flex;
    width: 100%;
    margin-top: auto;
    align-items: center;
    gap: 8px;

    .rating {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];;
      })
    }

    .reviews {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];

        svg path {
          stroke: @vars[neutral-400-to-neutral-dark-400];
        }
      });
    }
  }
}
