@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.faq-wrapper {
  margin: 64px auto 0;
  width: 1140px;

  @media @viewport-mobile {
    margin: 48px auto 0;
    width: 100%;
    padding: 0 15px;
  }

  .faq-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 24px;
    }

    h4 {
      text-align: left;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: @dark;
      margin-bottom: 0;

      @media @viewport-mobile {
        width: 100%;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .faq-btn {
      border-radius: 12px;
      padding: 12px 24px;
      cursor: pointer;
      background: @bluePrimary;
      transition: all .3s ease;

      @media @viewport-mobile {
        width: 100%;
        text-align: center;
      }

      &:hover {
        background: @blueDepressed;
      }

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: @white;
      }
    }
  }

  .faq-content {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @media @viewport-mobile {
      margin-top: 24px;
      gap: 16px;
    }

    .ant-collapse {
      width: 100%;
      border: none;
      border-radius: 0;
      background: transparent;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media @viewport-mobile {
        gap: 16px;
      }

      &-item {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: @dark;
        padding: 24px;
        border-radius: 16px;
        border: 1px solid @Neutral100;

        @media @viewport-mobile {
          padding: 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }

      &-content {
        border: none;
      }

      &-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 0;
        padding-inline-start: 0 !important;

        @media @viewport-mobile {
          align-items: flex-start;
        }

        .ant-collapse-expand-icon {
          margin-left: 0 !important;
          padding-left: 0 !important;
        }

        &-text {
          width: 100%;

          .faq-item-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media @viewport-mobile {
              max-width: 92%;
              word-break: break-word;
            }
          }
        }
      }

      .ant-collapse-content-box {
        padding: 12px 0 0 !important;

        .faq-item-text {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: @Neutral500;

          @media @viewport-mobile {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
}
