@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.main-sidebar {
  // height: 100%;

  &_shadow {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    transition: all .3s ease;
    z-index: -1;

    &.active {
      background: rgba(0,0,0,.45);
      z-index: 35;
    }
  }

  &_wrap {
    padding: 24px 16px 0;

    @media @viewport-mobile {
      padding: 16px 16px 0;

      &:has(.main-sidebar-header-settings) {
        padding: 0;
      }
    }
  }

  .sidebar-wrap-links {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media @viewport-mobile {
    &.closed-sidebar {
      width: 0 !important;
      overflow: hidden !important;
    }

    &.opened-sidebar {
      width: calc(100vw - 48px) !important;
      overflow-y: auto !important;
      min-height: unset !important;
      height: 100dvh !important;
      .custom-scroll-bar();
      overscroll-behavior: contain;

      &:has(.main-sidebar-header-settings) {
        width: 100vw !important;
      }
    }
  }

  &.big-sidebar {
    width: 272px;
  }

  & > * {
    position: relative;
    left: 0;
    transition: all .3s ease;
    height: 100%;
  }

  &.transition > * {
    left: -200%;
  }

  width: 72px;
  transition: width .3s ease;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  min-height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  .with-theme({
    background: @vars[blue-xlight-to-neutral-dark];
    border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &.big-sidebar {

    .main-sidebar-header {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      & > svg {
        flex-shrink: 0;
      }
    }
  }

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 17px;
    margin-bottom: 24px;

    @media @viewport-mobile {
      margin-bottom: 14px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0;
      height: 40px;
    }

    &-settings {
      display: flex;
      padding: 0 8px;
      margin-bottom: 16px;
      align-items: flex-start;
      gap: 8px;
      cursor: pointer;

      @media @viewport-mobile {
        align-items: center;
        margin-bottom: 0;
        padding: 16px;
      }

      svg {
        transform: rotate(90deg);
        margin-top: 2px;

        .with-theme({
          path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        })
      }

      span {
        white-space: nowrap;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.5px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }

    &-btn {
      cursor: pointer;
      transition: all .3s ease;

      path {
        transition: all .3s ease;
      }

      &:hover {
        path {
          .with-theme({
            stroke: @vars[blue-primary-to-white];
          })
        }
      }
    }
  }

  .ant-collapse-header {
    padding: 0 !important;

    &-text {
      width: 100%;
      margin: 0;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  &.big-sidebar {

    .sidebar-link {
      padding: 10px 12px;

      &_icon {
        margin-right: 8px;
      }
    }
  }

  .sidebar-link {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    transition: all .3s ease;
    border-radius: 10px;
    position: relative;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    &-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      white-space: nowrap;
      flex-shrink: 0;
      max-width: 190px;
      text-overflow: ellipsis;
      overflow: hidden;
      @media @viewport-mobile {
        max-width: 100%;
      }

      &.overflowing {
        &:before {
          position: absolute;
          left: 25px;
          z-index: 10;
          top: 45px;
          width: max-content;
          max-width: 300px;
          white-space: break-spaces;
          border-radius: 6px;
          padding: 10px 12px;
          font-size: 12px;
          line-height: 16px;
          background: @Neutral800;
          color: @white;
          text-align: left;
          content: attr(data-text);

          @media @viewport-mobile {
            max-width: 200px;
          }
        }
      }
    }

    & > svg {
      flex-shrink: 0;
    }

    &:not(.active):hover {
      .with-theme({
        background: @vars[blue-100-to-neutral-dark-100];
      })
    }

    &.active, &.sidebar-dropdown-link-main-selected {
      .with-theme({
        color: @vars[neutral-800-to-100];
      })
    }

    &_icon {
      margin-right: 0;

      @media @viewport-mobile {
        margin-right: 8px;
      }
    }

    &_text {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      white-space: nowrap;

      svg {
        transition: all .3s ease;
        .with-theme({
          path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        })
      }
    }

    &.sidebar-dropdown-link {
      margin-left: 28px;
      width: unset !important;
    }

    &.sidebar-dropdown-link.active {

      .sidebar-link_text {
        .with-theme({
          color: @vars[blue-primary-to-white];
        })
      }
    }

    &.active {

      &:not(.sidebar-dropdown-link-main) {
        .with-theme({
          background: @vars[white-to-neutral-dark-100];
          box-shadow: @vars[box-shadow-active-menu];
        })
      }
    }

    &.sidebar-dropdown-link-main-selected {

      .sidebar-link_text {
        &:not(.setting) {

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &.link-logout {

      svg path {
        .with-theme({
          stroke: @vars[neutral-400-to-neutral-dark-400];
        });
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  &:has(.main-sidebar-header-settings) {

    @media @viewport-mobile {
      .sidebar-link {
        width: calc(100% - 32px);
        margin: 0 auto 12px;
        .with-theme({
          box-shadow: none !important;
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        &-text {
          width: calc(100% - 28px);
          display: flex;
          justify-content: space-between;

          svg {
            .with-theme({
              path {
                stroke: @vars[neutral-600-to-neutral-dark-500];
              }
            })
          }
        }

        &:last-child {
          margin: 0 auto;
        }
      }
    }
  }

  .chrome-extension-link {

    &-wrapper {
      flex-shrink: 0;
      width: 100%;
      min-width: 239px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 12px;
      padding: 8px 12px;
      cursor: pointer;
      transition: all .3s ease;
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[dark-dropshadow-neutral];
      });

      &:hover {
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-100];
        })
      }
    }

    &-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
      text-transform: capitalize;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &-wrapper {
        font-family: 'Inter', sans-serif;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
      }
    }

    &-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      })
    }

    &-icon {
      width: 46px;
      height: 34px;

      div {
        background-position: unset !important;
      }
    }
  }

  &_bottom {
    margin-top: auto;
    padding: 0 16px 16px;
    @media screen and (max-height: 600px) {
      margin-top: 100px;
    }
    @media @viewport-mobile {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
      padding: 0 16px 16px;
    }

    &.logout{
      @media @viewport-mobile {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 0 0 16px;
      }
    }
  }

  .main-sidebar-free-button {
    width: 100%;
    transition: all .3s ease;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: none;
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];
      color: @vars[neutral-800-to-100];
    });

    &:hover, &:focus, &:active {
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-100];
      })
    }
  }
}
