@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.overview-advertiser-slider {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    padding: 8px 16px;
  }

  &-title {
    margin-right: 16px;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  &-items {
    scroll-snap-type: x mandatory;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &::-webkit-scrollbar-track-piece:start {
      display: none;
    }
    &::-webkit-scrollbar-track-piece:end {
      display: none;
    }
  }

  &-item {
    scroll-snap-align: end;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 6px 8px;
    gap: 12px;
    border-radius: 8px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark-50];
    });
    
    &:hover {
      cursor: pointer;  
    }

    &-image {
      display: flex;
      align-items: flex-end;
      position: relative;
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        border-radius: 25%;
      }
  
      &-checkmark {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        right: -7px;
        bottom: -5px;
        border-radius: 50%;

        .with-theme({
          background: @vars[white-to-neutral-dark-50];
        });
      }
    }


    &-name {
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    &-total_ads {
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }

  &-arrows {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    overflow: visible;
    height: 32px;

    &:before {
      content: '';
      width: 100px;
      height: 100%;
      left: -100px;
      top: 0;
      position: absolute;
      z-index: 2;
      .with-theme({
        background: @vars[right-gradient-bg];
      });
    }
  }

  &-arrow {
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.45;
    }

    &.prev {
      transform: rotate(180deg);
    }
  }
}
