@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.admin-store-tracket-page {
  .admin-store-tracket-page-header {
    .custom-breadcrumbs {
      border: none;
    }

    .expandable-chart-info {
      padding: 24px 32px;
    }

    .with-theme ({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
    })
  }


  .report-main-block {
    border: none !important;
    margin: 0;
    padding: 24px 32px;

    .download-report {
      border-radius: 10px;
    }
  }

  .chart-block {
    margin: 0px;
  }
}

.admin-store-table {
  min-height: 100dvh;

  .product-main-block_wrapper {
    padding-bottom: 24px;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark];
    });
  }

  .ant-table-sticky-scroll {
    display: none;
  }

  .product-database-table-header {
    margin-top: 8px;
  }

  .product-actions > span {
    cursor: pointer;

    &:hover {
      svg path {
        fill: @bluePrimary;
      }
    }
  }

  .sales-tracker-stores-table-wrapper .sales-tracker-stores-table-body .product-database-table-cell.cell-actions .product-actions:after {
    bottom: -32px;
  }

  .sales-tracker-search-wrapper {
    width: 100%;
  }

  .input-filter-wrapper{
    .input-product-filter-wrapper{
      &:last-of-type{
        .input-product-filter-list{
          margin-bottom: 0;
        }
      }
    }
  }

  .selects-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 24px;
  }

  .switch-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .switch-button-wrapper {
    display: flex;
    justify-content: space-between;

    & > button {
      border-radius: 10px;
    }

    .switch-button-reset {
      background: transparent;
      transition: all .3s ease;
      padding: 12px 19px;
      height: 40px;
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
        color: @vars[neutral-800-to-200];
      });

      &:not([disabled]):hover {
        border-color: @bluePrimary;
        color: @bluePrimary;
      }
    }

    .switch-button-apply {
      margin-left: 16px;
      background: @bluePrimary;
      color: @white !important;
      border: none !important;

      &[disabled] {
        color: @Neutral500 !important;
        border: 1px solid transparent;
        .with-theme({
          background: @vars[neutral-100-to-800] !important;
        })
      }

      &:hover {
        background: @blueDepressed;
      }
    }
  }

  .switch-options-wrapper {
    display: flex;

    .switch-option {
      display: flex;
      align-items: center;
      margin-right: 16px;

      span {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        .with-theme({
          color: @vars[neutral-800-to-200];
        })
      }
    }

    .ant-switch {
      width: 32px;
      min-width: unset;
      height: 20px;
      border-radius: 50px;
      margin-right: 12px;
      .with-theme({
        background: @vars[neutral-200-to-700];
      });

      &:focus, &:hover {
        outline: none;
        box-shadow: none;
      }

      &-handle {
        width: 16px;
        height: 16px;
        background: @white;
        box-shadow: none;
        border-radius: 50%;
      }

      &.ant-switch-checked {
        background: @bluePrimary;

        .ant-switch-handle {
          left: calc(100% - 18px);
        }
      }
    }

  }

  .ant-collapse.ant-collapse-icon-position-start.ant-collapse-ghost {
    width: 100%;
  }

  .ant-collapse-header-text, .ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost {
    width: 100%;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse.ant-collapse-ghost {
    .ant-collapse-item {
      &.ant-collapse-no-arrow > .ant-collapse-header{
        padding: 24px 16px;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}
