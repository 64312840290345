@import '../../../styles/theme/theme';

.shopify_app-page{
  height: 100dvh;
  padding: 24px 64px;
  max-width: 1088px;

  display: flex;
  align-items: center;

  margin: 0 auto !important;

  .ant-spin-nested-loading{
    height: auto;
  }

  .top-line{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;

    .ant-btn{
      margin-left: 32px;
    }
  }

  h2.title{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }

  .welcome-block{
    justify-content: space-between;

    .info-block-wrapper{
      width: auto;
    }

    .divider{
      margin: 0 24px;
    }
  }

  @media @viewport-mobile {
    padding: 20px;
    align-items: flex-start;

    .top-line{
      display: block;

      .ant-btn{
        width: 100%;
        margin: 48px 0 0;
      }
    }

    .welcome-block{
      margin: 0;

      .info-block-wrapper{
        width: 100%;
        margin: 0;
      }
    }
  }
}

.ant-modal.custom-modal.shopify-connect {

  @media screen and (max-height: 600px) {

    .ant-modal-body {
      height: unset !important;
    }

    .custom-modal-btn-wrapper {
      position: relative !important;
    }
  }

  @media @viewport-mobile {
    border-radius: 0 !important;
    &.modal-without-border{
      .ant-modal-content{
        border-radius: 0 !important;
      }
    }
    &.modal-autoheight {
      .ant-modal-content {
        height: unset;
      }
    }
    .ant-modal-content {
      overflow-y: auto;
      height: 100dvh;



      .ant-modal-header {
        padding: 16px 16px 0;
        border-radius: 0;

        .custom-modal-title {
          padding: 0;
        }
      }

      .ant-modal-body {
        max-height: unset !important;
        height: calc(100% - 44px);
      }

      .shopify-connect-new-options-wrapper:not(.shopify-connect-niches-wrapper) {
        gap: 0;
        padding: 28px 16px 0;

        .shopify-connect-new-option {
          padding: 0;
          box-shadow: none !important;
          border-radius: 0;
          gap: 8px;
          background: transparent !important;

          &-text {
            max-width: calc(100% - 70px);
          }

          h4 {
            font-size: 14px;
          }

          &:first-of-type {
            padding-bottom: 16px;
          }

          &:last-of-type {
            padding-top: 16px;
          }

          &:nth-of-type(2) {
            padding: 16px 0;
            .with-theme({
              border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
              border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
            })
          }
        }
      }

      .shopify-connect-niches-wrapper {
        padding: 28px 16px 0;

        .shopify-connect-new-option {
          height: 40px;
          padding: 0;
          box-shadow: none !important;
          border: none !important;
          background: transparent !important;
        }
      }

      .custom-modal-btn-wrapper {
        position: absolute;
        bottom: unset;
        justify-content: center;
        padding: 16px 8px 16px 0;
        margin-top: auto;
        .with-theme({
          border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        button:not(.no-border):not(.wide) {
          width: calc(50% - 22px);
        }

        .wide {
          width: calc(100% - 32px);
        }

        &.second{
          bottom: 0 !important;
        }
      }
    }
  }

  .shopify-connect-new-options-wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .shopify-connect-new-option {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      gap: 16px;
      padding: 16px;
      border-radius: 16px;
      cursor: pointer;
      transition: all .3s ease;
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus, &:active {
          background: @vars[neutral-50-to-neutral-dark-100];
          color: @vars[neutral-800-to-100];
        }
      });

      &-text {
        font-family: 'Inter', sans-serif;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
        max-width: 310px;

        h4 {
          margin-bottom: 0;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

      }

      &-check {
        margin-left: auto;
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        .with-theme({
          background: @vars[white-to-neutral-dark-50];
          box-shadow: @vars[box-shadow-btn-secondary];
        });
      }

      &--selected {

        .shopify-connect-new-option-check {
          background: @bluePrimary;

          &:after {
            position: absolute;
            content: '';
            left: 5px;
            top: 5px;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            z-index: 2;
            .with-theme({
              background: @vars[white-to-neutral-dark-50];
            });
          }
        }
      }
    }
  }

  .shopify-connect-new-spinner-wrapper {
    height: 336px;
  }

  .shopify-connect-new-result {

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-title {
        margin-top: 24px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        gap: 12px;

        @media @viewport-mobile {
          flex-direction: column;
          margin-top: 28px;
          align-items: flex-start;
        }

        span {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          width: calc(100% - 40px);
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }

        &-number {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @white;
          background: @bluePrimary;
          border-radius: 8px;
        }
      }

      &-data {
        padding: 16px;
        width: 100%;

        &-credentials {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;

          &-item {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;

            &-label {
              width: 72px;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 19.6px;
              letter-spacing: -0.3px;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              })
            }

            &-value {
              width: calc(100% - 84px);
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 19.6px;
              letter-spacing: -0.3px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              @media @viewport-mobile {
                flex-wrap: nowrap;
                text-wrap: nowrap;
              }
              .with-theme({
                color: @vars[neutral-800-to-100];
              });
              &-email,&-password{
                @media @viewport-mobile {
                  width: 120px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              span {
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: nowrap;
                cursor: pointer;
                color: @bluePrimary;

                &:hover svg path {
                  stroke: @blueDepressed;
                }
              }
            }

            &:last-of-type {

              .shopify-connect-new-result-info-data-credentials-item-value {
                color: @Neutral500 !important;
                text-decoration: underline;
                gap: 4px;
              }
            }
          }
        }

        &-list {
          display: flex;
          flex-direction: column;
          gap: 12px;
          align-items: flex-start;

          &-note {
            margin-top: -8px;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            });

            span {
              .with-theme({
                color: @vars[neutral-800-to-100];
              });
            }
          }

          &-item {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            });

            span {
              .with-theme({
                color: @vars[neutral-800-to-100];
              });
            }
          }
        }

        &-warning {
          margin-top: 16px;
          display: flex;
          padding: 8px 12px;
          align-items: flex-start;
          gap: 12px;
          border-radius: 10px;
          .with-theme({
            background: @vars[yellow-to-neutral-dark-100];
          });

          span {
            width: calc(100% - 36px);
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            })
          }
        }
      }
    }
  }

  .ant-spin-container{
    @media @viewport-mobile {
      overflow-y: scroll;
    }
  }

  .custom-modal-text {
    margin-top: 0;
  }
}

.ant-modal.custom-modal.autods-import-unavailable {
  .ant-modal-header{
    padding: 24px 24px 0;

    .ant-modal-title {
      .with-theme({
        color: @vars[neutral-800-to-200] !important;
      });
    }
  }

  .ant-modal-body p {
    padding: 0 24px 24px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500] !important;
    });
  }
}

.ant-modal.custom-modal.autods-connect {
  font-family: 'Inter', sans-serif;

  @media @viewport-mobile {
    border-radius: 24px 24px 0 0;

    .ant-modal-header {
      padding: 0 !important;
      margin: 0 !important;
    }

    .custom-modal-btn-wrapper {
      justify-content: center;

      button {
        width: calc(50% - 6px);
      }
    }
  }

  .ant-modal-title {
    padding: 24px 32px 16px;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  .ant-modal-header {
    margin-bottom: 16px;
  }

  .ant-modal-close {
    display: none;
  }

  .custom-modal-text {
    text-align: center;
    margin-top: 24px;
    padding: 0 32px;

    @media @viewport-mobile {
      margin-top: 16px;
    }
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .left {
      height: 1px;
      width: 133px;
      .with-theme({
        background: @vars[box-shadow-left-line];
      });

      @media @viewport-mobile {
        width: 100px;
      }
    }

    .middle {
      width: 40px;
      height: 1px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      })
    }

    .circle {
      width: 48px;
      height: 48px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      .with-theme({
        border: 1px solid @vars[neutral-100-to-neutral-dark-100];
      });

      .inner {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 6px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: @white;
        .with-theme({
          box-shadow: @vars[box-shadow-circle];
        })
      }

      &.blue {
        border: 1px solid @bluePrimary;
        .with-theme({
          background: @vars[white-to-blue-900];
        })
      }
    }

    .right {
      height: 1px;
      width: 133px;
      .with-theme({
        background: @vars[box-shadow-right-line];
      });

      @media @viewport-mobile {
        width: 100px;
      }
    }
  }
}

.ant-modal.custom-modal.shopify-import {
  overflow-y: visible !important;

  .ant-spin-nested-loading {
    overflow: visible !important;
  }

  .dropdown-store-wrapper {
    width: calc(100% - 32px);
    padding: 8px;
    border-radius: 12px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }

    .with-theme({
      background: @vars[white-to-neutral-dark];
      box-shadow: @vars[box-shadow-object-large];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    .user_store-item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      cursor: pointer;
      transition: all .3s ease;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        })
      }

      svg {
        margin-left: auto;
      }

      .user_store-name {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        white-space: nowrap;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      .user_store-link {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        })
      }
    }
  }

  .ant-dropdown-trigger.dropdown-store {
    display: flex;
    height: 40px;
    padding: 6px 12px;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark];
    });

    &:hover, &.ant-dropdown-open {
      border: 1px solid @bluePrimary !important;
    }

    &.ant-dropdown-open svg {
      transform: rotate(180deg);
    }

    .dropdown-store_name {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      white-space: nowrap;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    .dropdown-store_domain {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      })
    }

    svg {
      margin-left: auto;
      transition: all .3s ease;
    }
  }
}
.shopify-connect-new-created-stores-wrapper{
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .shopify-connect-new-created-store{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    padding: 8px;
    &-title-wrapper{
      display: flex;
      align-items: center;
    }
    &-title{
      .with-theme({
        color: @vars[neutral-800-to-neutral-100]
      })
    }
    &-domain{
      color: @bluePrimary;
    }
    &-icon{
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-right: 12px;
      .with-theme({
        background: @vars[blue-light-to-neutral-dark-200];
      })
    }

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200]
    })
  }
}

.custom-modal-title {
  .badge {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: 6px;
    margin-left:4px;
    .with-theme({
      color: @vars[blue-depressed-to-neutral-dark-500];
      background: @vars[blue-light-to-neutral-dark-200];
    })
  }
  .custom-modal-text{
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }

}
