@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';
@import "../../../styles/utils/variables";

.credit-card-form{
  width: 49.7vw;
  max-width: 716px;

  &_settings{
    width: 100%;
    padding: 0 8px;

    .ex1{
      &-wrap{
        .error {
          min-height: 24px;
          line-height: 16px;
          padding: 4px 0;
          text-align: left;
        }
      }

      &-field{
        border: none;

        @media @viewport-mobile {
          margin-bottom: 12px;
        }
      }

      &-fields{
        .ex1-field{
          margin-bottom: 0;

          @media @viewport-mobile {
            &:first-of-type{
              margin-bottom: 12px;
            }
          }
        }
      }

      &.container{
        min-height: auto;
      }
    }

    > .dialog-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-top: 0;

      .btn-primary:first-of-type{
        margin-right: 12px;
        background: transparent;

        .with-theme({
          color: @vars[neutral-800-to-white];
          border-color: @vars[neutral-100-to-800];
        });

        &:hover {
          border-color: @bluePrimary;
          color: @bluePrimary;
        }
      }

      .ant-btn:disabled{
        .with-theme({
          color: @Neutral500 !important;
        });
      }

      .ant-btn.border-only{
        background: transparent!important;
      }
    }

    .double-btn-wrp{
      position: absolute;
      bottom: 0;
      margin-top: 0;
      width: 100%;

      > button {
        min-height: 48px;
        width: 100%;
        border-radius: @border-radius !important;
      }
    }

    .ex1-input:focus ~ .ex1-label,
    .ex1-input.focus ~ .ex1-label,
    .ex1-input.val ~ .ex1-label,
    .ex1-input.complete ~ .ex1-label,
    .ex1-input.invalid ~ .ex1-label{
      font-size: 10px;
    }
  }

  @media @viewport-mobile {
    width: 100%;
    max-width: 100%;
  }

  .dialog-footer {
    display: flex;
    padding-top: 8px;

    > .ant-btn:disabled {
      color: @Neutral300 !important;

      > svg {
        fill: @Neutral300;
      }
    }
  }

  .double-btn-wrp{
    margin-top: 20px;
  }
}

.ex1{

  &-wrap{
    margin: auto;

    .error {
      max-height: 32px;
      color: @red500;
      text-align: center;
      font-weight: 500;
    }
  }

  &-field{
    position: relative;
    border-radius: @border-radius;
    border: 1px solid @Neutral100;

    margin-bottom: 16px;
    height: 48px;

    &-icon{
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      right: 15px;

      & > svg {
        .with-theme({
          fill: @vars[neutral-300-to-500];
        });
      }
    }

    > .ex1-input{
      color: @Neutral800;
      width: calc(100% - 4px);
      margin-left: 2px;
      display: block;
      background: transparent;
      border-radius: @border-radius;
      border: 1px solid @Neutral100;
      padding: 23px 15px 7px;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.09px;

      .with-theme({
        color: @vars[neutral-800-to-200];
        outline: none;
      });

      cursor: text;
      transition: all 0.3s linear;
      box-shadow: none;

      &:focus-visible, &:focus{
        outline: none;
      }

      &::placeholder{
        color: transparent;
      }

      &:focus::placeholder{
        color: #7b808c;
      }

      &.disable{
        pointer-events: none;
      }
    }
  }

  &-fields{
    display: flex;
    margin-left: -16px;
    @media @viewport-mobile {
      flex-direction: column;
      margin-left: 0;
    }

    .ex1-field{
      flex: 1;
      margin-left: 16px;

      @media @viewport-mobile {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  &-label{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: @Neutral300;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    padding-left: 15px;
    z-index: 1;
    transition: all 0.3s linear;

    .with-theme({
      color: @vars[neutral-300-to-800];
    });
  }

  &.container {
    min-height: 192px;
    margin: auto;
  }
}

.ex1-input:focus ~ .ex1-label,
.ex1-input.focus ~ .ex1-label,
.ex1-input.val ~ .ex1-label,
.ex1-input.complete ~ .ex1-label,
.ex1-input.invalid ~ .ex1-label{
  font-size: 8px;
  line-height: 12px;
  color: @Neutral500;
  top: 13px;
  left: 0;
}

.ex1-field {
  border: none;
}

.ex1-input {
  .with-theme({
    border: 1px solid @vars[neutral-100-to-800];
  });
}

.ex1-input:focus,.ex1-input.focus {
  border: 1px solid @bluePrimary;
  box-shadow: 0 0 0 2px rgba(34, 90, 234, .2);
}

.ex1-input.invalid:focus,.ex1-input.invalid.focus {
  border: 1px solid @redPrimary;
  box-shadow: 0 0 0 2px rgba(215,19,19,.2);
}

.ex1-input:hover,.ex1-input.hover {
  border: 1px solid @bluePrimary;
  font-size: 14px;
  line-height: 16px;
}

.ex1-input.invalid {
  border: 1px solid @redPrimary;
  box-shadow: 0 0 0 2px rgba(215,19,19,.2);
}

@media @viewport-mobile {
  .score {
    .ant-tooltip-arrow {
      right: 39px;
    }
  }
}
