@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.free-bonuses-wrapper {
  margin-top: 56px;
  width: 520px;

  @media @viewport-mobile {
    margin-top: 48px;
    width: 100%;
  }

  h3 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: @dark;
    text-align: left;

    @media @viewport-mobile {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }

  .free-bonuses-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @media @viewport-mobile {
      margin-top: 24px;
      gap: 16px;
    }

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 16px;
      border: 1px solid @Neutral100;
      border-radius: 16px;
      background: @white;

      @media @viewport-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 23px;
      }

      &-image {
        width: 140px;
        height: 120px;
        background-repeat: no-repeat;
        background-size: contain;

        @media @viewport-mobile {
          background-size: cover;
        }

        &.design {
          background-image: url("../../../Images/ClickFunnelsDesign.png");
        }

        &.shopify {
          background-image: url("../../../Images/ClickFunnelsShopify.png");
          width: 120px;
          margin-left: 20px;

          @media @viewport-mobile {
            background-size: contain !important;
          }
        }

        &.mystery {
          background-image: url("../../../Images/ClickFunnelsMystery.png");
        }
      }

      &-content {
        width: calc(100% - 156px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media @viewport-mobile {
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        h5 {
          margin-bottom: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @Neutral500;
        }
      }

      &-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 8px;
        color: @dark;
        margin-bottom: 0;

        @media @viewport-mobile {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }

      &-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: @Neutral500;

        @media @viewport-mobile {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
        }
      }

      &-value {
        margin-top: 32px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: @greenPrimary;

        @media @viewport-mobile {
          margin-top: 16px;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }

  .free-bonuses-total {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 12px;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: @dark;

      @media @viewport-mobile {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    & > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: @redPrimary;
      padding: 8px 12px;
      border-radius: 12px;
      background: @redLight;

      @media @viewport-mobile {
        width: 100%;
        text-align: center;
      }
    }
  }

  .free-bonuses-btn {
    margin-top: 30px;
    cursor: pointer;
    display: flex;
    padding: 8px 24px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid rgba(22, 137, 90, 0.25);
    background: rgba(22, 137, 90, 0.05);
    color: @greenPrimary;
    transition: all .3s ease;

    @media @viewport-mobile {
      margin-top: 16px;
    }

    &:hover {
      color: @greenDepressed;
      border: 1px solid @greenDepressed;
    }

    p {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;

      @media @viewport-mobile {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      opacity: .75;
    }
  }
}
