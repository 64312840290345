@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
.chart-section{
  display: flex;
  flex-direction: column;
  .chart-section-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px 24px;
    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 24px;
    }
  }
  .chart-section-down{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px 16px;
    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;
    }
  }
  .chart-section-left{
    display: flex;
  }
  .chart-section-right{
    display: flex;
    margin-left: auto;
    gap: 12px;
    button{
      height: 36px;
    }
    @media @viewport-mobile {
      margin-left: unset;
      width: 100%;
    }
  }
  .chart-section-title{
    font-family: Inter,sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
    &.skeleton{
      width: 150px;
      display: inline-block;
      height: 20px;
      color: transparent;
      border-radius: @border-radius;
      .skeleton-animation-bg();
    }
  }
}

.demography-block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 0 24px;
  @media @viewport-mobile {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 16px;
    gap: 8px;
  }
}

.demography-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  @media @viewport-mobile {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.demography-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.3px;

  .with-theme({
    color: @vars[neutral-600-to-neutral-dark-500];
  });

  &.skeleton {
    width: 130px;
    display: inline-block;
    height: 19.6px;
    color: transparent;
    border-radius: @border-radius;
    .skeleton-animation-bg();
  }
}

.demography-value {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.3px;
  .with-theme({
    color: @vars[neutral-800-to-neutral-100];
  });
  .status{
    &.skeleton {
      display: none;
    }
    color: @orangePrimary;
    &.green{
      color: @greenPrimary;
    }
  }
  &.skeleton {
    width: 90px;
    display: inline-block;
    height: 24px;
    color: transparent;
    border-radius: @border-radius;
    .skeleton-animation-bg();
  }
}


.country-flags{
  display: flex;
  align-items: center;
  gap: 2px;
  svg  {
    width: 18px;
  }
  &.skeleton {
    display: none;
  }
}
.extra-flags{
  background: @bluePrimary;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.3px;
  text-align: center;
  color: @white;
  padding: 2px 4px;
  border-radius: 6px;
}

.badge {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left:4px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  text-wrap: nowrap;
  @media @viewport-mobile {
    width: 100%;
    align-items: center;
    text-align: center;
    height: 32px;
    span{
      width: 100%;
    }
  }
  .with-theme({
    color: @vars[blue-depressed-to-neutral-dark-500];
    background: @vars[blue-light-to-neutral-dark-200];

    &:hover {
      background: @vars[blue-to-neutral-dark-300];
      color: @vars[blue-xlight-to-neutral-dark-500];

      .icon-btn.ant-tooltip-open,.icon-btn{
        svg{
          path{
            fill: #C5CCE3 !important;
          }
        }
      }
    }
  })
}

.demography-value-date {
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  align-items: flex-start;
  text-wrap: nowrap;

  &.skeleton {
    width: 90px;
    display: inline-block;
    color: transparent;
    border-radius: @border-radius;
    .skeleton-animation-bg();
    height: 24px;
    div{
      visibility: hidden;
    }
  }

  .with-theme({
    color: @vars[neutral-800-to-100];
  });

  &-gray {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    text-wrap: nowrap;
    .with-theme({
      color: @vars[neutral-400-to-neutral-dark-400];
    });
  }
}
.break-down-section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media @viewport-mobile {
    flex-direction: column;
  }
}

.chart-view {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  border-radius: 10px;
  @media @viewport-mobile {
    width: 100%;
  }
  .with-theme({
    background: @vars[neutral-50-to-neutral-dark-100];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });
  &-btn {
    transition: all .3s ease;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    &.skeleton {
      display: inline-block;
      color: transparent;
      border-radius: @border-radius;
      .skeleton-animation-bg();
      height: 28px;
      svg{
        visibility: hidden;
      }
    }
  
    @media @viewport-mobile {
      width: 100%;
    }
    &--active {
      box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
      .with-theme({
        background: @vars[white-to-neutral-dark-100];

        .first-svg path {
          stroke: @vars[neutral-800-to-white];
        }
        .second-svg path{
          fill: @vars[neutral-800-to-white];
        }
      });
    }
  }
}

.adLibrary-modal-item-header-action{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 8px;
  cursor: pointer;
  .with-theme({
    box-shadow: @vars[box-shadow-btn-secondary];
  })
}