@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.fadspot-page-inner {
  min-height: 100dvh;
  padding-top: 0 !important;

  @media @viewport-mobile {
    padding-bottom: 24px !important;
  }

  .fadspot-page-all-ads {
    .fadspot-page-overview-advertisers {
      margin-top: 16px;
      width: 100%;
      border-radius: @border-radius;
      background: transparent;
      padding: 16px;
      font-family: 'Inter', sans-serif;
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
      });
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      min-height: 88px;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });

        svg {
          margin-left: 4px;
        }
      }

      .ant-carousel {
        width: 101% !important;
      }

      .fadspot-page-overview-advertisers-wrapper {
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        gap: 1%;
        flex-wrap: nowrap;
        width: 100%;

        @media @viewport-mobile {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
        }
      }

      .fadspot-page-overview-advertisers-title-wrapper {
        width: 100%;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          display: flex;
          align-items: center;
        }

        .fadspot-page-overview-advertisers-title-value {
          color: @Neutral500;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
        }
      }

      .fadspot-page-overview-advertisers-title-buttons {
        gap: 4px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          cursor: pointer;
          background: transparent;
          padding: 0;
          width: 20px;
          height: 20px;

          &[disabled] {
            cursor: not-allowed;

            svg path {
              fill: @Neutral500;
            }
          }
        }
      }

      .fadspot-page-overview-advertisers-title-button-prev {
        svg {
          transform: rotate(180deg);
          margin-top: -3px;
        }
      }
    }
  }

  .ant-spin-nested-loading {
    overflow: unset !important;
  }

  &-info {
    &-wrapper {
      margin: 24px 0 0;
      width: 100%;
      height: 90px;
      border-radius: @border-radius;
      padding: 20px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
      });

      @media @viewport-mobile {
        flex-wrap: wrap;
        height: unset;
        gap: 12px;
      }

      .cascading-wrapper {
        height: 40px;

        @media @viewport-mobile {
          margin: 0 auto;
        }

        .cascading-dropdown-menu-item--advanced .cascading-dropdown-wrapper-inner {
          left: unset !important;
          right: calc(100% + 12px);
        }
      }
    }

    &-store {
      display: flex;
      align-items: center;
      gap: 8px;

      &-icon {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        .with-theme({
          background: @vars[blue-100-to-900];
        });

        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
          border-radius: 50%;
        }
      }

      &-title {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: default;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });
      }
    }

    &-count {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;

      span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: @Neutral500;

        &.value {
          font-size: 16px;
          line-height: 24px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          })
        }
      }
    }

    &-track-brand {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: @white !important;
      background: @bluePrimary;
      cursor: pointer;
      width: 153px;
      height: 40px;

      @media @viewport-mobile {
        width: 100%;
      }

      svg {
        margin-right: 8px;
      }

      &:hover {
        background: @blueDepressed;
      }
    }
  }

  .fadspot-page-items-wrapper {
    overflow: visible;
    overflow-y: auto !important;

    &-overview {
      margin: 10px;
    }
  }
}
