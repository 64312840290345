@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';
@import '../../../styles/utils/variables';

.bd-admin-page {
  .bd-list {
    overflow-y: scroll;
    height: calc(100dvh - 210px);
    padding-bottom: 40px;
    &::-webkit-scrollbar {
      width: 0;
    }
    .bd-list-wrapper {
      max-width: 1088px;
      padding: 0 16px;
      margin-left: auto!important;
      margin-right: auto!important;

      @media @viewport-mobile {
        padding: 0;
        margin-left: -4px!important;
        margin-right: -4px!important;
      }
    }

    @media @viewport-mobile {
      height: calc(var(--app-height) - 280px);
    }
  }
  .bd-search {
    max-width: 1088px;
    padding: 0 24px;
    margin: 0 auto;

    @media @viewport-mobile {
      padding: 0;
    }

    .search_wrapper {
      margin: 24px 0;

      @media @viewport-mobile {
        flex-flow: wrap;
      }
      .search-input {
        max-width: 636px;
        @media @viewport-mobile {
          max-width: 100vw;
          margin: 0;
        }
        .ant-input-affix-wrapper {
          @media @viewport-mobile {
            margin-bottom: 16px;
          }
          input {
            color: @Neutral500;
          }
          input::placeholder {
            color: @Neutral300 !important;
            opacity: 1;
            box-shadow: none;
          }
        }
      }
      .bd-admin-select {
        min-width: 208px;
        margin-right: 16px;
        @media @viewport-mobile {
          min-width: calc(100vw - 164px - 56px);
        }
        .ant-select-selector {
          display: flex;
          align-items: center;
          border-radius: 10px;
          height: 40px;
          background-color: transparent;
          .with-theme({
            border-color:  @vars[neutral-200-to-neutral-dark-200];
          });
          .ant-select-selection-item {
            color: @Neutral500;
          }
        }
        .bd-admin-dropdown {
          height: 136px;
          padding: 0;
          &.ant-select-dropdown {
            .with-theme({
              background-color:  @vars[color-white-to-dark] !important;
              border-color:  @vars[neutral-100-to-800];
            });
          }

          .rc-virtual-list {
            &-holder {
              &-inner {
                padding: 8px;
                .ant-select-item {
                  border-radius: @border-radius;
                  min-height: 40px;
                  line-height: 30px;
                  color: @Neutral500;
                  &.ant-select-item-option-selected {
                    color: @Neutral500;
                    background-color: transparent;
                    font-weight: 500;
                  }

                  &.ant-select-item-option-active {
                    color: @blue500;
                    font-weight: 500;
                    background-color: @blue100;
                    .with-theme({
                      background-color:  @vars[blue-100-to-900] !important;
                    });
                  }
                }
              }
            }
          }
        }
      }
      .add-new-btn {
        border-radius: 10px;
        min-width: 164px;
      }
    }
    .bd-admin-select {
      &:hover {
        box-shadow: none;
      }
      .ant-select-selector {
        padding-left: 24px;
        &:hover {
          box-shadow: none;
        }
        .ant-select-selection-search {
          input {
            color: @Neutral500;
          }
        }
        .ant-select-selection-placeholder {
          line-height: 48px !important;
          color: @Neutral300 !important;
        }
      }
    }
  }
}
