@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.numbers-breakdown-modal {

  @media @viewport-mobile {
    .ant-spin-nested-loading {
      overflow: visible !important;
    }
  }

  .ant-modal-title {
    padding: 24px 0 0 24px;
  }
  .collection-modal-content {
    padding: 0;

    .collection-modal-text {
      padding: 0 24px;

      @media @viewport-mobile {
        padding: 0 16px;
      }
    }

    .product-database-table-cell {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      gap: 12px;
      .with-theme({
        border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
      }

      .product-database-table-image {
        width: 64px;
        height: 64px;
        border-radius: 10px;

        img {
          width: fit-content;
          max-width: 100%;
          height: auto;
          max-height: 100%;
          object-fit: contain;
          border-radius: 10px;

          @media @viewport-mobile {
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .product-info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .product-name {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.3px;
          font-weight: 500;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }

        .info-wrapper-text {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.3px;
          font-weight: 400;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];

            a {
              text-decoration: underline;
              cursor: pointer;
              color: @vars[neutral-500-to-neutral-dark-500];

              @media @viewport-mobile {
                display: block;
                max-width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          });
        }
      }
    }

    .product-details-card {
      padding: 16px 24px;

      @media @viewport-mobile {
        padding: 16px;
      }

      &-body {
        margin-top: 0;

        .numbers-breakdown-card-label {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
