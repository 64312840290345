@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.quick-search {
  &-wrapper {
    display: flex;
    align-items: center;
    padding: 16px 32px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media @viewport-mobile {
      overflow-y: scroll;
      padding: 16px;
    }
  }
  &-title {
    padding-right: 16px;
    font-weight: 400;
    .with-theme({
    color: @vars[neutral-500-to-neutral-dark-500];
  });
  }

  &-items {
    display: flex;
    gap: 12px;

    .quick-search-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 7px 12px;
      border-radius: 10px;
      transition: all 0.3s ease;


      @media @viewport-mobile {
        justify-content: center;
        height: 32px;
        min-width: 158px;
        padding: 6px;
      }

      svg {
        margin-right: 12px;

        @media @viewport-mobile {
          margin-right: 4px;
        }
      }

      .with-theme({
          color: @vars[neutral-800-to-100];
          background-color: @vars[white-to-neutral-dark-50];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          box-shadow: @vars[box-shadow-btn-secondary-without-border];
          &:hover {
            box-shadow: @vars[box-shadow-btn-secondary-without-border];
            background: @vars[neutral-50-to-neutral-dark-100];
          }

          &.active {
            box-shadow: @vars[box-shadow-btn-secondary-without-border];
            background: @vars[neutral-50-to-neutral-dark-100];
          }

          &.disabled {
            cursor: not-allowed;
            pointer-events: none;

            &:hover {
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            }
            background: @vars[neutral-100-to-neutral-dark-200] !important;
          }

          &.active.disabled {
            &:hover {
              border-color: @bluePrimary;
            }
          }
        });
    }
  }
  .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });
}
