@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.dropdown-input-wrapper {  
  .dropdown-input-menu {
    padding: 16px 16px 0;
    width: 250px;
    height: 328px !important;
    border-radius: 12px;
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      box-shadow: @vars[box-shadow-object-middle]
    });

    @media @viewport-mobile {
      width: 100%;
      height: 248px !important;
    }

    &:has(.empty) {
      height: 140px !important;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 0;
      border-radius: 6px;

      .with-theme({
        color: @vars[blue-depressed-to-blue-primary];
        background-color: @vars[blue-light-to-neutral-dark-100];
      })
    }

    &-items {
      height: calc(100% - 52px);
      overflow-y: auto;

      &.loading {
        position: relative;
        overflow-y: hidden;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
          border-radius: 0 0 @border-radius @border-radius;
          backdrop-filter: blur(6px);
          background: transparent;
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @bluePrimary;
        });
      }
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }

      &.empty > .empty-state {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 8px;
        font-family: 'Inter', sans-serif;

        .empty-state-product-database-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          & > div {
            font-size: 14px;
            line-height: 16px;
            color: @Neutral500;
          }
        }
      }
    }

    &-item {
      padding: 8px;
      cursor: pointer;
      border-radius: @border-radius;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      margin-bottom: 4px;
      
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
      
      });
      transition: all .3s ease;

      &:hover {
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        });
      }

      &-info {
        display: flex;
        align-items: center;
      }

      .logo {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }

      .title {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;

        h4 {
          font-weight: 500;
          margin-bottom: 0;
          .with-theme({
            color: @vars[neutral-800-to-200];
          })
        }

        span {
          font-weight: 400;
          color: @Neutral500;
        }
      }

      &-count {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: @Neutral500;
      }

      &-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });

        sup {
          top: -1em;
          font-size: 50%;
        }
      }

      &-button {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        border: none;
        outline: none;
        box-shadow: none;
        transition: all .3s ease;
        color: @bluePrimary;
        background: transparent;
        border-radius: 0;

        &:hover {
          color: @blueDepressed;
        }
      }
    }
  }
}

.input-filter-wrapper {

  .input-product-filter {
    &-title {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    &-list {
      position: relative;
      padding: 6px 12px 0;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      transition: all .3s ease;
      margin-top: 8px;
      margin-bottom: 16px;
      list-style: none;
      background: transparent;
      min-height: 40px;
      align-items: center;
      border-radius: 10px;
      box-shadow: none !important;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200]
      });

      &:hover, &:focus-within {
        border-color: @bluePrimary !important;
      }

      li {
        float: left;
      }

      .ant-tag {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        white-space: pre-wrap;
        border: none;
        padding: 3px 8px;
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: @border-radius;
        margin-right: 4px;
        .with-theme({
          background: @vars[white-to-neutral-dark];
          color: @vars[neutral-800-to-100];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        &-close-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0;
          padding: 1.75px;
          border-radius: 4px;

          .with-theme({
            background: @vars[neutral-100-to-neutral-dark-100];

            svg {
              width: 15px;
              height: 15px;
              path {
                stroke: @vars[neutral-500-to-neutral-dark-500];
              }
            }
          })
        }
      }

      input {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        padding: 4px 0;
        background: transparent !important;
        height: 22px;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        &::placeholder {
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          })
        }
      }
    }
  }
}
