@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.store-products-opened {

  &.change-modal.custom-modal {
    .ant-modal-body {
      padding: 0 !important;
      overflow-x: hidden;
    }
  }
  .ant-spin-nested-loading {
    overflow: visible;
  }
}

.store-products-modal-wrapper {
  margin: 0 0 16px 0;
  .load-filter-preset-title {
    padding: 16px 16px 8px 16px;
  }

  .adspot-products-table-body {
    .ant-table-sticky-scroll {
      display: none;
    }
    :where(.css-dev-only-do-not-override-ecti0p).ant-table-wrapper .ant-table .ant-table-title, :where(.css-dev-only-do-not-override-ecti0p).ant-table-wrapper .ant-table .ant-table-header {
      border-radius: 0px !important;
    }
    .ant-table-thead > tr > th {
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-200] !important;
      });
    }
    .ant-table .ant-table-thead td {
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-200] !important;
      });
    }

    .ant-table-row .ant-table-cell {
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
      });
    }
  }

  .load-filter-preset-subtitle {
    padding-left: 16px;
  }

  .ant-table-cell-row-hover {
    .with-theme({
      background-color: transparent !important;
    });
  }

  .ant-table-body {
    margin: 0 !important;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }
  }

  .product-actions {
    margin: 0 !important;
    button {
      border-radius: 8px;
      border: 1px solid black;
      padding: 8px 12px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &:hover {
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-100] !important;
          });
        }
        span {
          .with-theme({
            color: @vars[neutral-800-to-100] !important;
        });
      }
    }
  }

  .store-products-modal-action {
    width: 130px;
    height: 40px;
    background: transparent !important;

    &:hover, &:focus {
      span {
        color: @bluePrimary !important;
      }
    }

    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      transition: all .3s ease;
      .with-theme({
        color: @vars[neutral-800-to-200];
      })
    }
  }

  .sales-tracker-stores-products-modal-table-wrapper {

    .product-database-table-image, .product-info-wrapper, .product-database-table-cell_clickable {
      cursor: default;
    }

    .product-url:hover {
      text-decoration: none;
    }

    .product-database-table-body {
      margin-top: 16px;
      .ant-table-body {
        &::-webkit-scrollbar {
          width: 4px; height: 0;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }
        &::-webkit-scrollbar-track-piece:start {
          background: transparent;
          margin-top: 10px;
        }
        &::-webkit-scrollbar-track-piece:end {
          background: transparent;
          margin-bottom: 10px;
        }
      }
    }
    .product-database-table-body .ant-table-thead > tr > th:first-of-type, .product-database-table-body .ant-table-row > td:first-of-type {
      padding-left: 16px !important;
      padding-right: 20px !important;

      @media @viewport-mobile {
        padding-left: 16px !important;
      }
    }

    .ant-table-header.ant-table-sticky-holder {
      overflow: visible !important;

      @media @viewport-mobile {
        overflow: hidden !important;
      }
    }

    .ant-table-body {
      margin-right: 8px;
    }

    .ant-table-thead {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        left: -24px;
        bottom: 0;
        width: calc(100% + 48px);
        height: 1px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }
      &:before {
        position: absolute;
        content: '';
        left: -24px;
        top: -1px;
        width: calc(100% + 48px);
        height: 1px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }
    }
  }
}
