@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.pick-your-stores-wrapper {
  margin-top: 36px;
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @media @viewport-mobile {
    margin-top: 24px;
    width: 100%;
  }

  .pick-your-stores-form {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 24px;
    box-shadow: 0 8px 40px 0 rgba(34, 90, 234, 0.10);
    border: 2px solid @bluePrimary;

    &-total {
      width: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: @Neutral800;
    }

    &-one-time-offer {
      margin-top: 24px;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      padding: 12px;
      border-radius: 12px;
      border: 1px dashed #16895A;
      background: rgba(22, 137, 90, 0.05);

      .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;
        border-radius: @border-radius !important;
        border: 1px solid @greenPrimary !important;

        &:after {
          inset-inline-start: 27.5%;
        }
      }

      .ant-checkbox {
        top: 0 !important;

        &-checked {

          .ant-checkbox-inner {
            background: @greenPrimary !important;
          }

          &:after {
            border: none !important;
          }
        }
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: @greenPrimary;
      }

      p {
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: @dark;
      }
    }

    &-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: @Neutral800;
      text-align: left;
      margin-bottom: 0;
    }

    &-subtitle {
      width: 100%;
      border-radius: 8px;
      background: @Neutral100;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: @Neutral500;
      padding: 8px 12px;
      margin: 16px auto;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 12px;

      .skeleton {
        background-image: linear-gradient(
          90deg,
          @Neutral100 20%,
          @white 37%,
          @Neutral100 63%
        );
        background-size: 400% 100%;
        animation: ant-skeleton-loading 1.4s ease infinite;
      }

      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 12px;
        border-bottom: 1px solid @Neutral100;

        @media @viewport-mobile {
          padding: 0 8px 12px;
        }

        &.selected {
          border-bottom: none;
        }

        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }

        label {
          display: flex;
          align-items: center;
        }

        .ant-checkbox-inner {
          width: 20px !important;
          height: 20px !important;
          border-radius: @border-radius !important;
          border: 1px solid @Neutral200 !important;

          &:after {
            inset-inline-start: 27.5%;
          }
        }

        .ant-checkbox {
          top: 0 !important;

          &-checked {

            .ant-checkbox-inner {
              background: @bluePrimary !important;
            }

            &:after {
              border: none !important;
            }
          }
        }

        label span:not(.ant-checkbox), .pick-your-stores-form-content-item-title {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: @dark;
          padding-inline-end: 0;
        }

        &-subtext {
          color: @greenPrimary !important;
          margin-left: 4px;
        }

        &-price {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @dark;
        }

      }
    }

    &-submit {
      margin-top: 24px;
      display: flex;
      width: 100%;
      padding: 11px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: @bluePrimary;
      cursor: pointer;
      transition: all .3s ease;

      &.disabled {
        background: @Neutral500;
        cursor: not-allowed;
      }

      &:not(.disabled):hover {
        background: @blueDepressed;
      }

      span {
        color: @white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      svg {
        margin-top: 2px;
      }
    }

    .subtle-text {
      margin-top: 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: @Neutral500;
      text-align: center;
    }
  }

  .pick-your-stores-partners {
    width: 377px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../Images/ClickFunnelsPartners.png");

    @media @viewport-mobile {
      width: 100%;
      height: 92px;
    }
  }

  .pick-your-stores-comment {
    margin-top: 24px;
    width: 520px;
    height: 348px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../Images/ClickFunnelsComment.png");

    @media @viewport-mobile {
      margin-top: 8px;
      width: 100%;
      height: 286px;
    }
  }
}
