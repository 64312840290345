@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.saturation-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 8px;

  @media @viewport-mobile {
    gap: 16px;
    padding-top: 0;
  }

  &-indicator-line {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &-item {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      text-transform: capitalize;

      &.active {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
      }

      &.low {
        .with-theme({
          color: @greenPrimary;
        });
      }

      &.medium {
        .with-theme({
          color: @yellowPrimary;
        });
      }

      &.high {
        .with-theme({
          color: @redPrimary;
        });
      }
    }
  }

  &-speedometer-wrap {
    position: relative;
    width: 339px;
    height: 169px;

    @media @viewport-mobile {
      width: 100%;
    }

    .speedometer-img {
      width: 100%;
      height: auto;
    }

    .speedometer-arrow {
      position: absolute;
      transform: translate(-50%, 0);
      bottom: 0;
      left: 50%;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &-icon{
        position: absolute;
        scale: 1.01;
        transform: translate(-50%, -100%);
        left: 50%;
        top: 35%;
        max-height: 89px;
        height: 6.2vw;

        @media @viewport-mobile {
          height: 90px;
        }

        &-wrap{
          position: absolute;
          width: 32px;
          height: 32px;
          transform-origin: center;
          left: 50%;
          top: 50%;
          transition: all .75s cubic-bezier(0,1.16,.43,1.14);
        }
      }

      &-count{
        box-sizing: content-box;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: @Neutral800;
        box-shadow: 0 0 24.9265px rgba(110, 125, 175, 0.3);
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.09px;
        color: @white;
      }
    }
  }

  &-value {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    &-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      padding: 0 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      text-transform: capitalize;
      border-radius: 100px;

      &.low {
        .with-theme({
          color: @greenPrimary;
          background: @vars[green-100-to-900];
        });
      }

      &.medium {
        .with-theme({
          color: @yellowPrimary;
          background: @vars[yellow-100-to-900];
        });
      }

      &.high {
        .with-theme({
          color: @redPrimary;
          background: @vars[red-100-to-900];
        });
      }
    }
  }
}
