@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.course-chapter-page {
  font-family: 'Inter', sans-serif;
  font-style: normal;

  .course-chapter-info-wrapper {
    width: 100%;
    height: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    border-radius: 24px;
    background: radial-gradient(136.93% 177.46% at 1.4% 9.62%, rgba(255, 255, 255, 0.55) 0%, rgba(89, 0, 255, 0.46) 46%, #FFF 100%), #225AEA;
    box-shadow: 0px 24px 24px -12px rgba(34, 90, 234, 0.04), 0px 12px 12px -6px rgba(34, 90, 234, 0.04), 0px 6px 6px -3px rgba(34, 90, 234, 0.04), 0px 3px 3px -1.5px rgba(34, 90, 234, 0.04), 0px 1px 1px -0.5px rgba(34, 90, 234, 0.04);
    border: 2px solid @bluePrimary;

    @media @viewport-mobile {
      padding: 16px;
      gap: 16px;
      width: calc(100vw - 32px);
      height: calc(100vw - 32px);
    }

    .title {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.5px;
      font-weight: 700;
      color: @bluePrimary;
      text-shadow: 0.0px 4.0px 0.008px @white, 3.92px 0.84px 0.008px @white, 1.68px -3.64px 0.008px @white, -3.2px -2.4px 0.008px @white, -3.04px 2.6px 0.008px @white, 1.92px 3.52px 0.008px @white, 3.84px -1.12px 0.008px @white, -0.28px -4.0px 0.008px @white, -3.96px -0.6px 0.008px @white, -1.4px 3.76px 0.008px @white, 3.36px 2.16px 0.008px @white, 2.84px -2.8px 0.008px @white, -2.16px -3.36px 0.008px @white, -3.76px 1.4px 0.008px @white, 0.56px 3.96px 0.008px @white, 4.0px 0.32px 0.008px @white, 1.16px -3.84px 0.008px @white, -3.48px -1.92px 0.008px @white, -2.64px 3.0px 0.008px @white, 2.36px 3.2px 0.008px @white, 3.64px -1.64px 0.008px @white, -0.84px -3.92px 0.008px @white, -4.0px -0.04px 0.008px @white, -0.88px 3.92px 0.008px @white, 3.64px 1.68px 0.008px @white, 2.44px -3.2px 0.008px @white, -2.6px -3.04px 0.008px @white, -3.52px 1.88px 0.008px @white, 1.08px 3.84px 0.008px @white, 4.0px -0.24px 0.008px @white, 0.6px -3.96px 0.008px @white, -3.72px -1.44px 0.008px @white, -2.2px 3.36px 0.008px @white, 2.8px 2.88px 0.008px @white, 3.4px -2.12px 0.008px @white, -1.36px -3.76px 0.008px @white, -3.96px 0.52px 0.008px @white, -0.32px 4.0px 0.008px @white, 3.84px 1.16px 0.008px @white, 1.96px -3.48px 0.008px @white, -3.0px -2.68px 0.008px @white, -3.24px 2.36px 0.008px @white, 1.6px 3.68px 0.008px @white, 3.92px -0.8px 0.008px @white, 0.08px -4.0px 0.008px @white, -3.88px -0.92px 0.008px @white, -1.72px 3.6px 0.008px @white, 3.16px 2.44px 0.008px @white;
      filter: drop-shadow(0px 4.784px 4.784px rgba(0, 0, 0, 0.06)) drop-shadow(0px 9.568px 19.136px rgba(34, 90, 234, 0.64)) drop-shadow(0px 0px 0px rgba(34, 90, 234, 0.16));

      @media @viewport-mobile {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .description {
      max-width: 450px;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: -0.3px;
      color: @Neutral100;
    }

    .stats-wrapper {
      display: flex;
      align-items: center;

      .ant-divider {
        border-top: 1px solid @NeutralDark700 !important;
        border-inline-start: 1px solid @NeutralDark700 !important;;
      }

      .stat {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.3px;
        color: @white;

        &:first-of-type {
          svg path {
            stroke: @NeutralDark700;
          }
        }

        &:last-of-type {
          svg path {
            fill: @NeutralDark700;
          }
        }
      }
    }
  }

  &-content {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media @viewport-mobile {
      padding: 16px;
    }
  }

  &-chapter {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    &-title {
      padding-bottom: 12px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.3px;
      font-weight: 500;
      width: 100%;
      text-align: left;
      padding-left: 16px;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      })
    }

    &-wrapper {
      width: calc(100% - 352px - 24px);
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-secondary];
      });
      border-radius: 12px;

      @media @viewport-mobile {
        width: 100%;
        padding: 16px 0;
      }
    }
  }

  &-progress {
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });
    border-radius: 16px;
    padding: 16px;
    width: 352px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    &-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;


      &-subtitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        &:not(.completed) {
          svg path {
            .with-theme({
              fill: @vars[neutral-400-to-neutral-dark-400];
            })
          }
        }

        span {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      &-bar {
        width: 100%;
        min-height: 8px;
        flex: 1;
        border-radius: 128px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .with-theme({
          background: @vars[neutral-100-to-neutral-dark-100];
        });

        span {
          display: block;
          min-height: 8px;
          border-radius: 128px;
          background: @greenPrimary;
        }
      }
    }
  }

  &-instructor {
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });
    border-radius: 16px;
    padding: 16px;
    width: 352px;

    &-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];

        svg path {
          stroke: @vars[neutral-400-to-neutral-dark-400];
        }
      })
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-bio {
      gap: 2px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-image {
      width: 36px;
      height: 36px;
      min-height: 36px;
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position: center;
      .with-theme({
        background-color: @vars[blue-xlight-to-neutral-dark-50];
      });
      border-radius: 50%;
    }

    &-name {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    &-subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }

    &-text {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }
}
