@import '../../../styles/utils/variables';
@import '../../../styles/theme/theme';
/*=================
* TIMER
*=================*/

.timer-animation {
  width: 100%;
  height: auto;
  display: block;
  color: @Neutral800;
  margin-top: 8px;

  * { cursor: default; }

  &--clock {
    width: 100%;
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: space-between;

    .clock-display-grp-wrap{
      width: 58px;
      height: 64px;
      padding: 7px 0 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      border: 1px solid;
      border-color: @blue500;
      border-radius: @border-radius;

      .with-theme({
        background: @vars[blue-100-to-900];
      });

      .clock-display-grp-title{
        margin-bottom: 3px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });
      }

      @media @viewport-mobile {
        width: 69px;
      }
    }

    .clock-display-grp {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: @blue500;

      .number-grp {
        max-width: 50%;
        width: auto;
        display: block;
        height: 32px;
        float: left;
        overflow: hidden;

        .number-grp-wrp {
          width: 100%;
          position: relative;

          .num {
            width: 100%;
            position: relative;
            height: 32px;
            p {
              width: auto;
              display: table;
              font-family: 'Inter', sans-serif;
              font-weight: 600;
              font-size: 22px;
              line-height: 32px;
              font-weight: bold;
            }
          }
        }
      }
    }


    &.theme-red {
      .clock-display-grp-wrap{
        border-color: @red500;
        .with-theme({
          background: @vars[red-100-to-900];
        });
      }

      .clock-display-grp {
        color: @red500;
      }
    }

    &.theme-yellow{
      .clock-display-grp-wrap{
        border-color: @yellow500;
        .with-theme({
          background: @vars[yellow-100-to-900];
        });
      }

      .clock-display-grp {
        color: @yellow500;
      }
    }

    &.theme-pink{
      .clock-display-grp-wrap{
        border-color: @pink500;
        .with-theme({
          background: @vars[pink-100-to-900];
        });
      }

      .clock-display-grp {
        color: @pink500;
      }
    }
  }
}
