@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.react-swipe-button {
  position: relative;
  width: 100%;
  height: 48px;

  @media @viewport-mobile {
    height: 72px;
  }
}

.react-swipe-button,
.react-swipe-button * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

.rsb-container{
  width: 100%;
  height: 100%;
  background: @blue500;
  border-radius: @border-radius;
  position: relative;
  overflow: hidden;

  &.red{
    background: @red500;

    .rsbc-slider-arrow{
      fill: @red500;
    }
  }

  &.with-animation-hover{
    transition: .6s;
    &:before{
      content: '';
      display: block;
      position: absolute;
      background: rgba(255,255,255,0.5);
      width: 60px;
      height: 100%;
      left: 0;
      top: 0;
      opacity: .5;
      filter: blur(30px);
      transform: translateX(-100px)  skewX(-15deg);
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      background: rgba(255,255,255,0.2);
      width: 30px;
      height: 100%;
      left: 30px;
      top: 0;
      opacity: 0;
      filter: blur(5px);
      transform: translateX(-100px) skewX(-15deg);
    }
    &:hover{
      &:before{
        transform: translateX(490px)  skewX(-15deg);
        opacity: 0.6;
        transition: .7s;
      }
      &:after{
        transform: translateX(490px) skewX(-15deg);
        opacity: 1;
        transition: .7s;
      }
    }
  }

  .rsbc-slider {
    width: 100%;
    position: absolute;
    height: 48px;
    top:0;
    left: 48px;
    margin-left:-100%;
    background: transparent;
    border-radius: @border-radius;
    z-index: 100;
    cursor: pointer;

    @media @viewport-mobile {
      height: 72px;
      left: 72px;
    }
  }

  .rsbc-slider-text{
    position: absolute;
    top:0;
    left:0;
    right:0;
    line-height: 48px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #fff;
    font-size: 14px;

    @media @viewport-mobile {
      line-height: 72px;
    }
  }

  .rsbc-slider-circle {
    position: absolute;
    right: 4px;
    background: #ffffff;
    top:4px;
    height: 40px;
    width: 40px;
    border-radius: @border-radius;

    @media @viewport-mobile {
      height: 64px;
      width: 64px;
    }
  }

  .rsbc-slider-arrow{
    position: absolute;
    height: 24px;
    width: 24px;
    top: 8px;
    right: 8px;
    z-index: 10;

    @media @viewport-mobile {
      height: 32px;
      width: 32px;
      top: 16px;
      right: 16px;
    }
  }

  .rsbc-text {
    position: absolute;
    top:0;
    left:0;
    right:0;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #fff;

    @media @viewport-mobile {
      height: 72px;
      line-height: 72px;
    }
  }

  .rsbc-slider-circle_empty {
    position: absolute;
    right: 4px;
    top:4px;
    height: 40px;
    width: 40px;
    border-radius: @border-radius;
    background: transparent;
    border: 2px solid #ffffff;
    transition: all 0.5s;

    @media @viewport-mobile {
      height: 64px;
      width: 64px;
    }
  }

  &-unlocked{
    cursor: default;

    .rsbc-slider{
      cursor: default;
      pointer-events: none;
    }

    .rsbc-slider-circle_empty{
      transition: all 0.5s;
      right: auto;
      left: 4px;
    }
  }
}
