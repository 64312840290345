.add-card-wrapper {
  .cancel-sub-title {
    margin-bottom: 16px;
  }

  form {
    position: relative;
  }

  .dialog-footer {
    bottom: 0;
  }
}
