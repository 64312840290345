@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.main-block-stat {
  .with-theme({
    border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

&-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @media @viewport-mobile {
    margin: 0;    
  }

  .with-theme({
    color: @vars[neutral-500-to-neutral-dark-500]
  });
}

&-value {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
  text-transform: capitalize;

  @media @viewport-mobile {
    margin: 0;    
  }

  .domain-link {

    span {
      font-weight: 400;
      text-decoration: underline;
      text-transform: lowercase;
      margin-right: 5px;
    }
  }

  &-socials {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
  }

  .with-theme({
    color: @vars[neutral-600-to-100];
  });

&--clickable {
  color: @bluePrimary !important;
  cursor: pointer;
  padding-right: 8px;
  gap: 8px;

  @media @viewport-mobile {
    padding: 0;
  }

  .main-block-view-value {

    .with-theme({
      color: @vars[neutral-600-to-100];
    });
  }

  .main-block-view-divider {
    .with-theme({
      background-color: @vars[neutral-200-to-neutral-dark-200];
    });
  }

  &:hover {
    color: @blueDepressed !important;
  }
}
}
}