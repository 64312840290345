@import '../../../styles/utils/variables';
@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.custom-breadcrumbs {
  padding: 24px 32px;
  display: flex;
  flex-wrap: wrap;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  &.skeleton {
    gap: 4px;
  }

  &_admin {
    margin-top: 0;
  }

  > ol li {
    display: flex;
    flex-wrap: nowrap;
    min-height: 24px;

    .ant-breadcrumb-link > a, span {
      transition: all .3s ease;
      display: inline-block;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      text-decoration-line: underline;
      text-underline-offset: 2px;
      color: @Neutral500;
      background: transparent !important;

      &:hover {
        color: @bluePrimary;
      }
    }

    .ant-breadcrumb-separator {
      display: flex;
      align-items: center;
      height: 24px;
      max-width: 24px;

      > svg {
        transition: none !important;
        .with-theme({
          fill: @vars[neutral-300-to-neutral-dark-300];
        });
      }
    }

    &:nth-last-child(-n+2){
      .ant-breadcrumb-separator {
        > svg {
          .with-theme({
            fill: @vars[neutral-300-to-neutral-dark-300];
          });
        }
      }
    }

    &:first-child {
      .ant-breadcrumb-link > a, span {
        text-transform: capitalize;
      }
    }

    &:last-child {
      .ant-breadcrumb-link > a, span {
        text-decoration: none;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        @media @viewport-mobile {
          display: flex;
          align-items: center;
          .with-theme({
            color: @bluePrimary;
          });
          svg {
            margin-right: 8px;
            transform: rotateY(180deg);
            .with-theme({
              fill: @bluePrimary;
            });
          }
        }
      }

      .ant-breadcrumb-separator {
        display: none;
      }
    }
  }

  &.skeleton{
    > span {
      .ant-breadcrumb-link > a, span {
        .skeleton-animation-bg();
        border-radius: @border-radius;
        .with-theme({
          color: transparent;
        });
      }

      .ant-breadcrumb-separator {
        color: transparent;
        > svg {
          .with-theme({
            fill: transparent;
          });
        }
      }

      &:last-child {
        .ant-breadcrumb-link > a, span {
          .with-theme({
            color: transparent;
          });
        }
      }
    }
  }
}