@import '../../../styles/theme/theme';
@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';
@import '../../../styles/base/animation';
@import '../../../styles/utils/variables';

.product-details-page_admin {
  height: 100%;
  max-height: 100dvh;
  .block-with-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    &_label {
      display: inline-block;
      height: 24px;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
      color: @Neutral800;
      &.light-text {
        color: @Neutral500;
        display: flex;
        align-items: center;
        line-height: 32px;
        height: 32px;
        .blue-block {
          margin-left: 8px;
        }
      }
      &.bold-text {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        display: flex;
      }
      .important {
        color: @red500;
      }
      .blue-block {
        display: inline-block;
        height: 32px;
        line-height: 24px;
        background: @blue100;
        border-radius: @border-radius;
        padding: 4px 8px;
        font-size: 16px;
        color: @blue500;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
      }

      &.space-between {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .btn-primary {
          min-width: 96px;
        }
        div {
          &:last-child {
            text-align: right;
            color: @Neutral500;
          }
        }
      }

      .icon-btn {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        margin-left: 4px;

        svg {
          width: 16px;
          height: 16px;
          fill: @Neutral300;
        }
      }
    }

    &_input {
      padding: 11px 15px;

      &::placeholder {
        font-size: 14px;
        line-height: 20px;
        opacity: 1;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        .with-theme({
          color: @vars[neutral-400-to-neutral-dark-400];
        })
      }
      &.without-arrows {
        .ant-input-number-input-wrap {
          .ant-input-number-input {
            &::placeholder {
              font-size: 14px;
              line-height: 20px;
              opacity: 1;
              font-family: 'Inter', sans-serif;
              font-weight: 500;
              .with-theme({
                color: @vars[neutral-400-to-neutral-dark-400];
              })
            }
          }
        }

        .ant-input-number-handler-wrap {
          display: none;
        }
      }
      &.with-suffix-num {
        .ant-input-suffix {
          div {
            padding-left: 12px;
            text-align: right;
            border-left: 1px solid @Neutral100;
            margin-left: 5px;
            font-size: 14px;
            line-height: 20px;
            color: @Neutral500;
          }
        }
      }

      &.ant-input-affix-wrapper {
        background-color: inherit;
        border-color: @Neutral100;

        &-disabled {
          border-color: @blue100;
          background-color: @blue100;
        }

        .ant-input-prefix {
          margin-right: 0;

          .divider {
            width: 1px;
            min-height: 24px;
            margin: 0 12px;
            background-color: @Neutral100;
          }
        }
      }

      &.ant-input,
      .ant-input {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        background-color: inherit;
        color: @Neutral500;

        &-disabled {
          background-color: @blue100;
          border-color: @blue100;
          color: @blue500;
        }
      }

      &.ant-input-number {
        width: 100%;
        background-color: inherit;
        border-color: @Neutral100;
        &::placeholder {
          font-size: 14px;
          line-height: 20px;
          color: @Neutral300;
          opacity: 1;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
        }

        .ant-input-number-input {
          width: 100%;
          height: 24px;
          padding: 0 0 0 49px;

          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          color: @Neutral500;

          &-wrap {
            position: relative;

            &:before {
              position: absolute;
              display: block;
              content: url('../../Images/price.svg');
              top: 0;
              left: 0;
              width: 36px;
              height: 24px;
              padding-right: 12px;
              border-right: 1px solid @Neutral100;
            }
          }
        }

        .ant-input-number-handler {
          border-left: 1px solid @Neutral100;
          &-wrap {
            background: inherit;
          }
          &-down {
            border-top: 1px solid @Neutral100;
          }
          &:active {
            background: inherit;
          }
        }
      }
    }
    &_input-number {
      &.ant-input-number-focused {
        box-shadow: none;
        border-color: @blue500;
      }
      &:focus {
        box-shadow: none;
      }
      display: inline-block;
      width: 100%;
      border: 1px solid #f2f3f8;
      height: 48px;
      border-radius: @border-radius;
      .ant-input-number-handler-wrap {
        opacity: 1 !important;
        width: 32px;
        height: 70% !important;
        top: 7px !important;
        border-left: 1px solid #f2f3f8;
        padding-left: 6px;
        padding-right: 6px;

        .ant-input-number-handler-down {
          border-top: none;
        }
        .ant-input-number-handler-up {
          border-bottom: 1px solid #f2f3f8;
          .anticon {
            margin-top: -7px;
          }
        }

        .ant-input-number-handler-up,
        .ant-input-number-handler-down {
          height: 50% !important;
          border-left: none;

          .anticon {
            width: 12px;

            svg {
              fill: @Neutral500 !important;
            }
          }
          &:active {
            background-color: transparent;
          }
          &:hover {
            height: 50% !important;
            .anticon {
              svg {
                fill: @blue500 !important;
              }
            }
          }
        }
      }
      .ant-input-number-input-wrap {
        line-height: 48px;
        display: inline-block;
        .ant-input-number-input {
          color: @Neutral500;
          vertical-align: middle;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          font-family: 'Inter', sans-serif;

          &::placeholder {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
          }
        }
      }
    }

    &_btn-wrap {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .ant-btn,
      .ant-btn:focus {
        min-width: 96px;
      }
    }

    .status-view {
      position: relative;
      display: flex;
      width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: @border-radius;
      border: 1px solid @Neutral100;
      text-align: center;

      &_btn {
        position: relative;
        font-size: 14px;
        width: 50%;
        color: @Neutral500;
        cursor: pointer;
        background-color: transparent;
        transition: color 0.3s ease;

        &--active {
          color: @white;
          cursor: not-allowed;
        }
      }

      &_indicator {
        position: absolute;
        width: calc(50% + 1px);
        height: 48px;
        border-radius: @border-radius;
        top: -1px;
        left: -1px;
        background-color: @purple500;
        transition: all 0.3s ease;

        &--disabled {
          background-color: transparent;
        }

        &--active {
          left: 50%;
          background-color: @green500;
        }
      }
    }
  }
  .block-with-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    min-height: 512px !important;
    &.empty_data {
      justify-content: center;
      .empty-data-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &_icon {
          .icon-empty-warning {
            path {
              fill: #C5CCE3 !important;
            }
          }
        }
        &_text {
          font-size: 22px;
          line-height: 32px;
          color: #C5CCE3;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
        }
      }
    }
  }
  .footer-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    background-color: @blue100;
    cursor: pointer;

    div {
      color: @blue500;
      font-size: 18px;
      line-height: 24px;
      display: inline-block;
      margin: 0 5px;
    }
    span {
      display: inline-block;
      transform: rotate(90deg) !important;
      padding-right: 3px;
    }
  }

  @media @viewport-mobile {
    height: calc(var(--app-height) - 71px);
  }

  .search {
    .search_wrapper {
      margin-bottom: 16px;
      margin-top: 0;

      .category-admin-select {
        .category-admin-dropdown {
          .ant-select-item-option {
            font-size: 14px;
            font-weight: 400;
            
            &-content {
              gap: 4px;
    
              span {
                padding-left: 4px;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.3px;
                .with-theme({
                  color: @vars[neutral-800-to-100];
                })
              }
            }
          }
        }

        .ant-select-arrow {
          right: 0 !important;
          left: unset;
        }
      }

      .ant-input-group-addon {
        button {
          height: 40px !important;
        }
      }

      .ant-select-selector {
        min-width: 150px;
        height: 40px !important;

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }

      .search-input {
        margin: 0;
      }
    }
  }
}

.product-details-page_admin-modal {
  .ant-modal-content {
    padding: 20px 20px 24px !important;
  }

  .bd-icon-modal {
    position: relative;
    margin-top: 8px;
    .configure-buttons-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      border: 1px solid #f2f3f8;
      height: 48px;
      border-radius: @border-radius;
      position: relative;
      margin-bottom: 32px;
      cursor: pointer;
      .bd-btn {
        z-index: 4;
        width: 230px;
        height: 48px;
        color: @Neutral500;
        text-align: center;
        line-height: 48px;
        &.active {
          color: #fff;
          width: 232px !important;
        }
      }
      .configure-buttons-slider {
        position: absolute;
        z-index: 3;
        border-radius: @border-radius;
        width: 232px;
        margin-top: -1px;
        height: 48px;
        background: @blue500;
        transition: 0.3s cubic-bezier(0, 0, 0, 1);
        &.benefits {
          margin-left: 0%;
        }
        &.drawbacks {
          margin-left: 50%;
        }
      }
    }
    .search {
      .search_wrapper {
        margin-bottom: 16px;
        margin-top: 0;
        .search-input {
          margin: 0;
        }
      }
    }
    .bd-icons-list {
      overflow-y: scroll;
      height: 309px;
      padding-right: 14px;

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @bluePrimary;
        });
      }
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }
      .bd-icons-wrapper {
        .bd-icon-card {
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 12px;
          cursor: default !important;
          .ant-checkbox-wrapper {
            margin-left: auto;
            .ant-checkbox {
              .ant-checkbox-inner {
                border-radius: @border-radius;
                width: 24px;
                height: 24px;
                border: 1.5px solid @Neutral200;
                &.ant-checkbox-checked {
                  .ant-checkbox-inner {
                    border-color: @blue500;
                  }
                }
                &:after{
                  content: url("../../Images/checkbox-mark.svg");
                  left: 50%;
                  top: 50%;
                  font-size: 0;
                  border: none;
                  width: 16px;
                  height: 16px;
                  transform: scale(1) translate(-50%, -50%);
                }
              }
            }
          }
          .bd-icon-img {
            width: 48px;
            height: 48px;
            border-radius: @border-radius;
          }
          &:hover {
            .ant-checkbox-wrapper {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border-color: @blue300;
                }
              }
            }
          }
          &.drawback:not(.used) {
            cursor: pointer;
            .bd-icon-img.drawback {
              background-color: @yellow100;
            }
            .bd-icon-img > svg {
              *[stroke='#323232'] {
                stroke: @yellow500;
              }
            }
            &.selected {
              background-color: @yellow500;
              .bd-icon-img > svg {
                *[stroke='#323232'] {
                  stroke: @white;
                }
              }
              .bd-icon-text {
                color: @white;
              }
            }
            &:hover:not(.selected) {
              .bd-icon-text {
                color: @yellow400;
              }
              .bd-icon-img > svg {
                *[stroke='#323232'] {
                  stroke: @yellow400;
                }
              }
            }
          }
          &.benefit:not(.used) {
            cursor: pointer;
            .bd-icon-img.benefit {
              background-color: @blue100;
            }
            .bd-icon-img > svg {
              *[stroke='#323232'] {
                stroke: @blue500;
              }
            }
            &.selected {
              background-color: @blue500;
              .bd-icon-img > svg {
                *[stroke='#323232'] {
                  stroke: @white;
                }
                .bd-icon-text {
                  color: @white;
                }
              }
            }
            &:hover:not(.selected) {
              .bd-icon-text {
                color: @blue400;
              }
              .bd-icon-img > svg {
                *[stroke='#323232'] {
                  stroke: @blue400;
                }
              }
            }
          }
          &.used {
            .bd-icon-img > svg {
              *[stroke='#323232'] {
                stroke: @Neutral300;
              }
            }
            .bd-icon-text {
              color: @Neutral300;
            }
          }

          .bd-icon-img {
            display: inline-block;
            width: 32px;
            height: 32px;
            svg {
              width: 32px;
              height: 32px;
            }
          }
          .bd-text-block {
            display: inline-flex;
            flex-direction: column;
          }

          .bd-icon-text {
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            color: @Neutral800;
            margin-left: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .bd-icon-description {
            margin-left: 12px;
            font-size: 12px;
            line-height: 16px;
            color: @Neutral500;
          }
        }
      }
    }
    .ant-divider {
      margin-bottom: 0;
    }
    .bd-icon-counter {
      position: absolute;
      bottom: -80px;
      left: 0;
      width: 130px;
      div {
        font-size: 16px;
        line-height: 24px;
        color: #151E3A;
        margin-bottom: 8px;
        display: flex;
        width: 100%;
        span {
          display: inline-block;
          margin-left: auto;
        }
      }
    }
  }
}
