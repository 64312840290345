@import '../../../styles/utils/variables';

.empty-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  .empty-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.140071px;
    color: #97a3c7;

    margin-left: 8px;
    width: 66%;
  }

  &.performance {
    height: 183px;
  }

  &.status {
    height: 120px;

    .empty-text {
      max-width: 228px;
      width: 44%;
    }
  }

  &.targeting {
    padding-top: 36px;
    height: 165px;
    background-position: center left;

    .empty-text {
      max-width: 222px;
      width: 44%;
    }
  }

  &.screenshot {
    height: 135px;
    padding: 10px;
    justify-content: flex-start;
    border: 1px solid rgba(29, 121, 251, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 8px;

    .empty-text {
      max-width: 116px;
      width: 55%;
      margin-left: 14px;
      text-align: left;
      flex: 1 1;
    }
  }

  &.watchlist {
    padding-top: 20vh;
    width: 100%;
    flex-direction: column;

    .empty-text {
      max-width: 600px;
      width: 55%;
      font-family: 'Gilroy-Regular';
      font-size: 45.4316px;
      line-height: 51px;
      text-align: center;
      letter-spacing: -0.141974px;
      color: #97a3c7;

      margin-top: 16px;
    }

    &.small {
      padding-top: 17px;

      > svg {
        width: 42px;
        height: 42px;
      }
      .empty-text {
        width: 90%;
        font-size: 18px;
        line-height: 25px;
        margin-left: 0;
        margin-top: 8px;
      }
    }

    &.for-tpp-admin {
      padding-top: 22vh;

      > svg {
        height: 84px;
      }

      .empty-text {
        font-size: 40px;
        line-height: 51px;
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 35px;
      }
    }
  }

  &.folder {
    width: 100%;
    height: 17.4vw;
    flex-direction: column;

    &.small {
      height: 300px;
      background-color: #eaedf4;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .empty-text {
      font-family: 'Gilroy-Bold', sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #bdc4db;

      margin-left: 0;
      margin-bottom: 8px;
      margin-top: 10px;
    }
  }

  &.adlibrary {
    width: 100%;
    min-height: 300px;
    height: calc(100dvh - 510px);

    .text-wrap {
      max-width: 542px;
      margin-left: 40px;
    }

    .empty-text {
      width: 100%;
      margin: 0;
      font-family: 'Gilroy-Regular', sans-serif;
      font-size: 48px;
      line-height: 58px;
      letter-spacing: -1px;
      color: #97a3c7;
    }

    .sub-text {
      margin: 0;
      font-family: 'Gilroy-Regular', sans-serif;
      font-size: 24px;
      line-height: 58px;
      letter-spacing: -1px;
      color: #97a3c7;
      text-align: center;
    }
  }

  &.no-access {
    margin: 0 auto;

    .text-wrap {
      max-width: 504px;
      padding-top: 6.25vw;
      .empty-text {
        max-width: 343px;
      }
    }

    .empty-text {
      all: initial;
      display: block;
      font-family: 'Gilroy-Bold', sans-serif;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.2px;
      color: @blue;
      text-align: center;
      margin: 0 auto 12px;
    }

    .sub-text {
      font-family: 'Gilroy-Regular', sans-serif;
      font-size: 16px;
      line-height: 23.5px;
      text-align: center;
      letter-spacing: -0.2px;
      color: @blue;
      margin-bottom: 32px;
    }

    .ant-btn {
      width: 343px;
      border-radius: 10px;
      margin: 0 auto;
      font-size: 14px;
    }
  }

  &.dark {
    .empty-text,
    .sub-text {
      color: rgba(255, 255, 255, 0.5);
    }

    &.no-access {
      .empty-text,
      .sub-text {
        color: @blue;
      }
    }
  }
}
