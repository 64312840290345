@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.inner-page-info {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 32px;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }

  &-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: calc(100% / 7);
    padding: 8px 24px;
    .with-theme({
      border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      width: 100% !important;
      flex-direction: row;
      justify-content: space-between;
      border-right: none !important;
      padding: 4px 0 !important;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &:last-of-type{
      border-right: none !important;
      padding-right: 0 !important;
      width: calc(100% / 4.5);;
    }

    &:first-of-type {
      width: calc(100% / 9.5);
      padding-left: 0 !important;
    }

    .title {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      })
    }

    .value {
      white-space: nowrap;
      text-transform: capitalize;
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &:not(:has( > div.ant-divider)):not(:has( > a)) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;
      }

      & > span {
        transition: all .3s ease;
        color: @bluePrimary;
        cursor: pointer;

        &:not(.disabled):hover {
          color: @blueDepressed;
        }

        &.disabled {
          cursor: not-allowed;
          position: relative;
          .with-theme({
            color: @vars[neutral-300-to-neutral-dark-300];
          });

          .disabled-calendar_tooltip {
            text-transform: none;
            max-width: 400px;
            top: -35px !important;
          }
        }
      }
    }
  }
}
