@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.auto-ds-banner {
  padding: 24px 0;
  width: calc(100% - 64px);
  margin: 0 auto;
  border-radius: @border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0 24px;
    height: auto;
    width: calc(100% - 32px);
    gap: 16px;
  }

  &-btn {
    display: flex;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: none !important;
    background: var(--Light-Background-Default-Light, #FFF);
    .with-theme({
      color: @vars[neutral-800-to-100];
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus, &:active {
        background: @vars[neutral-50-to-neutral-dark-100];
        color: @vars[neutral-800-to-100];
      }
    });

    &.disabled {
      cursor: not-allowed;
      box-shadow: none !important;
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100];
        color: @vars[neutral-300-to-neutral-dark-300] !important;
      })
    }
  }

  &-info {

    &-logo {
      width: 115px;
      height: 48px;

      @media @viewport-mobile {
        width: 99px;
        height: 40px;
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      @media @viewport-mobile {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: 'Inter', sans-serif;

      @media @viewport-mobile {
        gap: 4px;
      }

      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        @media @viewport-mobile {
          font-size: 14px;
        }
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        @media @viewport-mobile {
          font-size: 14px;
        }
      }
    }
  }
}
