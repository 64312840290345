@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.example-of-stores-wrapper {
  margin: 64px auto 0;
  background: #FCFCFC;
  padding: 64px 0;

  @media @viewport-mobile {
    padding: 24px 0 48px;
  }

  .example-of-stores-content {
    width: 1140px;
    margin: 0 auto;

    @media @viewport-mobile {
      width: 100%;
      padding: 0 15px;
    }

    h4 {
      text-align: left;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: @dark;
      margin-bottom: 0;

      @media @viewport-mobile {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .example-of-stores-cards {
      margin-top: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 15px;

      @media @viewport-mobile {
        display: block;
        margin-top: 24px;

        .slick-dots {
          bottom: -24px;

          & > li {
            width: 6px;
            height: 6px;

            &.slick-active > button {
              background: @bluePrimary;
            }

            & > button {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: @blueLight;
            }
          }
        }

      }

      .example-of-stores-card {
        width: 32%;
        height: 257px;
        border-radius: 16px;
        border: 1px solid @Neutral100;
        background: @white;
        box-shadow: 0 8px 40px 0 rgba(112, 123, 160, 0.10);
        background-repeat: no-repeat;
        background-size: cover;

        @media @viewport-mobile {
          width: 100%;
          box-shadow: none;
        }

        &--0 {
          background-image: url("../../../Images/ClickFunnelsExample0.png");
        }

        &--1 {
          background-image: url("../../../Images/ClickFunnelsExample1.png");
        }

        &--2 {
          background-image: url("../../../Images/ClickFunnelsExample2.png");
        }

        &--3 {
          background-image: url("../../../Images/ClickFunnelsExample3.png");
        }

        &--4 {
          background-image: url("../../../Images/ClickFunnelsExample4.png");
        }

        &--5 {
          background-image: url("../../../Images/ClickFunnelsExample5.png");
        }
      }
    }
  }
}
