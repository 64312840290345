@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.portfolio-page {
  padding: 0 !important;
  overflow: unset!important;
  overflow-x: visible!important;
  max-height: 100dvh;
  overscroll-behavior: contain;

  .sales-tracker-top-page-header .title-wrapper-icon {
    border: 1px solid rgba(70, 13, 144, 0.50);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #661AC6;
    box-shadow: 0px 20px 20px -10px rgba(70, 13, 144, 0.04), 0px 10px 10px -5px rgba(70, 13, 144, 0.04), 0px 5px 5px -2.5px rgba(70, 13, 144, 0.04), 0px 2.5px 2.5px -1.25px rgba(70, 13, 144, 0.04), 0px 0.833px 0.833px -0.417px rgba(70, 13, 144, 0.04), 0px 0px 0px 0.833px rgba(70, 13, 144, 0.04);
  }
}
