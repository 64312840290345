@import '../../../../styles/theme/theme';
@import '../../../../styles/base/animation';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/variables';

.bd-card {
  height: 149px;
  background-color: transparent;
  .with-theme({
    border-color:  @vars[neutral-100-to-blue-900];
  });
  .bd-card-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.18px;
    color: #151E3A;
    margin-top: 12px;

    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  }
  .bd-card-subtitle {
    font-size: 12px;
    line-height: 16px;
    color: #707BA0;
    margin-top: 8px;
  }
  .bd-icons-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    a.icon-btn > svg.icon-edit-pencil {
      &:hover {
        path {
          fill: @blue400;
        }
      }
    }
    .benefit-drawback-img {
      width: 48px;
      height: 48px;
      border-radius: @border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 32px;
        height: 32px;
      }
      &.drawback {
        .with-theme({
          background-color:  @vars[yellow-100-to-900];
        });
        svg {
          *[stroke='#323232'] {
            stroke: @yellow500;
          }
        }
      }
      &.benefit {
        .with-theme({
          background-color:  @vars[blue-100-to-900];
        });
        svg {
          *[stroke='#323232'] {
            stroke: @blue500;
          }
        }
      }
    }
  }
  .ant-card-body {
    padding: 16px;
  }
}
