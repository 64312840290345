@import "../../styles/utils/variables";
@import '../../styles/theme/theme';

.discount-card {

  .input-block {
    margin-bottom: 8px;
  }

  span.error {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: @redPrimary;
  }

  .discount-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .ant-input-affix-wrapper {
    box-shadow: none !important;
    padding: 6px 12px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark-50];
    });

    &.error {
      border: 1px solid @redPrimary !important;
    }

    .ant-input {
      height: 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
      });
    }

    .ant-input-prefix, .ant-input-suffix {
      width: 20px;
      height: 20px;
      svg path {
        .with-theme({
          stroke: @vars[neutral-400-to-neutral-dark-400];
        });
      }
    }
  }
}
