@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.suppliers-page {
  padding: 0;
  overflow: unset!important;
  overflow-x: visible!important;

  .sales-tracker-top-page-header {

    .title-wrapper-icon {
      border: 1px solid rgba(7, 47, 148, 0.50);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #225AEA;
      box-shadow: 0px 30px 30px -15px rgba(6, 73, 42, 0.04), 0px 15px 15px -7.5px rgba(6, 73, 42, 0.04), 0px 7.5px 7.5px -3.75px rgba(6, 73, 42, 0.04), 0px 3.75px 3.75px -1.875px rgba(6, 73, 42, 0.04), 0px 1.25px 1.25px -0.625px rgba(6, 73, 42, 0.04), 0px 0px 0px 1.25px rgba(6, 73, 42, 0.04);

      svg {
        margin-top: 3px;
        margin-left: 2px;
      }
    }
  }

  &-cards-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 32px;
    gap: 24px;

    @media @viewport-mobile {
      flex-direction: column;
      padding: 32px 16px;
      gap: 32px;
    }
  }

  .link.skeleton {
    .skeleton-animation-bg();
    border-radius: 10px;
    display: block;
  }
}
