@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.fadspot-page {
  min-height: 100vh;

  .sales-tracker-top-page-credits {
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[neutral-50-to-neutral-dark-50];
    })
  }
  .fadspot-page-products-filters {
    display: flex;
    padding: 24px 32px;
    justify-content: space-between;

    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      padding: 16px;
    }

    &-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      @media @viewport-mobile {
        flex-direction: column;
        width: 100%;
        align-items: stretch;
      }

      &-buttons {
        display: flex;
        justify-content: space-between;
        gap: 12px;
      }

      .filters-panel {
        display: flex;
        gap: 12px;
        .search-input {
          margin: 0;
          min-width: 400px;

          @media @viewport-mobile {
            min-width: 70%;
          }
        }

        .search-button {
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
          @media @viewport-mobile {
            width: 30%;
          }
        }
      }

    }

    .search-buttons-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .fadspot-top-page-credits-mobile {
      display: flex;
      flex-direction: column  ;
      align-items: center;

      .credits-title {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
          background: @vars[neutral-50-to-neutral-dark-50];
        });
      }

      .credits-value {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .with-theme({
          border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
          border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        .total-green {
          color: @greenPrimary;
        }

        .total-orange {
          color: @orangePrimary;
        }

        .total-red {
          color: @redPrimary;
        }

        .separator {
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          })
        }

        .maximum {
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }
      }
    }


  .fadspot-page-presents-dropdown {
    margin-right: 12px;
  }

  .fadspot-page-filter-btn {
    padding: 8px;
    max-height: 40px;

    &:hover {
      .with-theme({
        background-color: @vars[neutral-50-to-neutral-dark-100];
      });
    }

    &.active {
      .with-theme({
        background-color: @vars[neutral-50-to-neutral-dark-100];
      });
    }

    &.disabled {
      cursor: not-allowed;

      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-200] !important;
      });
    }

    .fadspot-page-filters-preset-btn  {
      margin: 6px;
    }
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .adblock-warning {
      display: flex;
      align-items: center;
      gap: 16px;

      &-icon {
        width: 128px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: @border-radius;
        .with-theme({
          background: @vars[yellow-100-to-900];
        });
      }

      &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-family: 'Inter', sans-serif;

        h3 {
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 8px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });
        }

        p {
          font-size: 14px;
          line-height: 20px;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          });

          &:has(> a) {
            margin-top: 20px;
          }

          a {
            text-decoration: none;
            color: @bluePrimary;
            cursor: pointer;

            &:hover {
              color: @blueDepressed;
            }
          }
        }
      }
    }
  }

  .sales-tracker-top-page-header {
    .title-wrapper-icon {
      border: 1px solid rgba(181, 87, 0, 0.50);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #DF7009;
      box-shadow: 0px 20px 20px -10px rgba(98, 36, 10, 0.04), 0px 10px 10px -5px rgba(98, 36, 10, 0.04), 0px 5px 5px -2.5px rgba(98, 36, 10, 0.04), 0px 2.5px 2.5px -1.25px rgba(98, 36, 10, 0.04), 0px 0.833px 0.833px -0.417px rgba(98, 36, 10, 0.04), 0px 0px 0px 0.833px rgba(98, 36, 10, 0.04);
    }
  }

  &-filters {
    padding: 0 32px;

    @media @viewport-mobile {
      padding: 16px;
    }

    &-wrapper {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
      background: transparent;

      @media @viewport-mobile {
        border: none !important;
      }

      &.active {
        max-height: 1200px;
        overflow: visible;
        padding: 24px 0;
        .with-theme({
          border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });
        
        @media @viewport-mobile {
          max-height: 2500px;
          padding: 0;
        }
      }
    }

    &-title-wrapper {
      &-item {
        &.video {
          @media screen and (min-width: 1920px) {
            max-width: 310px;
            .fadspot-page-filters-title-wrapper-slider {
              width: 180px;
            }
          }

        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-slider {
        margin-right: 10px;
        width: 260px;
        @media screen and (min-width: 1920px) {
          width: 170px;
        }
      }
    }

    &-title {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 0;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });

      @media @viewport-mobile {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &-preset-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &-preset-btn {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: @bluePrimary;
      cursor: pointer;
      transition: all 0.3s ease;

      &.ant-dropdown-open {
        .dropdown-arrow-icon-wrapper {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &.disabled {
        cursor: not-allowed;
        a {
          cursor: inherit;
        }
      }
    }

    &-main-block-wrapper {
      margin-bottom: 22px;

      .fadspot-page-filters-inputs {
        position: relative;

        &:after {
          position: absolute;
          content: '';
          top: 0;
          right: -23px;
          height: 100%;
          width: 1px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });

          @media @viewport-mobile {
            display: none;
          }
        }

        .info-text_tooltip {
          max-width: 285px;

          @media @viewport-mobile {
            max-width: 210px;
          }
        }

        &-header {
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 24px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }
      }

      .fadspot-page-filters-options {
        &-title {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 8px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          @media @viewport-mobile {
            font-size: 16px;
            font-weight: 500;
            height: 24px;
          }
        }

        &-creative-wrapper {
          display: flex;
          justify-content: space-between;
          max-width: 355px;
          border-radius: 12px;
          margin: 24px 20px;
          margin: 24px 0px;
          padding: 4px;

          @media @viewport-mobile {
            gap: 12px;
            height: 40px;
            min-width: 100%;
          }

          .with-theme({
            background-color: @vars[neutral-50-to-neutral-dark-50];
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });
        }

        &-creative-button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 12px;
          color: @Neutral600;
          border-radius: 8px;
          transition: all 0.5s ease;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;

          @media @viewport-mobile {
            padding: 6px;
          }

          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });

          svg {
            margin-right: 6px;
          }

          &:hover {
            cursor: pointer;

            .with-theme({
              color: @vars[neutral-800-to-100];
              box-shadow: @vars[box-shadow-object-middle];
              background-color: @vars[white-to-neutral-dark-200];
            });
          }

          &.active {
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            .with-theme({
              background-color: @vars[white-to-neutral-dark-200];
              color: @vars[neutral-800-to-100];
            });
          }

          @media @viewport-mobile {
            width: 100%;
          }
        }

        &-creative-button:first-of-type {
          @media @viewport-mobile {
            max-width: 40px;
          }
        }

        &-engagement-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
        }

        &-engagement-filter {
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          min-width: 249px;
          flex: 1;

          @media @viewport-mobile {
            width: 100%;
          }

          .ant-slider-rail {
            height: 8px;
            .with-theme({
              background-color: @vars[blue-100-to-neutral-dark-100];
            });
          }

          .ant-slider-track {
            height: 8px;
          }

          .ant-slider-handle {
            margin-top: 2px;

            &:focus {
              box-shadow: none;
              border: none;
            }

            &:after {
              .with-theme({
                box-shadow: 0 0 0 2px @vars[neutral-800-to-neutral-dark];
              });
            }
          }

          &.disabled {
            .fadspot-page-filters-options-engagement-filter-title {
              .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500] !important;
              })
            }
            .ant-slider {
              cursor: not-allowed;
            }
            .ant-slider-track {
              .with-theme({
                background: @vars[neutral-500-to-neutral-dark-500] !important;
              });
            }
            .ant-slider-rail {
              .with-theme({
                background: @vars[neutral-500-to-neutral-dark-500] !important;
              });
            }
            .ant-slider-handle {
              .with-theme({
                background: @vars[neutral-500-to-neutral-dark-500] !important;
              });
            }
          }

          &-title {
            .with-theme({
              color: @vars[neutral-800-to-200];
            });
          }

          &-range {
            width: 58px;
            .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500];
            });
          }
        }
      }
    }

    &-dropdowns {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      @media @viewport-mobile {
        flex-direction: column;
      }
    }

    &-button {
      &-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        position: relative;

        .ant-tooltip {
          width: 215px;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: @Neutral50;

          a {
            text-decoration: none;
            color: @bluePrimary;
          }
        }

        @media @viewport-mobile {
          justify-content: space-between;
        }
      }

      &-apply {
        margin-left: 16px;
        background: @bluePrimary !important;
        color: @white !important;
        border: none !important;
        border-radius: @border-radius;
        cursor: pointer;
        transition: all 0.3s ease;

        .with-theme({
          box-shadow: @vars[box-shadow-btn-primary];
        });

        &.disabled {
          color: @Neutral500 !important;
          border: 1px solid transparent;
          cursor: not-allowed;
          .with-theme({
            background: @vars[neutral-100-to-800] !important;
          });
        }

        &:hover {
          background: @blueDepressed !important;
        }
      }

      &-reset {
        display: flex;
        align-items: center;
        background: transparent;
        transition: all 0.3s ease;
        padding: 10px 12px;
        height: 40px;
        border-radius: 10px;
        border: none !important;

        @media @viewport-mobile {
          margin-top: 24px;
          width: 100%;
          justify-content: center;
        }

        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          color: @vars[neutral-800-to-100];
          box-shadow: @vars[box-shadow-btn-secondary];
        });

        &:hover {
          .with-theme({
            border-color: @vars[neutral-200-to-neutral-dark-200];
            color: @vars[neutral-800-to-100];
            background: @vars[neutral-100-to-neutral-dark-100];
          });
        }
      }
    }
  }

  &-quick-search-wrapper {
    margin-top: 12px;
    margin-bottom: 8px;
    border-radius: @border-radius;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 23px;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
    });

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 23px;
    }

    .fadspot-page-quick-search-title {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      margin-right: 8px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }

    .fadspot-page-quick-search-items {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      @media @viewport-mobile {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 12px;
        width: 100%;
      }
    }

    .fadspot-page-quick-search-item {
      border-radius: @border-radius;
      padding: 8px 12px;
      border: none;
      color: @bluePrimary;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      cursor: pointer;
      font-weight: 500;
      line-height: 16px;
      transition: all 0.3s ease;
      .with-theme({
        background: @vars[blue-100-to-900];
      });

      @media @viewport-mobile {
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
      }

      &:hover {
        color: @white;
        background: @blueDepressed;
      }

      &.active {
        cursor: default;
        color: @white;
        background: @bluePrimary;

        &:hover {
          color: @white;
          background: @bluePrimary;
        }
      }
    }
  }

  &-pagination-wrapper {
    margin-top: 16px;
    border-radius: @border-radius;
    width: 100%;
    padding: 8px 32px 0px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @viewport-mobile {
      align-items: flex-start;
      padding: 8px 16px;
    }

    .fadspot-page-pagination-header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      .fadspot-page-pagination-title {
        font-size: 18px;

        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      .fadspot-page-pagination-count {
        padding: 4px 6px;
        border-radius: 6px;

        @media @viewport-mobile {
          font-size: 12px;
        }

        .with-theme({
          background-color: @vars[blue-light-to-blue-dark];
          color: @vars[blue-depressed-to-blue-primary];
        });
      }
    }


    @media @viewport-mobile {
      flex-direction: column;
      gap: 16px;
    }

    .product-database-table-header {
      padding: 0;
    }
  }

  &-items-wrapper {
    margin-top: 24px;
    overflow-y: auto;
    height: calc(100dvh - 80px);
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 24px;
    padding-bottom: 24px;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 16px;
      flex-wrap: nowrap;
      overflow-x: hidden;
    }

    @media screen and (min-width: 1441px) and (max-width: 1919px) {
      column-gap: calc((100% - 1290px) / 2);
      padding: 0 calc((100% - 1290px) / 4);
    }

    @media screen and (min-width: 769px) and (max-width: 1441px) {
      justify-content: center;
    }

    @media screen and (min-width: 1920px) {
      column-gap: 16px;
      padding: 0 26px;
    }

    .fadspot-items-empty {
      width: 100%;
      height: calc(100vh - 300px);
      display: flex;
      align-items: center;
      justify-content: center;

      @media @viewport-mobile {
        height: auto;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-sup {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 8px;

        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      &-sub {
        font-size: 14px;
        font-weight: 400;

        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      &-image {
        margin-bottom: 24px;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @bluePrimary;
      });
    }
    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
    }
  }

  .default-search-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 100%;
    margin-top: 24px;

    .with-theme({
      background-color: @vars[neutral-50-to-neutral-dark-50];
      border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &-text {
      font-size: 14px;
      font-weight: 500;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      });
    }
  }
}
