@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.fadspot-page-products {
  font-family: 'Inter', sans-serif;

  &-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    margin-top: 32px;
    height: calc(100vh - 118px);
    overflow-y: scroll;

    @media @viewport-mobile {
      justify-content: center;
    }

    .product-cards-page-item {
      width: 268px;
      height: 520px;
      border-radius: 12px;
      .with-theme({
        background-color: @vars[white-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        width: 100%;
      }

      .item {
        &-logo {
          width: 100%;
          height: 268px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 11px;
          border-top-right-radius: 11px;
          background: @blueLight;
          position: relative;
          max-height: unset;
          transition: all linear 1s;

          &-rank {
            position: absolute;
            min-width: 32px;
            min-height: 32px;
            padding: 6px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: @black;
            opacity: .5;
            z-index: 1;
            color: @white;
            left: 7px;
            top: 7px;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          &:hover{
            a {
              overflow: visible;
              border-radius: 12px;
              img{
                border-radius: 12px;
                transform: scale(1.01);
                height: max-content;
              }
            }

            @media @viewport-mobile {
              a {
                overflow: hidden;

                img {
                  transform: scale(1);
                  height: 268px;
                  border-radius: @border-radius @border-radius 0 0;
                }
              }
            }
          }

          a {
            width: 100%;
            height: 268px;
            overflow: hidden;
            transition: all 0.3s ease;

            img {
              width: 100%;
              height: 268px;
              border-top-left-radius: 11px;
              border-top-right-radius: 11px;
              object-fit: cover;
              object-position: top;
              transition: all 0.3s ease;

              @media @viewport-mobile {
                object-position: 0;
              }
            }
          }
        }

        &-content {
          margin-top: 12px;
          font-family: Inter, sans-serif;
          padding: 0 16px;
        }

        &-title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          margin-bottom: 12px;

          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          &.overflowing {
            &:before {
              position: absolute;
              left: 16px;
              top: 20px;
              width: max-content;
              max-width: 230px;
              white-space: break-spaces;
              border-radius: 6px;
              padding: 16px;
              font-size: 12px;
              line-height: 16px;
              color: @white;
              text-align: left;
              content: attr(data-text);
              .with-theme({
                background-color: @vars[neutral-800-to-neutral-dark];
              });
            }
          }
        }

        &-stat {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 12px;

          &-key {
            font-weight: 400;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            });
            text-align: left;
          }

          &-value {
            text-align: right;
            display: flex;
            align-items: center;

            .with-theme({
              color: @vars[neutral-800-to-100];
            });

            &.clickable {
              color: @bluePrimary;
              cursor: pointer;

              .primary {
                font-size: 18px;
                margin-right: 5px;
                font-weight: 400;
              }

              .secondary {
                font-size: 14px;
                font-weight: 400;

                .with-theme({
                  color: @vars[neutral-600-to-neutral-dark-500];
                });
              }

              &:hover {
                color: @blueDepressed;
              }
            }
          }
        }

        &-btn-wrapper {
          margin-top: 24px;
          display: flex;
          align-items: center;
          gap: 12px;
          width: 100%;

          @media @viewport-mobile {
            justify-content: center;
          }

          .item-btn {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: @border-radius;
            cursor: pointer;
            transition: all .3s ease;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            gap: 8px;
            border-radius: 8px;

            @media @viewport-mobile {
              width: calc(50% - 6px);
            }

            &.import {
              font-weight: 400;
              .with-theme({
                color: @vars[neutral-800-to-200];
                background: @vars[white-to-neutral-dark-50];
                border: 1px solid @vars[neutral-200-to-neutral-dark-200];
                box-shadow: @vars[box-shadow-btn-secondary-without-border];

                svg path {
                  fill: @vars[neutral-800-to-200];
                }
              });

              &:hover {
                color: @bluePrimary;
                border: 1px solid @bluePrimary;

                svg path {
                  fill: @bluePrimary;
                }
              }
            }

            &.track {
              color: @white;
              background: @bluePrimary;
              border: none;
              font-weight: 400;
              .with-theme({
                box-shadow: @vars[box-shadow-btn-secondary-without-border];
              });

              svg path {
                stroke: @white;
              }

              &:hover {
                background: @blueDepressed;
              }

              &.tracked {
                background: @redPrimary;

                &:hover {
                  background: @redDepressed;
                }
              }
            }
          }
        }
      }
    }
    @media @viewport-mobile {
      justify-content: center;
      padding: 0 16px;
      margin: 0 !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }

    .sales-tracker-top-table-body-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sales-tracker-top-table-body-empty-text {

      p {
        text-align: center;

        &:first-of-type {
          font-size: 28px;
          line-height: 36px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          })
        }

        &:last-of-type {
          margin-top: 10px;
          font-size: 16px;
          line-height: 20px;
          color: @Neutral500;
        }
      }
    }
  }

  &-general {
    &-filters {
      padding: 24px 32px 0;

      @media @viewport-mobile {
        padding: 24px 16px 0;
      }
    }

    .fadspot-page-products-bottom-content {
      display: flex;
      align-items: center;
      padding-top: 12px;

      @media @viewport-mobile {
        padding: 0;
        overflow-x: scroll;
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 12px;
        font-weight: 500;

        @media @viewport-mobile {
          justify-content: center;
          min-width: 130px;
          height: 24px;
        }

        &:first-child {
          margin: 0;
        }
        .with-theme({
          background: @vars[blue-light-to-neutral-dark-200];
          color: @vars[blue-depressed-to-neutral-dark-700];
        });

        &.clear-all {
          cursor: pointer;

          @media @viewport-mobile {
            min-width: 85px;
          }
          &:hover {
            .with-theme({
            background: @vars[neutral-100-to-neutral-dark-100];
            })
          }
        }

        &.create-date {
          @media @viewport-mobile {
            min-width: 290px;
          }
        }

        &.create-date {
          @media @viewport-mobile {
            min-width: 290px;
          }
        }

        &.engagement-date {
          @media @viewport-mobile {
            min-width: 350px;
          }
        }

        span {
          .with-theme({
            color: @vars[blue-to-neutral-dark-400];
          });
        }

        .close {
          cursor: pointer;
          svg path {
            .with-theme({
              fill: @vars[blue-to-neutral-dark-400];

              &:hover {
                fill: @vars[blue-depressed-to-neutral-dark-700];
              }
            });
          }
        }

      }

      &-divider {
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
          border-color: @vars[neutral-200-to-neutral-dark-200];
        });
      }
    }

    .product-cards-page-dropdown-wrapper {
      .report-main-block {
        .select-block {
          width: unset !important;
          @media @viewport-mobile {
            width: 200px !important;
          }
        }
      }
      & > .select-block {
        width: unset !important;

        .dropdown-database-disabled {
          .with-theme({
            background: @vars[neutral-50-to-neutral-dark-50] !important;
          });
          cursor: not-allowed;
          position: relative;

          &:hover {
            .with-theme({
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            });
          }
  
          .disabled-calendar_tooltip {
            max-width: 400px;
            top: -35px !important;
          }
        }

        @media @viewport-mobile {
          min-width: 140px;
        }
      }

      @media @viewport-mobile {
        flex-direction: row;
        overflow-x: auto;
      }
    }

    .main-filter-dropdown {
      border-radius: 10px;
      padding: 8px 12px;

      @media @viewport-mobile {
        min-width: 138px;
      }

      .with-theme({
        &:hover {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        color: @vars[neutral-800-to-200];
        // box-shadow: @vars[box-shadow-btn-secondary-without-border];
      });

      &:has(.ant-dropdown-open) {
        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[neutral-50-to-neutral-dark-100];
        });
        box-shadow: none;
      }

      svg path {
        fill: transparent;
      }
    }

    .report-main-block {
      padding: 0;

      @media @viewport-mobile {
        width: 100%;
      }

      .report_period {
        .ant-dropdown-trigger.dropdown-database {
          padding: 8px 12px;
          height: 38px;
        }
        .with-theme({
          color: @vars[neutral-800-to-100];
          // box-shadow: @vars[box-shadow-btn-secondary-without-border];
        });

        .ant-dropdown-trigger.dropdown-database.ant-dropdown-open {
          .with-theme({
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            background: @vars[neutral-50-to-neutral-dark-100];
          });
        }

        .ant-dropdown-trigger.dropdown-database:not([disabled]):hover {
          .with-theme({
            background: @vars[neutral-50-to-neutral-dark-100];
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });
        }
      }
    }

    &-header-title {
      display: flex;
      align-items: center;
      gap: 8px;

      &-text {
        font-size: 18px;
      }

      &-count {
        padding: 4px 6px;
        border-radius: 6px;

        @media @viewport-mobile {
          font-size: 12px;
        }

        .with-theme({
          background-color: @vars[blue-light-to-blue-dark];
          color: @vars[blue-depressed-to-blue-primary];
        });
      }

      .with-theme({
        color: @vars[neutral-800-to-100];
        font-weight: 500
      });
    }
    .fadspot-page-products-categories {
      display: flex;
      justify-content: space-between;
      max-width: 355px;
      height: 40px;
      border-radius: 12px;
      padding: 4px;

      .with-theme({
        background-color: @vars[neutral-50-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        gap: 6px;
        height: 40px;
        min-width: 100%;
      }

    }

    .fadspot-page-products-category {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      color: @Neutral600;
      border-radius: 8px;
      transition: all 0.5s ease;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-right: 4px;

      @media @viewport-mobile {
        padding: 6px;
        &:first-of-type {
          max-width: 40px;
        }
      }

      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      svg {
        margin-right: 6px;
      }

      &:hover {
        cursor: pointer;

        .with-theme({
          color: @vars[neutral-800-to-100];
          box-shadow: @vars[box-shadow-object-middle];
          background-color: @vars[white-to-neutral-dark-200];
        });
      }

      &.active {
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .with-theme({
          background-color: @vars[white-to-neutral-dark-200];
          color: @vars[neutral-800-to-100];
        });
      }

      &.disabled {
        cursor: not-allowed;
      }

      @media @viewport-mobile {
        width: 100%;
      }
    }

    .fadspot-page-products-dropdown-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      @media @viewport-mobile {
        gap: 10px;
        width: 100%;
      }

      .main-filter-dropdown {
        position: relative;

        &.price {
          .main-filter-dropdown-content-wrapper {
            @media @viewport-mobile {
              left: 0 !important;
            }
          }
        }
        &.revenue {
          .main-filter-dropdown-content-wrapper {
            left: -249px !important;
            @media @viewport-mobile {
              left: 0 !important;
            }
          }
        }
        &.sales {
          .main-filter-dropdown-content-wrapper {
            left: -397px !important;
            @media @viewport-mobile {
              left: 0 !important;
            }
          }
        }

      }
    }

    .fadspot-page-products-filters-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      margin: 16px 0;
      background: transparent;

      @media @viewport-mobile {
        height: auto !important;
        flex-direction: column;
        align-items: flex-start;
      }

      .product-cards-page-search {
        width: 520px;
        min-width: 360px;

        @media @viewport-mobile {
          flex-direction: row;
          width: 100%;
        }

        .top-products-search-wrapper {
          display: flex;
          align-items: center;
          gap: 16px;

          @media @viewport-mobile {
            flex-direction: column;
            gap: 10px;
            flex-direction: row;

            .sales-tracker-search {
              width: 100%;
            }
          }

          & > button {
            width: 80px !important;
          }

          .top-products-search-list {
            align-items: center;
            border-radius: @border-radius;
            display: flex;
            min-height: 40px;
            padding: 0 18px;
            width: calc(100% - 83px);
            transition: all .3s ease;
            .with-theme({
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            });

            @media @viewport-mobile {
              width: 100%;
            }

            &:focus-within {
              border: 1px solid @bluePrimary;
            }

            .top-products-search-prefix {
              align-items: center;
              display: flex;
              margin-right: 16px;

              .ant-divider {
                height: 32px;
                margin-left: 16px;
                margin-right: 0;
                .with-theme({
                  background: @vars[neutral-100-to-800];
                })
              }

              svg path {
                .with-theme({
                  fill: @vars[neutral-200-to-neutral-dark-200];
                });
              }
            }

            .top-products-search-list-items {
              align-content: center;
              display: flex;
              flex-wrap: wrap;
              max-width: calc(100% - 65px);
              min-height: 30px;

              .ant-input {
                background-color: initial!important;
                border: none!important;
                caret-color: @bluePrimary;
                height: 30px!important;
                padding: 0!important;

                &::placeholder {
                  .with-theme({
                    color: @vars[neutral-400-to-neutral-dark-400];
                  });
                }
              }

              .ant-tag {
                align-items: center;
                justify-content: space-between;
                border: none;
                border-radius: 6px;
                display: flex;
                font-family: Inter,sans-serif;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                margin-bottom: 8px;
                margin-right: 8px;
                white-space: pre-wrap;
                .with-theme({
                  border: 1px solid @vars[neutral-200-to-neutral-dark-200];
                  color: @vars[neutral-800-to-200];
                });

                @media @viewport-mobile {
                  padding: 0 4px;
                }

                &-close-icon {
                  padding: 1.75px;
                  border-radius: 4px;

                  .with-theme({
                    svg {
                      width: 15px;
                      height: 15px;

                      @media @viewport-mobile {
                        width: 13px;
                        height: 13px;
                      }

                      path {
                        stroke: @vars[neutral-600-to-neutral-dark-500];
                      }
                    }
                  })
                }
              }
            }
          }
        }
      }

      .fadspot-page-products-dropdown-wrapper {
        .main-filter-dropdown.price .main-filter-dropdown-content-wrapper {
          left: -246px !important;

          @media @viewport-mobile {
            left: 0 !important;
          }
        }

        .main-filter-dropdown.ads .main-filter-dropdown-content-wrapper {
          left: -370px !important;

          @media @viewport-mobile {
            left: 0 !important;
          }
        }
      }
      .top-products-search-list {
        border-radius: 10px !important;
      }
      button {
        border-radius: 10px;
        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          color: @vars[neutral-800-to-100];
          background-color: @vars[white-to-neutral-dark];
        });

        &:hover {
          .with-theme({
          background: @vars[neutral-50-to-neutral-dark-100];
          });
        }
        &[disabled] {
          .with-theme({
            background: @vars[neutral-50-to-neutral-dark-100];
          });
        }
      }
    }
  }

  &-detailed {
    font-family: 'Inter', sans-serif;
    .fadspot-page-overview-banner {
      margin-top: 0;
    }

    .report-main-block {
      .select-block {
        width: auto !important;
        min-width: 190px;
        @media @viewport-mobile {
          max-width: 100% !important;
        }
      }
    }

    .fadspot-page-product-info-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      @media @viewport-mobile {
        flex-direction: column;
      }

      .fadspot-page-product-image {
        border-radius: @border-radius;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 36px;
          height: 36px;
          object-fit: cover;
          border-radius: @border-radius;

          @media @viewport-mobile {
            width: 32px;
            height: 32px;
          }
        }
      }

      .fadspot-page-product-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        @media @viewport-mobile {
          width: 100%;
        }

        .main {
          width: 100%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          @media @viewport-mobile {
            height: unset;
          }

          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 22px 32px;

            @media @viewport-mobile {
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
            }

            .with-theme({
              border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
              border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
            });


            .product-info-header {
              display: flex;
              align-items: center;

              @media @viewport-mobile {
                width: 100%;
                margin-bottom: 16px;
              }

              h3 {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 12px;
                height: 20px;
                .with-theme({
                  color: @vars[neutral-800-to-200];
                });

                @media @viewport-mobile {
                  text-align: left;
                  max-width: 100%;
                }
              }
            }

            .product-info-buttons {
              display: flex;
              align-items: center;
              gap: 12px;

              @media @viewport-mobile {
                flex-direction: column;
                width: 100%;
              }

              button {
                border-radius: 10px;

                @media @viewport-mobile {
                  width: 100%;
                }

                &.product-info-btn-import {
                  background-color: @bluePrimary !important;
                  border: none;

                  span {
                    color: @Neutral50 !important;
                  }
                }

                .with-theme({
                  border: 1px solid @vars[neutral-200-to-neutral-dark-200];
                  background-color: @vars[white-to-neutral-dark-50];
                  box-shadow: @vars[box-shadow-btn-secondary-without-border];
                });

                &:hover {
                  border: 1px solid @blueDepressed;
                }

                span {
                  display: flex;
                  align-items: center;
                  font-weight: 400;

                  .with-theme({
                    color: @vars[neutral-800-to-100];
                  });

                  svg {
                    margin-right: 8px;
                  }
                }
              }
            }


            span {
              position: relative;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              cursor: pointer;
              color: @bluePrimary;
              transition: all .3s ease;

              .with-theme({
                color: @vars[neutral-800-to-200];
              });
            }
          }

          .stats {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            gap: 12%;
            padding: 22px 32px;

            .with-theme({
              border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
            });

            @media @viewport-mobile {
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              padding: 12px 16px;
              margin-bottom: 24px;
            }

            .stat {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              padding-left: 24px;
              flex-wrap: wrap;

              @media @viewport-mobile {
                width: 100%;
                padding: 0;
                flex-direction: row;
                align-items: start;
                justify-content: space-between;
              }

              &:first-child {
                padding-left: 0;
              }

              .stat-divider {
                .with-theme({
                  background-color: @vars[neutral-200-to-neutral-dark-200];
                  border-color: @vars[neutral-200-to-neutral-dark-200];
                });
              }

              .stat-quick-search {
                display: flex;
                align-items: center;
                gap: 8px;
              }

              .with-theme({
                border-left: 1px solid @vars[neutral-200-to-neutral-dark-200];

                @media @viewport-mobile {
                  border: none;
                }

                &:first-child {
                  border: none;
                }
              });

              h4 {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;

                .with-theme({
                  color: @vars[neutral-500-to-neutral-dark-500];
                });
              }

              &.price {
                span {
                  max-width: unset;
                  color: @bluePrimary !important;
                  &:hover {
                    cursor: pointer;
                    color: @blueDepressed !important;
                  }
                }
              }

              span {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                white-space: nowrap;
                max-width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;
                .with-theme({
                  color: @vars[neutral-600-to-neutral-dark-500];
                });

                &.clickable {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  color: @bluePrimary;
                  transition: all .3s ease;
                  cursor: pointer;

                  &:hover {
                    color: @blueDepressed;
                  }
                }
              }
            }
          }
        }

        .search {
          height: 96px;
          width: 100%;
          padding: 16px 24px;
          border-radius: @border-radius;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .with-theme({
            border: 1px solid @vars[neutral-100-to-800];
          });

          @media @viewport-mobile {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            height: unset;

            .btn {
              width: 100% !important;
            }
          }

          & > div {
            display: flex;
            flex-direction: column;
            gap: 8px;

            h4 {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              color: @Neutral500;
            }

            & > div {
              display: flex;
              align-items: center;
              gap: 16px;
            }
          }

          .fadspot-page-product-import {
            width: 128px;
            height: 48px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;

            svg {
              margin-left: 12px;

              path {
                fill: @white;
              }
            }
          }
        }
      }
    }

    .fadspot-page-product-chart-wrapper {
      margin: 16px 32px;
    }

    .fadspot-page-product-additional-info {
      margin: 16px 32px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @media @viewport-mobile {
        margin: 0;
        flex-direction: column;
        align-items: flex-start;
      }

      .additional-info-block {
        width: calc(50% - 8px);

        &.wide {
          width: 100%;
        }

        @media @viewport-mobile {
          width: 100%;
        }

        h4 {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 8px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          })
        }

        .landing-pages {
          padding: 16px;
          height: 350px;
          border-radius: @border-radius;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .with-theme({
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];

            @media @viewport-mobile {
              border: none;
            }
          });


          .landing-pages-count {
            padding: 12px 0px 24px 0px;
            .with-theme({
              color: @vars[neutral-800-to-100];
              font-size: 24px;
            });
          }

          .landing-pages-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            & > h3 {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              })
            }
            span {
              color: @blue400;

              &:hover {
                cursor: pointer;
                color: @blue600;
              }

              svg path {
                &:hover {
                  stroke: @blue600;
                }
              }
            }
          }

          .links {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 12px;
            overflow-y: hidden;
            width: 100%;

            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
              border-radius: 2px;
            }
            &::-webkit-scrollbar-button {
              display: none;
            }
            &::-webkit-scrollbar-track-piece {
              background: transparent;
              border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              .with-theme({
                background: @bluePrimary;
              });
            }
            &::-webkit-scrollbar-track-piece:start {
              background: transparent;
              margin-top: 10px;
            }
            &::-webkit-scrollbar-track-piece:end {
              background: transparent;
              margin-bottom: 10px;
            }

            .link {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;

              .landing-pages-divider {
                .with-theme({
                  background-color: @vars[neutral-200-to-neutral-dark-200];
                  border-color: @vars[neutral-200-to-neutral-dark-200];
                });
              }
              .with-theme({
                color: @vars[neutral-800-to-100];
              });

              svg {
                margin-right: 4px;

                path {
                  .with-theme({
                    fill: @vars[neutral-500-to-neutral-dark-500];
                  });
                }
              }

              & > div {
                display: flex;
                align-items: center;
                padding-right: 10px;
                width: calc(100% - 20px);
                justify-content: space-between;

                & > span {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  & > a {
                    max-width: 85%;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    text-decoration: underline;
                    .with-theme({
                      color: @vars[neutral-500-to-neutral-dark-500];
                    });

                    &:hover {
                      color: @bluePrimary;
                    }
                  }
                }

                & > div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  min-width: 70px;
                }
              }
            }
          }
        }

        .ad-types {

          @media @viewport-mobile {
            height: unset;
            padding-bottom: 0;
          }

          .ad-types-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            width: 100%;

            @media @viewport-mobile {
              flex-direction: column;
              align-items: center;
            }

            .chart {
              width: 170px;
              height: 170px;
              position: relative;
              overflow: hidden;
              background: gold;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              @media @viewport-mobile {
                width: 136px;
                height: 136px;
              }

              .sector {
                position: absolute;
                top: 50%;
                left: 50%;
                transform-origin: 0 0;
                width: 1000px;
                height: 1000px;

                &.video {
                  background: @blue400;
                }

                &.photo {
                  background: @orangePrimary;
                }

                &.carousel {
                  background: @greenPrimary;
                }
              }

              .total-info {
                position: relative;
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 136px;
                height: 136px;
                border-radius: 50%;
                .with-theme({
                  background: @vars[white-to-neutral-dark-50];
                });

                @media @viewport-mobile {
                  width: 110px;
                  height: 110px;
                }

                h4 {
                  margin-bottom: 0;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  .with-theme({
                    color: @vars[neutral-600-to-neutral-dark-500];
                  })
                }

                span {
                  padding: 0;
                  margin: 0;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 40px;
                  .with-theme({
                    color: @vars[neutral-800-to-100];
                  })
                }
              }
            }

            .stats {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              width: 100%;
              gap: 24px;

              @media @viewport-mobile {
                width: 100%;
              }

              .stat {
                width: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 8px;

                .title {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  .with-theme({
                    color: @vars[color-dark-to-white];
                  });

                  .title-block-wrapper-count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 80px;
                    .title-block-wrapper-divider {
                      .with-theme({
                        background-color: @vars[neutral-200-to-neutral-dark-200];
                        border-color: @vars[neutral-200-to-neutral-dark-200];
                      });
                    }

                    .title-block-count {
                      font-size: 16px;
                      .with-theme({
                        color: @vars[neutral-800-to-100];
                      });
                    }
                  }

                  .title-video-block {
                    height: 8px;
                    width: 8px;
                    background-color: @blue400;
                    border-radius: 2px;
                    margin-right: 14px;
                  }

                  .title-photo-block {
                    height: 8px;
                    width: 8px;
                    background-color: @orangePrimary;
                    border-radius: 2px;
                    margin-right: 14px;
                  }

                  .title-carousel-block {
                    height: 8px;
                    width: 8px;
                    background-color: @greenPrimary;
                    border-radius: 2px;
                    margin-right: 14px;
                  }

                  span {
                    .with-theme({
                      color: @vars[neutral-600-to-neutral-dark-500];
                    });
                  }

                  svg {
                    margin-right: 4px;
                  }

                  & > div {
                    display: flex;
                    align-items: center;
                  }
                }

                .track {
                  width: 100%;
                  border-radius: @border-radius;
                  height: 6px;
                  .with-theme({
                    background: @vars[blue-100-to-900];
                  });

                  .active {
                    height: 100%;
                    border-radius: @border-radius;
                  }
                }
              }
            }
          }
        }

        .ad-status {

          @media @viewport-mobile {
            height: unset;
          }

          .info-stats {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @media @viewport-mobile {
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
            }

            .info-stat-wrapper {
              display: flex;
              align-items: center;
              gap: 8px;
              width: 50%;

              @media @viewport-mobile {
                width: 100%;
              }

              .bar {
                width: 8px;
                height: 84px;
                border-radius: 2px;
                background: #FFEBEB;

                &.active {
                  background: #C5FFDD;
                }
              }

              .stats {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .title {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  color: @Neutral500;
                }

                .value {
                  font-size: 26px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 40px;
                  .with-theme({
                    color: @vars[neutral-800-to-200];
                  })
                }

                .types {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  .type {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    svg {
                      margin-right: 4px;
                    }

                    span {
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px;
                      .with-theme({
                        color: @vars[neutral-800-to-200];
                      })
                    }
                  }
                }
              }
            }
          }

          .diagram {
            width: 100%;
            height: 72px;
            margin-top: 16px;
            border-radius: @border-radius;
            background: #FFEBEB;

            .active {
              border-radius: @border-radius 0 0 @border-radius;
              background: #C5FFDD;
              position: relative;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
