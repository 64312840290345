@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import '../../../../../styles/utils/variables';

.description-block {
  margin: 24px 32px;
  &_wrapper&_wrapper {
    .block-with-label {
      .description-subblock {
        max-height: 276px;
        min-height: 276px;
        width: 100%;
        padding: 15px 6px 15px 15px;
        border: 1px solid #f2f3f8;
        border-radius: @border-radius;

        &-error {
          border: 1px solid @redPrimary !important;
        }

        .description-textarea {
          &:focus {
            border: none;
            box-shadow: none;
          }
          border-radius: 0;
          border: none;
          font-size: 16px;
          line-height: 24px;
          color: @Neutral800;
          max-height: 246px;
          min-height: 246px;
          width: 100%;
          padding: 0 6px 0 0;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }
        }
      }
    }
  }
  .common-links-block {
    width: 100%;
    max-height: 276px;
    height: 276px;
    border: 1px solid #f2f3f8;
    border-radius: @border-radius;
    padding: 15px 6px 15px 15px;
    &_wrapper {
      width: 100%;
      height: 246px;
      padding-right: 6px;
      overflow-y: scroll;
      .custom-scroll-bar();
    }
    .block-with-label {
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 24px;
      color: @Neutral500;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 0;
      }
      &_input {
        color: @Neutral800;
      }
    }
  }
}
