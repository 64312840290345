@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.switch-block-settings {
  padding: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  text-wrap: nowrap;
  .with-theme({
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    background: @vars[neutral-50-to-neutral-dark-50];
  });
  @media @viewport-mobile {
    width: 100%;
  }
  .value {
    transition: all .3s ease-in-out;
    padding: 6px 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
    @media @viewport-mobile {
      width: 100%;
      text-wrap: nowrap;
    }

    &.active {
      box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
      .with-theme({
        background: @vars[white-to-neutral-dark-200];
        color: @vars[neutral-800-to-white];
      });
      @media @viewport-mobile {
        width: 100%;
      }
    }

    &-tag {
      display: flex;
      padding: 4px 10px;
      align-items: center;
      border-radius: 6px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100];
        color: @vars[neutral-600-to-neutral-dark-500];
      })
    }
  }
}