@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.store-tracker-page-overview-blocked {
  margin: 0 32px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: calc(100% - 64px);
  height: 450px;
  .with-theme({
    background: @vars[neutral-50-to-neutral-dark-50];
  });
  &.adLibrary{
    height: unset;
  }

  @media @viewport-mobile {
    margin: 0 16px 40px;
    width: calc(100% - 32px);
    aspect-ratio: 1;
    height: unset;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 40px;
    height: 40px;
    .with-theme({
      background: @vars[neutral-800-to-neutral-dark];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    })
  }

  &-title {
    margin-top: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      margin-top: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  &-text {
    width: 450px;
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-500-to-neutral-dark-500];
    });

    @media @viewport-mobile {
      padding: 0 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      width: 100%;
    }
  }

  &-btn {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    .with-theme({
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark-50];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;

      &:hover {
        background: @vars[neutral-50-to-neutral-dark-100] !important;
      }

      svg path {
        stroke: @vars[neutral-500-to-neutral-dark-500];
      }
    });

    @media @viewport-mobile {
      margin-top: 16px;
    }
  }
}
