@import '../../../styles/theme/theme';
@import "../../../styles/utils/variables";

.payment-option-card{
  padding: 13px 15px;
  border-radius: @border-radius;
  border: 1px solid;

  .with-theme({
    background-color: @vars[white-to-neutral-dark-50];
    border-color: @vars[neutral-200-to-neutral-dark-200];
    // border-color: @vars[neutral-100-to-800];
  });

  display: flex;
  align-items: center;
  justify-content: space-between;


  &:not(:last-of-type){
    margin-bottom: 16px;
  }

  .info-wrp{
    > span {
      display: block;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
    }

    > .info-title{
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.18px;
      margin-bottom: 4px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }

    > .info-text{
      font-size: 12px;
      line-height: 16px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }
  }
}
