@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.product-database-page {
  padding: 0 !important;
  overflow: unset !important;
  overflow-x: visible !important;
  height: 100dvh;
  overscroll-behavior: contain;
  font-family: 'Inter', sans-serif;

  @media @viewport-mobile {
    overflow-x: hidden !important;
    max-width: 100vw;
  }

  .sales-tracker-top-page-header {
    margin-bottom: 48px;

    .title-wrapper-icon {
      border: 1px solid rgba(9, 111, 69, 0.50);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #16895A;
      box-shadow: 0px 30px 30px -15px rgba(6, 73, 42, 0.04), 0px 15px 15px -7.5px rgba(6, 73, 42, 0.04), 0px 7.5px 7.5px -3.75px rgba(6, 73, 42, 0.04), 0px 3.75px 3.75px -1.875px rgba(6, 73, 42, 0.04), 0px 1.25px 1.25px -0.625px rgba(6, 73, 42, 0.04), 0px 0px 0px 1.25px rgba(6, 73, 42, 0.04);
    }
  }

  .sales-tracker-top-page-limits-wrapper {
    gap: 12px;
  }

  &-no_scroll {
    overflow-y: hidden !important;
  }

  .link.disabled {
    pointer-events: none;
  }

  .block-with-label-custom {
    &:after {
      width: 1px;
      height: calc(100% - 16px);
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      })
    }
  }

  &.productDatabase-page {
    .page-options-wrapper {
      .search-options .search {
        flex-direction: row;
      }
      .search-submit {
        @media @viewport-mobile {
          width: 88px;
        }
      }
    }
    .view-options {
      .filters {
        @media @viewport-mobile {
          width: 100%;
        }
      }
    }
    .fadspot-page-quick-search-wrapper.skeleton {
      margin: 0;
      border: none;

      @media @viewport-mobile {
        .fadspot-page-quick-search-items {
          margin: 0;
        }
      }

      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        border-radius: 0px;
      });
    }

    .product-database-table-wrapper {
      border-top: none;
      margin-top: 0;

      &-empty {
        height: calc(100vh - 260px);
      }

      .ant-table-body {
        &::-webkit-scrollbar {
          width: 4px; height: 0;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }
        &::-webkit-scrollbar-track-piece:start {
          background: transparent;
          margin-top: 10px;
        }
        &::-webkit-scrollbar-track-piece:end {
          background: transparent;
          margin-bottom: 10px;
        }
      }
    }

    .database-product-card-price {
      margin-bottom: 4px;
      margin-top: 8px;
    }

    .database-product-cards .database-product-card {
      height: 525px;

      @media @viewport-mobile {
        height: unset;
      }
    }

    .limited-banner {
      margin: 0;
    }
  }


  .product-database-filter-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    background: transparent;
    padding: 0 32px;

    .product-details-card {
      padding: 0;
    }

    &.active {
      max-height: 2500px;
      overflow: visible;
      padding: 24px 32px;
    }
    @media @viewport-mobile {
      padding: 0;
      &.active {
        padding: 0;
      }
    }
  }

  .product-details-card-header {
    @media @viewport-mobile {
      margin-top: 20px;
      padding: 0 24px;
    }
  }

  .product-details-card-header .header-tag {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 16px;
  }

  @media @viewport-mobile {
    max-width: 100vw;
    padding: 20px;
    height: calc(var(--app-height) - 71px);
    overflow-y: auto;

    .empty-row {
      display: none;
      min-height: 110px;
    }
  }

  .product-database-mobile-banner {
    color: @yellowPrimary;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid @yellowPrimary;
    border-radius: @border-radius;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 8px;
    .with-theme ({
      background: @vars[yellow-100-to-900];
    });

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      })
    }
  }
}

.preset-notification-block {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  .with-theme({
    color: @vars[neutral-800-to-200];
  });

  @media @viewport-mobile {
    font-size: 12px;
    line-height: 16px;
  }
}

.preset-delete-notification {
  .notification__icon {
    .with-theme({
      background: @vars[red-100-to-900];
    });
  }
  .notification__name {
    width: 100%;
  }
}

.notification--restore {
  .notification__name {
    width: 100% !important;
  }
}
