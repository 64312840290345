@import '../../../styles/base/ui-kit';
@import '../../../styles/theme/theme';

.theme-switcher {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.3px;
  position: relative;

  &.settings {

    @media @viewport-mobile {
      width: 100%;
    }

    .ant-dropdown {
      position: absolute;
      left: -42px !important;
      top: 48px !important;
    }

    .theme-switcher-inner {
      justify-content: space-between;
      width: 120px;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
        background: @vars[white-to-neutral-dark] !important;

        &:hover, &.ant-dropdown-open {
          border: 1px solid @bluePrimary !important;
        }
      });

      @media @viewport-mobile {
        width: 100%;
      }

      span {
        margin-left: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        @media @viewport-mobile {
          margin-left: 12px;
          width: calc(100% - 32px);

          & > svg {
            margin-left: auto;
          }
        }
      }

      &.ant-dropdown-open {
        span svg {
          transform: rotate(180deg) !important;
        }
      }

      span svg {
        transition: all .3s ease;
        transform: rotate(0);

        path {
          .with-theme({
            stroke: @vars[neutral-400-to-neutral-dark-400];
          })
        }
      }
    }
  }

  .ant-dropdown {
    position: absolute;
    left: 95% !important;
    top: -4px !important;
  }

  &-dropdown {
    display: flex;
    width: 163px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 12px;
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      box-shadow: @vars[box-shadow-object-large];
    });

    &-option {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      margin: 0 auto;
      width: calc(100% - 8px);
      transition: all .3s ease;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        })
      }

      .sun path {
        .with-theme({
          stroke: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      .moon path {
        .with-theme({
          fill: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      .checkmark {
        margin-left: auto;
      }

      span {
        margin-left: 8px;
        font-weight: 500;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

      }
    }
  }

  &-inner {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 4px auto;
    width: calc(100% - 8px);
    border-radius: 8px;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
      .with-theme({
        background: @vars[blue-100-to-neutral-dark-100];
      })
    }

    .sun path {
      .with-theme({
        stroke: @vars[neutral-600-to-neutral-dark-500];
      })
    }

    .moon path {
      .with-theme({
        fill: @vars[neutral-600-to-neutral-dark-500];
      })
    }

    div {
      margin-left: 8px;
      font-weight: 500;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    span {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      svg {
        transform: rotate(-90deg);
        .with-theme({
          path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        })
      }
    }
  }
}
