@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/animation';
@import '../../../../../styles/base/ui-kit';

.drop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
  font-family: 'Inter', sans-serif;

  @media @viewport-mobile {
    flex-direction: column;
    align-items: center;
  }

  &_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    @media @viewport-mobile {
      gap: 8px;
      align-items: center;
      width: calc(100% - 32px);
      margin: 0 auto;
      padding: 16px 0;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      })
    }

    &-week {
      display: flex;
      align-items: center;
      gap: 12px;

      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      .drop_info-circle {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        .with-theme({
          background: @vars[neutral-300-to-neutral-dark-300];
        })
      }

      .drop_info-progress {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: @purplePrimary;
      }
    }

    &-available {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      span {
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }
  }

  &_timer {

    @media @viewport-mobile {
      align-items: center;
      width: calc(100% - 32px);
      margin: 0 auto;
      padding: 16px 0;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      })
    }
  }

  .timer {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    gap: 40px;

    @media @viewport-mobile {
      gap: 0;
      justify-content: space-evenly;
    }

    // NUMBERS
    &_num {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      @media @viewport-mobile {
        font-size: 24px;
        line-height: 22px;
      }
    }

    // GROUP OF NUMBERS
    .number-group {
      overflow: hidden;
      position: relative;

      .number-grp {
        display: block;
        height: 28px;
        float: left;
        overflow: hidden;

        .number-grp-wrp {
          width: 100%;
          position: relative;
          top: 5px;

          .num {
            width: 100%;
            position: relative;
            height: 28px;

            p {
              width: auto;
              display: table;
            }

          }
        }
      }
    }

    &_text {
      margin-top: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      text-transform: capitalize;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      @media @viewport-mobile {
        margin-top: 0;
      }
    }
  }

  &_data {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media @viewport-mobile {
      width: calc(100% - 32px);
      padding: 16px 0;
      align-items: flex-start;
    }
  }

  &_row {
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @viewport-mobile {
      width: 100%;
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.strong {
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }
  }

  &_link {
    transition: all .3s ease;
    color: @bluePrimary;
    cursor: pointer;

    &:hover {
      color: @blueDepressed;
      text-decoration: underline;
    }
  }
}

.theme-default {
  .drop {

    .timer {

      &_days {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media @viewport-mobile {
          gap: 2px;
        }

        &-separator {
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          .with-theme({
            color: @vars[neutral-300-to-neutral-dark-300];
          })
        }

        .timer_num .num {
          .with-theme({
            background: @vars[neutral-800-to-100];
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          });
        }
      }

      // HOURS
      &_hours {
        .timer_num .num{
          .with-theme({
            background: @vars[neutral-800-to-100];
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          });
        }
      }

      // MIN
      &_min {
        .timer_num .num{
          .with-theme({
            background: @vars[neutral-800-to-100];
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          });
        }
      }

      // SEC
      &_sec {
        .timer_num .num{
          .with-theme({
            background: @vars[neutral-800-to-100];
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          });
        }
      }
    }
  }
}
