@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.compare-headers-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;

  .compare-headers-first-section{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: auto;

    .switch-block-settings-header{
      width: 100px;
      color: @greenPrimary;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
    }
  }
}
