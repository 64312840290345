@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.university-video-card {

  &-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 357px;
    height: 332px;
    border-radius: 16px;
    padding: 16px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      width: 100%;
      height: auto
    }
  }

  &-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    height: 184px;
    min-height: 150px;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    .with-theme({
      background-color: @vars[blue-xlight-to-neutral-dark-50];
    });

    svg {
      z-index: 5;
    }

    @media @viewport-mobile {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: auto;
    }
    @media screen and (orientation: landscape) and (max-width: 800px) {
      height: 300px;
    }

    &.completed {

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        z-index: 2;
        .with-theme({
          background: @vars[completed-video];
        });
      }
    }
  }

  &-info {
    padding: 16px 0 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  }

  &-status {
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    .progress-wrapper {
      height: 8px;
      flex: 1;
      border-radius: 128px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100];
      });

      .progress {
        height: 100%;
        border-radius: 128px;
        background: @greenPrimary;
      }
    }

    span {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &.complete {
      color: @greenPrimary !important;
    }
  }

  &-title {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  &-description {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }
}
