@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.benefit-drawback-card{
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-text-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
  }

  &-img-wrap{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    .with-theme({
      background: @vars[blue-xlight-to-neutral-dark-50];
    });

    > svg{
      width: 24px;
      height: 24px;
      *[stroke='#323232']{
        stroke: @blue500;
      }
    }

    &.drawback{
      .with-theme({
        background-color: @vars[yellow-100-to-900];
      });
      > svg{
        *[stroke='#323232']{
          stroke: @yellow500;
        }
      }
    }
  }

  &-label{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  &-text{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500]
    });
  }
}
