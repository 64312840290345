@import '../../../styles/utils/variables';
@import '../../../styles/base/animation';
@import '../../../styles/theme/theme';

.not-found{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .with-theme({
    background: @vars[color-white-to-dark];
  })
}

.not-found img{
  position: absolute;
  transform: translate(-50%, 0);
  top: 40px;
  left: 50%;
  cursor: pointer;
}

.not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  .with-theme({
    color: @vars[neutral-800-to-200];
  });

  p {

    &:first-of-type {
      font-size: 36px;
      line-height: 40px;
    }

    &:last-of-type {
      margin-top: 16px;
      font-size: 30px;
      line-height: 36px;
    }

    & a {
      text-decoration: underline;
    }
  }

  & > div {
    font-size: 120px;
    line-height: 140px;
    font-weight: bold;
    color: @Neutral500;
    margin-bottom: 32px;
  }
}

@media (max-width: 1151px) {
  .not-found {
    justify-content: center;
    align-items: flex-start;
  }

  .not-found-content {
    width: auto;
    align-items: center;
    text-align: center;
    margin-top: 160px;
  }

}

@media (max-width: 767px) {
  .not-found img{
    width: 24px;
    top: 16px;
  }
  .not-found-content {
    margin-top: 184px;
  }

  .not-found-content > * {
    width: 264px;
  }

  .not-found-content > div {
    font-size: 80px;
    line-height: 96px;
    margin-bottom: 24px;
  }

  .not-found-content > p {
    font-size: 16px;
    line-height: 24px;
  }
}
