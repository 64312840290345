@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.product-details-card {
  position: relative;
  padding: 24px;
  width: 100%;
  font-family: 'Inter', sans-serif;

  &.competitors-product-detail-wrapper {
    padding: 0;
  }

  &:has(.interest-card-header) {
    padding: 0;
    .product-details-card-body {
      margin-top: 24px;

      @media @viewport-mobile {
        margin-top: 16px;
      }

      .os-scrollbar.os-scrollbar-vertical {
        right: 10px;
      }
    }
  }

  &:has(.numbers-breakdown-card) {

    .product-details-card-header {
      gap: 24px;

      .link {
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: @bluePrimary;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
          color: @blueDepressed;
        }

        &.disabled {
          cursor: not-allowed;
          .with-theme({
            color: @vars[neutral-300-to-700] !important;

            svg path {
              stroke: @vars[neutral-300-to-700];
            }
          })
        }
      }
    }

    .product-details-card-body {
      margin-top: 24px;

      @media @viewport-mobile {
        margin-top: 16px;
      }
    }
  }

  &:has(.suppliers-header) {
    padding: 0;
    .product-details-card-header-toggle {
      margin-bottom: 0;
    }

    .product-details-card-body {
      margin-top: 0;

      @media @viewport-mobile {
        overflow-x: auto;
      }

      .competitors-card-list-header {
        margin-bottom: 16px;

        @media @viewport-mobile {
          padding: 0 16px;
          min-width: 1050px;
        }

        span:not(.skeleton):first-of-type {
          width: 45% !important;
          margin-left: 0;
        }

        span:not(.skeleton) {
          width: 10%;
          margin-left: auto;

          &.small {
            width: 7%;
          }
        }

        span:not(.skeleton):last-of-type {
          width: 20% !important;
        }
      }
    }
  }

  &:has(.videos-card-wrap) {

    .product-details-card-body {
      margin-top: 24px;

      @media @viewport-mobile {
        margin-top: 16px;
      }
    }

    .product-details-card-header-toggle {
      margin-bottom: 0;
    }

    .content-btn-wrapper {
      margin-left: 4px;
      display: flex;
      align-items: center;
      gap: 8px;

      .content-btn {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 8px;
        transition: all .3s ease;
        .with-theme({
          box-shadow: @vars[box-shadow-btn-secondary];
          background: @vars[white-to-neutral-dark-50];

          &:hover {
            background: @vars[neutral-50-to-neutral-dark-100];
          }
        });

        svg {
          margin-left: 2px;
          pointer-events: none;
        }

        svg path {
          .with-theme({
            stroke: @vars[neutral-800-to-100];
          });
        }

        &.disabled {
          cursor: not-allowed;
          .with-theme({
            box-shadow: none !important;
            background: @vars[neutral-100-to-neutral-dark-100];

            &:hover {
              background: @vars[neutral-100-to-neutral-dark-100];
            }
          });

          svg path {
            .with-theme({
              stroke: @vars[neutral-300-to-neutral-dark-300];
            });
          }
        }

        &-prev {
          transform: rotate(180deg);
        }
      }
    }
  }

  &:has(.competitors-card-wrap) {
    padding: 0;
    .product-details-card-header {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media @viewport-mobile {
        padding: 0 16px 16px;
      }

      .link {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: @bluePrimary;

        &:not(.disabled):hover {
          @blueDepressed;
        };

        &.disabled {
          color: @Neutral500;
        }
      }

      .title {
        display: flex;
        align-items: center;
        gap: 8px;

        .header-tag {
          padding: 4px 6px;
          border-radius: 6px;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.3px;
          .with-theme({
            background: @vars[blue-xlight-to-neutral-dark];
            color: @vars[blue-depressed-to-white];
          })
        }
      }
    }

    .product-details-card-body {
      margin-top: 0;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;

    @media @viewport-mobile {
      gap: 0;
      row-gap: 16px;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    svg {
      path {
        transition: all .3s ease;
      }

      &:hover path {
        fill:  @bluePrimary;
      }
    }

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.7px;
      white-space: nowrap;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    .text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-400-to-neutral-dark-400];
      });
    }

    &-toggle {
      margin-bottom: 8px;
      margin-left: auto;
      cursor: pointer;
      height: 40px;
      padding: 4px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        width: 100%;
        margin-bottom: 0;
      }

      &-option {
        user-select: none;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all .3s ease;
        border-radius: 8px;
        padding: 8px 12px;
        height: 32px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });

        @media @viewport-mobile {
          width: 50%;
          justify-content: center;
        }

        &.active {
          box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
          .with-theme({
            background: @vars[white-to-neutral-dark-200];
            color: @vars[neutral-800-to-100];
          })
        }
      }

    }
  }

  &-body {
    margin-top: 12px;
  }
}
