@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.numbers-breakdown-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .value {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.7px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  .mobile-description {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.3px;
    font-family: 'Inter', sans-serif;
    margin-top: 8px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  .ant-tooltip-inner {
    white-space: break-spaces;
    max-width: 200px;
  }

  &-slider-wrap {
    display: flex;
    align-items: center;
    gap: 24px;

    @media @viewport-mobile {
      flex-wrap: wrap;
    }

    .ant-col {
      width: calc((100% - 48px) / 3);

      @media @viewport-mobile {
        width: 100%;
      }
    }

    &-small {
      display: flex;
      align-items: center;
      gap: 16px;

      @media @viewport-mobile {
        flex-wrap: wrap;
        row-gap: 16px;
        column-gap: 12px;
      }

      .ant-col {
        width: calc((100% - 48px) / 4);

        @media @viewport-mobile {
          width: calc(50% - 6px);
        }
      }
    }

    &-smallest {
      margin-top: 32px;
      display: flex;
      align-items: center;
      gap: 8px;

      @media @viewport-mobile {
        margin-top: 24px;
        flex-wrap: wrap;
        gap: 4px;
      }

      .with-bg {
        width: calc((100% - 24px) / 4);
        padding: 11px;
        .with-theme({
          background: @vars[blue-xlight-to-neutral-dark-100];
        });

        @media @viewport-mobile {
          padding: 16px;
          width: calc(50% - 2px);
        }

        &:first-of-type {
          border-radius: 16px 0 0 16px;

          @media @viewport-mobile {
            border-radius: 0;
          }
        }

        &:last-of-type {
          border-radius: 0 16px 16px 0;

          @media @viewport-mobile {
            border-radius: 0;
          }
        }
      }
    }
  }

  .skeleton{
    height: 24px;
    border-radius: @border-radius;
    .skeleton-animation-bg();
  }

  &-wrap{
    &.pot_profit{
      @media only screen and (max-width: 991px){
        order: -1;
      }
    }
  }

  &-label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    &.small {
      margin-bottom: 8px;

      @media @viewport-mobile {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }

      @media @viewport-mobile {
        & > span:not(.icon-btn) {
          width: calc(100% - 25px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }

    .icon-btn {
      display: block;
      width: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 16px;
      margin-left: 4px;

      svg {
        width: 16px;
        height: 16px;
        .with-theme({
          fill: @vars[neutral-300-to-500];
        });
      }
    }
  }

  &-input {
    width: 100%;
    padding: 5px 12px;
    border-radius: 10px;
    background: transparent;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];

      &[disabled] {
        background: @vars[neutral-50-to-neutral-dark-50];
        color: @vars[neutral-400-to-neutral-dark-400] !important;
        border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
      }
    });

    &:not([disabled]):hover, &:not([disabled]):focus {
      border: 1px solid @bluePrimary !important;
      box-shadow: none !important;
    }

    &.input {
      font-family: 'Inter', sans-serif !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      height: 40px !important;
      background: transparent !important;
      letter-spacing: -0.3px !important;
      .with-theme({
        color: @vars[neutral-800-to-100] !important;

        &[disabled] {
          background: @vars[neutral-50-to-neutral-dark-50];
          color: @vars[neutral-400-to-neutral-dark-400] !important;
          border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
        }
      });
    }

    input {
      font-family: 'Inter', sans-serif !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      height: 28px !important;
      letter-spacing: -0.3px !important;
      padding: 0 !important;
      .with-theme({
        color: @vars[neutral-800-to-100] !important;
      });
    }
  }

  &-slider {
    width: 100%;
    height: 24px;
    padding: 10px 0;
    margin: 14px 0 40px !important;

    &:hover .ant-slider-handle:after {
      box-shadow: rgba(34, 90, 234, 0.12) 0 0 0 5px;
      inset-inline-start: 0;
      inset-block-start: 0;
    }

    .ant-slider-step {
      display: none;
    }

    .ant-slider-handle{

      &:before {
        display: none;
      }

      &:after {
        display: none;
        width: 24px !important;
        height: 24px !important;
        border: 2px solid @bluePrimary;
        box-shadow: none;
        background-color: transparent;
      }
    }

    .ant-slider-mark {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      top: 27px;

      &-text {
        transform: translateX(0) !important;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        });

        &:first-of-type {
          right: auto !important;
          left: 0;
          text-align: left;
        }

        &:last-of-type {
          left: auto !important;
          right: 0;
          text-align: right;
        }
      }
    }

    .ant-slider-rail {
      border-radius: 8px;
      .with-theme({
        background-color: @vars[neutral-100-to-neutral-dark-100];
      });
    }

    .ant-slider-handle {
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%) !important;
      top: 50%;
      margin-top: 0;
      border-radius: 50%;
      .with-theme({
        background: @vars[neutral-800-to-100] !important;
        box-shadow: 0 0 0 4px @vars[white-to-neutral-dark-50];
      })
    }
  }

  &-result {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 12px 16px;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.7px;
    border-radius: 16px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[neutral-50-to-neutral-dark-50];
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      height: auto;
      width: 100%;
      font-size: 18px;
      line-height: 24px;
    }

    p {
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }
}

.ant-slider-track {
  .with-theme({
    background-color: @vars[neutral-800-to-100];
  })
}

.ant-slider:hover .ant-slider-track {
  .with-theme({
    background-color: @vars[neutral-800-to-100];
  })
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  .with-theme({
    background-color: @vars[neutral-900-to-neutral-dark-50];
  })
}
