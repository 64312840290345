@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.input-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media @viewport-mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &-label {
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  &-input-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 136px);

    @media @viewport-mobile {
      width: 100%;
      gap: 8px;
    }

    .filter-input {
      height: 40px;
      width: calc(50% - 6px);
      padding: 6px 8px 6px 12px;
      border-radius: 10px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        width: 50%;
      }

      .ant-input-suffix {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-700-to-neutral-dark-500];
        })
      }

      &::placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-400-to-neutral-dark-400];
        })
      }
    }
  }
}
