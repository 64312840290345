@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.overview-card {

  &-wrapper {
    display: flex;
    flex-direction: row;

    @media @viewport-mobile {
      flex-wrap: wrap;
      row-gap: 16px;

      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  &-wrap {
    padding: 16px 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 80px;
    position: relative;
    @media @viewport-mobile {
      width: 50%;
      height: 64px;
      padding: 0;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &.wide {
        width: 100%;

        &:last-of-type {
          border-bottom: none !important;
        }
      }
    }

    .divider {
      width: 1px;
      height: 64px;
      position: absolute;
      right: 24px;
      top: 8px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        display: none;
      }
    }
  }

  &-label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: @Neutral500;

    > .icon-btn {
      svg path {
        transition: all .3s ease;
      }

      &:hover {
        > svg path {
          fill: @bluePrimary;
        }
      }
    }
  }

  &-value {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    .score-item {
      width: 4px;
      height: 16px;
      border-radius: 1px;
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100];
      });

      &.active {
        background: @bluePrimary;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .saturation-icon {
      width: 32px;
      height: 16px;
      margin: 0 0 6px 8px;

      &.disabled{
        margin: 0 0 6px 8px;
        > path{
          .with-theme({
            fill: @vars[neutral-100-to-neutral-dark-100];
          });
        }
      }
    }

    .low{
      line-height: 24px;
      color: @greenPrimary;
    }

    .medium{
      line-height: 24px;
      color: @yellowPrimary;
    }

    .high{
      line-height: 24px;
      color: @redPrimary;
    }
  }
}

@media @viewport-mobile {
  #product-details-block-overview-id {
    .ant-tooltip-arrow {
      left: 92px;
    }
  }
}
