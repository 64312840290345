@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.empty-state-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 44px 0;

  .empty-state_wrapper {
    margin: unset;
  }

  .empty-state-header-filters {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    max-width: 390px;
    text-align: center;
    .with-theme({
      color: @vars[neutral-300-to-700];
    });
  }
}

.filters-preset-table {
  display: flex;

  .collection-modal-list-empty {
    margin: 0;
    height: 210px;
  }
  .filter-preset-cell-menu-wrapper {
    left: 310px !important;
  }

  .ant-table {
    background: transparent;

      &-body {
        overflow-y: auto !important;

        &::-webkit-scrollbar {
          width: 4px; height: 0;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @bluePrimary;
          });
        }
        &::-webkit-scrollbar-track-piece:start {
          background: transparent;
          margin-top: 10px;
        }
        &::-webkit-scrollbar-track-piece:end {
          background: transparent;
          margin-bottom: 20px;
        }
      }
  }

  .presets-table-wrapper {
    border-spacing: 2px;
  }

  .preset-filter-row {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    width: 100%;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });

    .editable-text_input {
      background: transparent;
      color: @bluePrimary;
    }

    .editable-text_icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);

      &--success {
        right: 50px;
      }
      svg {
        .with-theme({
          fill: @vars[neutral-200-to-700];
        });
      }
    }

    &-selected {
      .ant-table-cell-row-hover {
        .with-theme({
          background-color: @vars[neutral-100-to-neutral-dark-200] !important;
        });
      }

      .preset-filters-more-icon {
        circle {
          fill: @white;
        }
      }
    }

    .ant-table-cell {
      display: flex;
      justify-content: space-between;
      padding: 14px 12px;
      border-radius: 12px;
      cursor: pointer;
      height: 48px;
      width: 100%;
      margin-bottom: 12px;

      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &-row-hover {
        .with-theme({
          background-color: @vars[neutral-100-to-neutral-dark-200] !important;
        });
      }

      .filter-preset-cell-text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-wrapper {
          display: flex;
          gap: 8px;

          .filter-preset-cell-text-circle {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;
            &-selected {
              background-color: #225aea;
            }

            &:after {
              position: absolute;
              content: '';
              left: 50%;
              top: 50%;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              transform: translate(-50%, -50%);
              z-index: 2;
              .with-theme({
                background: @vars[white-to-neutral-dark-50];
              });
            }

            .with-theme({
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            });
          }
        }
        .filter-preset-cell-text-dropship {
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          });
        }

        .preset-filters-more-icon {
          circle {
            transition: all 0.3s ease;
          }
        }
        &.filter-preset-cell-text-highlighted {
          .preset-filters-more-icon {
            circle {
              fill: @bluePrimary;
            }
          }
        }
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    .with-theme({
      background-color: @vars[neutral-100-to-neutral-dark-200] !important;
    });
  }

  .ant-table-tbody > tr.preset-filter-row-selected:hover > td {
    .with-theme({
      background-color: @vars[neutral-100-to-neutral-dark-200] !important;
    });
  }

  .ant-table-tbody > tr.preset-filter-row-highlighted > td {
    .with-theme({
      background-color: @vars[neutral-100-to-neutral-dark-200] !important;
    });
  }

  .ant-table-header.ant-table-sticky-holder {
    display: none;
  }

  &.top {
    border-radius: 6px 6px 0 0;
  }

  &.bottom {
    border-radius: 0 0 6px 6px;
  }

  &-header {
    padding: 8px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: @Neutral500;
    .with-theme({
      background: @vars[blue-200-to-neutral-800];
    });
  }
}

.filter-preset-action-dropdown {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 8px;
  max-width: 210px;
  .with-theme({
    background: @vars[white-to-neutral-dark];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    box-shadow: @vars[box-shadow-object-large];
  });

  .action-dropdown_item {
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
    .with-theme({
      background: @vars[white-to-neutral-dark];

      &.cancel-btn:hover {
        background: @redLight !important;
        span {
          .with-theme({
            background-color: @vars[red-100-to-900];
          })
        }
      }

      &:hover {
        background-color: @vars[neutral-100-to-neutral-dark-100];
      }
    });

    &.active {
      .with-theme({
        svg path {
          stroke: @vars[neutral-600-to-neutral-dark-500];
        }
      });
    }

    &.tracked {
      &:hover {
        background-color: @redLight;
      }
      .ant-space {
        svg path {
          stroke: @redPrimary;
        }

        span {
          color: @redPrimary !important;
        }
      }
    }

    .ant-space {
      gap: 8px !important;
      width: 100%;
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }
    }
  }
}
