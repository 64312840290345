@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.how-it-works-wrapper {
  margin: 64px auto 0;
  width: 1140px;

  @media @viewport-mobile {
    width: 100%;
    margin: 48px auto 0;
    padding: 0 15px;
  }

  .how-it-works-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @viewport-mobile {
      flex-direction: column;
    }

    .how-it-works-title {

      h4 {
        text-align: left;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        color: @dark;
        margin-bottom: 8px;

        @media @viewport-mobile {
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
      }

      h5 {
        max-width: 566px;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: @Neutral500;
        margin-bottom: 0;

        @media @viewport-mobile {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          max-width: 100%;
        }
      }
    }

    .how-it-works-btn {
      border-radius: 12px;
      padding: 12px 24px;
      cursor: pointer;
      background: @bluePrimary;
      transition: all .3s ease;

      @media @viewport-mobile {
        margin-top: 24px;
        width: 100%;
        text-align: center;
      }

      &:hover {
        background: @blueDepressed;
      }

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: @white;
      }
    }
  }

  .how-it-works-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    @media @viewport-mobile {
      margin-top: 24px;
      display: block;

      .slick-dots {
        bottom: -24px;

        & > li {
          width: 6px;
          height: 6px;

          &.slick-active > button {
            background: @bluePrimary;
          }

          & > button {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: @blueLight;
          }
        }
      }
    }

    .how-it-works-card {
      width: 32%;
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      padding: 24px;
      border-radius: 24px;
      border: 1px solid @Neutral100;
      background: @white;

      @media @viewport-mobile {
        padding: 24px 16px;
        gap: 16px;
      }

      &-step {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: @bluePrimary;
        padding: 8px 12px;
        border-radius: 12px;
        background: @blueLight;
      }

      &-title {
        margin-bottom: 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: @dark;
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: @Neutral500;
      }
    }
  }
}
