@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.products-overview {
  .ant-spin-nested-loading {
    overflow: visible !important;
  }
}

.database-product-cards {
  height: calc(100dvh - 80px);
  padding: 24px 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
  overflow-y: auto;

  @media @viewport-mobile {
    height: calc(100dvh - 127px - 69px);
    padding: 16px;
    display: block;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });
  }
  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }

  .database-product-card {
    margin-bottom: 24px;
    position: relative;
    border-radius: 16px;
    width: 263px;
    height: 504px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark];
    });

    &:last-of-type {
      margin-bottom: 0;
    }

    @media @viewport-mobile {
      width: 100%;
      min-height: 500px;
      height: unset;
    }

    &-image {
      width: 100%;
      height: 263px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px 16px 0 0;
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-50];
      });

      @media @viewport-mobile {
        width: 100%;
        height: calc(100vw - 36px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 16px 16px 0 0;

        @media @viewport-mobile {
          object-fit: cover;
        }
      }
    }

    &-info {
      padding: 16px 12px;
      height: calc(100% - 264px);

      @media @viewport-mobile {
        height: unset;
      }
    }

    &-title {
      display: block;
      max-width: 235px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      @media @viewport-mobile {
        max-width: 94%;
      }
    }

    &-price {
      margin-top: 12px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      & > span {
        display: flex;
        align-items: center;
      }

      .main {
        cursor: pointer;
        transition: all .3s ease;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: -0.5px;
        color: @bluePrimary;

        &:hover {
          color: @blueDepressed;
        }
      }

      .original {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        })
      }
    }

    &-stat {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      .value {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      &.last {
        margin-bottom: 0;

        .value {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }

    &-btn-wrapper {
      position: absolute;
      bottom: 16px;
      left: 12px;
      display: flex;
      align-items: center;
      gap: 8px;

      @media @viewport-mobile {
        position: static;
        margin-top: 12px;
      }
    }

    @media @viewport-mobile {
      .product-actions-options {
        width: 40px;
      }
    }

    &-btn, .product-actions-options {
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      border-radius: 8px;
      cursor: pointer;
      transition: all .3s ease;
      border: none !important;
      .with-theme({
        color: @vars[neutral-800-to-100] !important;
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      });

      @media @viewport-mobile {
        height: 40px;
      }

      .icon-btn > svg {
        path {
          .with-theme({
            stroke: @vars[neutral-800-to-100];
          })
        }
      }

      &.wide {
        width: 200px;

        @media @viewport-mobile {
          width: calc(100% - 48px);
        }
      }

      &.tracked-btn {
        display: flex;
        align-items: center;
        gap: 4px;
        color: @white !important;
        &.is-tracked {
          background-color: @redPrimary;
        }

        &.is-not-tracked {
          background-color: @bluePrimary;
          svg path {
            stroke: @white;
          }
        }
      }

    }
  }
}
