@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.billing-subpage {

  &-block {
    margin-bottom: 64px;

    @media @viewport-mobile {
      margin-bottom: 48px;
      padding: 0 16px;

      &:last-of-type {
        padding: 0;

        .billing-subpage-block-title-wrapper {
          padding: 0 16px;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        @media @viewport-mobile {
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 8px;
        }
      }
    }

    &-link {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      color: @bluePrimary;
      transition: all .3s ease;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;

      &.disabled {
        cursor: not-allowed;

        .with-theme({
          color: @vars[neutral-300-to-neutral-dark-300];
        });

        svg {

          path {
            .with-theme({
              stroke: @vars[neutral-300-to-neutral-dark-300];
            });
          }
        }
      }

      svg {
        transform: rotate(-90deg);

        path {
          stroke: @bluePrimary;
        }
      }

      &:not(.disabled):hover {
        color: @blueDepressed;
      }
    }

    &-note {
      margin-left: auto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      @media @viewport-mobile {
        margin-left: 0;
      }
    }

    &-stat {

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &.vertical {
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 8px;

          .billing-subpage-block-stat-value {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.7px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            });
          }
        }
      }

      &-key {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      &-value {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        @media @viewport-mobile {
          display: flex;
          align-items: center;

          .icon-btn {
            margin-top: -2px;
          }
        }

        span {
          margin-right: 4px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }
      }
    }

    &-limit-wrapper {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .stats {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-200-to-neutral-dark-200];
          });

          .stat {
            display: flex;
            align-items: center;
            gap: 4px;

            .value {
              .with-theme({
                color: @vars[neutral-800-to-100];
              })

            }

            .key {
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              });
            }
          }
        }

        .title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }

      .track {
        margin-top: 12px;
        width: 100%;
        height: 8px;
        border-radius: 128px;
        .with-theme({
          background: @vars[neutral-100-to-neutral-dark-100];
        });

        &-progress {
          width: 0;
          min-width: 5px;
          height: 100%;
          background: @greenPrimary;
          border-radius: 128px;

          &.maximum {
            background: @redPrimary !important;
          }

          &-warning {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            color: @redPrimary;
            margin-top: 12px;
            display: block;
          }
        }
      }
    }

    &-card-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 358px;
      height: 64px;
      padding: 8px 12px;
      border-radius: 12px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background: @vars[white-to-neutral-dark];
      });

      &.payment-type-icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .info {
        margin-left: 12px;
      }

      &.empty, .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;

        .title {
          font-weight: 500;
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }

        .text {
          font-weight: 400;
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          });
        }
      }
    }

    &-address-wrapper {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
      column-gap: 24px;

      @media @viewport-mobile {
        gap: 12px;
        column-gap: 12px;
      }

      .item {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc((100% - 72px) / 4);
        gap: 4px;

        @media @viewport-mobile {
          width: calc(50% - 6px);

          &.wide {
            width: 100%;
          }
        }

        .title {
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        .value {
          width: 100%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }
    }

    &-table-wrapper {

      @media @viewport-mobile {
        width: 100%;
        overflow-x: scroll;
      }

      .empty-state-product-skeleton-wrapper {
        border-radius: 8px;
        .with-theme({
          border: 2px solid @vars[white-to-neutral-dark-50];
        })
      }

      .empty-state-product {
        height: 300px;

        p {
          max-width: unset;
        }
      }

      .table {

        &-header {
          display: flex;
          align-items: center;
          gap: 16px;
          flex-wrap: nowrap;
          .with-theme({
            border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });

          @media @viewport-mobile {
            width: fit-content;
          }

          .item {
            height: 40px;
            min-width: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-500-to-neutral-dark-500];
            });

            @media @viewport-mobile {
              &:first-of-type {
                padding-left: 16px;
              }
            }

            &.wide {
              width: 150px;

              @media @viewport-mobile {
                min-width: 150px;
              }
            }

            &.wider {
              width: 110px;
              min-width: 110px;
            }

            &.widest {
              width: 220px;

              @media @viewport-mobile {
                min-width: 150px;
              }
            }
          }

        }

        &-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          max-height: 360px;

          @media @viewport-mobile {
            width: fit-content;
          }

          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }

          &::-webkit-scrollbar-button {
            display: none;
          }

          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }

          &::-webkit-scrollbar-track-piece:start {
            background: transparent;
            margin-top: 10px;
          }

          &::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-bottom: 10px;
          }
        }

        &-row {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 16px;
          flex-wrap: nowrap;
          .with-theme({
            border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });

          .item {
            height: 64px;
            min-width: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            });

            @media @viewport-mobile {
              &:first-of-type {
                padding-left: 16px;
              }
            }

            & > span:has(> svg) {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 10px;

              svg {
                cursor: pointer;
                path {
                  .with-theme({
                    stroke: @vars[neutral-500-to-neutral-dark-500];
                  })
                }
              }
            }

            .payment-tag {
              display: flex;
              padding: 4px 6px;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: -0.3px;

              &.Paid {
                color: @greenPrimary;
                .with-theme({
                  background: @vars[green-100-to-900];
                });
              }

              &.Unpaid {
                color: @redPrimary;
                .with-theme({
                  background: @vars[red-100-to-900];
                });
              }

              &.Pending {
                color: @purplePrimary;
                .with-theme({
                  background: @vars[purple-100-to-900];
                });
              }
            }

            &.wide {
              width: 150px;

              @media @viewport-mobile {
                min-width: 150px;
              }
            }

            &.wider {
              width: 110px;

              @media @viewport-mobile {
                min-width: 110px;
              }
            }

            &.widest {
              width: 220px;

              @media @viewport-mobile {
                min-width: 220px;
              }
            }
          }
        }
      }
    }
  }
}
