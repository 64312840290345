@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.university-page {
  padding: 0 !important;
  overflow: unset!important;
  overflow-x: visible!important;
  max-height: 100dvh;
  overscroll-behavior: contain;

  .title-wrapper-icon {
    border: 1px solid rgba(7, 47, 148, 0.50);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #225AEA;
    box-shadow: 0px 30px 30px -15px rgba(6, 73, 42, 0.04), 0px 15px 15px -7.5px rgba(6, 73, 42, 0.04), 0px 7.5px 7.5px -3.75px rgba(6, 73, 42, 0.04), 0px 3.75px 3.75px -1.875px rgba(6, 73, 42, 0.04), 0px 1.25px 1.25px -0.625px rgba(6, 73, 42, 0.04), 0px 0px 0px 1.25px rgba(6, 73, 42, 0.04);

    svg {
      margin-top: 5px;
    }
  }

  &-tools {
    &-wrapper {
      padding: 24px 32px;

      @media @viewport-mobile {
        padding: 24px 16px;
      }
    }

    &-header {
      font-family: 'Inter', sans-serif;
      font-style: normal;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.5px;
      margin-bottom: 0;
      .with-theme({
        color: @vars[neutral-800-to-100];
      })
    }
  }

  &-cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 32px 24px;
    column-gap: 16px;
    row-gap: 24px;
    flex-wrap: wrap;
    width: 100%;

    @media @viewport-mobile {
      row-gap: 16px;
      padding: 0 16px 16px;
    }
  }

  .link.skeleton {
    display: block;
  }
}
