@import '../../styles/utils/variables';
@import '../../styles/base/ui-kit';
@import '../../styles/theme/theme';

.onboarding-plan {
  padding-bottom: 0 !important;

  .ant-spin-nested-loading {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
  }

  &-header {
    width: calc(100% - 128px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 34px auto 26px;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 18px;
      align-items: flex-start;
      width: calc(100% - 32px);
      margin: 34px auto;
    }
  }

  &-cards-wrapper {
    width: calc(100% - 224px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 32px;
    padding-bottom: 24px;

    @media @viewport-mobile {
      flex-direction: column;
      width: calc(100% - 32px);
      gap: 24px;
    }
  }
}

.logout-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  .logout-wrapper-divider{
    width: 276px;
    max-width: 276px;
    height: 1px;
    background: @Neutral200;
  }
  .logout-wrapper-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.3px;
    color: @Neutral600;
    padding: 6px 12px;
    span{
      color: @blue400;
      cursor: pointer;
    }
  }
}