@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.trial-finished {

  &-modal {

    &.pending-payment-modal {

      .trial-finished-title {
        margin-top: 0;

        @media @viewport-mobile {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .trial-finished-text {
        @media @viewport-mobile {
          margin-top: 14px;

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .cancel-sub-image {
        margin: 40px auto;

        @media @viewport-mobile {
          margin: 20px auto;
        }
      }

      .pending-payment-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px;
        gap: 12px;

        .trial-finished-button {
          margin: 0;
          width: 50%;
        }

        .cancel-button {
          box-shadow: none;
          transition: .3s all;
          .with-theme({
            background: @vars[white-to-neutral-dark-50] !important;
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            color: @vars[neutral-800-to-100];
          });

          &:hover {
            .with-theme({
              background: @vars[neutral-50-to-neutral-dark-100] !important;
              color: @vars[neutral-800-to-100] !important;
            });
          }
        }
      }
    }

    .ant-modal-close {
      display: none;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-image {
    margin: 40px auto;
    width: 256px;
    height: 256px;
    background: url("../../Images/FinishedTrialLight.png") no-repeat center center;
    background-size: cover;

    &--dark {
      background: url("../../Images/FinishedTrialDark.png") no-repeat center center;
      background-size: cover;
    }

    @media @viewport-mobile {
      margin: 20px auto;
    };
  }

  &-title {
    margin-bottom: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-text {
    margin-bottom: 16px;

    p {
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: @Neutral500;
      padding: 0 34px;

      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }

  &-button {
    margin-top: 32px;
    height: 40px !important;
    background: @bluePrimary;
    color: @white;
    border-radius: 10px;
    border: none;
    outline: none;
    margin: 16px;
    
    .with-theme({
      box-shadow: @vars[box-shadow-btn-primary];
    });

    &:hover, &:focus, &:active {
      background: @blueDepressed !important;
      color: @white !important;
    }
  }
}

@media @viewport-mobile {
  .trial-finished-modal {
    left: 0;
  }
}
