@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '~react-image-gallery/styles/css/image-gallery.css';

.product-details-images-block {
  width: 385px;

  @media @viewport-mobile {
    width: 100%;
  }

  .image-gallery-swipe {
    overflow: hidden;
    border-radius: 16px;
  }

  .custom-original-img {
    .image-gallery-image {
      height: 385px;
      border-radius: 16px;

      @media @viewport-mobile {
        height: calc(100vw - 32px);
      }
    }
  }

  .image-gallery-thumbnails {

    &-skeleton {
      padding: 12px 0 0;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    padding: 12px 0 0;
    .image-gallery-thumbnails-container {
      text-align: left;

      .custom-thumbnail-img {
        width: 48px;
        border: none;

        &:not(:first-of-type) {
          margin-left: 12px;
        }

        .image-gallery-thumbnail-image {
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
