@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.moneyback-wrapper {
  width: 520px;
  display: flex;
  align-items: center;
  gap: 12px;

  @media @viewport-mobile {
    width: 100%;
    flex-direction: column;
  }

  .moneyback-circle {
    width: 108px;
    height: 108px;
    border-radius: 50%;
    background: @blueLight;
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
      width: 93px;
      height: 93px;
      border-radius: 50%;
      background: @bluePrimary;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: @white;
      text-transform: uppercase;

      span {
        font-size: 9.333px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      p {
        font-size: 16.8px;
        font-style: normal;
        font-weight: 700;
        line-height: 26.133px;
      }
    }
  }

  .moneyback-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    width: calc(100% - 120px);

    @media @viewport-mobile {
      width: 100%;
      text-align: center;
    }

    h4 {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: @Neutral800;
      margin-bottom: 0;

      @media @viewport-mobile {
        text-align: center;
        width: 100%;
      }
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: @Neutral500;

      @media @viewport-mobile {
        margin: 0 auto;
        width: 90%;
      }
    }
  }

}
