@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.collection-modal {
  font-family: 'Inter', sans-serif;

  .ant-modal-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    padding: 16px 16px 0;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  .input--collections-modal {
    height: 40px;
    padding: 6px 12px;
    border-radius: 10px;
    border: none !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      box-shadow: none !important;
      background: @vars[white-to-neutral-dark] !important;
      border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark] !important;

      &:not([disabled]):hover, &:not([disabled]):focus {
        border: 1px solid @bluePrimary !important;
      }
    });
  }

  .ant-modal-close {
    top: 14px !important;
    right: 14px !important;
  }

  .ant-modal-footer {
    @media @viewport-mobile {
      display: none !important;
    }
  }

  .ant-modal-header {
    @media @viewport-mobile {
      padding: 0 !important;
    }
  }

  &-content {
    padding: 0 16px;

    .search_wrapper {
      margin-bottom: 0;

      .search-input {
        margin-right: 0;
      }
    }
  }

  .action-dropdown {
    margin-top: 16px;
    width: 100%;
    padding: 0 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    &-item {
      border-radius: 8px;
      height: 36px;
      width: 100%;
      padding: 0 8px;
      transition: all .3s ease;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      .ant-space {
        display: flex;
        align-items: center;
        gap: 8px !important;
        height: 36px;
      }

      &.red {
        color: @redPrimary;
      }

      &:hover {
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        })
      }
    }

  }

  &-text {
    margin-bottom: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    .bold {
      font-weight: 500;
    }
  }

  &-btn-wrapper {
    display: flex;
    align-items: flex-start;
    padding: 0 16px;

    @media @viewport-mobile {
      padding: 0 16px 16px;
    }
  }

  &-btn {
    margin: 0;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    outline: none !important;

    @media @viewport-mobile {
      width: calc(50% - 6px);
    }

    &[disabled] {
      cursor: not-allowed;
      box-shadow: none !important;
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100] !important;
        color: @vars[neutral-300-to-neutral-dark-300] !important;
      })
    }

    &-cancel {
      margin-left: auto;
      margin-right: 12px;
      .with-theme({
        color: @vars[neutral-800-to-100] !important;
        background: @vars[white-to-neutral-dark-50] !important;
        box-shadow: @vars[box-shadow-btn-secondary] !important;

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100] !important;
        }
      });
    }

    &-save {
      color: @white !important;
      background: @bluePrimary;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-primary];
      });

      &:hover, &:focus {
        background: @blueDepressed !important;
      }
    }

    &-create {
      box-shadow: none !important;
      color: @bluePrimary !important;
      background: transparent !important;

      @media @viewport-mobile {
        width: auto !important;
      }

      &:hover, &:focus {
        color: @blueDepressed !important;
      }
    }

    &.wide {
      width: 100%;
    }

    &.red {
      color: @redPrimary !important;
      .with-theme({
        background: @vars[white-to-neutral-dark] !important;
        box-shadow: @vars[box-shadow-btn-secondary] !important;
      });

      &:hover, &:focus {
        color: @redPrimary !important;
        .with-theme({
          background: @vars[white-to-neutral-dark] !important;
          box-shadow: @vars[box-shadow-negative] !important;
        })
      }
    }
  }

  &-list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    max-height: 168px;
    min-height: 100px;

    &.preset-list {
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @bluePrimary;
        });
      }

      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }
    }

    &-item {
      width: 100%;
      min-height: 48px;
      height: 48px;
      font-family: 'Inter', sans-serif;
      transition: all .3s ease;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0 16px;
      border-radius: 12px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background: @vars[white-to-neutral-dark];
      });

      &.preset-item {
        position: relative;

        .ant-dropdown {
          top: 10px !important;
          left: 240px !important;
        }

        &.last {
          .ant-dropdown {
            top: -50px !important;
          }
        }

        &.only {
          .ant-dropdown {
            top: 3px !important;
          }
        }

        .sales-tracker-top-page-header-dropdown {
          width: 125px;

          .sales-tracker-top-page-header-dropdown-option {
            margin: 0;
            width: 100%;

            &:not(.red) {
              svg path {
                .with-theme({
                  fill: @vars[neutral-600-to-neutral-dark-500];
                })
              }

            }

            &.red {
              span {
                color: @redPrimary !important;
              }
            }
          }
        }
      }

      &:not(.active):has(.collection-modal-list-item-content:hover), &:not(.active):has(.collection-modal-list-item-content-select-icon:hover) {
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-50] !important;
        });
        .collection-modal-list-item-content-select-icon {
          border: 1px solid @bluePrimary !important;
        }
      }

      &.active {

        .collection-modal-list-item-content {
          cursor: default;
        }

        .collection-modal-list-item-content-select-icon {
          cursor: default;
          background: @bluePrimary !important;
          .with-theme({
            border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
          });

          &:after {
            border-radius: 50%;
            position: absolute;
            content: '';
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            .with-theme({
              background: @vars[white-to-neutral-dark];
            });
          }
        }
      }

      &-fav-icon {

        svg.icon-favorite:hover path{
          .with-theme({
            stroke: @vars[neutral-800-to-100];
          });
        }

      }

      &-content {
        height: 100%;
        width: calc(100% - 70px);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          .collection-modal-list-item-content-select-icon {
            border: 1px solid @bluePrimary !important;
          }
        }

        &-image {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: 24px;
          border-radius: 6px;
          .with-theme({
            border: 2px solid @vars[white-to-neutral-dark];
            background: @vars[neutral-50-to-neutral-dark-50];
          });

          &:not(&:first-of-type) {
            margin-left: -10px;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }

          svg path {
            .with-theme({
              stroke: @vars[neutral-300-to-neutral-dark-300];
            })
          }
        }

        &-images {
          display: flex;
          align-items: center;
        }

        &-title {
          max-width: 170px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0 0 0 4px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }

        &-default {
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          });
        }

        &-select-icon {
          border-radius: 50%;
          margin-left: auto;
          position: relative;
          width: 20px;
          height: 20px;
          transition: all .3s ease;
          cursor: pointer;
          .with-theme({
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            background: @vars[white-to-neutral-dark];
          });

          &:hover {
            border: 1px solid @bluePrimary !important;
          }
        }
      }
    }

    &-empty {
      height: 250px;
      margin-top: 24px;
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media @viewport-mobile {
        width: 100%;
      }

      .empty-state-product-skeleton {
        display: flex;
        align-items: center;
        gap: 12px;
        width: fit-content;
        padding: 14px 16px;
        border-radius: 10px;
        .with-theme({
          background: @vars[white-to-neutral-dark-50];
          box-shadow: @vars[box-shadow-circle];
        });

        .link.skeleton {
          display: block;
        }

        & > div {
          display: flex;
          align-items: center;
        }

        &-wrapper {
          border-radius: 6px;
          width: 24px;
          height: 24px;
          .with-theme({
            border: 2px solid @vars[white-to-neutral-dark];
          });
        }
      }

      h3 {
        margin-top: 24px;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      p {
        text-align: center;
        margin-top: 8px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      &-btn {
        margin: 16px auto 0;
        height: 36px;
        padding: 6px 12px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        .with-theme({
          color: @vars[neutral-800-to-100] !important;
          background: @vars[white-to-neutral-dark-50];
          box-shadow: @vars[box-shadow-btn-secondary];

          &:hover, &:focus {
            background: @vars[neutral-50-to-neutral-dark-100];
          }
        });
      }
    }
  }

  &.collection-delete-modal, &.collection-create-modal {
    .collection-modal-text {
      max-width: 380px;
    }
  }

  &.collection-rename-modal {
    .collection-modal-text {
      max-width: 370px;
    }
  }

  &.product-actions-modal {
    .filter-preset-action-dropdown.database-action-dropdown, .adLibrary-item-header-action-dropdown {
      min-width: 100%;
      border: none;
      box-shadow: none;
      padding: 0 0 16px;
      @media @viewport-mobile {
       min-width: 100%;
      }
    }
  }

  &.products-pagination-modal {
    .page-size-dropdown {
      width: 100%;

      &-list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
        padding: 8px;

        &-item {
          width: 100%;
          font-family: 'Inter', sans-serif;
          font-weight: 400;
          letter-spacing: -0.3px;
          font-size: 14px;
          line-height: 20px;
          transition: all .3s ease;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 8px;
          border-radius: 8px;
          width: 100%;

          &:hover {
            .with-theme({
              background: @vars[blue-100-to-neutral-dark-100] !important;
            })
          }
        }
      }
    }
  }

  &.products-filter-modal {
    .dropdown-database {
      &-menu {
        padding: 0;
        overflow: hidden;

        .ant-input-affix-wrapper.dropdown-search.input {
          margin-bottom: 8px;
          height: 40px;
          padding: 6px 12px;
          border-radius: 12px;
          border: none !important;
          .with-theme({
            background: @vars[white-to-neutral-dark];
            border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
          });

          .ant-input-prefix {
            margin-right: 8px;
          }

          &:hover{
            .with-theme({
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            })
          }

          &.ant-input-affix-wrapper-focused{
            border: 1px solid  @bluePrimary !important;
          }

          > .ant-input {
            .with-theme({
              color: @vars[neutral-800-to-100];
              background: @vars[white-to-neutral-dark];

              &::placeholder {
                color: @vars[neutral-400-to-neutral-dark-400];
              }
            });
          }
        }
      }

      &-list {

        &.empty {
          width: 100%;
          align-items: center;
          padding-bottom: 4px;
          gap: 8px;

          .dropdown-database-list-item {
            width: calc(100% - 16px);
            padding: 8px;
            border-radius: 10px;
            .with-theme({
              box-shadow: @vars[box-shadow-circle];
              background: @vars[white-to-neutral-dark-50];
            })
          }

          .empty-state_wrapper-text {
            width: calc(100% - 16px);
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            })
          }

          .empty-state_wrapper-select {
            margin: 0;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            })
          }
        }

        margin-top: 8px;
        overflow-y: auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;

        &::-webkit-scrollbar {
          width: 4px; height: 0;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          border-radius: 2px;
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }

        &-item {

          &.test-item {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            margin-top: 1px;
            width: 100%;
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            letter-spacing: -0.3px;
            font-size: 14px;
            line-height: 20px;
            transition: all .3s ease;
            .with-theme({
              color: @vars[neutral-800-to-100];
            });

            & > span.value {
              white-space: pre-wrap;
              max-width: calc(100% - 30px);
            }

            .info-wrap {
              margin-left: 5px;
              span:first-of-type {
                display: block;
              }
              span:last-of-type {
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                .with-theme({
                  color: @vars[neutral-600-to-neutral-dark-500];
                });
              }
            }
          }

          &-hidden {
            display: none !important;
          }

          & .dropdown-database-list-item-checkbox {
            position: relative;
            margin-right: 10px;
            transition: all .3s ease;
            border-radius: @border-radius;
            width: 20px;
            height: 20px;
            .with-theme({
              border: 1.5px solid @vars[neutral-200-to-neutral-dark-200];
            });

            &:hover {
              border: 1.5px solid @bluePrimary;
            }

            &--active {
              background: @bluePrimary;
              border: 1.5px solid @bluePrimary !important;

              &:after {
                content: url("../../Images/checkbox-mark.svg");
                left: 50%;
                top: 50%;
                font-size: 0;
                border: none;
                width: 16px;
                height: 16px;
                transform: scale(1) translate(-50%, -50%);
                position: absolute;
              }

              span {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                bottom: -1px;
                right: -1px;
                border-radius: @border-radius;
                border: 0 solid rgba(34, 90, 234, .2);
                opacity: 1;
                -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
                animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
                -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
                display: block;
                pointer-events: none;
              }
            }
          }

          &:hover {
            .with-theme({
              background: @vars[blue-100-to-900];
            });
            .dropdown-database-list-item-checkbox {
              .with-theme({
                border: 1.5px solid @bluePrimary;
              });
            }
          }
        }
      }
    }

    .dropdown-date-wrapper .ant-picker-dropdown-range {
      top: 8px !important;
    }

    .dropdown-date-wrapper {
      padding: 50px 16px 16px;
      border: none;
      background: transparent;
      box-shadow: none;
    }
  }

  &.account-modal {

    //@media @viewport-mobile {
    //  border-radius: 0;

    //  .ant-modal-content {
    //    height: 100dvh;
    //    overflow-y: auto;
    //    border-radius: 0 !important;
    //
    //    .ant-modal-header {
    //      border-radius: 0 !important;
    //    }
    //
    //    .ant-modal-body {
    //      position: relative;
    //      max-height: unset !important;
    //      height: calc(100% - 44px);
    //
    //      .ant-divider {
    //        position: absolute;
    //        width: 100%;
    //        bottom: 72px;
    //      }
    //
    //      .collection-modal-btn-wrapper {
    //        position: absolute;
    //        width: 100%;
    //        bottom: 0;
    //      }
    //    }
    //  }
    //}

    .ant-modal-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      & > svg {
        margin-left: -12px;
      }
    }

    .resend {

      &-text {
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        text-decoration-line: underline;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        });
      }

      &-timer {
        display: flex;
        align-items: center;
        cursor: progress;

        .ant-statistic-content-prefix {
          margin-right: 0;
        }

        .ant-statistic-content {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-300-to-neutral-dark-300];
          });
        }
      }
    }

    .collection-modal-btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0;
    }

    .collection-modal-btn-cancel {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .collection-modal-text {
      padding: 0 16px;
    }

    .collection-modal-top {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .forgot-password {
        margin-right: 16px;
        color: @bluePrimary;
        font-weight: 500;
        transition: .3s all;
        &:hover {
          cursor: pointer;

          color: @blueDepressed;
        }
      }
    }

    .collection-modal-content {
      padding: 0;
    }

    .change-form {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;

      .input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        &.error:not(.confirm) .input {
          .with-theme({
            border: 1px solid @redPrimary !important;
          })
        }

        & > span.title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
          &.required .asterisk {
            color: red;
            margin-left: 2px;
            font-size: 12px;
          }
        }

        .error {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          color: @redPrimary;

          &.confirm {
            color: @greenPrimary;
          }
        }

        .input {
          height: 40px;
          padding: 6px 12px;
          border-radius: 10px;
          border: none !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            box-shadow: none !important;
            background: @vars[white-to-neutral-dark] !important;
            border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
            color: @vars[neutral-800-to-100] !important;
            background: @vars[white-to-neutral-dark] !important;

            &:not([disabled]):hover, &:not([disabled]):focus {
              border: 1px solid @bluePrimary !important;
            }
          });
        }
      }
    }
  }

  &.language-modal {
    .language-dropdown {
      max-height: 160px;
      overflow-y: auto;
      display: flex;
      width: 100%;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      @media @viewport-mobile {
        max-height: 330px;
      }

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }

      &-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 8px;
        margin: 0 auto;
        width: calc(100% - 8px);
        transition: all .3s ease;
        cursor: pointer;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        &:hover {
          .with-theme({
            background: @vars[blue-100-to-neutral-dark-100];
          })
        }
      }
    }
  }

  &.edit-card-modal {
    overflow-y: visible !important;

    .ant-spin-nested-loading {
      overflow: visible !important;
    }

    .collection-modal-content {
      padding: 0;

      .collection-modal-text {
        padding: 0 16px;
      }
    }

    .edit-card-modal-header {
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      height: 36px;
      padding: 12px 16px;
      .with-theme({
        border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background: @vars[neutral-50-to-neutral-dark-50];
      });

      &-item {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.28px;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        });

        &.active {
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }
    }

    .inner-modal {
      display: block;
      width: 100%;

      &.hidden {
        display: none;
      }

      .ant-input-affix-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        border-radius: 12px;
        height: 40px;
        padding: 6px 12px;
        .with-theme({
          border-: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
          box-shadow: none !important;

          &:hover, &:focus-within {
            border: 1px solid @bluePrimary !important;
          }
        });

        .ant-input-prefix {
          margin-inline-end: 0;
          width: 20px;
          height: 20px;

          svg {
            width: 20px;
            height: 20px;
            .with-theme({
              path {
                fill: @vars[neutral-600-to-neutral-dark-500];
              }
            })
          }
        }

        input {
          height: 20px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
            caret-color: @vars[neutral-800-to-100];
          })
        }
      }

      .input-wrapper-dropdown {

        &-options {
          padding: 8px;
          max-height: 240px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }
          &::-webkit-scrollbar-track-piece:start {
            background: transparent;
            margin-top: 10px;
          }
          &::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-bottom: 10px;
          }

          &.empty {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            padding-bottom: 4px;
            gap: 8px;

            .dropdown-database-list-item {
              width: calc(100% - 16px);
              padding: 8px;
              border-radius: 10px;
              .with-theme({
                box-shadow: @vars[box-shadow-circle];
                background: @vars[white-to-neutral-dark-50];
              })
            }

            .empty-state_wrapper-text {
              width: calc(100% - 16px);
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.3px;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              })
            }

            .empty-state_wrapper-select {
              margin: 0;
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              letter-spacing: -0.3px;
              .with-theme({
                color: @vars[neutral-800-to-100];
              })
            }
          }
        }

        &-option {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          transition: all .3s ease;
          cursor: pointer;
          border-radius: 8px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          &:hover {
            .with-theme({
              background: @vars[blue-100-to-neutral-dark-100];
            })
          }
        }
      }
    }

    .change-form-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      &.hidden {
        display: none;
      }
    }

    .change-form-inner {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;
    }

    .card-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .form-error {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: @redPrimary;
      }

      &:has(.form-error) {
        .complex-input {
          border: 1px solid @redPrimary !important;

          &.focused, &:hover {
            border: 1px solid @redPrimary !important;
          }
        }
      }

      .complex-input {
        transition: all .3s ease;
        width: 100%;
        display: flex;
        height: 40px;
        padding: 6px 12px;
        align-items: center;
        gap: 12px;
        border-radius: 10px;
        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[white-to-neutral-dark];
        });

        &.focused, &:hover {
          border: 1px solid @bluePrimary;
        }
      }

      .card-field {

        &.number {
          width: calc(100% - 30px - 50px - 20px - 24px);

        }

        &.expiration {
          width: 50px;
        }

        &.cvv {
          width: 30px;
        }
      }
    }

    .ant-dropdown-trigger.input-wrapper {
      position: relative;
      cursor: pointer;
      transition: all .3s ease;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        box-shadow: none !important;
        background: @vars[white-to-neutral-dark] !important;
        border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
        color: @vars[neutral-800-to-100] !important;
        background: @vars[white-to-neutral-dark] !important;

        &:hover, &:focus {
          border: 1px solid @bluePrimary !important;
        }
      });

      svg {
        transition: all .3s ease;
        .with-theme({
          path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        })
      }

      &.ant-dropdown-open {
        svg {
          transform: rotate(180deg);
        }

        .with-theme({
          border: 1px solid @bluePrimary !important;
        })
      }
    }

    .ant-dropdown {
      top: 300px !important;

      &:has(.state) {
        top: 358px !important;
      }

      .input-wrapper-dropdown {
        border-radius: 12px;
        width: 100%;
        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[white-to-neutral-dark];
          box-shadow: @vars[box-shadow-object-large];
        });

        .ant-input-affix-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          border-radius: 12px 12px 0 0;
          height: 40px;
          padding: 6px 12px 6px 0;
          border: none !important;
          .with-theme({
            border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
            box-shadow: none !important;

            &:hover, &:focus-within {
              border-bottom: 1px solid @bluePrimary !important;
            }
          });

          .ant-input-prefix {
            margin-inline-end: 0;
            width: 20px;
            height: 20px;

            svg {
              width: 20px;
              height: 20px;
              .with-theme({
                path {
                  fill: @vars[neutral-600-to-neutral-dark-500];
                }
              })
            }
          }

          input {
            height: 20px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
              caret-color: @vars[neutral-800-to-100];
            })
          }
        }

        &-options {
          padding: 8px;
          max-height: 240px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }
          &::-webkit-scrollbar-track-piece:start {
            background: transparent;
            margin-top: 10px;
          }
          &::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-bottom: 10px;
          }
        }

        &-option {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          transition: all .3s ease;
          cursor: pointer;
          border-radius: 8px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          &:hover {
            .with-theme({
              background: @vars[blue-100-to-neutral-dark-100];
            })
          }
        }
      }
    }
  }

  &.edit-address-modal {

    .ant-dropdown {
      top: 330px !important;

      &:has(.state) {
        top: 385px !important;
      }
    }
  }

  &.edit-details-modal {

    .ant-dropdown {
      top: 415px !important;

      &:has(.state) {
        top: 470px !important;
      }
    }
  }

  &.edit-limits-modal {

    .ant-modal-content {
      padding: 0 !important;
      border-radius: 20px;
    }

    .sales-tracker-limit-modal-content {
      padding: 0 16px;

      &:has(.final-confirmation-form-wrapper) {
        padding: 0;
      }

      .sales-tracker-limit-modal-sliders-wrapper {

        .sales-tracker-limit-modal-slider-title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }

        .sales-tracker-limit-modal-slider {

          .ant-slider {
            height: 38px;
            margin: 8px 0 0;
            padding-block: 12px 0;
          }

          .ant-slider-step {
            top: 14px;

            .ant-slider-dot, .ant-slider-dot-active {
              width: 2px !important;
              height: 8px !important;
              border-radius: 0 !important;
              border: none !important;
              .with-theme({
                background: @vars[white-to-neutral-dark-50] !important;
              });
            }
          }

          .ant-slider-handle {
            inset-block-start: 9px;
            width: 0 !important;
            height: 0 !important;

            &:before {
              display: none;
            }

            &:after {
              box-shadow: none !important;
              inset-inline-start: -5px !important;
              inset-block-start: 0 !important;
              width: 14px !important;
              height: 14px !important;
              .with-theme({
                background: @vars[neutral-800-to-100] !important;
              })
            }
          }

          .ant-slider-rail {
            height: 8px;
            border-radius: 8px;
            .with-theme({
              background: @vars[neutral-100-to-neutral-dark-100];
            })
          }

          .ant-slider-track {
            height: 8px;
            border-radius: 8px;
            .with-theme({
              background: @vars[neutral-800-to-100];
            })
          }

          .ant-slider-mark {
            top: 25px;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.3px;

            &-text {
              .with-theme({
                color: @vars[neutral-500-to-neutral-dark-500];

                &:last-of-type {
                  padding-right: 18px;
                }

                &:first-of-type {
                  padding-left: 18px;
                }

                &-active {
                  color: @vars[neutral-800-to-100];
                }
              });
            }
          }
        }
      }

      .sales-tracker-limit-modal-cost {

        &-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .title {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;

          .key {
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            });
          }

          .value {
            .with-theme({
              color: @vars[neutral-800-to-100];
            });
          }
        }

        .value {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;

          .key {
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            });
          }

          .value {
            .with-theme({
              color: @vars[neutral-800-to-100];
            });
          }
        }
      }

      .final-confirmation-plan {

        .ant-spin-container {
          width: 100%;
          display: flex;
          position: relative;
          align-items: center;
          gap: 16px;

          @media @viewport-mobile {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .final-confirmation-icon {
          position: absolute;
          left: 50%;
          top: 34px;
          transform: translate(-50%, 0);
          display: flex;
          padding: 8px;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          background: @Neutral800;
          .with-theme({
            box-shadow: @vars[box-shadow-object-middle];
          });

          @media @viewport-mobile {
            top: 120px;
          }
        }

        .switch-plan {

          &-wrapper {
            width: calc(50% - 8px);
            padding: 16px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            .with-theme({
              background: @vars[white-to-neutral-dark-50];
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            });

            @media @viewport-mobile {
              width: 100%;
            }
          }

          &-title {
            text-transform: capitalize;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            })
          }

          &-info-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 12px;
            flex-wrap: wrap;

            @media @viewport-mobile {
              gap: 8px;
              width: 100%;
            }
          }

          &-info {
            width: calc(50% - 6px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;

            @media @viewport-mobile {
              width: calc((100% - 16px) / 3);
            }

            .title {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.3px;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              });
            }

            .value {
              text-transform: capitalize;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.3px;
              .with-theme({
                color: @vars[neutral-800-to-100];
              });
            }
          }
        }
      }

      .final-confirmation-form-wrapper {
        .ant-dropdown {
          top: 264px !important;

          &:has(.state) {
            top: 322px !important;
          }
        }
      }
    }
  }

  &.shopify-stores-modal {
    .user_store-item {
      width: 100%;
      padding: 0 !important;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-start;

      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
      }

      .name {
        white-space: nowrap;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      .domain {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      .btn-cancel {
        margin-left: auto;
        height: 32px;
        padding: 6px 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        border-radius: 10px;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        outline: none !important;
        color: @redPrimary !important;
        .with-theme({
          background: @vars[white-to-neutral-dark] !important;
          box-shadow: @vars[box-shadow-btn-secondary] !important;
        });

        &:hover, &:focus {
          color: @redPrimary !important;
          .with-theme({
            background: @vars[white-to-neutral-dark] !important;
            box-shadow: @vars[box-shadow-negative] !important;
          })
        }
      }

      .btn-check-limit {
        margin-left: auto;
        height: 32px;
        padding: 6px 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        border-radius: 10px;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        outline: none !important;
        color: @redPrimary !important;
        .with-theme({
          color: @vars[neutral-800-to-100] !important;
          background: @vars[white-to-neutral-dark-50] !important;
          box-shadow: @vars[box-shadow-btn-secondary] !important;

          &:hover, &:focus {
            background: @vars[neutral-50-to-neutral-dark-100] !important;
          }
        });
      }
    }

    .user_store-list {
      padding: 2px 8px 8px;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }

      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }

      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }

      .user_store-item {
        width: 100%;
        padding: 2px 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-start;

        svg {
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
        }

        .name {
          white-space: nowrap;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }

        .domain {
          white-space: nowrap;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }

        .btn-cancel {
          margin-left: auto;
          height: 32px;
          padding: 6px 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          border-radius: 10px;
          cursor: pointer;
          transition: all .3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none !important;
          outline: none !important;
          color: @redPrimary !important;
          .with-theme({
            background: @vars[white-to-neutral-dark] !important;
            box-shadow: @vars[box-shadow-btn-secondary] !important;
          });

          &:hover, &:focus {
            color: @redPrimary !important;
            .with-theme({
              background: @vars[white-to-neutral-dark] !important;
              box-shadow: @vars[box-shadow-negative] !important;
            })
          }
        }
      }
    }
  }

  &.aditem-info-modal {

    .collection-modal-text {
      max-width: 450px;
    }

    .aditem-info-modal-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 16px;
    }

    .aditem-info-modal-info-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }

    .aditem-info-modal-info-text {
      display: flex;
      align-items: center;

      .value {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.7px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      .status {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-400-to-neutral-dark-400];

          svg path {
            fill: @vars[neutral-400-to-neutral-dark-400];
          }
        });
      }
    }
  }

  &.adlibrary-sort-modal {

    .collection-modal-text {
      padding: 0 16px;
    }

    .sales-tracker-top-page-header-dropdown.sort {
      width: 100%;
      border: none !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      padding: 0;
      gap: 0 !important;

      .sort-header {
        padding: 0 16px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-400-to-neutral-dark-400];
        });

        &-options {
          padding: 0 16px;
          margin-top: 8px;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &-option {
          width: 50%;
          cursor: pointer;
          transition: all .3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 12px 8px;
          border-radius: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            background: @vars[white-to-neutral-dark-50];
            color: @vars[neutral-500-to-neutral-dark-500];

            &:hover {
              background: @vars[neutral-50-to-neutral-dark-100];
            }
          });

          &.active {
            border: 1px solid @bluePrimary !important;
            color: @white !important;
            background: @bluePrimary !important;
          }
        }
      }

      .sort-options {
        padding: 0 16px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        gap: 4px;

        .sort-option {
          display: flex;
          border-radius: 10px;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          height: 34px;
          padding: 0 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          cursor: pointer;
          transition: all .3s ease;
          .with-theme({
            color: @vars[neutral-800-to-100];

            &:hover {
              background: @vars[neutral-50-to-neutral-dark-100];
            }
          });

          &.active {
            background: @bluePrimary !important;
            color: @white !important;
          }
        }
      }
    }

    .collection-modal-btn-wrapper {
      padding: 0 16px;
      width: 100%;
    }

    .collection-modal-btn {
      width: 50%;
    }
  }

  &.forgot-password-modal {
    top: unset !important;
    left: 0 !important;
  }
}
