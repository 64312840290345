@import "variables.less";
@import "../theme/theme";

.onboarding-scroll() {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });
  }
}

.invoice-total() {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 16px;
  div {
    &:first-child {
      font-size: 12px;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      color: #707BA0;
    }
    &:last-child {
      font-size: 18px;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
      span {
        font-size: 12px;
        padding-left: 3px;
      }
    }
  }
}

.ant-select-item-option-active() {

  &:not(.ant-select-item-option-selected) {
    background-color: #fff;

    > div {

      &:hover {
        background: @Neutral100;
        .with-theme({
          background: @vars[neutral-100-to-blue-900] !important;
        });
      }
    }
  }
}

.ant-select-item() {
  padding: 0 4px;
  background: #FFFFFF;

  > div {
    border-radius: @border-radius;
    height: 40px;
    line-height: 24px;
    padding: 8px 12px;
    display: flex;

    > .check-icon {
      margin-left: auto;
    }

    .ant-checkbox-wrapper {
      font-size: 8px;
      line-height: 35px;

      .ant-checkbox {
        align-self: center;
      }

      .ant-checkbox + span {
        padding: 0 12px;
        width: 100%;

        span {
          font-size: 14px !important;
          font-weight: normal;
          line-height: 35px;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.products_interest_targeting() {
  &:first-child {
    border-left: 1px solid transparent;
    text-align: left;
    padding: 12px 16px 11px 12px;

    @media @viewport-mobile {
      padding: 8px 0 8px 0;
      border: none;
    }
  }
  &:last-child {
    border-right: 1px solid transparent;
    padding: 12px 12px 11px 0;
    max-width: 30px !important;
    @media @viewport-mobile {
      padding: 0;
      border: none;
    }
  }

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: @Neutral100;
    position: absolute;
    z-index: 3;
    //top: -3px;
    bottom: -2px;
    left: 0;

    .with-theme({
      background: @vars[defatult-border-color];
    });
    @media @viewport-mobile {
      content: none;
    }
  }

  &:first-child::after {
    left: 10px;

    @media @viewport-mobile {
      all: inherit;
      content: '';
      padding: 0;
      height: 1px;
      width: calc(100vw - 40px);
      background-color: @Neutral100;
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      border: none;
      .with-theme({
        background-color: @vars[defatult-border-color];
      });
    }
  }
  &:last-child::after {
    left: -10px;
  }
}

.score_counter() {
  font-weight: 500;
  font-size: 14px;
  height: 64px;
  color: @Neutral800;
  .with-theme({
    color: @vars[neutral-800-to-200];
  });
  &.score-counter {
    height: 48px;
    width: 48px;
    display: inline-block;
    position: relative;
    .score-counter-num {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      line-height: 48px;
      font-size: 14px;
      color: @Neutral800;

      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }
  }
}

.some_td_styles() {
  td.ant-table-cell {
    background-color: transparent;

    &::after {
      content: none;
    }
    @media @viewport-mobile {
      border: none;
    }
    .with-theme({
      border-bottom: 1px solid @vars[defatult-border-color];
      border-top: 1px solid @vars[defatult-border-color];
      @media @viewport-mobile {
        border: none;
      }
    });
  }

  td:first-child {
    border-left: 1px solid;
    border-top-left-radius: @border-radius;
    border-bottom-left-radius: @border-radius;

    .with-theme({
      border-left: 1px solid @vars[defatult-border-color];
      @media @viewport-mobile {
        border: none;
      }
    });
  }

  td:last-child {
    border-right: 1px solid;
    border-top-right-radius: @border-radius;
    border-bottom-right-radius: @border-radius;

    .with-theme({
      border-right: 1px solid @vars[defatult-border-color];
      @media @viewport-mobile {
        border: none;
      }
    });
  }
}

.some_title_styles() {
  .title{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;

    .with-theme({
      color: @vars[neutral-800-to-200];
    });

    @media @viewport-mobile {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .text{
    margin: 8px 0 32px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: normal;

    @media @viewport-mobile {
      margin: 8px 0 24px;
    }
  }

  .ant-btn.btn-primary{
    height: 40px;
    padding: 0 19px;

    @media @viewport-mobile {
      width: 100%;
    }
  }
}
