@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

@keyframes pulses {
  0%, 100% {
    transform: scale(1);
  }
  10%, 40%, 70% {
    transform: scale(1.02);
  }
  20%, 50%, 80% {
    transform: scale(1.01);
  }
  30%, 60%, 90% {
    transform: scale(0.99);
  }
}


.global-wrap {
  &.theme-light {
    background-color: @white !important;
  }

  &.theme-dark {
    background-color: @NeutralDark;

    .confirm-email-banner {
      background: @yellow900;
      color: @Neutral200;

      span {
        &:first-of-type svg path {
          fill: @yellowPrimary;
        }
        &:last-of-type svg path {
          fill: @Neutral200;
        }
      }
    }
  }

  .main-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    align-items: stretch;

    @media @viewport-mobile {
      height: calc(var(--app-height) - 68px);
      overflow: hidden;

      &:has(.setting_account), &:has(.setting_billing), &:has(.setting_plan), &:has(.setting_integrations) {
        height: 100dvh !important;
      }
    }
  }

  &.big-sidebar {

    .main-content {
      max-width: calc(100% - 272px);
    }
  }

  .main-content {
    transition: all .3s ease;
    flex: 1;
    overflow: auto;
    position: relative;
    max-width: calc(100% - 72px);
    margin: 0 0 0 auto;

    @media @viewport-mobile {
      flex: 1 0 auto;
      max-width: 100vw;
      margin: 0;
      height: calc(var(--app-height) - 68px);

      &.setting_account, &.setting_billing, &.setting_plan, &.setting_integrations {
        height: 100dvh !important;
      }
    }
  }

  &:after {
    content: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 68px;
    position: absolute;
    z-index: 1000;
    background-color: rgba(6, 61, 140, 0.3);

    @media @viewport-mobile {
      height: calc(100% - 68px);
    }
  }
}

.header {
  height: 68px;
  padding: 24px 24px;
  display: none;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  &_right-part {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  &_item {
    margin-left: 16px;
    .trans();

    &--cart {
      cursor: pointer;
      .ant-badge{
        &-dot{
          background: @blue500;
          top: 7px;
          right: 2px;

          .with-theme({
            box-shadow: 0 0 0 1px @vars[color-white-to-dark];
          });
        }
      }
    }

    &--notifications {
      .ant-dropdown-trigger {
        & + div {
          .ant-dropdown {
            top: 100% !important;
            right: -74px !important;
            left: revert !important;
          }
        }
      }
    }

    &--user {
      margin-left: 24px;
      .ant-dropdown-trigger {
        & + div {
          .ant-dropdown {
            top: 100% !important;
            right: 0 !important;
            left: revert !important;
          }
        }
      }
    }
  }

  .menu-button {
    padding: 0;
    margin: 0;
    display: none;

    @media @viewport-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media @viewport-mobile {
    display: flex;
    padding: 16px;
  }
}
