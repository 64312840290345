@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.store-tracker-page-overview-empty {
  margin: 0 32px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: calc(100% - 64px);
  height: 450px;
  .with-theme({
    background: @vars[neutral-50-to-neutral-dark-50];
  });

  &-skeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 14px 12px;
    border-radius: 10px;
    width: fit-content;
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-circle];
    });

    span {
      display: block;
    }
  }

  @media @viewport-mobile {
    margin: 0 16px 40px;
    width: calc(100% - 32px);
    aspect-ratio: 1;
    height: unset;
  }

  &-icons {
    display: flex;
    align-items: center;

    &-left {
      display: flex;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &-icon {
    z-index: 10;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 48px;
    height: 48px;
    .with-theme({
      background: @vars[neutral-800-to-neutral-dark];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &-small {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background: @vars[white-to-neutral-dark-50];
      });
    }
  }

  &-title {
    margin-top: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      margin-top: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-500-to-neutral-dark-500];
    });

    @media @viewport-mobile {
      padding: 0 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      width: 100%;
    }
  }

  &-btn {
    margin: 16px auto 0;
    height: 36px;
    padding: 6px 12px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    .with-theme({
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus {
        background: @vars[neutral-50-to-neutral-dark-100];
      }
    });
  }
}
