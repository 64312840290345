@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.order-now-wrapper {
  margin-top: 56px;
  width: 520px;

  @media @viewport-mobile {
    margin-top: 48px;
    width: 100%;
  }

  h3 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: @dark;
    text-align: left;

    @media @viewport-mobile {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }

  .order-now-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &-item {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 16px;

      &-border {
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        border-radius: @border-radius;
        background: @bluePrimary;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h4 {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: @dark;
          margin-bottom: 0;
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: @Neutral500;
        }
      }
    }
  }
}
