@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.report-main-block {
  display: flex;
  align-items: center;
  padding: 24px 32px !important;
  margin: 0 !important;
  width: 100%;
  justify-content: space-between;
  border: none !important;

  @media @viewport-mobile {
    padding: 16px !important;
    flex-direction: column;
    align-items: flex-start;
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 16px;

    @media @viewport-mobile {
      width: 100%;
      gap: 0;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.5px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    .key {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      display: flex;
      align-items: center;
      gap: 4px;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      });

      .value {
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }
    }
  }

  &-switch {
    display: flex;
    align-items: center;
    gap: 8px;

    @media @viewport-mobile {
      width: 100%;
      align-items: flex-start;
      margin-bottom: 16px;
    }

    .ant-switch {
      width: 36px;
      min-width: unset;
      height: 20px;
      border-radius: 50px;
      .with-theme({
        box-shadow: 0px 1px 2px 0px rgba(21, 30, 58, 0.05) inset, 0px 0px 7px 1px rgba(21, 30, 58, 0.04) inset;
        background: @vars[neutral-200-to-neutral-dark-200] !important;
      });

      &:focus, &:hover {
        outline: none;
        box-shadow: none;
      }

      &-handle {
        width: 16px;
        height: 16px;
        background: @white;
        box-shadow: none;
        border-radius: 50%;
      }

      &.ant-switch-checked {
        background: @greenToggle !important;

        .ant-switch-handle {
          left: calc(100% - 18px);
        }
      }
    }

    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      })
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 12px;

    @media @viewport-mobile {
      flex-direction: column;
      width: 100%;
    }
  }

  .select-block {
    width: unset !important;

    @media @viewport-mobile {
      width: 100% !important;
      min-width: 100% !important;
    }

    .ant-dropdown-trigger.dropdown-database .dropdown-database_value {
      margin: 0;
    }
  }

  &-btn, .dropdown-database.report_period {
    display: flex;
    height: 36px !important;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all .3s ease;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    border-radius: 8px;
    user-select: none;
    .with-theme({
      border: none !important;
      background: @vars[white-to-neutral-dark];
      color: @vars[neutral-800-to-100] !important;
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &.ant-dropdown-open {
        background: @vars[neutral-50-to-neutral-dark-100];
      }

      &.dropdown-database-disabled {
        background: @vars[neutral-50-to-neutral-dark-50] !important;
        cursor: not-allowed;
        position: relative;

        .disabled-calendar_tooltip {
          max-width: 400px;
          top: -35px !important;
        }
      }
    });

    @media @viewport-mobile {
      width: 100%;
    }

    &[disabled] {
      cursor: not-allowed;
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100] !important;
        color: @vars[neutral-300-to-neutral-dark-300] !important;

        svg path {
          stroke: @vars[neutral-300-to-neutral-dark-300] !important;
        }
      })
    }
  }
}
