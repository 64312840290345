@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.suppliers-page-card {

  &-wrapper {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    padding: 16px;
    display: flex;
    flex-direction: column;
    min-width: 352px;
    width: calc((100% - 48px) / 3);
    height: 720px;
    border-radius: 16px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      width: 100%;
      min-width: 100%;
      height: max-content;
      padding: 0 0 32px;
      border-radius: 0;
      border: none !important;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
      });

      &:last-of-type {
        border-bottom: none !important;
        padding: 0 !important;
      }
    }
  }

  &-bio {
    display: flex;
  }

  &-image {
    position: relative;
    width: 48px;
    height: 48px;
    min-height: 48px;
    border-radius: 50%;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    .with-theme({
      background-color: @vars[blue-100-to-900];
    });
    margin-right: 12px;

    div {
      border-radius: 50%;
      display: flex;
      width: 20px;
      height: 20px;
      padding: 2px;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
      })
    }
  }

  &-name {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    &-wrapper {
      max-width: calc(100% - 60px);
    }
  }

  &-job {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin-top: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    &.overflowing {
      &:before {
        position: absolute;
        left: 0;
        top: -25px;
        width: max-content;
        max-width: 230px;
        white-space: break-spaces;
        border-radius: 6px;
        padding: 16px;
        font-size: 12px;
        line-height: 16px;
        background: @Neutral800;
        color: @white;
        text-align: left;
        content: attr(data-text);
        display: none;
      }

      &:hover:before {
        display: block;
      }
    }
  }

  &-description {
    margin-top: 18px;
    max-height: 100px;
    overflow-y: scroll;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    @media @viewport-mobile {
      min-height: 50px;
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
  }

  &-button {
    margin: 24px auto 32px;
    width: 100%;
    display: flex;
    height: 40px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: var(--Light-Background-Default-Light, #FFF);
    .with-theme({
      color: @vars[neutral-800-to-100];
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus, &:active {
        background: @vars[neutral-50-to-neutral-dark-100];
        color: @vars[neutral-800-to-100];
      }
    });
  }

  &-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-info-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &:last-of-type {
      margin-bottom: 2px;
    }
  }

  &-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-info-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-500-to-neutral-dark-500];
    })
  }

  &-info-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-country-wrapper {
    margin-top: 24px;

    .suppliers-page-card-info-title {
      display: flex;
      align-items: center;
      gap: 12px;
      
      &>span {
        min-width: 78px;
      }
      
      &>div {
        height: 1px;
        width: 100%;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        })
      }
    }
  }

  &-country-info-wrapper {
    max-height: 120px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    gap: 8px;

    &::-webkit-scrollbar {
      width: 2px;
      height: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
  }

  &-country-info {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  }

  &-country-image {
    width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: @border-radius;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}
