@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

// SEARCH
.search {
  &_wrapper {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 0 32px;

    @media @viewport-mobile {
      margin: 24px 0 16px;
    }
  }

  &_product-view,
  &_button {
    flex: 0;
  }

  &_button {
    @media @viewport-mobile {
      .btn {
        width: 48px !important;
        min-width: 48px !important;
        height: 48px !important;
        padding: 0 !important;

        &_text {
          display: none;
        }
      }
    }
  }

  // SEARCH INPUT
  &-input {
    position: relative;
    width: 100%;
    margin-right: 16px;

    @media @viewport-mobile {
      margin-right: 12px;
    }
    .ant-input-affix-wrapper {
      box-shadow: none !important;
      height: 40px;
      padding: 6px 12px;
      background: transparent;
      border-radius: 10px !important;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      .ant-input-prefix {
        svg path{
          .with-theme({
            fill: @vars[neutral-400-to-neutral-dark-400];
          });
        }
      }

      &-focused, &:hover {
        border-color: @bluePrimary !important;
      }

      &-disabled{
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-200];
        });

        .ant-input.ant-input-disabled[disabled]::placeholder{
          opacity: 1;
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400]!important;
          });
        }
      }

      @media @viewport-mobile {
        padding: 0 12px;
      }

      .ant-input-prefix {
        .search-divider {
          height: 32px;
          margin-left: 16px;
          margin-right: 0;
          border-color: transparent;

          @media @viewport-mobile {
            margin-left: 12px;
          }
          .with-theme({
            background-color: @vars[neutral-100-to-800];
          });
        }
      }
      .ant-input {
        border-radius: 0;
        background-color: transparent;

        &::placeholder {
          @media @viewport-mobile {
            font-size: 14px;
          }
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400] !important;
          });
        }
      }
    }

    .ant-input-group-addon {
      display: none;
    }
  }
}
