@import "../../../styles/utils/variables";

.login-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    height: var(--app-height);
}

.skip-offer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 7.777vw;
  background: @blue100;

  @media @viewport-mobile {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-btn {
    cursor: pointer;
    margin-left: 25%;
    display: flex;
    align-items: center;
    gap: 4px;

    @media @viewport-mobile {
      margin-left: 0;
    }

    span {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: @bluePrimary;
      transition: all .3s ease;
    }

    svg {
      margin-top: 2px;
      transition: all .3s ease;
    }

    &:hover {
      span {
        color: @blueDepressed;
      }

      svg path {
        fill: @blueDepressed;
      }
    }
  }

  &-text {
    margin-left: 25%;

    @media @viewport-mobile {
      margin-left: 0;
    }

    svg {
      margin-right: 8px;
    }

    span {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: @dark;
    }
  }
}

.header-row {
  min-height: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 7.777vw;
  border-bottom: 1px solid #f2f3f8;
}
