@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.sales-tracker-product-page {

  .product-page-table-skeleton {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    .with-theme({
      border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      &:not(&-header) {
        height: 80px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        width: 100px;

        &:first-of-type {
          padding: 8px 16px 8px 32px;
          width: 150px;
        }

        &:last-of-type {
          padding: 8px 32px 8px 16px;
          justify-content: flex-end;
        }

        &:not(:first-of-type), &:not(:last-of-type) {
          padding: 8px 16px;
        }
      }

      &-header {
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-50];
        })
      }
    }
  }
}
