@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';
@import '../../../styles/utils/variables.less';
@import '../../../styles/utils/mixin.less';

.invoice-landing-page {
  margin: 50px auto;
  border: 1px solid rgb(201, 198, 198);
  border-radius: @border-radius;
  max-width: 600px;
  padding: 40px !important;
  position: relative;

    @media @viewport-mobile {
      height: var(--app-height);
      overflow: auto;
      padding: 20px !important;
      margin: 0 auto;
      border: none;
    }
    .invoice-pdf-btn-landing {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid @blue;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 38px;
      bottom: 100px;
      @media @viewport-mobile {
        top: 75px;
        right: 40px;
      }
      .with-theme({
        background-color: @vars[blue-100-to-900] !important;
      });
    }
    .invoice {
      min-height: 762px;
      position: relative;
      .text-grey {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #707BA0;
      }
      .text-dark {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        .with-theme({
          color: @vars[neutral-800-to-200];
        });
      }
      .title-block-landing {
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: @border-radius;
        color: @blue;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 18px;
        border-bottom: 32px rgb(238, 234, 234);
        background-color: @blue100;
        .with-theme({
          background-color: @vars[blue-100-to-900] !important;
        });
        &.error {
          color: #D71313;
          .with-theme({
            background-color: @vars[red-100-to-900] !important;
          });
        }
      }
      .invoice-header {
        height: 48px;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        @media @viewport-mobile {
          flex-direction: column;
          height: 96px;
          justify-content: center;
          align-items: center;
          img {
            margin-bottom: 8px;
          }
        }

        &_info-landing {
          height: 100%;
          width: 280px;
          .invoice-header-flexbox {
            display: flex;
            justify-content: space-between;
            padding: 4px 0;
          }
        }
      }
      .invoice-paid {
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        &_date {
          height: 100%;
          padding: 8px 0;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });
        }
        &_amount {
          height: 100%;
          color: @blue;
          padding: 8px 16px;
          border-radius: @border-radius;
          font-size: 18px;
          line-height: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          white-space: nowrap;
          text-align: center;
          background-color: @blue100;
          .with-theme({
            background-color: @vars[blue-100-to-900] !important;
          });

          &.error {
            color: #D71313;
            .with-theme({
              background-color: @vars[red-100-to-900] !important;
            });
          }
          span {
            font-size: 12px;
            padding-left: 3px;
          }
        }
      }
      .invoice-bill {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        border-top: 1px solid rgb(238, 234, 234);
        padding-top: 24px;
        .with-theme({
          border-color: @vars[neutral-100-to-blue-900] !important;
        });
        .bill-block {
          &.bill-to > div {
            text-align: right;
          }
          .bill-header {
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 24px;
            font-family: 'Inter', sans-serif;
            font-weight: 700;
            text-align: left;
            .with-theme({
              color: @vars[neutral-800-to-200];
            });
          }
          .bill-text {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin: 8px 0;
            text-align: left;
            .with-theme({
              color: @vars[neutral-800-to-200];
            });
          }
        }
      }
      .invoice-table {
        margin-top: 24px;
        .invoice-table-header {
          height: 40px;
          line-height: 40px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          border-bottom: 1px solid rgb(238, 234, 234);
          border-top: 1px solid rgb(238, 234, 234);
          .with-theme({
            border-color: @vars[neutral-100-to-blue-900] !important;
          });
          div {
            width: 150px;
            &:first-child {
              width: 200px;
            }
            &:nth-child(2) {
              width: 200px;
            }
            &:last-child {
              text-align: right;
              width: 100px;
            }
          }
        }
        .invoice-table-rows {
          height: 40px;
          line-height: 40px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          border-bottom: 1px solid rgb(238, 234, 234);

          .with-theme({
            border-color: @vars[neutral-100-to-blue-900] !important;
            color: @vars[neutral-800-to-200];
          });
          div {
            width: 150px;
            &:first-child {
              width: 200px;
            }
            &:nth-child(2) {
              width: 200px;
            }
            &:last-child {
              text-align: right;
              width: 100px;
            }
          }
        }
      }
      .invoice-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 40px;
        div {
          font-size: 12px;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          color: #707BA0;
        }
      }

      .invoice-total {
        .invoice-total();
      }

      .invoice-footer-landing {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 40px;
        display: flex;
        padding-top: 16px;
        border-top: 1px solid rgb(238, 234, 234);
        margin-top: auto;
        margin-bottom: 0;
        justify-content: space-between;

        .with-theme({
          border-color: @vars[neutral-100-to-blue-900] !important;
        });
        div {
          font-size: 10px;
          color: #707BA0;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          span {
            .with-theme({
              color: @vars[neutral-800-to-200];
            });
          }
        }
        img {
          display: inline-block;
          width: 24px;
          height: 24px;
        }
      }
    }
}
