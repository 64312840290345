@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.university-page {

  &-course {
    &-wrapper {
      padding: 16px 32px 16px 0;
      cursor: pointer;
      width: 100%;
      min-height: 224px;
      display: flex;
      align-items: center;
      gap: 24px;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        padding: 16px 0;
        min-height: unset;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      &.no-clickable{
        cursor: default;
      }
    }

    &-image {
      margin-left: 32px;
      width: 370px;
      height: 208px;
      border-radius: 24px;
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position: center;
      .with-theme({
        background-color: @vars[blue-xlight-to-neutral-dark-50];
      });

      @media @viewport-mobile {
        margin: 12px 16px 0 16px;
        width: calc(100vw - 32px);
        height: calc(100vw - 32px);
        max-height: 162px;
      }
    }

    &-info {
      width: calc(100% - 24px - 370px - 64px);
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      letter-spacing: -0.3px;

      @media @viewport-mobile {
        width: calc(100vw - 32px);
        margin: 0 16px;
        padding: 0;
      }

      &-lessons {
        display: flex;
        align-items: center;

        @media @viewport-mobile {
          flex-wrap: wrap;
          gap: 12px;

          .ant-divider {
            display: none;
          }
        }

        &-tag {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          letter-spacing: -0.3px;
          color: @blueDepressed;
          padding: 4px 6px;
          border-radius: @border-radius;
          .with-theme({
            background: @vars[blue-100-to-neutral-dark-200];
            color: @vars[blue-600-to-neutral-dark-500]
          });

          @media @viewport-mobile {
            width: 100%;
          }
        }

        &-progress {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          });

          @media @viewport-mobile {
            width: calc(50% - 6px);
            align-items: flex-start;
            padding: 4px 12px;
          }

          .count {
            margin-right: 4px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            })
          }

          svg {
            margin-right: 8px;
          }
        }

        &-duration {
          svg {
            path {
              .with-theme({
                fill: @vars[neutral-500-to-neutral-dark-500];
              })
            }
          }
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }

      &-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
        margin-top: 12px;

        @media @viewport-mobile {
          font-size: 20px;
          line-height: 28px;
        }
      }

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      &-watch {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 12px;
        margin-top: 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: @white;
        border-radius: 10px;
        outline: none;
        border: none;
        background: @bluePrimary;
        height: 40px;
        .with-theme({
          box-shadow: @vars[box-shadow-btn-primary];
        });

        @media @viewport-mobile {
          margin-top: 16px;
          width: 100%;
        }

        &:not([disabled]):hover, &:not([disabled]):focus {
          background: @blueDepressed;
          color: @white;
        }

        &[disabled] {
          cursor: not-allowed;
          box-shadow: none !important;
          .with-theme({
            background: @vars[neutral-100-to-neutral-dark-100] !important;
            color: @vars[neutral-300-to-neutral-dark-300] !important;
          });
        }
      }
    }
  }
}
