@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.ad-item-nav {
    padding: 0px 32px;

    &-wrapper {
        height: 52px;
        display: flex;
        align-items: center;

        .ad-item-nav-tab {
            text-decoration: underline;
            display: flex;
            align-items: center;
            font-weight: 400;

            .ad-item-nav-logo {
                margin-right: 8px;
                
                img {
                    border-radius: 6px;
                    width: 20px;
                    height: 20px;
                }
            }
            
            .ad-item-nav-name {
                cursor: pointer;
            }

            &.active {
                text-decoration: none;
                font-weight: 500;

                .ad-item-nav-name {
                    cursor: auto;
                }
                .with-theme({
                    color: @vars[neutral-800-to-100];
                });
            }
            
            .with-theme({
                color: @vars[neutral-500-to-neutral-dark-500];
            });

            svg {
                margin: 0px 8px;
                path{
                    .with-theme({
                        stroke: @vars[neutral-300-to-neutral-dark-300];
                    });
                }
            }
            
        }
    }
}