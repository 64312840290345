@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.category-portfolio {
  max-width: 400px;
  text-align: center;
  margin: 264px auto 0 !important;

  @media @viewport-mobile {
    margin: 316px auto 0 !important;
  }

  &_name {
    margin: 0 0 64px;
    font-size: 48px;
    line-height: 72px;
    font-weight: 600;
    .with-theme({
      color: @vars[category-text-color];
    });

    @media @viewport-mobile {
      font-size: 32px;
      line-height: 48px;
    }

    &.is-active {
      margin: 0 0 12px;
    }

    & + .category_sub-text {
      margin: 0 0 64px;

      @media @viewport-mobile {
        margin: 0 0 48px;
      }
    }

    & + .category_icon {
      margin: 0 0 136px;
    }
  }

  &_sub-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 24px;

    & + .category_icon {
      margin: 0 0 104px;
    }

    @media @viewport-mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &_icon {
    margin: 0 0 96px;
    .panel_image {
      width: 300px;
      height: 300px;

      @media @viewport-mobile {
        width: 164px;
        height: 164px;
      }
    }
  }

  &_button {
    @media @viewport-mobile {
      padding: 0 20px;
    }
    button {
      .btn-x-large();
    }

    &--disabled {
      button[disabled] {
        .with-theme({
          background-color: @vars[neutral-100-to-800];
          color: @vars[neutral-300-to-700];
        });

        cursor: not-allowed;
      }
    }
  }
}
