@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/animation';
@import '../../../../../styles/base/ui-kit';

.collections-page-list-item {
  font-family: "Inter", sans-serif;
  border-radius: 16px;
  width: 264px;
  height: 460px;
  .with-theme({
    background: @vars[white-to-neutral-dark];
    box-shadow: @vars[box-shadow-btn-secondary];
  });

  @media @viewport-mobile {
    width: 100%;
    height: unset;
  }

  &-images {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    width: 100%;
    height: 264px;

    @media @viewport-mobile {
      width: calc(100vw - 34px);
      height: calc(100vw - 34px);
    }
  }

  &-image {
    width: 131px;
    height: 131px;
    display: flex;
    align-items: center;
    justify-content: center;
    .with-theme({
      background: @vars[neutral-50-to-neutral-dark-50];
    });

    @media @viewport-mobile {
      width: calc(50% - 1px);
      height: calc(50% - 1px);
    }

    &.left {
      border-radius: 16px 0 0 0;

      img {
        border-radius: 16px 0 0 0;
      }
    }

    &.right {
      border-radius: 0 16px 0 0;

      img {
        border-radius: 0 16px 0 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      width: 90%;
      height: 90%;
    }
  }

  &-content-wrapper {

  }

  &-info {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    .collection-item_set-as-default {
      cursor: pointer;
    }

    &-name {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

    }

    &-count {
      text-align: center;
      height: 24px;
      min-width: 24px;
      padding: 4px 6px;
      border-radius: 6px;
      .with-theme({
        color: @vars[blue-depressed-to-neutral-100];
        background: @vars[blue-100-to-neutral-dark-100];
      })
    }

    &-action {
      cursor: pointer;
      margin-left: auto;

      .action-dropdown {
        width: 125px;
        border-radius: 12px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        .with-theme({
          background: @vars[white-to-neutral-dark];
          box-shadow: @vars[box-shadow-object-large];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        &-item {
          border-radius: 8px;
          height: 36px;
          width: 100%;
          padding: 0 8px;
          transition: all .3s ease;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          .ant-space {
            display: flex;
            align-items: center;
            gap: 8px !important;
            height: 36px;

            &-item {

            }
          }

          &.red {
            color: @redPrimary;
          }

          &:hover {
            .with-theme({
              background: @vars[blue-100-to-neutral-dark-100];;
            })
          }
        }

      }
    }
  }

  &-tabs {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    })
  }

  &-tab {
    position: relative;
    padding: 8px 12px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    &.active {
      cursor: default;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &:after {
        position: absolute;
        content: '';
        bottom: 3px;
        left: 15%;
        width: 70%;
        height: 2px;
        background: @bluePrimary;
      }
    }

    &-value-wrapper {
      padding: 16px 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    &-value-item {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: -0.3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      .value {
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }
  }
}
