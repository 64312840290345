@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';
@import '../../../styles/utils/variables';

.settings-page {
  padding: 32px calc(((100vw - 272px) - 728px) / 2) 0;
  overflow: unset!important;
  overflow-x: visible!important;
  height: 100dvh;
  overscroll-behavior: contain;
  font-family: 'Inter', sans-serif;

  &:has(.plan-subpage-block-custom) {
    padding: 32px 70px 0;
    @media @viewport-mobile {
      padding: 32px  0;
    }
  }

  @media @viewport-mobile {
    padding: 0;
    overflow: hidden !important;
  }

  &-header {

    &-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      padding: 16px;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      svg {
        transform: rotate(90deg);
        .with-theme({
          path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        })
      }
    }


    h3 {
      text-align: left;
      margin: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.7px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      @media @viewport-mobile {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.3px;
      }
    }

    h4 {
      text-align: left;
      margin: 12px 0 32px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      @media @viewport-mobile {
        margin: 8px 0 0;
      }
    }
  }

  .link.skeleton {
    display: block;
  }

  .settings-content {
    @media @viewport-mobile {
      height: calc(100dvh - 65px);
      overflow-y: auto;
      padding: 16px;

      &-billing {
        padding: 16px 0 0;
      }
    }
  }
}


  &.smallHeight {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}