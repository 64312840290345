@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.confirm-email-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: @yellow100;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.3px;
  color: @Neutral800;
  cursor: pointer;
  padding: 11px 32px;
  .with-theme({
    border-top: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
  });

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    svg{
      path{
        fill: @yellowPrimary !important;
      }
    }
  }

  .text-container {
    display: flex;
    justify-content: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        white-space: nowrap;
      }

      a {
        margin: 0 4px;
        color: #225AEA;
        text-decoration: none;
        text-wrap: nowrap;  

        &:hover {
          color: @blueDepressed;
        }
      }
    }
  }

  @media @viewport-mobile {
    align-items: center;
    padding: 16px;

    .text-container {
      flex-direction: column;
      div {
        justify-content: flex-start;
        text-align: center;
      }
    }
  }
}
