@import '../../../styles/theme/theme';
@import "../../../styles/utils/variables";

.switch-block {
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-switch {
    width: 32px;
    height: 20px;
    min-width: unset;
    border-radius: 100px;
    background: rgba(21, 30, 58, 0.10) !important;
    box-shadow: 0px 1px 2px 0px rgba(21, 30, 58, 0.05) inset, 0px 0px 7px 1px rgba(21, 30, 58, 0.04) inset !important;

    &-checked {
      .ant-switch-handle {
        inset-inline-start: calc(100% - 18px) !important;
      }
    }

    .ant-switch-handle {
      inset-inline-start: 2px;

      &::before {
        width: 16px;
        height: 16px;
      }
    }

    .ant-switch-inner {
      width: 32px;
      height: 20px;
      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;
    }

    &-checked {
      background: #34C759 !important;
    }
  }

  &-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: @Neutral600;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-tag {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: @greenDepressed;
    padding: 4px 6px;
    border-radius: @border-radius;
    background: @greenLight;
  }
}
