@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.store-products-modal-table-skeleton {

  &-wrapper {
    margin-top: 16px;
    max-height: 65vh;
    overflow: hidden;

    span.link.skeleton {
      display: block;
    }

  }

  &-row {
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });
  }

  &-header {
    width: 100%;
    display: flex;
    padding: 16px 24px;
    .with-theme({
      border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    })
  }
}
