@import '../../../styles/base/ui-kit';
@import '../../../styles/theme/theme';

.language-switcher{
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.3px;
  position: relative;


  .ant-dropdown {
    position: absolute;
    left: 95% !important;
    top: -120px !important;
  }

  &-dropdown {
    display: flex;
    width: 163px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 12px;
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      box-shadow: @vars[box-shadow-object-large];
    });

    &-option {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      margin: 0 auto;
      width: calc(100% - 8px);
      transition: all .3s ease;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-100];
        })
      }

      .language path {
        .with-theme({
          fill: @vars[neutral-600-to-neutral-dark-500];
        })
      }

      .checkmark {
        margin-left: auto;
      }

      span {
        margin-left: 8px;
        font-weight: 500;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

      }
    }
  }

  &-inner {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 4px auto;
    width: calc(100% - 8px);
    border-radius: 8px;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
      .with-theme({
        background: @vars[blue-100-to-neutral-dark-100];
      })
    }

    .language path {
      .with-theme({
        fill: @vars[neutral-600-to-neutral-dark-500];
      })
    }

    div {
      margin-left: 8px;
      font-weight: 500;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    span {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      svg {
        transform: rotate(-90deg);
        .with-theme({
          path {
            stroke: @vars[neutral-600-to-neutral-dark-500];
          }
        })
      }
    }
  }
}
