@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.university-video-page {

  @media @viewport-mobile {
    height: 100dvh;
  }

  .video-wrapper {
    width: 64vw;
    height: 100%;
    margin-left: 32px;
    border-radius: 16px;
    .with-theme({
      background-color: @vars[blue-xlight-to-neutral-dark-50];
    });

    iframe {
      border-radius: 16px;
    }

    @media @viewport-mobile {
      margin: 0;
      width: 100vw;
      height: 60vw;
      border-radius: 0;

      iframe {
        border-radius: 0;
      }
    }
  }

  .playlist-wrapper {
    width: calc(32vw - 24px - 64px);
    margin-right: 32px;
    height: 100%;
    border-radius: 12px;
    .with-theme({
      background: @vars[white-to-neutral-dark];
      box-shadow: @vars[box-shadow-btn-secondary];
    });

    @media @viewport-mobile {
      margin: 24px 16px 0;
      width: calc(100% - 32px);
      height: 400px;
    }

    .title-wrapper {
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      });
      height: 40px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        height: 70px;
      }

      .title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      .lessons-quantity {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }

        .count {
          margin-right: 4px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }
    }

    .playlist {
      height: calc(100% - 40px);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media @viewport-mobile {
        height: calc(100% - 70px);
      }

      &::-webkit-scrollbar {
        width: 2px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }

      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;
        background: transparent;
        transition: all .3s ease;
        .with-theme({
          border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        &:not(.active):hover {
          .with-theme({
            background: @vars[blue-xlight-to-neutral-dark-50];
          })
        }

        &.completed:not(.active) {

          .with-theme({
            .info-wrapper {
              .title, .playlist-item-duration {
                color: @vars[neutral-400-to-neutral-dark-400];
              }
            }

            .playlist-item-icon {
              svg path {
                fill: @vars[neutral-300-to-neutral-dark-300];
              }
            }
          });
        }

        &.active {
          .playlist-item-icon {
            border: none !important;
            .with-theme({
              background: @vars[blue-100-to-neutral-dark-100];
            });

            svg path {
              fill: @bluePrimary
            }
          }
          .with-theme({
            background: @vars[blue-xlight-to-neutral-dark-50];
          });
        }

        &-icon {
          margin-right: 12px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          background: transparent;
          .with-theme({
            border: 1px solid @vars[neutral-100-to-neutral-dark-100];
          });
          svg path {
            .with-theme({
              fill: @vars[neutral-400-to-neutral-dark-400]
            });
          }
        }

        &-duration {
          margin-top: 4px;
          display: flex;
          align-items: center;
          gap: 8px;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];

            svg path {
              fill: @vars[neutral-400-to-neutral-dark-400];
            }
          })
        }

        .info-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1 1;
        }

        &-status {
          margin-left: auto;
        }

        .title {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }

        .image {
          width: 132px;
          height: 74px;
          background-size: cover;
          background-position: center;
          margin-right: 20px;
        }
      }
    }
  }

  &-player-wrapper {
    display: flex;
    align-items: center;
    height: 34vw;
    gap: 24px;
    width: 100%;
    margin: 32px 0 24px;

    @media @viewport-mobile {
      height: auto !important;
      gap: 16px;
      margin: 0;
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 1920px) {
      height: 32vw;
    }

    @media screen and (max-width: 1440px) {
      height: 30vw;
    }
  }

  &-content-wrapper {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    padding: 0 32px;

    @media @viewport-mobile {
      padding: 0 16px;
    }
  }

  &-title {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &-text {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    .with-theme({
      color: @vars[neutral-500-to-neutral-dark-500];
    });

    a {
      text-decoration: none;
      cursor: pointer;
      color: @bluePrimary;
      transition: all .3s ease-in-out;

      &:hover {
        color: @blueDepressed;
      }
    }
  }
}
