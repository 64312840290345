@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';

.access-limited-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  gap: 12px;
  border-radius: 24px;
  padding: 30px 0;
  .with-theme({
    background: @vars[neutral-50-to-neutral-dark-50];
  });

  .empty-image-table{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;

    .with-theme({
      background: @vars[neutral-900-to-neutral-dark-50];
      border: 1px solid  @vars[neutral-200-to-neutral-dark-200];
    });
  }

  .access-limited-title{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: center;

    .with-theme({
      color: @vars[neutral-800-to-100];
      text-decoration: unset
    });
  }
  .access-limited-description{
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: -0.3px;
    text-align: center;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
      text-decoration: unset
    });
  }
  .redirect-btn {
    display: flex;
    align-items: center;
    padding: 0 12px;
    width: auto;
    height: 40px;
    border-radius: 10px !important;
    cursor: pointer;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];
      });
      &:hover, &:focus, &:active {
        .with-theme({
        background: @vars[neutral-50-to-neutral-dark-100];
        color: @vars[neutral-800-to-100];
      });
      }
    }
}