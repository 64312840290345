@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.plan-subpage {
  width: 100%;

  .ant-spin-nested-loading {
    overflow: visible !important;
  }

  &-block-custom {
    width: 100%;
    margin-bottom: 12px;

    &-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media @viewport-mobile {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
      }
    }

    &-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }


    &-plans {
      margin-top: 12px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 16px;
      flex-wrap: wrap;
      padding-bottom: 32px;
    }
  }
}
