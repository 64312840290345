@import '../../../../styles/theme/theme';

.min-max-input-wrapper {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;

  @media @viewport-mobile {
    margin-bottom: 12px;
  }

  .icon-btn {
    svg {
      .with-theme({
        path {
          stroke: @vars[neutral-600-to-neutral-dark-500];
        }
      })
    }
  }

  .ant-input-suffix {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-700-to-neutral-dark-500];
    })
  }

  .ant-input-affix-wrapper {
    margin-left: 16px;
    height: 40px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    transition: all .3s ease;
    .with-theme({
      background: @vars[white-to-neutral-dark];
      border-color: @vars[neutral-200-to-neutral-dark-200] !important;
    });

    .ant-input {
      background: transparent;

      &::placeholder {
        .with-theme({
          color: @vars[neutral-400-to-neutral-dark-400];
        })
      }
    }

    &:hover {
      border-color: @bluePrimary !important;
      box-shadow: none !important;
    }
    &:focus-within {
      border-color: @bluePrimary !important;
      box-shadow: 0 0 0 2px rgba(34, 90, 234, .2) !important;
    }
  }
}
