@import '../../../styles/base/ui-kit';
@import '../../../styles/theme/theme';

.scroll-bar {
  &--shadow {
    position: relative;
  }

  &-track-vertical {
    position: absolute;
    width: 2px;
    right: 0;
    bottom: 2px;
    top: 2px;
    border-radius: 2px;
    z-index: 10;

    .with-theme({
      background-color: transparent !important;
    });

    & > div {
      border-radius: 2px;
      .with-theme({
        background-color: @vars[neutral-200-to-neutral-dark-200] !important;
      })
    }
  }
}

.scroll-shadow {
  &--top, &--bottom {
    position: absolute;
    left: 0;
    right: 0;
    height: 40px;

  }

  &--top {
    top: -20px;
    transform: rotate(180deg);
    .with-theme({
      background: @vars[notif-gradient-top];
    });
  }

  &--bottom {
    bottom: -15px;
    .with-theme({
      background: @vars[notif-gradient-bottom];
    });
  }
}

.os-host {
  & > .os-scrollbar-vertical,
  & > .os-scrollbar-vertical {
    width: 2px !important;
    padding: 0;
    margin: 10px 0;
  }

  .os-scrollbar{
    &-track {
      .with-theme({
        background-color: transparent !important;
      });
    }

    &-handle {
      .with-theme({
        background-color: @bluePrimary !important;
      });
    }
  }
}
