@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.cascading-dropdown-modal {
  padding: 16px;

  &-header {
    display: flex;
    flex-direction: column;
    &-sup {
      font-size: 16px;
      font-weight: 500;

      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    &-sub {
      font-size: 14px;
      font-weight: 400;

      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      font-size: 14px;
      height: 40px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &-advance {
        padding: 8px;
        font-size: 14px;

        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        span {
          display: flex;
          justify-content: space-between;
          svg {
            transform: rotate(-90deg);
            path {
              .with-theme({
                stroke: @vars[neutral-800-to-100];
              });
            }
          }
        }
      }
    }
  }

  .cascading-modal-button {
    width: 100%;
    background-color: @bluePrimary;
    border-radius: 10px;
    border: none;
    &:hover {
      background-color: @blueDepressed;
    }
  }
}