@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/animation';
@import '../../../../../styles/base/ui-kit';

.admin-drops{

  &_wrapper{
    overflow-y: scroll;
    height: calc(100dvh - 180px);
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }

    .admin-drops-calendar:not(:last-of-type){
      padding-bottom: 48px;
      border-bottom: 1px solid @Neutral100;
      margin-bottom: 48px;
    }

    &_internal-block{
      max-width: 1088px;
      padding: 0 24px;
      margin: 0 auto;

      @media @viewport-mobile {
        padding: 0;
      }
    }

    @media @viewport-mobile {
      height: calc(100dvh - 250px);
      height: calc(var(--app-height) - 250px);
    }
  }

  &-calendar{
    position: relative;

    &_header {
      .flex-row-space-between();
      align-items: center;
      margin-bottom: 16px;
    }

    &_date {
      flex: 1;
      text-align: left;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      })

    }

    &_month {
      display: inline-block;
      position: relative;
      margin-right: 32px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -20px;
        bottom: 0;
        margin: auto;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: @blue500;
      }
    }

    &_year {
      display: inline-block;
    }

    &_link {
      font-size: 16px;
      line-height: 24px;
      &--disabled {
        cursor: not-allowed;
      }
    }

    &_category{
      border-radius: @border-radius;
      border: 1px solid @Neutral100;
      overflow: hidden;
      display: flex;
      align-items: center;

      @media @viewport-mobile {
        flex-direction: column;
      }

      &-content-wrapper {
        display: flex;
        align-items: center;

        @media @viewport-mobile {
          flex-direction: column;
          width: 100%;

          & > a {
            width: 100%;
          }
        }
      }

      &_header{
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .img{
          width: 48px;
          height: 48px;
        }

        .title{
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: @Neutral800;
          text-transform: capitalize;
          margin-left: 10px;
        }
      }

      &.disabled{
        .title{
          color: @Neutral300;
        }

        .admin-drops-week{
          &_glob_wrap{
            &:hover{
              background-color: #ffffff;
            }
          }

          &_wrap{
            &_header, &_footer{
              color: @Neutral300;
            }
          }
          &_text, &_status{
            color: @Neutral300;
          }
        }
      }
    }
  }

  &-week{

    &_glob_wrap{
      //border-top: 1px solid @Neutral100;
      padding: 16px;
      transition: background-color 0.3s ease;

      &:hover{
        background-color: @blue100;
      }
    }

    &_wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_header{
        font-size: 16px;
        line-height: 24px;
        color: @Neutral800;
      }

      &_footer{
        font-size: 12px;
        line-height: 16px;
        color: @Neutral300;
      }
    }

    &_text{
      color: @Neutral500;
    }

    &_status{
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      color: @blue500;
      margin: 8px 0;
      &.Ready{
        color: @green500;
      }
      &.Progress{
        color: @purple500;
      }
      &.Pending{
        color: @yellow500;
      }
    }
  }
}
