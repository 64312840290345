@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.suppliers-header {
  justify-content: flex-end;
  padding: 24px;

  @media @viewport-mobile {
    padding: 0 16px 16px;
  }

  .title {
    margin-right: auto;
  }
}

.suppliers-card {
  display: flex;
  align-items: center;
  padding: 0 24px;

  @media @viewport-mobile {
    padding: 0 16px;
    min-width: 1050px;
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &:last-of-type {
    margin-bottom: 16px;
  }

  &-value {
    width: 10%;
    margin-left: auto;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    &.small {
      width: 7%;
    }
  }

  &-feedback {
    width: 20%;
    margin-left: auto;

    &>span {
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      .feedback {
        color: @greenPrimary;

        &.negative {
          color: @orangePrimary;
        }
      }
    }
  }

  &-rating {
    width: 20%;
    margin-left: auto;

    & > span {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      .stars-wrapper {
        display: flex;
        align-items: center;
        gap: 2px;

        .star {
          position: relative;

          .block {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            z-index: 2;
            .with-theme({
              background: @vars[white-to-neutral-dark];
            })
          }
        }
      }
    }
  }

  &-info-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45%;
    margin-left: 0;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .icon-wrap.skeleton {
      min-width: 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 12px;
      @media only screen and (max-width: 1200px) {
        min-width: 36px;
        width: 36px;
        height: 36px;
      }
      @media @viewport-mobile {
        min-width: 24px;
        width: 24px;
        height: 24px;
      }
    }

    .suppliers-card-link {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: @bluePrimary;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .3s ease;
      white-space: nowrap;

      &:hover {
        color: @blueDepressed;
      }

      svg {
        margin-right: 0;
        margin-left: 4px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
