@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';


.ad-library-modal-table{
  .adLibrary-modal-table{
    padding: 0 24px;
    @media @viewport-mobile {
      padding: 0 16px;
    }
    .ant-table-header{
      .with-theme({
        background: unset !important
      });
    }
    .ant-table-thead {
      tr{
        td{
          display: none;
        }
      }
      > tr > th {
        padding: 0 !important;
        .with-theme({
          border-top: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
          color: @vars[neutral-600-to-neutral-dark-500];
        });
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-of-type {
          padding-left: 40px !important;
        }
        .with-theme({
          background: unset !important
        });
      }
    }
    .ant-table-tbody{
      tr.ant-table-row > td{
        .with-theme({
          background: unset !important
        });
      }
      .ant-table-row > td:first-of-type {
        padding-left: 0 !important;
      }
      .ant-table-row > td:last-of-type {
        padding-left: 40px !important;
      }
      .adLibrary-table-row{
        font-weight: 500;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: -0.3px;
        height: 48px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

    }
  }
}