@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.favorite {
  cursor: pointer;

  &.disabled{
    cursor: not-allowed;
    > svg > path{
      .with-theme({
        stroke: @vars[neutral-200-to-neutral-dark-200];
      });
    }
  }
}
