@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.invoice {
  padding: 40px;
  min-height: 600px;
  height: 90dvh;
  position: relative;

  @media @viewport-mobile {
    height: 100%;
  }

  &-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;


    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -1.2px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }

      &-subtitle {
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }
      }
    }
  }

  &-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    border-radius: 6px;
    margin-top: 24px;
    .with-theme({
      background: @vars[green-100-to-900];
    });

    &.due {
      .with-theme({
        background: @vars[red-100-to-900];
      });

      &-text {
        color: @redPrimary;
      }

      &-date {
        color: @redPrimary;
      }
    }

    &-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.3px;
      color: @greenPrimary;
    }

    &-date {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3px;
      color: @greenPrimary;
    }
  }

  &-billing-info {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;

    &-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 16px);

      &-title {
        display: block;
        margin-bottom: 8px;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        });
      }

      &-value {
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
        margin-bottom: 4px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }
    }
  }

  &-billing-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .divider {
      border-top: 1px solid @greenPrimary;

      &-due {
        border-top: 1px solid @redPrimary;
      }
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.row-value {
        margin-bottom: 4px;

        .invoice-billing-details-row-value {
          display: flex;
          align-items: center;
          width: 33%;

          &:nth-of-type(2) {
            justify-content: center;
          }

          &:last-of-type {
            justify-content: flex-end;
          }
        }
      }

      &-title {
        display: flex;
        align-items: center;
        width: 33%;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 160% */
        letter-spacing: 0.4px;
        text-transform: uppercase;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        });

        &:nth-of-type(2) {
          justify-content: center;
        }

        &:last-of-type {
          justify-content: flex-end;
        }
      }

      &-value {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        &.total-value {
          color: @greenPrimary !important;
          font-weight: 600;

          &-due {
            color: @redPrimary !important;
          }
        }
      }
    }
  }

  &-footer {
    padding: 16px 24px;
    border-radius: 0 0 20px 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .with-theme({
      background: @vars[green-100-to-900];
    });

    &.due {
      .with-theme({
        background: @vars[red-100-to-900];
      });

      &-text {

        a {
          color: @redPrimary;

          &:hover {
            color: @redDepressed;
          }
        }
      }
    }

    &-text {
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 160% */
      letter-spacing: -0.2px;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      });

      a {
        text-decoration: none;
        color: @greenPrimary;
        transition: all .3s ease;

        &:hover {
          color: @greenDepressed;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 8px;

      .invoice-pdf-btn svg path {
        .with-theme({
          stroke: @vars[neutral-500-to-neutral-dark-500];
        })
      }
    }
  }
}
