@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.credits-limited-banner {
  border-radius: 0;
  .with-theme({
    border: 1px solid @vars[neutral-200-to-700] !important;
    background: @vars[neutral-100-to-800] !important;
  });

  .text {
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
      margin-bottom: 3px;
    }

    .subscription-tooltip {
      width: 196px;
      left: 235px !important;
      top: 166px !important;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      @media @viewport-mobile {
        left: 205px !important;
        top: 66px !important;
      }
    }
  }

  .ant-btn.btn-primary.white {
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800] !important;
      background: @vars[color-white-to-dark] !important;
    })
  }
}

.limited-banner {
  color: @yellowPrimary;
  padding: 11px 15px;
  box-sizing: border-box;
  border: 1px solid @yellowPrimary;
  border-radius: @border-radius;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  .with-theme ({
    background: @vars[yellow-100-to-900];
  });

  @media @viewport-mobile {
    flex-wrap: wrap;
  }

  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0 8px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  }

    .trial-text {
      margin-left: auto;

      @media @viewport-mobile {
        margin: 5px 0;
        padding: 0;
      }
    }

  .ant-btn.btn-primary.white {
    margin-left: auto;
    height: 32px;
    padding: 0 15px;

    .with-theme ({
      border-color: @vars[neutral-100-to-800];
      background: @vars[color-white-to-dark];
      color: @vars[neutral-800-to-white];
    });

    &:hover {
      border-color: @Neutral500 !important;
    }

    &:focus {
      .with-theme ({
        box-shadow: 0 0 0 4px @vars[neutral-100-to-800];
      });
    }

    @media @viewport-mobile {
      width: 100%;
    }
  }

    .trial-banner-button {
      margin-left: unset !important;

      @media @viewport-mobile {
        width: 100%;
      }
    }

  &_error{
    border: 1px solid @redPrimary;
    .with-theme ({
      background: @vars[red-100-to-900];
    });

    svg{
      fill: @redPrimary;
    }
  }

  &.skeleton{
    border: none;
    height: 54px;
    .skeleton-animation-bg();
  }
}

.note-banner {
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: @border-radius;
  margin-top: 38px;

  font-family: 'Inter', sans-serif;

  .with-theme ({
    background: @vars[neutral-100-to-700];
    color: @vars[neutral-900-to-200];
    border-color: @vars[neutral-200-to-800];
  });

  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;

    .with-theme({
      color: @vars[color-dark-to-white];
    });

    svg {
      margin-right: 8px;
    }
  }

  .description{
    padding-left: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &.skeleton{
    border: none;
    height: 84px;
    .skeleton-animation-bg();
  }
}