@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.sales-tracker-note {
  padding: 12px 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  .with-theme({
    border-bottom: 1px solid@vars[neutral-200-to-neutral-dark-200];
    background: @vars[yellow-100-to-900];
  });

  @media @viewport-mobile {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }
}
