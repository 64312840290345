@import '../../../../styles/base/ui-kit';

.method-add-product-card{
  min-height: 208px;
  border-radius: @border-radius;
  border: 1px solid @Neutral100;
  background-color: transparent;
  margin: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  .icon{
    margin-bottom: 16px;
  }

  &_title{
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: @Neutral800;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;

    .coming-soon{
      display: inline-block;
      margin-left: 8px;
      padding: 4px 8px;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      border-radius: @border-radius;
      background-color: @blue100;
      color: @blue500;
    }
  }

  &_text{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: @Neutral500;
    transition: color 0.3s ease;
    margin-bottom: 0;
  }

  &_textarea.ant-input{
    padding: 15px;
    font-size: 12px;
    line-height: 16px;

    &:placeholder-shown {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &:not(.disabled):hover{
    border-color: @blue500;
    background-color: @blue100;
    > p {color: @blue500;}
  }

  &.disabled{
    cursor: not-allowed;

    > .icon{
      fill: @Neutral200;
    }
    > p {
      color: @Neutral200;
    }
  }
}
