@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.experts-wrapper {
  width: 520px;

  @media @viewport-mobile {
    width: 100%;
  }

  h3 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: @dark;
    text-align: left;

    @media @viewport-mobile {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }

  .experts-image-wrapper {
    margin-top: 32px;
    border-radius: 16px;
    box-shadow: 0 8px 40px 0 rgba(112, 123, 160, 0.10);
    background-repeat: no-repeat;
    background-size: contain;
    width: 512px;
    height: 391px;
    background-image: url("../../../Images/ClickFunnelsMain.png");

    @media @viewport-mobile {
      margin-top: 32px;
      width: 100%;
      height: 263px;
      background-size: cover;
    }
  }

  & > p {
    margin-top: 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: @Neutral500;

    @media @viewport-mobile {
      margin-top: 24px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .experts-btn {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 12px;
    background: @bluePrimary;
    transition: all .3s ease;
    color: @white;
    margin-top: 24px;

    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      opacity: .75;
    }

    p {
      margin-top: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    &:hover {
      background: @blueDepressed;
    }
  }
}
