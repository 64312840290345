@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.more-products-block {
  padding-top: 16px;

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media @viewport-mobile {
      margin-bottom: 16px;
    }

    &-title {
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.7px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      @media @viewport-mobile {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.7px;
      }
    }

    .btn-wrapper {
      .nav-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;

        &.prev {
          margin-right: 8px;
        }

        &.disabled {
          cursor: not-allowed;
          .with-theme({
            path {
              stroke: @vars[neutral-300-to-neutral-dark-300];
            }
          });
        }
      }
    }
  }

  &-body{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 0 5px 5px;
    gap: 16px;
    scroll-snap-type: x mandatory;
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 4px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @bluePrimary;
      });
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-left: 10px;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-right: 10px;
    }

    @media @viewport-mobile {
      padding: 5px;
    }

    .product {
      display: block;
      scroll-snap-align: center;
      width: 260px;
      min-width: 260px;
      height: 500px;
      border-radius: 16px;
      overflow: hidden;
      .with-theme({
        box-shadow: @vars[box-shadow-btn-secondary];
      });

      @media @viewport-mobile {
        width: 100%;
        min-width: 100%;
        min-height: 500px;
        height: unset;
      }

      &_image {
        display: block;
        width: 260px;
        height: 260px;

        @media @viewport-mobile {
          width: 100%;
          height: calc(100vw - 36px);
        }

        img {
          width: fit-content;
          max-width: 100%;
          height: auto;
          max-height: 100%;
          object-fit: contain;
          border-radius: 10px 10px 0 0;
        }
      }

      &_wrap {
        .flex-row-space-between();
        align-items: center;
        padding: 12px;
      }

      &_name {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        transition: none;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });
      }

      &_status{
        width: 12px;
        min-width: 12px;
        height: 12px;
        margin-left: 8px;
        border-radius: 50%;

        &.Progress{
          background-color: @purple500;
        }

        &.Ready{
          background-color: @green500;
        }
      }

      &_details {
        padding: 0 12px 12px;
        display: flex;
        flex-flow: column nowrap;
        gap: 8px;
      }

      &-detail {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_price {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.5px;
          padding: 0 12px 12px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          @media @viewport-mobile {
            font-size: 16px;
            line-height: 20px;
            padding: 0 12px 8px;
          }
        }

        &_title {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        &_value {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }
      }

      &_view-details {
        height: 32px;
        padding: 6px 8px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        .with-theme({
          color: @vars[neutral-800-to-100];
          background: @vars[white-to-neutral-dark-50];
          box-shadow: @vars[box-shadow-btn-secondary];

          &:hover, &:focus {
            background: @vars[neutral-50-to-neutral-dark-100];
          }
        });

        &.wide {
          flex: 1;
        }
      }

      &-btn-wrapper {
        padding: 0 12px;
        display: flex;
        align-items: center;
        gap: 8px;

        @media @viewport-mobile {
          padding: 0 12px 12px;
        }
      }
    }
  }
}
