@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.store-tracker-page-tabs {
  padding: 16px 32px;
  display: flex;
  width: 100%;
  align-items: center;
  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    padding: 16px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .store-tracker-page-tab {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 0 12px;
    cursor: pointer;
    transition: all .3s ease;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    user-select: none;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    &:not(.active):not(.disabled):hover {
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    &.active {
      cursor: default;
      position: relative;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &:after {
        position: absolute;
        content: '';
        width: 32px;
        height: 2px;
        background: @bluePrimary;
        bottom: -16px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.disabled {
      .with-theme({
        color: @vars[neutral-400-to-neutral-dark-400];
      });
      position: relative;
      cursor: not-allowed;

      .disabled-calendar_tooltip {
        max-width: 400px;
        top: -35px !important;
      }
    }

    .count {
      .with-theme({
        color: @vars[neutral-400-to-neutral-dark-400];
      });
    }
  }
}
