@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.competitor-research-empty-block {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;

  @media @viewport-mobile {
    height: 200px;
  }

  .link.skeleton {
    display: block;
  }

  &.active {
    height: calc(100dvh - 93px - 91px - 24px - 18px);

    @media @viewport-mobile {
      height: calc(100dvh - 69px - 127px);
    }
  }

  &-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
  }

  &-text {
    max-width: 450px;
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    @media @viewport-mobile {
      width: 100%;
      padding: 0 16px;
    }
  }

  &-btn {
    margin: 24px auto 0;
    height: 36px;
    padding: 6px 12px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    .with-theme({
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus {
        background: @vars[neutral-50-to-neutral-dark-100];
      }
    });

    @media @viewport-mobile {
      width: calc(100% - 32px);
    }
  }
}
