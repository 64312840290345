@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.account-subpage {
  width: 100%;

  &-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 45px;
    width: 100%;

    @media @viewport-mobile {
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      })
    }

    &-name {
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
      margin-bottom: 24px;

      @media @viewport-mobile {
        flex-direction: column;
        gap: 12px;
      }

      .input {
        height: 40px;
        padding: 6px 12px;
        border-radius: 10px;
        border: none !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          background: @vars[white-to-neutral-dark] !important;
          border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
          color: @vars[neutral-800-to-100] !important;
          background: @vars[white-to-neutral-dark] !important;

          &:hover, &:focus {
            border: 1px solid @bluePrimary !important;
          }
        });

        &-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          width: calc(100% - 12px);

          @media @viewport-mobile {
            width: 100%;
          }
        }

        &-label {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }
    }

    &-email {
      display: flex;
      align-items: flex-end;
      gap: 16px;
      width: 100%;

      @media @viewport-mobile {
        flex-direction: column;
        gap: 12px;
      }

      .input {
        height: 40px;
        padding: 6px 12px;
        border-radius: 10px;
        border: none !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          background: @vars[white-to-neutral-dark] !important;
          border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
          color: @vars[neutral-800-to-100] !important;
          background: @vars[white-to-neutral-dark] !important;

          &:not(:has( > input[disabled])):hover, &:not( > :has(input[disabled])):focus, &:not([disabled]):hover, &:not([disabled]):focus {
            border: 1px solid @bluePrimary !important;
          }

          &:has(input[disabled]), &[disabled] {
            color: @vars[neutral-300-to-neutral-dark-300] !important;
            background: @vars[neutral-100-to-neutral-dark-100] !important;
            border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
          }
        });

        &-tag {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.3px;
          height: 24px;
          padding: 4px 6px;
          border-radius: 6px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
            color: @vars[neutral-400-to-neutral-dark-400];
          })
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          width: calc(50% - 12px);

          @media @viewport-mobile {
            width: 100%;
          }
        }

        &-label {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }
      }

      .btn-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        @media @viewport-mobile {
          flex-direction: column;
          width: 100%;
        }

        span {
          user-select: none;
          display: flex;
          align-items: center;
          height: 40px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          padding: 0 12px;
          transition: all .3s ease;
          cursor: pointer;
          color: @bluePrimary;

          &:hover {
            color: @blueDepressed;
          }
        }

        .verify.disabled {
          cursor: not-allowed;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          })
        }
      }
    }

    &-preferences {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: 100%;

      .preference {

        &-wrapper {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media @viewport-mobile {
            flex-direction: column;
            gap: 12px;
            align-items: flex-start;
          }

          .ant-dropdown {
            top: 48px !important;
            right: 0 !important;
            left: unset !important;
          }

          .language-dropdown {
            max-height: 160px;
            overflow-y: auto;
            display: flex;
            width: 163px;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            border-radius: 12px;
            .with-theme({
              background: @vars[white-to-neutral-dark-50];
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
              box-shadow: @vars[box-shadow-object-large];
            });

            &::-webkit-scrollbar {
              width: 4px;
              border-radius: 2px;
            }
            &::-webkit-scrollbar-button {
              display: none;
            }
            &::-webkit-scrollbar-track-piece {
              background: transparent;
              border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              .with-theme({
                background: @vars[neutral-200-to-neutral-dark-200];
              });
            }
            &::-webkit-scrollbar-track-piece:start {
              background: transparent;
              margin-top: 10px;
            }
            &::-webkit-scrollbar-track-piece:end {
              background: transparent;
              margin-bottom: 10px;
            }

            &-inner {
              cursor: pointer;
              transition: all .3s ease;
              display: flex;
              width: 120px;
              height: 40px;
              padding: 6px 12px;
              justify-content: space-between;
              align-items: center;
              border-radius: 8px;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.3px;

              @media @viewport-mobile {
                width: 100%;
              }

              svg {
                transition: all .3s ease;
              }

              .with-theme({
                border: 1px solid @vars[neutral-200-to-neutral-dark-200];
                background: @vars[white-to-neutral-dark];
                color: @vars[neutral-800-to-100];

                svg path {
                  stroke: @vars[neutral-400-to-neutral-dark-400];
                }

                &.ant-dropdown-open, &:hover {
                  border: 1px solid @bluePrimary;
                }
              });

              &.ant-dropdown-open {
                & > svg {
                  transform: rotate(180deg);
                }
              }
            }

            &-option {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 6px 8px;
              margin: 0 auto;
              width: calc(100% - 8px);
              transition: all .3s ease;
              cursor: pointer;
              border-radius: 8px;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: -0.3px;
              .with-theme({
                color: @vars[neutral-800-to-100];
              });

              &:hover {
                .with-theme({
                  background: @vars[blue-100-to-neutral-dark-100];
                })
              }
            }
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        }

        &-title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }

        &-subtitle {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }
      }
    }

    &-accounts {
      width: 100%;

      .account {

        &-title {
          display: flex;
          align-items: center;
          gap:8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }

        &-wrapper {
          padding-right: 8px;
          height: 48px;
          display: flex;
          align-items: center;
          gap: 12px;
          width: 100%;

          &:not(:last-of-type) {
            .with-theme({
              border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
            })
          }
        }

        &-domain {
          display: block;
          max-width: calc(100% - 160px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          })
        }

        &-action {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          cursor: pointer;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          transition: all .3s ease;
          color: @bluePrimary;

          &:hover {
            color: @blueDepressed;
          }

          &.connected {
            color: @redPrimary;

            &:hover {
              color: @redDepressed;
            }
          }
        }
      }
    }
  }

  &-forgot-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    .forgot-password-subtitle {
      font-size: 14px;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      });

      .email-text {
        color: @bluePrimary;
      }

      .resend-link {
        color: @bluePrimary;
        &:hover {
          color: @blueDepressed;
          cursor: pointer;
        }
      }

      &.dont-see-confirm {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .check-email {
        display: flex;
        justify-content: space-between;

        .resend-timer {
          display: flex;
          gap: 8px;

          .ant-statistic-content {
            color: @bluePrimary;

            .ant-statistic-content-prefix {
              margin-right: 4px;
            }
          }
        }
      }
    }
    
    img {
      display: block;
      max-width: 337px;
      max-height: 280px;
      margin: auto;
    }
  }
}
