@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.skip-promo-wrapper {
  padding: 16px;

  .skip-promo-title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: @Neutral800;
  }

  .skip-promo-subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: @Neutral500;
    margin-top: 12px;
  }

  .skip-promo-divider {

  }

  .skip-promo-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .skip-promo-button-cancel {
    padding: 16px 24px;
    cursor: pointer;
    border-radius: @border-radius;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border: 1px solid @Neutral100;
    color: @Neutral800;
    background: @white;
    transition: all .3s ease;

    @media @viewport-mobile {
      width: 100%;
    }

    &:hover {
      border: 1px solid @bluePrimary;
      color: @bluePrimary;
    }
  }

  .skip-promo-button-apply {
    border: 1px solid @redPrimary;
    color: @white;
    background: @redPrimary;

    &:hover {
      border: 1px solid @redDepressed;
      color: @white;
      background: @redDepressed;
    }
  }
}
