@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.interest-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  .with-theme({
    color: @vars[neutral-800-to-200];
  });

  &-show-interest-btn {
    margin: 10px auto 0;
    height: 36px;
    width: calc(100% - 32px);
    padding: 6px 12px;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    cursor: pointer;
    transition: all .3s ease;
    border: none !important;
    .with-theme({
      color: @vars[neutral-800-to-100];
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus, &:active {
        background: @vars[neutral-50-to-neutral-dark-100] !important;
        color: @vars[neutral-800-to-100] !important;
      }
    });
  }

  &-header {
    padding: 24px 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @viewport-mobile {
      padding: 16px 16px 0;
    }

    .link {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: @bluePrimary;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        color: @blueDepressed;
      }
    }
  }

  &.header-line{
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[neutral-50-to-neutral-dark-50];
    });

    @media @viewport-mobile {
      height: 36px;
      padding: 0 16px;
    }

    .header-line-sort-item {
      svg {
        margin-left: 4px;
      }
    }
  }

  &-wrap{
    max-height: 180px;
    padding: 16px 24px;

    @media @viewport-mobile {
      padding: 16px;
    }
  }

  &-value-wrap {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-value {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    &-clickable {
      cursor: pointer;
    }
  }
}
