@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';
@import "../../../../../styles/utils/variables";

.drops-timer-block{
  margin-bottom: 8px;

  .drops-timer-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > .drops-timer-box {
      width: 32px;
      margin-right: 4px;

      > .ant-statistic {
        border: 1px solid @Neutral100;
        background-color: #ffffff;
        border-radius: @border-radius;

        .ant-statistic-content {
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          color: @blue500;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
        }
      }
    }

    &.disabled{
      > .drops-timer-box {
        > .ant-statistic {
          .ant-statistic-content {
            color: @Neutral300;
          }
        }
      }
    }
  }
}
