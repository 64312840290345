@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/animation';
@import '../../../../../styles/base/ui-kit';

.collections-page-list {
  height: calc(100dvh - 89px - 93px);
  overflow-y: auto;

  @media @viewport-mobile {
    height: calc(100dvh - 89px - 107px);
  }

  .ant-spin-nested-loading {
    overflow: visible !important;
  }

  &-wrapper {
    padding: 24px 32px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 16px;

    @media @viewport-mobile {
      padding: 16px;
      gap: 16px;
    }
  }
}
