@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';

.dropdown-filter-tag {
  border: none;
  border-radius: @border-radius;
  padding: 4px 8px;
  gap: 2px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: @bluePrimary;
  display: flex;
  align-items: center;
  .with-theme({
    background: @vars[blue-100-to-neutral-dark-200];
    color:@vars[blue-to-neutral-dark-700];
  })
}

.tag-title{
  .with-theme({
    color:@vars[blue-to-neutral-dark-400];
  })
}

.tag-value{
  .with-theme({
    color:@vars[blue-depressed-to-neutral-dark-700];
  })
}
