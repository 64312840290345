@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.cancel-sub {

  &-wrapper {
    display: flex;
    flex-direction: column;

    .discount-wrap {
      width: 100%;
      padding: 0 16px;

      & > button {
        margin-right: 0;
      }
    }
  }

  &-image {
    margin: 24px auto;
    width: 256px;
    height: 256px;
    background: url("../../Images/CancelSubLight.png") no-repeat center center;
    background-size: cover;

    &--dark {
      background: url("../../Images/CancelSubDark.png") no-repeat center center;
      background-size: cover;
    }
  }

  &-plan-info {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    border-radius: 12px;
    margin: 0 16px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &-title {
      text-align: left;
      text-transform: capitalize;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      })
    }

    &-stats {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    &-key {
      margin-bottom: 4px;
      width: 60px;
      text-transform: capitalize;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      })
    }

    &-value {
      width: 60px;
      text-transform: capitalize;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      })
    }
  }

  &-title {
    padding: 16px 16px 0;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-text {
    padding: 8px 16px 16px;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      })
    }
  }

  &-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-button {
    width: 224px;
    height: 48px;
    .with-theme({
      background: @vars[color-white-to-dark];
      border: 1px solid @vars[neutral-100-to-800];
      color: @vars[color-dark-to-white];
    });

    &:hover {
      color: @bluePrimary;
      border-color: @bluePrimary;
    }

    &--red {
      border: none !important;
      color: @white !important;
      background: @redPrimary !important;

      &:hover {
        background: @redDepressed !important;
        color: @white;
      }
    }
  }
}
