@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.store-tracker-product-card {
  width: calc((100% - 48px) / 4);
  max-width: 264px;
  border-radius: 16px;
  .with-theme({
    background: @vars[white-to-neutral-dark-50];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    width: 100%;
    min-width: 100%;
    max-width: unset;
    scroll-snap-align: start;
  }

  .details-tooltip.product_name-tooltip {
    max-width: calc(100% - 20px) !important;
    left: 10px !important;
    top: 5px !important;
  }

  &-image {
    position: relative;
    border-radius: 16px 16px 0 0;
    width: 100%;
    aspect-ratio: 1;

    &-count {
      position: absolute;
      left: 50%;
      bottom: 14px;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 4px 6px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.50);
      backdrop-filter: blur(2px);

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: @white;
      }
    }

    .rank {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      position: absolute;
      left: 8px;
      top: 8px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.50);
      backdrop-filter: blur(8px);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: @white;
      min-width: 32px;
      height: 32px;
    }

    & > img {
      width: 100%;
      aspect-ratio: 1 / 1.02;
      border-radius: 16px 16px 0 0;
      object-fit: cover;
      object-position: center;
    }
  }

  &-title {
    padding: 16px 12px;
    width: 100%;
    text-align: left;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    position: relative;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-price {
    padding: 0 12px;
    margin: 0 0 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    .main {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: @bluePrimary;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        color: @blueDepressed;
      }
    }

    .original {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      })
    }
  }

  &-stat {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    margin: 0 0 12px;

    .title {
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }

    .value {
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }
  }

  &-btn {
    position: relative;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 4px);
    height: 32px;
    cursor: pointer;
    transition: all .3s ease;
    border: none !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark-50];
      box-shadow: @vars[box-shadow-btn-secondary];

      &:hover, &:focus {
        background: @vars[neutral-50-to-neutral-dark-100];
      }
    });

    @media @viewport-mobile {
      width: 100%;
      height: 40px;
    }

    &--track {
      height: 32px !important;
      .with-theme({
        background: @bluePrimary !important;
        box-shadow: @vars[box-shadow-btn-primary] !important;
        color: @white !important;

        &:hover, &:focus {
          background: @blueDepressed !important;
        }
      });

      &.tracked {
        .with-theme({
          background: @redPrimary !important;
          box-shadow: none !important;
          color: @white !important;

          &:hover, &:focus {
            background: @redDepressed !important;
          }
        });
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 0 12px 12px;
    }
  }
}
