@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.report-main-block-new {
  padding: 22px 32px;

  @media @viewport-mobile {
    padding: 16px;
  }

  &.fadspot {

  .with-theme({
    border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
    @media @viewport-mobile {
      border: none;
    }
  });
    .main-title-stat {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      font-family: 'Inter', sans-serif;

      h3 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: @Neutral500;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        .with-theme({
          color: @vars[neutral-800-to-200];
        })
      }
    }
    .report-main-block_title {
      font-size: 18px;
      font-weight: 500;
      margin-right: 8px;
      svg {
        margin-left: 6px;
      }
    }
    .report-main-block-new-button {
      transition: all .3s ease;
      &.track {
        width: 168px !important;
        background: @bluePrimary !important;
        color: @white !important;
        margin-left: 4px;
        border: none !important;

        &:hover {
          background: @blueDepressed !important;
        }
      }
    }
  }

  flex-direction: column;

  &-button-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;

    @media @viewport-mobile {
      width: 100%;
    }

    .product-actions-options {
      transition: all .3s ease;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: @border-radius;
      svg path {
        fill: @Neutral500;
      };
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
        background: @vars[color-white-to-dark];
      });

      &:hover, &.ant-dropdown-open {
        border: 1px solid @Neutral800;
        .with-theme({
          box-shadow: 0 0 0 4px @vars[neutral-100-to-800];
        })
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;

    @media @viewport-mobile {
      width: 100%;
    }

    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      color: @vars[neutral-800-to-100];
      box-shadow: @vars[box-shadow-btn-secondary-without-border];
      background-color: @vars[white-to-neutral-dark-50];
    });

    svg {
      margin-right: 8px;
    }

    &:hover, &:focus {
      .with-theme({
        box-shadow: @vars[box-shadow-btn-secondary-without-border];
      });
      border: 1px solid @bluePrimary;
      color: @bluePrimary;
    }
  }

  .main-info {

    @media @viewport-mobile {
      width: 100%;
      margin-bottom: 16px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media @viewport-mobile {
        flex-direction: column;
      }

      .main-block-logo {
        width: 36px;
        height: 36px;
        background-color: rgba(255, 255, 255, 0.08);
        border-radius: 8px;
        margin-right: 12px;

        img {
          border-radius: 8px;
        }
      }

      .main-title {
        display: flex;
        align-items: center;
        gap: 8px;

        &.adSpot{
          align-items: flex-start;
          flex-direction: column;
          gap: unset;
          @media @viewport-mobile {
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
    
        }

        @media @viewport-mobile {
          align-items: center;
        }

      }

      @media @viewport-mobile {
        justify-content: flex-start;
      }
    }

    display: flex;
    align-items: center;

    .main-link {
      .report-main-block_link {
        text-decoration: underline;
        margin-right: 5px;
        font-weight: 400;
      }

      svg {
        .with-theme ({
          fill: @vars[neutral-300-to-700];
        });
      }
    }

  }

  .main-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media @viewport-mobile {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .stat-blocks-wrapper {
      max-width: calc(100% - 387px);
      min-width: calc(100% - 320px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media @viewport-mobile {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        min-width: unset;
        max-width: unset;

        .main-block-stat-wrapper {
          margin-bottom: 12px;

          .main-block-stat-title {
            margin-bottom: 0;
            line-height: 16px;
          }
        }
      }

      .main-block-stat-value {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 130px;
        overflow: hidden;

        @media @viewport-mobile {
          max-width: 290px;
        }

        &.overflowing {
          &:before {
            position: absolute;
            left: 0;
            top: -35px;
            width: max-content;
            max-width: 185px;
            white-space: break-spaces;
            border-radius: 6px;
            padding: 16px;
            font-size: 12px;
            line-height: 16px;
            background: @Neutral800;
            color: @white;
            text-align: left;
            content: attr(data-text);
            display: none;
          }

          &:hover:before {
            display: block;
          }
        }
      }
    }

    .channels-wrapper {
      min-width: 210px;
      max-width: 277px;

      .main-block-stat-value {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
