@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.clickfunnels-page {
  padding: 0  0 20px !important;
  font-family: "Inter", sans-serif;
  width: 100%;

  &-main-wrapper {
    display: flex;
    border-bottom: 1px solid @Neutral100;

    @media @viewport-mobile {
      flex-direction: column;
    }

    &-left {
      padding-bottom: 44px;
      padding-top: 48px;
      padding-right: 46px;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background: #FCFCFC;
      border-right: 1px solid @Neutral100;

      @media @viewport-mobile {
        width: 100%;
        padding: 32px 15px 24px;
        border-right: none;
      }
    }

    &-right {
      padding-bottom: 44px;
      padding-top: 48px;
      padding-left: 46px;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media @viewport-mobile {
        width: 100%;
        padding: 25px 15px 24px;
      }
    }
  }
}
