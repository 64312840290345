@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.ant-btn {
  &.btn {
    &--notification {
      .border();
      border-radius: @border-radius;
      width: 150px;
      height: 48px;

      font-size: 14px;
      line-height: 16px;
      color: @Neutral500;

      &:hover {
        color: @Neutral500;
        border-color: @bluePrimary;
      }

      .with-theme({
        background-color: @vars[color-white-to-dark];
      });

      @media @viewport-mobile {
        width: fit-content;
        align-self: end;
        height: 32px;
        font-size: 12px;
      }
    }
  }
}

.ant-btn.database-page-button.btn.disabled-transition {
  transition: none !important;

  & > svg {
    transition: none !important;
  }
}

.getting-started-button {
  position: fixed;
  bottom: 24px;
  right: 88px;
  background: #225AEA;
  border-radius: 50px;
  width: 196px;
  height: 48px;
  color: @white;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s ease;
  z-index: 1000;

  &:hover {
    background: @blueDepressed;
  }

  svg {
    margin-right: 10px;
  }

  .close-forever {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: @blueDeep;
    position: relative;
    margin-left: 16px;

    &:before, &:after {
      position: absolute;
      width: 8px;
      height: 1px;
      background: @white;
      content: '';
      top: 11.5px;
      left: 8px;
      border-radius: @border-radius;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
