@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.sales-tracker-top-page {
  overflow: unset !important;
  overflow-x: visible !important;
  max-height: unset !important;
  height: unset;

  .fadspot-page-quick-search-wrapper {
    margin: 0;
    width: 100%;
  }

  @media @viewport-mobile {
    overflow-y: scroll !important;
  }

  &-watch-tutorial {
    width: 169px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    transition: all .3s ease;
    gap: 10px;

    @media @viewport-mobile {
      width: 155px !important;
      height: 34px !important;
    }
  
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark-50];
    });

    &:hover {
      border: 1px solid @bluePrimary;

      span {
        color: @bluePrimary;
      }
    }

    &.tour {
      svg path {
        .with-theme({
          stroke: transparent;
          fill: @vars[neutral-600-to-neutral-dark-500];
        })
      }
    }

    span {
      display: flex;
      align-items: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      transition: all .3s ease;
      .with-theme({
        color: @vars[color-dark-to-white];
      })
    }

    .watch-tutorial-icon {
      svg path {
        transition: all .3s ease;
        .with-theme({
          fill: transparent;
          stroke: @vars[color-dark-to-white];
        })
      }
    }

    svg path {
      transition: all .3s ease;
      .with-theme({
        stroke: @vars[neutral-600-to-neutral-dark-500];
      })
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 24px 32px;
    margin-bottom: 0 !important;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &.topStores {
      @media @viewport-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .ant-tooltip-inner{
          width: 280px !important;
        }
        .sales-tracker-top-page-watch-tutorial {
          margin-right: 0px !important;
        }
      }

      .sales-tracker-top-page-watch-tutorial {
        margin-right: 12px;
      }
    }


    @media @viewport-mobile {
      padding: 0 !important;
      .title-wrapper-icon {
        display: none !important;
      }

      .sales-tracker-top-page-subheader {
        gap: 4px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 16px;

        .sales-tracker-top-page-title{
          padding:12px 16px;
          display: flex;
          align-items: center;
          gap: 4px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })

        }

        .sales-tracker-top-page-subtitle {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.3px;
        }
      }
    }

    .sales-tracker-top-page-credits {
      margin-left: auto;
      margin-right: 16px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      height: 36px;

      &-title {
        border-radius: 8px 0 0 8px;
        height: 100%;
        display: flex;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
          border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[neutral-50-to-neutral-dark-50];
        });
      }

      &-value {
        border-radius: 0 8px 8px 0;
        height: 100%;
        display: flex;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
          background: @vars[white-to-neutral-dark];
        });

        &.right-border {
          border-radius: 0;
          .with-theme({
            border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });
        }

        .current {
          color: @greenPrimary;
        }

        .total-green {
          color: @greenPrimary;
        }

        .total-orange {
          color: @orangePrimary;
        }

        .total-red {
          color: @redPrimary;
        }

        .separator {
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          })
        }

        .maximum {
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        .type {
          margin-left: 3px;
          .with-theme({
            color: @vars[neutral-500-to-neutral-dark-500];
          })
        }
      }
    }

    &-btn {
      cursor: pointer;
      position: relative;
      width: 40px !important;
      height: 40px !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      background: transparent !important;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      @media @viewport-mobile {
        width: 100%;
      }

      &-icon {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgba(158, 20, 20, 0.50);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #D71313;
        box-shadow: 0px 20px 20px -10px rgba(112, 6, 6, 0.04), 0px 10px 10px -5px rgba(112, 6, 6, 0.04), 0px 5px 5px -2.5px rgba(112, 6, 6, 0.04), 0px 2.5px 2.5px -1.25px rgba(112, 6, 6, 0.04), 0px 0.833px 0.833px -0.417px rgba(112, 6, 6, 0.04), 0px 0px 0px 0.833px rgba(112, 6, 6, 0.04);
      }
    }

    .ant-dropdown {
      position: absolute;
      left: unset !important;
      top: 60px !important;
      right: 35px !important;
    }

    &-dropdown {
      display: flex;
      width: 185px;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 12px;

      &.banner-credits-dropdown {
        margin-top: 10px;
      }

      .with-theme({
        background: @vars[white-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        box-shadow: @vars[box-shadow-object-large];
      });

      &-option {
        display: flex;
        align-items: center;
        padding: 6px 8px;
        margin: 0 auto;
        width: calc(100% - 8px);
        transition: all .3s ease;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          .with-theme({
            background: @vars[blue-100-to-neutral-dark-100];
          })
        }

        &.tour svg path {
          .with-theme({
            fill: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        &.tutorial svg path {
          .with-theme({
            stroke: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        span {
          margin-left: 8px;
          font-weight: 500;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

        }
      }
    }
  }

  &-title {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }

  &-limits {

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    padding: 12px 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 12px;
    color: @Neutral500;
    border-radius: @border-radius;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      background: @vars[color-white-to-dark];
    });

    .ant-divider {
      .with-theme({ background-color: @vars[neutral-100-to-800] });
    }
  }

  &-subheader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  &-subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: @Neutral500;
    margin-bottom: 0;
  }

  .period-filter-wrapper {
    .with-theme({
      padding: 4px;
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      width: 215px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border-radius: 10px;
      background: @vars[neutral-50-to-neutral-dark-50];
      &-icons{
        width: 72px;
      }
      @media @viewport-mobile {
        width: 100%;
      }
    });
  }

  .period-filter-wrapper-icons {
    padding: 4px;
    
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      width: 72px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border-radius: 10px;
      background: @vars[neutral-50-to-neutral-dark-50];
    })
  }

  .period-filter {
    width: 108px;
    height: 32px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;

    @media @viewport-mobile {
      width: 100%;
    }

    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500]
    });


    &.active {
      border-radius: 10px;
      z-index: 1;
      width: 99px;
      box-shadow: 0px 0px 0px 0px #0000001F;
      box-shadow: 0px 2px 6px -2px #6A73851F;
      box-shadow: 0px 5px 10px -2px #191C210F;
      .with-theme({
        background: @vars[white-to-neutral-dark-200];
        color: @vars[neutral-800-to-100]
      });
      @media @viewport-mobile {
        width: 100%;
      }
      svg{
        path{
          .with-theme({
            stroke: @vars[neutral-800-to-100];
          });
        }
      }
    }

    &.disabled {
      color: @Neutral500;
    }

    &-active {
      position: absolute;
      left: 0;
      top: 0;
      width: 99px;
      height: 32px;
      border-radius: 10px;
      transition: all .3s ease;


      @media @viewport-mobile {
        width: calc(100% / 2);
      }

      &-disabled {
        .with-theme({
          background: @vars[neutral-100-to-800];
        });
      }
    }
  }
  .vertical-divider{
    width: 1px;

    height: 20px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });
    &-small{
      height: 16px;
      width: 1px;
      margin: 0 12px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
      @media @viewport-mobile {
        margin: 0 6px;
      }
    }
  }

  .switch-option{
    display: flex;
    gap: 8px;
    align-items: center;
    text-wrap: nowrap;
    .ant-switch.ant-switch-checked{
      background: @greenToggle;
    }
    .switch-title{
      .with-theme({
        color: @vars[neutral-800-to-neutral-100];
      });
    }    
  }

  .main-filter-wrapper {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 24px;
    gap: 16px;

    @media @viewport-mobile {
      margin-top: 46px;
      padding: 0 16px;
    }
  }

  .main-top-filter-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media @viewport-mobile {
      flex-direction: column;
      gap: 12px;
    }
  }

  .main-filter-search {
    width: 100%;

    .top-products-search-wrapper {
      display: flex;
      gap: 16px;
      align-items: center;

      button {
        width: 70px;
        border-radius: 10px;

        @media @viewport-mobile {
          width: auto;
        }
      }

      .top-products-search-list {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding: 0 18px;
        width: calc(100% - 16px - 167px);
        border-radius: @border-radius;
        max-width: 400px;
        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        @media @viewport-mobile {
          width: 100%;
        }

        &:hover {
          box-shadow: none !important;
          border-color: @bluePrimary !important;
        }

        &:has(input[disabled]) {
          cursor: not-allowed;

          &:hover {
            .with-theme({
              border-color: @vars[neutral-100-to-800] !important;
            });
          }
        }

        &:focus-within {
          border-color: @bluePrimary !important;
          box-shadow: 0 0 0 2px rgba(34,90,234,.2) !important;
        }

        .top-products-search-prefix {
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
            path{
              .with-theme({
                fill: @vars[neutral-400-to-neutral-dark-400] !important;
              });
            }
          }
        }

        .top-products-search-list-items {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          min-height: 30px;
          max-width: calc(100% - 65px);

          @media @viewport-mobile {
            max-width: 255px;
          }

          .ant-tag {
            font-family: 'Inter',sans-serif;
            white-space: pre-wrap;
            padding: 4px 8px;
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            margin-right: 8px;
            .with-theme({
              background: @vars[neutral-50-to-neutral-dark-100];
              color: @vars[neutral-800-to-neutral-100];
              border: 1px solid @vars[neutral-200-to-neutral-dark];
            });

            &-close-icon {
              margin-left: 8px;
              border-radius: 4px;
              .with-theme({
                background: @vars[neutral-100-to-neutral-dark-100];
              });
              svg{
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        .ant-input {
          border: none !important;
          height: 30px !important;
          padding: 0 !important;
          background-color: transparent !important;
          caret-color: @bluePrimary;
          .with-theme({
            color: @vars[neutral-800-to-neutral-100];
          });

          &::placeholder {
            .with-theme({
              color: @vars[neutral-400-to-neutral-dark-400];
            })
          }
        }
      }
    }

    .search_wrapper {
      margin-bottom: 0;

      @media @viewport-mobile {
        margin-top: 0;
        flex-wrap: wrap;
        gap: 16px;
      }

      .search_button {
        @media @viewport-mobile {
          width: 100%;
          min-width: 100%;
        }
      }

      button {
        width: 167px;

        @media @viewport-mobile {
          width: 100% !important;
          min-width: 100% !important;
          height: 40px !important;
        }
      }

      .search-input {
        width: calc(100% - 16px - 167px);

        @media @viewport-mobile {
          width: 100%;
          margin-right: 0;
        }

        .ant-input-affix-wrapper {
          height: 40px;
        }
      }
    }
  }

  .main-filter-dropdown-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;

    @media @viewport-mobile {
      width: max-content;
      position: static;
      text-wrap: nowrap;
      order: 3;
    }
  }

  .sales-tracker-top-page-tags{
    padding-top: 12px;
    padding-left: 32px;
    display: flex;
    align-items: center;
    gap: 12px;
    @media @viewport-mobile {
      padding-left: 16px;
    }
    .sales-tracker-top-page-tags-clear-all{
      padding: 4px 8px;
      border-radius: @border-radius;
      display: flex;
      align-items: center;
      gap: 4px;
      .with-theme({
        background: @vars[blue-100-to-neutral-dark-200];
        color:@vars[blue-to-neutral-dark-700];
      });
    }
  }


  .main-filters-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 24px;
    gap: 16px;

    @media @viewport-mobile {
      gap: 16px;
      overflow-x: scroll;
      padding-bottom: 0;
    }
  }

  .sales-tracker-top-table-wrapper {
    margin-top: 0;
    padding: 0;

    @media @viewport-mobile {
      margin-top: 12px;
    }

    .product-database-table-header {
      &:after {
        display: none;
      }
    }
  }
  .tablet-arrow {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg{
      transform: rotate(270deg);
      transition: transform 0.3s ease;
    }
    &.expanded {
      svg{
        transform: rotate(0deg);
      }
    }
  }
  .title-column-top-tracker{
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title-column-title{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;

      .with-theme({
        color: @vars[neutral-800-to-100];
      });
    }

    .title-column-wrapper{
      display: flex;
      gap: 8px;
 


      .vertical-divider{
        height: 16px;
        width: 1px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }

      .title-column-link{
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 220px;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        });
        span{
          text-decoration: underline;
        }
      }


      .title-column-empty{
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
          text-decoration: unset
        });
      }

      .title-column-img-title{
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }
    }
  }

  .clickable-item {
    color: @bluePrimary;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      color: @blueDepressed;
    }
  }
}

.table-three-dots-wrapper{
  position: relative;
  width: 100%;
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
  margin-right: 22px;
}

.empty-image-table{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;

  .with-theme({
    background: @vars[neutral-900-to-neutral-dark-50];
    border: 1px solid  @vars[neutral-200-to-neutral-dark-200];
  });
}

.column-empty{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  .with-theme({
    color: @vars[neutral-500-to-neutral-dark-500];
  });
}