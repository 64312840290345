@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.user-trackings-empty {
  width: 100%;
  height: calc(100dvh - 93px - 91px - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-icons {
    display: flex;
    align-items: center;

    &-left, &-right {
      display: flex;
      align-items: center;
    }

    &-left {

      .user-trackings-empty-icon-wrapper {
        margin-right: -12px;
      }
    }

    &-right {
      .user-trackings-empty-icon-wrapper {
        margin-left: -12px;
      }
    }
  }

  &-icon-wrapper {
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[white-to-neutral-dark];
    });

    &--main {
      z-index: 4;
      width: 48px;
      height: 48px;
      .with-theme({
        background: @vars[neutral-800-to-neutral-dark];
      })
    }
  }

  &-title {
    margin-top: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }

  &-text {
    max-width: 550px;
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }

  &-btn {
    margin-top: 24px;
    cursor: pointer;
    display: flex;
    height: 36px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: @bluePrimary;
    transition: all 0.3s ease;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: @white !important;
    box-shadow: none !important;
    border: none !important;

    &:hover {
      background: @blueDepressed;
    }
  }
}
