@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.main-stats {
  padding: 16px 32px;

  @media @viewport-mobile {
    padding: 12px 16px;
  }

  .with-theme({
    border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  &-blocks-wrapper {
    justify-content: space-between;
    display: flex;

    @media @viewport-mobile {
      flex-direction: column;
    }

    .main-block-stat-wrapper {
      padding-left: 24px;

      @media @viewport-mobile {
        padding-left: 0;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .channels-wrapper {
        @media @viewport-mobile {
          width: 100%;
          padding-left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .with-theme({
        border-left: 1px solid @vars[neutral-200-to-neutral-dark-200];

        @media @viewport-mobile {
          border: none;
        }
      });

    &:first-child {
      padding: 0;
      border: none;
    }
    }
  }
}