@import '../../styles/utils/variables';
@import '../../styles/base/ui-kit';

.check-email-code {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;

  button {
    width: 248px;
    height: 48px;
    margin-top: 24px !important;
    color: @white;
    background: @bluePrimary;
    border: none !important;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    @media @viewport-mobile {
      width: calc(100% - 40px);
      height: 72px;
      position: fixed;
      bottom: 32px;
      left: 0;
    }

    &:hover {
      background: @blueDepressed;
    }
  }

  .page-content {
    height: fit-content !important;
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-style: normal;

    @media @viewport-mobile {
      width: 100% !important;
      height: 100% !important;
      padding: 0 20px !important;
      align-items: center;
      justify-content: center;
    }

    h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 8px;
      text-align: center !important;
      color: @Neutral800;

      @media @viewport-mobile {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: @Neutral500;
      text-align: center;
    }

    .img-wrapper {
      width: 364px;
      height: 364px;
      margin-top: 12px;

      @media @viewport-mobile {
        width: 296px;
        height: 296px;
        margin-top: 12px;
      }
    }
  }
}
