@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.input {
  height: 48px;
  .border();
  .border-radius();
  transition: all .3s ease;

  &:not[disabled]:hover {
    border: 1px solid @bluePrimary !important;
  }

  .with-theme({
    color: @vars[neutral-800-to-100];
    background-color: @vars[white-to-neutral-dark];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  & > input {
    .with-theme({
      background-color: @vars[white-to-neutral-dark];

      &::placeholder{
        color: @vars[neutral-400-to-neutral-dark-400];
    };
  });
  }

  &:focus {
    border-color: @blue500;
    box-shadow: none;
  }

  &::placeholder{
    .with-theme({
      color: @vars[neutral-400-to-neutral-dark-400];
    })
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s !important;
  -webkit-transition: background-color 5000000s ease-in-out 0s !important;
}
