@import '../../../../styles/theme/theme';

.compare-plans{
  display: flex;
}

.compare-plans-main-header{
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-bottom: 16px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  .with-theme({ 
    color: @vars[neutral-800-to-100] 
  });
  @media @viewport-mobile {
    padding-bottom: unset;
  }
}

.compare-plans-wrapper{
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.compare-plans-main-items-wrapper{
  display: flex;
  align-self: center;
  &:not(:last-child){
    .with-theme({ border-bottom:1px solid @vars[neutral-100-to-800] });
  }
  @media @viewport-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .compare-plans-items-header {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-align: left;
    height: 48px;
    text-decoration: underline;
    text-decoration-style: dashed;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.3px;
    cursor: pointer;
    .with-theme({ 
      color: @vars[neutral-600-to-neutral-dark-500];
      text-decoration-color:@vars[neutral-600-to-neutral-dark-500];
    });
  }

  .compare-plans-item-value{
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.3px;
    .with-theme({ 
      color: @vars[neutral-800-to-100];
    });
    @media @viewport-mobile {
      justify-content: space-between;
    }
  }
  .compare-plans-items-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 48px;
  
    .compare-plans-item-wrapper{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 33%;
      height: 48px;
      @media @viewport-mobile {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}

.compare-plans-section{
  @media @viewport-mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.compare-plans-accordion{
  .compare-plans-items-header {
    @media @viewport-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: -0.3px;
      text-decoration: underline;
      text-decoration-style: dashed;
      height: 36px !important;
      display: flex;
      align-items: center;
      
      .with-theme({
        text-decoration-color:@vars[neutral-600-to-neutral-dark-500];
        color: @vars[neutral-600-to-neutral-dark-500];
        border-bottom:1px solid @vars[neutral-100-to-800] 
      });

      svg {
        transition: transform 0.3s ease;
      }
    
      &.expanded svg {
        transform: rotate(180deg);
      }
    }
  }
  .compare-plans-item-value{
    @media @viewport-mobile {
      display: flex;
      justify-content: space-between;
      height: 32px;
      .with-theme({
        border-bottom:1px solid @vars[neutral-100-to-800] 
      });
    }
  }
}

.compare-plans-items-content {
  @media @viewport-mobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 12px;
  }
}
.compare-plans-item-plan{
  .with-theme({
    color: @vars[neutral-600-to-neutral-dark-500];
  })
}
.compare-plans-item-extra-value{
  @media @viewport-mobile {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    })
  }
}
