@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.plan-card-dropdown {
  display: flex;
  width: 200px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  .with-theme({
    background: @vars[white-to-neutral-dark-50];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    box-shadow: @vars[box-shadow-object-large];
  });
  .plan-card-dropdown-option {
    display: flex;
    align-items: center;
    padding: 8px 8px;
    margin: 0 auto;
    width: calc(100% - 8px);
    transition: all .3s ease;
    cursor: pointer;
    border-radius: 8px;

  
    &:hover {
      .with-theme({
        background: @vars[blue-100-to-neutral-dark-100];
      })
    }

    &.red {
      span {
        color: @redPrimary !important;
      }
      &:hover {
        .with-theme({
          background:  @vars[red-100-to-900]
        })
      }
    }
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }
}
@media @viewport-mobile {
  .onboarding-plan-cards-wrapper{
    .plan-card-onboarding{
      .ant-btn.btn-primary{
        &.border-only{
          box-shadow: 0px -1px 2px 0px rgba(242, 243, 248, 0.48) inset, 0px 0px 0px 1px rgba(197, 204, 227, 0.45), 0px 4px 4px 0px rgba(21, 30, 58, 0.04);
          color: @Neutral800;
        }
      }
    }
  }
}
