@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.inner-page-title {

  &-block {
    padding: 16px 32px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      padding: 16px;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }
  }

  &-image {
    display: flex;
    align-items: center;
    gap: 16px;

    .image {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      .with-theme({
        background: @vars[blue-100-to-900];
      });

      img {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        object-fit: contain;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > span {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: calc((100vw - 272px) * 0.48);
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        @media @viewport-mobile {
          max-width: 100%;
        }
      }

      .link {
        display: flex;
        align-items: center;
        gap: 8px;

        & > a {
          margin-right: 0;
          cursor: pointer;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          text-decoration-line: underline;
          transition: all 0.2s ease;
          color: @bluePrimary;

          &:hover {
            color: @blueDepressed;
          }
        }
      }
    }

  }

  &-action {
    display: flex;
    align-items: center;
    gap: 12px;

    @media @viewport-mobile {
      width: 100%;
    }

    &-dropdown {
      display: flex;
      width: 163px;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 12px;
      .with-theme({
        background: @vars[white-to-neutral-dark-50];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        box-shadow: @vars[box-shadow-object-large];
      });

      &-item {
        display: flex;
        align-items: center;
        padding: 6px 8px;
        gap: 8px;
        margin: 0 auto;
        width: calc(100% - 8px);
        transition: all .3s ease;
        cursor: pointer;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];

          &:hover {
            background: @vars[blue-100-to-neutral-dark-100];
          }
        });

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &-btn {
      position: relative;
      transition: all .3s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      border: none !important;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus, &.ant-dropdown-open {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      });

      @media @viewport-mobile {
        width: calc(50% - 6px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > svg {
        width: 20px;
        height: 20px;
      }

      &.disabled {
        cursor: not-allowed !important;
        .with-theme({
          background: @vars[neutral-100-to-neutral-dark-100] !important;
        })
      }
    }
  }
}
