@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.fadspot-page-all-ads {

	&-wrapper {
			padding: 16px 32px;

			@media @viewport-mobile {
				padding: 16px;
			}
			.with-theme({
				border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200]; 
			});

			.fadspot-page-all-ads-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 16px;

				span {
					font-size: 14px;
					font-weight: 400;

					.with-theme({
						color: @vars[neutral-600-to-neutral-dark-500];
					})
				}
			}
			.adspot-page-all-ads-buttons {
				display: flex;
				gap: 4px;

				.adspot-page-all-ads-buttons-btn {

					&:hover {
						border-radius: 6px;
						cursor: pointer;

						.with-theme({
							background-color: @vars[neutral-100-to-neutral-dark-100];
						})
					}

					svg path {
						.with-theme({
							stroke: @vars[neutral-500-to-neutral-dark-500];
						});
					}
					&.disabled {
						cursor: not-allowed;
						
						svg path {
							.with-theme({
							stroke: @vars[neutral-300-to-neutral-dark-300];
							});
						}
					}
				}
			}
	}
}
