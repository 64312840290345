@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.load-filter-preset {
  &-wrapper {
    margin: 16px;

    &.price-modal-wrapper {
      margin: 0px;

      @media @viewport-mobile {
        padding: 10px 0px 0px;
      }
    }
    
    .search {
      .search_wrapper {
        margin-bottom: 16px;
        .search-input {
          margin-right: 0px;
        }
      }
    }

    .preset-switcher {
      margin-bottom: 16px;
    }

    .load-filter-group {
      width: 100%;
      display: flex;
      border-radius: 10px;
      padding: 4px;

      &:before {
        display: none;
      }

      &.active {
        .with-theme({
          background: @vars[white-to-neutral-dark-200] !important;
          box-shadow: @vars[box-shadow-object-middle] !important;
        });
      
        span {
          .with-theme({
              color: @vars[color-dark-to-white] !important;
          });
        }
      }

      span {
        .with-theme({
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        background: @vars[neutral-50-to-neutral-dark-50];
      });

      .load-filter-group {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }
    .price-modal-info {
      display: flex;
      align-items: center;
      margin-top: 16px;
      padding: 0 16px 16px;
      gap: 16px;

      &-logo {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        width: 40px;
        border-radius: @border-radius;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
          border: 1px solid @vars[neutral-100-to-800];
        });

        img {
          border-radius: 6px;
          height: auto;
          max-height: 100%;
          max-width: 100%;
          object-fit: contain;
          width: fit-content;
        }
      }

      &-title {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        max-width: calc(100% - 100px);
        .with-theme({
          color: @vars[neutral-800-to-200];
        });

        @media @viewport-mobile {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .price-modal-chart-wrapper {
      margin-top: 16px;
      padding: 0 16px 16px;

      .price-modal-period {
        font-size: 14px;
        line-height: 20px;
        color: @Neutral500;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .with-theme({
          svg path {
           fill: @vars[neutral-300-to-700];
          }
        });

        svg:hover path {
          fill: @bluePrimary !important;
        }

        @media @viewport-mobile {
          gap: 8px;
        }

        .price-modal-period-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          @media @viewport-mobile {
            flex-direction: column;
            align-items: flex-start;
          }

          .chart-block_header {
            @media @viewport-mobile {
            width: 100%;
            }

            .period-filter {
              svg {
                path {
                  fill: transparent;
                }
                &:hover path {
                  fill: transparent !important;
                }
              }
            }
          }
        }

        .price-modal-period-title {
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          })
        }

        .price-modal-period-text {
          display: flex;
          align-items: center;

          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });

          @media @viewport-mobile {
            flex-direction: column;
            align-items: flex-start;;
            gap: 8px;
          }
        }

        &-currency {
          font-size: 20px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          })
        }

        &-updated {
          display: flex;
          align-items: center;
          gap: 4px;
          font-weight: 400;
          font-size: 12px;
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          });

          svg path {
            .with-theme({
              stroke: @vars[neutral-400-to-neutral-dark-400];
            });
          }
        }
      }
    }
  }

  &-title {
    font-size: 16px;
    line-height: 32px;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });
  }

  &-title.price-modal {
    padding: 18px 16px 0;

    @media @viewport-mobile {
      padding-top: 0;
     }
  }

  &-subtitle {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
    font-weight: 400;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  &-button-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .load-filter-preset-button {
      background: transparent;
      transition: all 0.3s ease;
      padding: 16px 24px;
      border-radius: 10px;
      .with-theme({
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        color: @vars[neutral-800-to-200];
      });

      &[disabled] {
        color: @Neutral500 !important;
        margin-left: 16px;
        .with-theme({
          background: @vars[neutral-100-to-800] !important;
        });
      }

      &:hover:not([disabled]) {
        border-color: @bluePrimary;
        color: @bluePrimary;
      }
    }
    .load-filter-preset-button.apply {
      border: none;
    }

    .load-filter-preset-button.apply:not([disabled]) {
      margin-left: 16px;
      background: @bluePrimary;
      color: @white !important;
      border: none;

      &:hover {
        background: @blueDepressed;
      }
    }
  }
}
