@import '../../../styles/theme/theme';
@import "../../../styles/utils/variables";

.plan-card-upcoming{
  position: relative;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid;

  .with-theme({
    background-color: @vars[white-to-neutral-dark-50];
    border-color: @vars[neutral-100-to-neutral-dark-200];
  });

  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;


  &:not(:last-of-type){
    margin-bottom: 16px;
  }

  .plan-info-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 12px;
    margin-bottom: 8px;
    border-bottom: 1px solid;
    .with-theme({
      border-color: @vars[neutral-200-to-neutral-dark-200];
    });

    .info-wrp{
      width: 100%;
      display: flex;
    }

    &_only-text{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media @viewport-mobile {
        justify-content: flex-start;
        gap: 40px;
      }

      .info-wrp{
        &:first-of-type{
          padding-right: 16px;
        }
        &:last-of-type{
          padding-left: 16px;
        }
      }
    }
  }

  .info-wrp{
    width: auto;
    > span {
      display: block;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
    }

    > .info-title{
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      font-size: 14px;
      margin-top: 4px;
      text-align: start;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });

      @media @viewport-mobile {
        font-size: 12px;
        line-height: 14px;
      }
    }

    .info-text{
      margin-top: 8px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      text-align: start;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }
  .ant-btn.only-icon{
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: transparent;
  }

  .custom-radio{
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    margin-right: 0;

    .ant-radio{
      top: 0;
      .ant-radio-inner {
        width: 24px;
        height: 24px;
        .with-theme({
          background-color: @vars[color-white-to-dark];
          border-color: @vars[neutral-400-to-600];
        });

        &:after {
          width: 16px;
          height: 16px;
          background-color: @blue500;
          border-radius: 50%;
        }
      }

      &-checked{
        .ant-radio-inner {
          .with-theme({
            border-color: @blue500;
          });
        }

        &.ant-radio-disabled{
          .ant-radio-inner {
            .with-theme({
              border-color: @vars[neutral-300-to-800]!important;
              &:after {
                background-color: @vars[neutral-300-to-800]!important;
              }
            });
          }
        }
      }
    }

  }

  &.with-red-btn{
    .ant-btn.only-icon{
      svg{
        fill: @red500;
      }
    }
  }
}
