@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.product-database-page.adlibrary-page {
  .sales-tracker-top-page-credits {
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[neutral-50-to-neutral-dark-50];
    })
  }

  .sales-tracker-top-page-header {

    .title-wrapper-icon {
      border: 1px solid rgba(7, 47, 148, 0.5);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #225AEA;
      box-shadow: 0px 30px 30px -15px rgba(6, 73, 42, 0.04), 0px 15px 15px -7.5px rgba(6, 73, 42, 0.04), 0px 7.5px 7.5px -3.75px rgba(6, 73, 42, 0.04), 0px 3.75px 3.75px -1.875px rgba(6, 73, 42, 0.04), 0px 1.25px 1.25px -0.625px rgba(6, 73, 42, 0.04), 0px 0px 0px 1.25px rgba(6, 73, 42, 0.04);
      svg {
        margin-top: 5px;
        margin-left: 4px;
      }
    }
  }

  @media @viewport-mobile {
    .sales-tracker-top-page-credits {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 72px;
      box-sizing: border-box;
      .with-theme({
        border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
        border-top: unset;
        background: unset;
      });

      &-title {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
          background: @vars[neutral-50-to-neutral-dark-50];
          color: @vars[neutral-600-to-neutral-dark-500];
        });
      }

      &-value {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;

        .total-green {
          color: @greenPrimary;
        }

        .total-orange {
          color: @orangePrimary;
        }

        .total-red {
          color: @redPrimary;
        }


        .current {
          .with-theme({
            color: @greenPrimary;
          });
        }

        .separator {
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          });
        }

        .maximum {
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }
      }
    }
  }

  .adlibrary-page-quick-search {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    gap: 16px;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      display: block;
      padding: 0;
      gap: 0;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }
      &::-webkit-scrollbar-track-piece:start {
        display: none;
      }
      &::-webkit-scrollbar-track-piece:end {
        display: none;
      }
    }

    &-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-500-to-neutral-dark-500];
      })
    }

    &-presets {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;

      @media @viewport-mobile {
        padding: 16px;
        width: fit-content;
      }
    }

    &-preset {
      display: flex;
      height: 32px;
      padding: 6px 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      transition: all .3s ease;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      border-radius: 8px;
      user-select: none;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        color: @vars[neutral-800-to-100];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover {
          background: @vars[neutral-50-to-neutral-dark-100];
        }

        svg path {
          stroke: @vars[neutral-500-to-neutral-dark-500];
        }
      });

      &.disabled {
        cursor: not-allowed;
        .with-theme({
          background: @vars[neutral-100-to-neutral-dark-100] !important;
        })
      }

      @media @viewport-mobile {
        white-space: nowrap;
      }
    }
  }

  .adlibrary-page-items-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 24px 32px;
    gap: 16px;
    justify-content: flex-start;
    max-height: calc(100% - 65px);
    overflow-y: auto;

    @media @viewport-mobile {
      padding: 16px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }
    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }
  }
}

.adLibrary-items-empty {
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media @viewport-mobile {
    height: auto;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-sup {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;

    .with-theme({
      color: @vars[neutral-800-to-100];
    });
  }

  &-sub {
    font-size: 14px;
    font-weight: 400;

    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
  }

  &-image {
    margin-bottom: 24px;
  }
}
