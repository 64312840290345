@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.product-details-page-name-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-family: 'Inter', sans-serif;

  span.ant-typography {
    margin-left: 8px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.7px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    });

    @media @viewport-mobile {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .link:not(.skeleton) {
    margin-left: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: @bluePrimary;
    transition: all .3s ease;

    &:hover, &:focus, &:active {
      color: @blueDepressed;
    }
  }
}
