@import '../../../../styles/theme/theme';
@import '../../../../styles/base/animation';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/variables';

.bd-modal {
  .ant-modal-content {
    padding: 20px 20px 20px !important;
  }

  &.choose_icon {
    .ant-modal-body {
      padding-top: 16px !important;
      padding-right: 10px !important;
    }
  }
  &.configure {
    .ant-modal-body {
      padding-left: 23px !important;
      padding-right: 23px !important;
    }
  }
  .ant-modal-footer {
    .double-btn-wrapper {
      .double-btn-block {
        .border-only {
          border-color: @Neutral200 !important;
        }
        .ant-btn {
          &.red {
            max-width: 91px !important;
          }
          &:disabled {
            color: @Neutral300 !important;
          }
        }
      }
    }
  }
  .ant-modal-body {
    padding-top: 24px !important;
    padding-bottom: 0 !important;
    .configure-modal {
      display: flex;
      flex-direction: column;
      position: relative;
      .configure-delete {
        position: absolute;
        z-index: 5;
        top: -30px;
        right: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .configure-delete-text {
          color: @red500;
          font-size: 14px;
          line-height: 16px;
          display: inline-block;
          margin-left: 12px;
        }
        &:hover {
          .configure-delete-text {
            color: @red400;
          }
          .icon-delete {
            path {
              fill: @red400 !important;
            }
          }
        }
      }
      .db-form {
        .change-form-item {
          margin-bottom: 16px;

          &:first-child {
            margin-bottom: 24px;
          }
          &:last-child {
            margin-bottom: 7px;
            .ant-input {
              color: @Neutral500;
            }
          }
          .ant-input {
            height: 48px;
            padding: 16px 24px;
            color: @Neutral800;
          }
        }
      }

      .configure-buttons-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        border: 1px solid #f2f3f8;
        height: 48px;
        border-radius: @border-radius;
        position: relative;
        margin-bottom: 32px;
        .bd-btn {
          z-index: 4;
          width: 230px;
          height: 48px;
          color: @Neutral500;
          text-align: center;
          line-height: 48px;
          &.active {
            color: #fff;
            width: 232px !important;
          }
        }
        .configure-buttons-slider {
          position: absolute;
          z-index: 3;
          border-radius: @border-radius;
          width: 232px;
          margin-top: -1px;
          height: 48px;
          background: @blue500;
          transition: 0.3s cubic-bezier(0, 0, 0, 1);
          &.benefits {
            margin-left: 0%;
          }
          &.drawbacks {
            margin-left: 50%;
          }
        }
      }
      .choose-icon-wrapper {
        display: flex;
        .choose-icon {
          width: 96px;
          height: 96px;
          border-radius: @border-radius;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 64px;
            height: 64px;
          }

          &.drawbacks {
            background-color: @yellow100;
            svg {
              *[stroke='#323232'] {
                stroke: @yellow500;
              }
            }
          }
          &.benefits {
            background-color: @blue100;
            svg {
              *[stroke='#323232'] {
                stroke: @blue500;
              }
            }
          }
        }
        .choose-icon-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          max-height: 96px;
        }
        .choose-icon-title {
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.09px;
          color: @Neutral800;
          margin-left: 24px;
          line-height: 16px;
          margin-bottom: 12px;
        }
        .choose-icon-text {
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.09px;
          color: @blue400;
          margin-left: 24px;
          line-height: 16px;
          cursor: pointer;
        }
      }
    }
    .choose-icon-modal {
      .search {
        .search_wrapper {
          margin-bottom: 24px;
          margin-top: 0;
          .search-input {
            margin: 0;
          }
        }
      }
      .bd-icons-list {
        overflow-y: scroll;
        height: 285px;
        padding-right: 14px;

        &::-webkit-scrollbar {
          width: 4px; height: 0;
          height: 4px;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          background: transparent;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          .with-theme({
            background: @vars[neutral-200-to-neutral-dark-200];
          });
        }
        .bd-icons-wrapper {
          .bd-icon-card {
            width: 148px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #f2f3f8;
            border-radius: @border-radius;
            padding: 8px 12px;
            &.drawback:not(.used) {
              cursor: pointer;
              .bd-icon-img > svg {
                *[stroke='#323232'] {
                  stroke: @yellow500;
                }
              }
              &.selected {
                background-color: @yellow500;
                .bd-icon-img > svg {
                  *[stroke='#323232'] {
                    stroke: @white;
                  }
                }
                .bd-icon-text {
                  color: @white;
                }
              }
              &:hover:not(.selected) {
                .bd-icon-text {
                  color: @yellow400;
                }
                .bd-icon-img > svg {
                  *[stroke='#323232'] {
                    stroke: @yellow400;
                  }
                }
              }
            }
            &.benefit:not(.used) {
              cursor: pointer;
              .bd-icon-img > svg {
                *[stroke='#323232'] {
                  stroke: @blue500;
                }
              }
              &.selected {
                background-color: @blue500;
                .bd-icon-img > svg {
                  *[stroke='#323232'] {
                    stroke: @white;
                  }
                }
                .bd-icon-text {
                  color: @white;
                }
              }
              &:hover:not(.selected) {
                .bd-icon-text {
                  color: @blue400;
                }
                .bd-icon-img > svg {
                  *[stroke='#323232'] {
                    stroke: @blue400;
                  }
                }
              }
            }
            &.used {
              .bd-icon-img > svg {
                *[stroke='#323232'] {
                  stroke: @Neutral300;
                }
              }
              .bd-icon-text {
                color: @Neutral300;
              }
            }

            .bd-icon-img {
              display: inline-block;
              width: 32px;
              height: 32px;
              svg {
                width: 32px;
                height: 32px;
              }
            }
            .bd-icon-text {
              display: inline-block;
              font-size: 14px;
              line-height: 16px;
              height: 16px;
              color: @Neutral800;
              margin-left: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
