@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';

.active-ads-modal-info-wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;

  .active-ads-info-modal-info-title{
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.3px;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    })
  }
  .active-ads-info-modal-info-text{
    display: flex;
    align-items: center;
    gap: 12px;

    .value{
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.7px;
      .with-theme({
        color: @vars[neutral-800-to-white];
      })
    }
    .vertical-divider-small {
      height: 16px;
      width: 1px;
    
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      })
    }
    .status{
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-400-to-neutral-dark-400];

        svg path {
          fill: @vars[neutral-400-to-neutral-dark-400];
        }
      });
    }
  }
}