@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.competitors-card-wrap {
  .ant-divider {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    &:last-of-type {
      display: none;
    }
  }
}
.competitors-card {
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media @viewport-mobile {
    padding: 0 16px;
  }

  &-wrap {
    padding-top: 16px;
  }

  &-list-header {
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      background: @vars[neutral-50-to-neutral-dark-50];
    });

    @media @viewport-mobile {
      padding: 0 16px;
      height: 36px;
    }

    span {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });

      &:first-of-type {
        width: 50%;
      }

      &:last-of-type {
        margin-left: auto;
      }
    }
  }

  .skeleton {
    height: 24px;
    border-radius: @border-radius;
    .skeleton-animation-bg();

    @media @viewport-mobile {
      height: 16px;
    }
  }

  &-wrap {
    min-height: 224px;
    max-height: 368px;
    margin-right: -12px;
    max-width: 100%;
    align-content: flex-start;

    @media @viewport-mobile {
      min-height: auto;
      max-width: 100%;
    }
  }

  &-info-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 50%;
    min-width: 230px;

    @media @viewport-mobile {
      min-width: unset;
    }
  }

  &-img {
    margin-right: 8px;
    width: 20px;
    height: auto;

    &.skeleton {
      height: 20px;
    }
  }

  &-link {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: @bluePrimary;
    display: flex;
    align-items: center;
    gap: 8px;

    @media only screen and (max-width: 1200px) {
      font-size: 14px;
      line-height: 20px;
    }

    @media @viewport-mobile {
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      max-width: 160px;
    }
  }

  &-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    height: 28px;
    padding: 8px 12px;
    border-radius: 100px;
    .with-theme({
      background-color: @vars[neutral-50-to-neutral-dark-50];
      color: @vars[neutral-600-to-neutral-dark-500];
    });

    &.skeleton {
      height: 32px;
      border-radius: @border-radius;

      @media @viewport-mobile {
        height: 24px;
        width: 60px;
      }
    }

    @media @viewport-mobile {
      padding: 4px 8px;
      border-radius: @border-radius;
    }
  }

  &-price {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: auto;
    .with-theme({
      color: @vars[neutral-800-to-200];
    });

    @media only screen and (max-width: 1200px) {
      font-size: 14px;
      line-height: 20px;
    }

    @media @viewport-mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.empty {
    padding: 18px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
