@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.videos-card {

  > div{
    height: 100%;
  }

  &:not(:last-of-type){
    margin-right: 16px;
  }

  .block-locked {
    overflow: hidden;
    border-radius: 8px;
    .with-theme({
      background: @vars[white-to-neutral-dark];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    &-btn {
      margin: 20px auto 0;
      height: 32px;
      padding: 6px 18px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      border-radius: 8px;
      cursor: pointer;
      transition: all .3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
        }
      });
    }

    &-info-wrap {
      position: relative;
      height: 60%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../../Images/LockedPortfolioVideoBg.png");

      svg {
        position: relative;
        z-index: 2;

        path {
          .with-theme({
            fill: @vars[white-to-neutral-dark-50];
          })
        }
      }

      &:after {
        z-index: 1;
        backdrop-filter: blur(5px);
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.01);
        border-radius: 8px 8px 0 0;
      }

      &_text {
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        font-family: 'Inter', sans-serif;

        .block-locked-title {
          margin-top: 10px;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }

        .block-locked-text {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }
      }
    }
  }

  .tiktok-embed {
    border-radius: @border-radius;
    margin: 0;
  }

  &.skeleton{
    min-width: 290px;
    height: 650px;
    border-radius: @border-radius;
    .skeleton-animation-bg();

    &.Facebook{
      height: 365px;
    }

    @media @viewport-mobile {
      height: 16px;
    }
  }

  &.disabled{
    display: none;
  }

  &-wrap{
    display: flex;
    align-content: flex-start;
    min-height: 345px;
    gap: 18px;

    @media @viewport-mobile {
      min-height: auto;
      max-width: 100%;
      overflow-y: hidden;
    }
  }

  &.empty{
    margin: 0 auto;
    padding: 18px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.os-viewport {
  overflow-x: scroll;
  overflow-y: hidden;
}
