@import '../../../styles/utils/variables';

.product-actions-admin {
  &:after {
    display: none;
  }
}

.store-admin-page {
  .sales-tracker-search-wrapper {
    padding: 24px 32px;

    .search {
      .search_wrapper {
        margin: 0;

        .search_button > button {
          border-radius: 10px;
          background: @bluePrimary;
          transition: .3s all linear;
          
          &:hover {
            background: @blueDepressed;
            color: @white;
          }

          &:disabled {
            background: @blueDepressed;
            color: @white;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .ant-switch {
    background: @Neutral200;

  &:hover {
    background: @Neutral200 !important;
  }

  &-checked {
    background: @bluePrimary;

    &:hover {
      background: @blueDepressed !important;
    }
  }

  &-inner {
    width: 110px;
  }
}
}
