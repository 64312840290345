@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.integrations-subpage {

  &-block {
    margin-bottom: 64px;

    @media @viewport-mobile {
      margin-bottom: 48px;
      padding: 0 16px;

      &:last-of-type {
        padding: 0;

        .billing-subpage-block-title-wrapper {
          padding: 0 16px;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        @media @viewport-mobile {
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 8px;
        }
      }
    }

    &-integrations-wrapper {
      margin-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      @media @viewport-mobile {
        gap: 0 ;
      }

      .item {
        padding: 16px 0;
        width: 100%;
        display: flex;
        align-items: center;
        .with-theme({
          border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        @media @viewport-mobile {
          flex-direction: column;
          align-items: flex-start;
        }

        &-icon {
          margin-right: 16px;
          width: 48px;
          height: 48px;
          padding: 8px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media @viewport-mobile {
            margin-right: 0;
            margin-bottom: 16px;
          }

          svg {
            width: 32px;
            height: 32px;
          }

          &.shopify {
            background: rgba(149, 191, 71, 0.15);
          }

          &.autods {
            background: @Neutral100;
          }
        }

        &-info {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 4px;

          @media @viewport-mobile {
            margin-bottom: 16px;
          }

          .title {
            position: relative;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-800-to-100];
            });

            .tag {
              z-index: 0;
              position: absolute;
              left: calc(100% + 4px);
              top: -2px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px 6px;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: -0.3px;
              border-radius: 6px;

              &.connected {
                color: @greenDepressed;
                .with-theme({
                  background: @vars[green-100-to-900];
                });
              }

              &.not-connected {
                color: @redDepressed;
                .with-theme({
                  background: @vars[red-100-to-900];
                });
              }
            }
          }

          .description {
            max-width: 380px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.3px;
            .with-theme({
              color: @vars[neutral-600-to-neutral-dark-500];
            })
          }
        }

        &-action {
          margin-left: auto;
          transition: all .3s ease;
          cursor: pointer;
          display: flex;
          align-items: center;
          border: none !important;
          height: 40px;
          padding: 6px 12px;
          border-radius: 10px;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-800-to-100];
            background: @vars[white-to-neutral-dark-50];
            box-shadow: @vars[box-shadow-btn-secondary];

            &:hover, &:focus {
              background: @vars[neutral-50-to-neutral-dark-100];
            }
          });

          @media @viewport-mobile {
            width: 100%;
            justify-content: center;
          }

          &.active {
            color: @redPrimary !important;
          }

          &.disabled {
            cursor: not-allowed;
            .with-theme({
              background: @vars[neutral-100-to-neutral-dark-100] !important;
            })
          }
        }

        .dropdown-store {
          position: relative;
          margin-left: auto;
          width: 240px;
          transition: all .3s ease;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 6px 12px;
          border-radius: 10px;
          box-shadow: none !important;
          .with-theme({
            background: @vars[white-to-neutral-dark-50];
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];

            &.ant-dropdown-open, &:hover {
              border: 1px solid @bluePrimary !important;
            }
          });

          @media @viewport-mobile {
            width: 100%;
            justify-content: center;
          }

          & > svg {
            transition: all .3s ease;
            margin-left: auto;
          }

          &.ant-dropdown-open {
            > svg {
              transform: rotate(180deg);
            }
          }

          &-info {
            display: flex;
            align-items: center;
            white-space: nowrap;
            gap: 8px;
            max-width: calc(100% - 20px);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.3px;

            svg {
              min-width: 20px;
              min-height: 20px;
              width: 20px;
              height: 20px;
            }

            .name {
              white-space: nowrap;
              .with-theme({
                color: @vars[neutral-800-to-100];
              })
            }

            .domain {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              })
            }
          }

          &-wrapper {
            width: 425px;
            border-radius: 12px;
            .with-theme({
              box-shadow: @vars[box-shadow-object-large];
              background: @vars[white-to-neutral-dark-50];
              border: 1px solid @vars[neutral-200-to-neutral-dark-200];
            });

            .user_store-list {
              padding: 8px;
              width: 100%;
              max-height: 200px;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;

              &::-webkit-scrollbar {
                width: 4px;
                border-radius: 2px;
              }

              &::-webkit-scrollbar-button {
                display: none;
              }

              &::-webkit-scrollbar-track-piece {
                background: transparent;
                border-radius: 2px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                .with-theme({
                  background: @vars[neutral-200-to-neutral-dark-200];
                });
              }

              &::-webkit-scrollbar-track-piece:start {
                background: transparent;
                margin-top: 10px;
              }

              &::-webkit-scrollbar-track-piece:end {
                background: transparent;
                margin-bottom: 10px;
              }

              .user_store-item {
                width: 100%;
                padding: 2px 8px;
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: flex-start;

                svg {
                  width: 16px;
                  height: 16px;
                  min-width: 16px;
                  min-height: 16px;
                }

                .name {
                  white-space: nowrap;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: -0.3px;
                  .with-theme({
                    color: @vars[neutral-800-to-100];
                  });
                }

                .domain {
                  white-space: nowrap;
                  overflow: hidden;
                  display: block;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: -0.3px;
                  .with-theme({
                    color: @vars[neutral-600-to-neutral-dark-500];
                  });
                }

                .btn-cancel {
                  margin-left: auto;
                  height: 32px;
                  padding: 6px 8px;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: -0.3px;
                  border-radius: 10px;
                  cursor: pointer;
                  transition: all .3s ease;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none !important;
                  outline: none !important;
                  color: @redPrimary !important;
                  .with-theme({
                    background: @vars[white-to-neutral-dark] !important;
                    box-shadow: @vars[box-shadow-btn-secondary] !important;
                  });

                  &:hover, &:focus {
                    color: @redPrimary !important;
                    .with-theme({
                      background: @vars[white-to-neutral-dark] !important;
                      box-shadow: @vars[box-shadow-negative] !important;
                    })
                  }
                }

                .btn-check-limit {
                  margin-left: auto;
                  height: 32px;
                  padding: 6px 8px;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: -0.3px;
                  border-radius: 10px;
                  cursor: pointer;
                  transition: all .3s ease;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none !important;
                  outline: none !important;
                  color: @redPrimary !important;
                  .with-theme({
                    color: @vars[neutral-800-to-100] !important;
                    background: @vars[white-to-neutral-dark-50] !important;
                    box-shadow: @vars[box-shadow-btn-secondary] !important;
        
                    &:hover, &:focus {
                      background: @vars[neutral-50-to-neutral-dark-100] !important;
                    }
                  });
                }
              }
            }
          }
        }
      }
    }
  }
}
