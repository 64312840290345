@import '../../styles/utils/variables';
@import '../../styles/base/ui-kit';

.sign-up-modal {
  border-radius: @border-radius;

  .ant-modal-content {
    padding: 0;
    border-radius: @border-radius !important;

    @media @viewport-mobile {
      width: 100%;
    }
  }

  @media @viewport-mobile {
    max-width: calc(100% - 40px) !important;
    display: flex !important;
    align-items: center;
    left: 20px;
    right: 0;
    margin: auto;
  }

  .ant-modal-close {
    right: 5px !important;
    top: 5px !important;
    width: 48px;
    height: 48px;

    svg {
      fill: @Neutral200;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  &-image {
    border-top-left-radius: @border-radius;
    border-top-right-radius: @border-radius;
    width: 100%;
    height: 196px;
    background-image: url('../Images/signup_mail.svg');
    background-repeat: no-repeat;
    background-size: cover;

    @media @viewport-mobile {
      background-image: url('../Images/signup_mail_mob.svg');
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:  0 32px 32px;

    &-title {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: @Neutral500;
    }

    &-mail {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 40px;
      margin-top: 8px;
      color: @Neutral800;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
    }

    &-mail-service {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid @Neutral100;
      border-radius: @border-radius;
      transition: all .3s ease;

      &:hover {
        border: 1px solid @bluePrimary;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    &-timer {
      .ant-spin {
        transform: scale(0.5);
      }
    }

    &-timer-wrapper {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: @Neutral500;

      & > span {
        color: @bluePrimary;
        transition: all .3s ease;
        cursor: pointer;

        &:hover {
          color: @blueDepressed;
        }
      }
    }
  }
}
