@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';
@import '../../../../styles/utils/mixin';


.adlibrary-page-items-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 16px;
  gap: 16px;
  justify-content: center;
  max-height: calc(100% - 65px);
  overflow-y: auto;

  @media @viewport-mobile {
    padding: 16px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });
  }
  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }
}
