@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.save-filter-preset-wrapper {

  .load-filter-preset-subtitle {
    margin-top: 12px;
    margin-bottom: 28px;
  }

  .save-filter-preset-input-wrapper {
    margin-top: 28px;
    display: flex;
    align-items: center;
  }

  .save-filter-preset-input {
    width: 100%;
    margin-left: 2px;
    height: 46px;

    &-error {
      border-color: #D71313;
    }

    &:hover {
      border-color: @bluePrimary;
    }

    &:focus-within {
      border-color: @bluePrimary;
      box-shadow: 0 0 0 2px rgba(34, 90, 234, .2);
    }

    &::placeholder {
      .with-theme({
        color: @vars[neutral-400-to-neutral-dark-400];
      })
    }

  }

  .save-filter-preset-btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;

  }

  .save-filter-preset-cancel {
    background-color: white;
    color: black;
  }

  .save-filter-preset-submit {
    padding: 16px 24px;
    background: @bluePrimary;
    color: @white !important;
    border: none;

    &:hover {
      background: @blueDepressed;
    }

    &[disabled] {
      color: @Neutral500 !important;
      .with-theme({
        background: @vars[neutral-100-to-800];
      });
    }
  }
}
