@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.sales-tracker{
  &-search {
    padding: 8px 26px;
    height: 40px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    outline: none;
    .with-theme({
      background: @vars[white-to-neutral-dark-50];
      color: @vars[neutral-800-to-neutral-100];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });


    &:hover, &:focus {
      .with-theme({
        background: @vars[neutral-50-to-neutral-dark-100];
        color: @vars[neutral-800-to-neutral-100];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });
    }

    &[disabled] {
      .with-theme({
        background: @vars[neutral-100-to-neutral-dark-100];
        color: @vars[neutral-300-to-neutral-dark-300];
        border: none;
      });
    }
  }
}
.sales-tracker-page {

  .sales-tracker-top-page-header {
    .title-wrapper-icon {
      border: 1px solid rgba(158, 20, 20, 0.50);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #D71313;
      box-shadow: 0px 20px 20px -10px rgba(112, 6, 6, 0.04), 0px 10px 10px -5px rgba(112, 6, 6, 0.04), 0px 5px 5px -2.5px rgba(112, 6, 6, 0.04), 0px 2.5px 2.5px -1.25px rgba(112, 6, 6, 0.04), 0px 0.833px 0.833px -0.417px rgba(112, 6, 6, 0.04), 0px 0px 0px 0.833px rgba(112, 6, 6, 0.04);
    }
  }

  .skeleton.link {
    display: block;
  }

  .skeleton-table-header {
    align-items: center;
    display: flex;
    width: 100%;
    height: 40px;
    .with-theme({
      background: @vars[neutral-50-to-neutral-dark-50];
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    })
  }

  .skeleton-table-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    .with-theme({
      border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .sales-tracker-table-body {
    .product-name {
      cursor: pointer;
    }
  }

  .product-database-table-cards {
    height: calc(100dvh - 155px);
    overflow-y: auto;
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 24px;

    @media @viewport-mobile {
      padding: 16px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      .with-theme({
        background: @vars[neutral-200-to-neutral-dark-200];
      });
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 10px;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }
  }

  &.sales-tracker-product-page {

    .chart-block {
      margin: 0 32px;
    }

    .ant-spin-nested-loading {
      height: unset !important;
    }
  }

  &.sales-tracker-store-page {

    .store-tracker-page-tab-content {

      .chart-block {
        margin: 0 32px 48px;

        @media @viewport-mobile {
          margin: 0 0 40px !important;
        }
      }

      .store-tracker-page-tab-subtitle {
        padding: 0 32px;
        margin-block-end: 16px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        });

        @media @viewport-mobile {
          padding: 0 16px;
        }

        .count {
          padding: 4px 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
          margin-right: -4px;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.3px;
          border-radius: 6px;
          .with-theme({
            color: @vars[blue-600-to-neutral-dark-500];
            background: @vars[blue-100-to-neutral-dark-100];
          })
        }

        span:not(.count):not(.link.skeleton) {
          margin-left: 16px;
          display: flex;
          align-items: center;
          gap: 4px;
          color: @bluePrimary;
          transition: all .3s ease;
          cursor: pointer;

          &:hover {
            color: @blueDepressed;
          }
        }
      }

      .store-tracker-page-tab-card-wrapper {
        width: calc(100% - 64px);
        margin: 0 32px 48px;
        display: flex;
        gap: 16px;

        @media @viewport-mobile {
          margin: 0 16px 16px;
          width: calc(100% - 32px);
          overflow-x: scroll;
          scroll-snap-type: x mandatory;

          .fadspot-page-item-wrapper {
            width: 100%;
            min-width: 100%;
            max-width: unset;
            scroll-snap-align: start;
          }

          &-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin: 0 auto 40px;
          }

          &-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            transition: all .3s ease;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });

            &.active {
              background: @bluePrimary !important;
            }
          }
        }
      }
    }

    .store-tracker-page-sales {
      width: 100%;

      .store-tracker-page-overview-empty {
        margin-top: 48px;
        background: transparent !important;
      }

      &-chart-wrapper {
        &:not(.small) {
          margin: 0 32px;

          @media @viewport-mobile {
            margin: 0;
          }
        }


        .chart-block {
          margin: 0 !important;
        }

        &.small {
          width: calc(50% - 12px);

          @media @viewport-mobile {
            width: 100%;
          }
        }
      }

      &-charts-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        margin: 24px 32px 0;

        @media @viewport-mobile {
          gap: 48px;
          flex-direction: column;
          align-items: flex-start;
          margin: 48px 16px 0;

          .chart-block {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }

      .report-main-block {

        @media @viewport-mobile {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          &-title, &-switch {
            width: fit-content;
          }

          &-actions {
            width: 100%;
          }
        }

        &-switch {
          margin-left: auto;
          margin-right: 24px;
        }
      }

      &-table-wrapper {
        margin-top: 48px;
        .with-theme({
          border-top: 1px solid @vars[neutral-200-to-neutral-dark-200];
        })
      }
    }

    .store-tracker-page-competitors {

      .ant-spin-nested-loading {
        overflow: visible !important;
      }

      &-options-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;

        @media @viewport-mobile {
          padding: 0 16px;
          flex-direction: column-reverse;
          gap: 16px;
        }

        .product-view {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 36px;
          padding: 4px;
          border-radius: 10px;
          .with-theme({
            background: @vars[neutral-50-to-neutral-dark-100];
            border: 1px solid @vars[neutral-200-to-neutral-dark-200];
          });

          &-btn {
            transition: all .3s ease;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            .with-theme({
              svg path {
                stroke: @vars[neutral-600-to-neutral-dark-500];
              }
            });

            &--active {
              box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
              .with-theme({
                background: @vars[white-to-neutral-dark-100];

                svg path {
                  stroke: @vars[neutral-800-to-100];
                }
              });
            }
          }

          @media @viewport-mobile {
            width: 100%;
            .with-theme({
              color: @vars[neutral-800-to-100];
            });

            .product-view-btn {
              width: 50%;
            }
          }
        }
      }

      .cascading-wrapper {
        height: 36px;

        .cascading-dropdown-value-selected {
          height: 24px;
        }

        .cascading-dropdown-value-checked {
          display: none;
        }
      }

      &-filter-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: flex-start;

        .select-block, .main-filter-dropdown {
          width: fit-content;
          min-width: fit-content;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        @media @viewport-mobile {
          width: 100%;
          overflow-x: scroll;
        }

        .languages-dropdown-content-wrapper {
          padding: 0 0 8px;

          .ant-input-affix-wrapper.dropdown-search.input {
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-radius: 16px 16px 0 0;
            box-shadow: none !important;
            margin-bottom: 8px !important;

            &:hover{
              .with-theme({
                border-bottom: 1px solid @vars[neutral-200-to-neutral-dark-200];
              })
            }

            &.ant-input-affix-wrapper-focused{
              border-bottom: 1px solid  @bluePrimary !important;
              box-shadow: 0 0 0 2px rgba(5, 122, 255, 0.06) !important;
            }
          }

          .ant-divider {
            max-width: calc(100% - 16px) !important;
            width: calc(100% - 16px) !important;
            min-width: unset !important;
          }

          .empty-state_wrapper-text {
            white-space: normal;
          }

          .main-filter-dropdown-content-button-wrapper {
            padding: 0 8px;
          }
        }
      }

      &-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        margin: 12px 32px 16px;
      }

      &-tag {
        height: 24px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
        cursor: pointer;
        transition: all .3s ease;
        border-radius: 6px;
        .with-theme({
          background: @vars[blue-100-to-neutral-dark-400];
          color: @vars[blue-400-to-neutral-dark-400];

          span {
            color: @vars[blue-400-to-neutral-dark-700] !important;
            text-transform: capitalize;

            &.main {
              text-transform: none !important;
            }
          }

          svg path {
            stroke: @vars[blue-400-to-neutral-dark-400];
          }
        });
      }

      .store-tracker-page-overview-empty {
        margin-top: 16px;
        background: transparent !important;
      }

      .product-database-table-body.store-tracker-page-sales-table-wrapper {
        margin-top: 16px;

        @media @viewport-mobile {
          margin-top: 16px;
        }

        &.cards {
          border-top: none !important;
          padding: 0 32px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          column-gap: 16px;
          row-gap: 24px;
          max-height: calc(100vh - 124px);
          overflow-y: auto;

          @media @viewport-mobile {
            gap: 16px !important;
            padding: 0 16px;
          }

          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }
          &::-webkit-scrollbar-track-piece:start {
            background: transparent;
            margin-top: 10px;
          }
          &::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-bottom: 10px;
          }
        }
      }

      .fadspot-page-item-wrapper {
        width: calc((100% - 48px) / 3);
        max-width: 383px !important;

        @media @viewport-mobile {
          width: 95%;
        }
      }
    }

    .store-tracker-page-products {

      .product-view {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px;
        height: 36px;
        border-radius: 10px;
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-100];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        &-btn {
          transition: all .3s ease;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          .with-theme({
            svg path {
              stroke: @vars[neutral-600-to-neutral-dark-500];
            }
          });

          &--active {
            box-shadow: 0px 5px 10px -2px rgba(25, 28, 33, 0.06), 0px 2px 6px -2px rgba(106, 115, 133, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
            .with-theme({
              background: @vars[white-to-neutral-dark-100];

              svg path {
                stroke: @vars[neutral-800-to-100];
              }
            });
          }
        }

        @media @viewport-mobile {
          width: 100%;
          .with-theme({
            color: @vars[neutral-800-to-100];
          });

          .product-view-btn {
            width: 50%;
          }
        }
      }

      .report-main-block-cards-sort {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 8px;
        height: 36px;
        .with-theme({
          background: @vars[neutral-50-to-neutral-dark-50];
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        @media @viewport-mobile {
          width: 100%;
        }

        &-btn {
          cursor: pointer;
          transition: all .3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 12px;
          border-radius: 6px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });

          @media @viewport-mobile {
            width: 33%;
          }

          &.active {
            .with-theme({
              background: @vars[white-to-neutral-dark-100];
              color: @vars[neutral-800-to-100];
            });
          }
        }
      }

      .ant-spin-nested-loading {
        overflow: visible !important;
      }

      .product-database-table-body.store-tracker-page-sales-table-wrapper {
        margin-top: 0;

        @media @viewport-mobile {
          margin-top: 0;
        }

        &.cards {
          border-top: none !important;
          padding: 0 32px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          column-gap: 16px;
          row-gap: 24px;
          height: calc(100vh - 84px);
          max-height: calc(100vh - 84px);
          overflow-y: auto;

          @media @viewport-mobile {
            gap: 16px !important;
            padding: 0 16px;
          }

          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-track-piece {
            background: transparent;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            .with-theme({
              background: @vars[neutral-200-to-neutral-dark-200];
            });
          }
          &::-webkit-scrollbar-track-piece:start {
            background: transparent;
            margin-top: 10px;
          }
          &::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
