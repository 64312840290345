@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.description-card {

  &-header {
    display: flex;
    align-items: center;
    gap: 32px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      font-family: 'Inter', sans-serif;

      .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500]
        });

        .icon-btn {
          width: 16px;
          height: 16px;
          line-height: 16px;
          font-size: 16px;
          margin-left: 4px;

          svg path {
            transition: all .3s ease;
          }

          &:hover {
            > svg path {
              fill: @bluePrimary;
            }
          }
        }
      }

      .value {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.5px;
        .with-theme({
          color: @vars[neutral-800-to-100];
        })
      }
    }
  }

  &-description {
    height: 244px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: pre-line;
    .custom-scroll-bar();

    @media @viewport-mobile {
      min-height: 244px;
      height: unset;
    }

    p {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    @media @viewport-mobile {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 18px;

      @media @viewport-mobile {
        width: 100%;
        gap: 0;
        justify-content: space-between;
      }

      .label {
        display: inline-block;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        .with-theme({
          color: @vars[neutral-500-to-neutral-dark-500];
        })
      }

      .list {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > .link {
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.18px;

          &.with-img {
            max-height: 24px;

            &:not(:last-of-type) {
              margin-right: 12px;
              padding-right: 12px;
              .with-theme({
                border-right: 1px solid @vars[neutral-200-to-neutral-dark-200];
              })
            }

            > svg {
              max-height: 24px;
            }
          }
        }
      }
    }

    .btn-wrap {
      display: flex;
      align-items: center;
      gap: 12px;

      @media @viewport-mobile {
        display: none;
      }
    }

    .btn {
      transition: all .3s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      border: none !important;
      height: 40px;
      padding: 6px 12px;
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      .with-theme({
        color: @vars[neutral-800-to-100];
        background: @vars[white-to-neutral-dark-50];
        box-shadow: @vars[box-shadow-btn-secondary];

        &:hover, &:focus {
          background: @vars[neutral-50-to-neutral-dark-100];
        }

        &[disabled] {
          cursor: not-allowed !important;
          background: @vars[neutral-100-to-neutral-dark-100] !important;
        }
      })
    }
  }
}
