@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';

  .modal-filter-preset-title {
    padding: 24px 24px 0 24px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    padding-bottom: 8px;
    .with-theme({
      color: @vars[neutral-800-to-100];
    })
  }
    .modal-filter-preset-description {
    padding: 0 24px 0 24px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: -0.3px;
    color: @Neutral600;
  }

  .load-filter-preset {
    &-wrapper {
      .price-modal-info {
        display: flex;
        align-items: center;
        margin-top: 16px;
        gap: 12px;
  
        &-logo {
          display: flex;
          height: 56px;
          justify-content: center;
          align-items: center;
          width: 56px;
          border-radius: @border-radius;
  
          .with-theme({
            background: @vars[blue-100-to-neutral-900];
            border: 1px solid @vars[neutral-100-to-800];
          });
  
        img {
          border-radius: 6px;
          height: 48px;
          max-height: 48px;
          max-width: 100%;
          object-fit: contain;
          width: fit-content;
        }
      }
  
      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;
      }
  
      &-link {
        text-decoration: underline;
      }
  
      &-title {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        max-width: calc(100% - 100px);
  
        .with-theme({
          color: @vars[neutral-800-to-200];
        });
  
      @media @viewport-mobile {
        font-size: 14px;
        line-height: 24px;
      }
    }
  
    &-additional-info {
      display: flex;
      align-items: center;
      gap: 12px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: -0.02em;
    }
  }
  
  .price-modal-chart-wrapper {
    margin-top: 16px;
  
    .price-modal-period {
      font-size: 14px;
      line-height: 20px;
      color: @Neutral500;
      margin-bottom: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
  
      .with-theme({
        svg path {
          fill: @vars[neutral-300-to-700];
        }
      });
  
    svg:hover path {
      fill: @bluePrimary !important;
    }
  
    @media @viewport-mobile {
      flex-direction: column;
      gap: 8px;
    }
  }
  }
  
  .price-modal-chart-top-block {
    @media @viewport-mobile {
      width: 100%;
    }
  
    .price-modal-chart-price-block {
      display: flex;
      flex-direction: column;
    }
  
    .price-modal-chart-second-block {
      display: flex;
      gap: 12px;
      align-items: center;
    }
  
    .price-modal-chart-last-updated {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.3;
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      })
  }
  
  .price-modal-chart-price {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.3px;
  }
  
  .price-modal-chart-price-value {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.7px;
    text-align: right;
  
    .with-theme({
      color: @vars[neutral-800-to-white];
    })
  }
  }
  }
  
  &-button-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    .load-filter-preset-button {
      background: transparent;
      transition: all .3s ease;
      padding: 16px 24px;
  
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
        color: @vars[neutral-800-to-200];
      });
  
    &[disabled] {
      color: @Neutral500 !important;
      margin-left: 16px;
  
      .with-theme({
        background: @vars[neutral-100-to-800] !important;
      })
  }
  
  &:hover:not([disabled]) {
    border-color: @bluePrimary;
    color: @bluePrimary;
  }
  }
  
  .load-filter-preset-button.apply {
    border: none;
  }
  
  .load-filter-preset-button.apply:not([disabled]) {
    margin-left: 16px;
    background: @bluePrimary;
    color: @white !important;
    border: none;
  
    &:hover {
      background: @blueDepressed;
    }
  }
  }
}
.vertical-divider-small {
  height: 16px;
  width: 1px;

  .with-theme({
    background: @vars[neutral-200-to-neutral-dark-200];
  })
}

.price-modal-info-item{
  display: flex;
  gap: 6px;
}

.price-modal-chart-main-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media @viewport-mobile {
    flex-direction: column;
    gap: 12px;
  }
}

.main-filter-options{
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 10px;
  height: 40px;
  padding: 4px;
  .with-theme({
    background: @vars[neutral-50-to-neutral-dark-50];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  .filter-option{
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    .with-theme({
      color: @vars[neutral-600-to-neutral-dark-500];
    });
    &.selected{
      .with-theme({
        background: @vars[white-to-neutral-dark-200];
        color: @vars[neutral-800-to-neutral-100];
      });
    }
  }
}