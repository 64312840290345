@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';

.main-filter-dropdown {
  padding: 6px 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: -0.30000001192092896px;
  border-radius: 8px;
  transition: all .3s ease;
  cursor: pointer;
  text-wrap: nowrap;
  .with-theme({
    color: @vars[neutral-800-to-200];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    width: 100%;
    position: relative;
  }

  svg {
    transition: all .3s ease;
  }

  &:hover {
    border: 1px solid @bluePrimary;
  }

  &:has(.ant-dropdown-open) {
    .with-theme({
      border: 1px solid @bluePrimary;
    });

    svg:not(.icon.icon-database-search):not(.icon.icon-empty-warning):not(.attention-outline-icon) {

      &:last-of-type {
        transform: rotateZ(180deg);
      }
    }
  }

  .main-filter-dropdown-inner {

    @media @viewport-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg {
      margin-left: 8px;

      @media @viewport-mobile {
        margin-left: 0;
      }
    }
  }

  .main-filter-dropdown-content-top-section{
    display: flex;
    justify-content: space-between;
  }
  .main-filter-dropdown-content-top-section-language{
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  .close-btn-icon{
    svg{
      .with-theme({
        fill: @vars[neutral-600-to-neutral-dark-500] !important;
      });
    }
  }

  .main-filter-dropdown-content-wrapper {
    position: absolute;
    width: auto !important;

    @media @viewport-mobile {
      border-radius: 24px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      width: 100% !important;
      position: fixed !important;
      left: 0 !important;
      top: unset !important;
      bottom: 0 !important;
    }
  }

  &-content-wrapper {
    top: 47px !important;
    cursor: default;
    width: 350px;
    padding: 16px;
    height: auto;
    border-radius: 16px;
    .with-theme({
      background: @vars[white-to-neutral-dark];
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
    });

    @media @viewport-mobile {
      width: 100% !important;
      height: auto;
    }
  }

  .calendar-dropdown-content-wrapper {
    width: 568px;
    height: 512px;
    padding: 0px !important;
    right: -22px;
    left: 0 !important;

    @media @viewport-mobile {
      width: 100%;
      height: 504px;
      left: 0 !important;

      .main-filter-dropdown-content-footer {
        margin-top: 360px !important;
      }

      .results {
        display: none;
      }
    }

    .ant-spin-nested-loading > div > .ant-spin {
      z-index: 4000;
    }

    .ant-spin-blur {
      opacity: 1;
    }

    .dropdown-date-wrapper {
      padding: 0 !important;
      border: none !important;
      width: unset !important;
      height: unset !important;

      .dropdown-database-wrapper-inner {
        top: 44px !important;
        z-index: 10000;

        .dropdown-database-list-item {
          border-radius: @border-radius;

          &:hover .ant-checkbox-inner {
            border: 1.5px solid @bluePrimary !important;
          }
        }

        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-family: Inter,sans-serif;
          font-weight: 400;
          min-height: 24px;
          line-height: 20px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          .ant-checkbox {
            top: 0;

            &-checked {
              .ant-checkbox-inner {
                border: 1.5px solid @bluePrimary !important;
                background: @bluePrimary !important;
              }
            }

            .ant-checkbox-inner {
              width: 20px;
              height: 20px;
              border-radius: @border-radius;
              .with-theme({
                border: 1.5px solid @vars[neutral-200-to-700];
                background-color: @vars[color-white-to-dark];
              });

              &:after {
                content: url("../../../../Images/checkbox-mark.svg");
                left: 50%;
                top: 50%;
                font-size: 0;
                border: none;
                width: 16px;
                height: 16px;
                transform: scale(1) translate(-50%, -50%);
                position: absolute;
              }
            }
          }
        }

      }

      .ant-dropdown-trigger.dropdown-database {

        & > svg {
          transition: all .3s ease;
          path {
            fill: @Neutral500;
          }
        }

        & > svg:last-of-type {
          transform: rotate(0deg) !important;
        }

        &.ant-dropdown-open {
          & > svg {
            path {
              fill: @bluePrimary;
            }
          }
        }
      }

      .ant-picker-panel button > svg {
        transform: rotate(0deg) !important;
        path {
          fill: @Neutral500;
        }
      }

      .dropdown-date-button-wrapper {
        margin-top: 0;
        gap: 0;
      }
    }
  }

  .languages-dropdown-content-wrapper {
    width: 252px !important;
    max-height: 392px;
    height: unset;
    padding: 7px;
    left: 0 !important;

    @media @viewport-mobile {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      width: 100% !important;
      position: fixed;
      left: 0 !important;
      top: unset !important;
      bottom: 0 !important;
    }
    .ant-input-affix-wrapper .ant-input-prefix{
      margin-right: 8px;
    }
    .ant-input-affix-wrapper.dropdown-search.input {
      height: 40px;
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
      });
      svg{
        width: 20px;
        height: 20px;
        path {
          fill: @Neutral500;
        }
      }

      &:hover {
        box-shadow: none !important;
        border-color: @bluePrimary !important;
      }

      &:focus, &:focus-within {
        box-shadow: 0 0 0 2px rgba(34, 90, 234, .2) !important;
        border-color: @bluePrimary !important;

        svg path {
          fill: @bluePrimary;
        }
      }

      input {
        background-color: transparent !important;
        caret-color: @bluePrimary;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });

        &::placeholder {
          .with-theme({
            color: @vars[neutral-400-to-neutral-dark-400];
          })
        }
      }
    }

    .dropdown-database-list {
      margin-top: 0;
      max-height: 240px;
      overflow-y: auto;

    @media @viewport-mobile {
      max-height: 208px;
    }

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }

      .dropdown-database-list-item.test-item {
        border-radius: @border-radius;
      }
    }

    .button-apply {
      width: 100%;
    }
  }

  &-content {

    &.main-filter-modal {
      padding: 16px;
    }

    .ant-spin-nested-loading {
      overflow: visible;
    }

    &-title {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      .with-theme({
        color: @vars[neutral-800-to-100];
      });

      svg {
        margin-left: 4px;
        margin-bottom: 1px;
      }

      .dropdown-bar-tooltip {
        max-width: 290px;

        .ant-tooltip-inner {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: @Neutral50;
          text-wrap: wrap;
        }
      }
    }

    &-bars-wrapper {
      margin-top: 24px;
      width: calc(100% - 12px);
      margin-left: 6px;

      .bars {
        display: flex;
        align-items: flex-end;
        justify-content: space-evenly;

        .bar {
          background: @bluePrimary;
          width: 12px;

          &.inactive {
            .with-theme({
              background: @vars[neutral-200-to-800];
            })
          }
        }
      }
    }

    .min-max-input-wrapper {
      margin-top: 16px;
      margin-bottom: 16px;
      gap: 12px;

      .min-max-input-item-wrapper{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .min-max-input-item-title{
          .with-theme({
            color: @vars[neutral-800-to-100];
          });
        }
        .ant-input-suffix{
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.30000001192092896px;
          color:@Neutral400;
        }
        .ant-input-affix-wrapper {
          width: 153px;
          margin-left: 0;

          @media @viewport-mobile {
            input {
              font-size: 12px;
            }
          }
        }
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media @viewport-mobile {
        flex-direction: column;
        gap: 12px;
      }
    }

    &-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      width: 100%;
      .ant-tooltip-inner {
        width: fit-content;
        @media @viewport-mobile {
          text-wrap: wrap;
        }
      }


      @media @viewport-mobile {
        width: 100%;
      }

      .button-apply {
        width: auto;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: -0.3px;
        transition: all .3s ease;
        border-radius: 8px;
        box-shadow: none;
        outline: none;
        border: none;
        cursor: pointer;
        background: @bluePrimary;
        color: @white !important;

        &-disabled {
          cursor: default;
          color: @Neutral500 !important;
          .with-theme({
            background: @vars[neutral-100-to-800] !important;
          });
        }

        &:hover, &:focus {
          background: @blueDepressed;
        }

        @media @viewport-mobile {
          width: 100% !important;
          height: 40px;
        }
      }

      .button-cancel {
        background: transparent;
        width: 76px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        transition: all .3s ease;
        border-radius: @border-radius;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        .with-theme({
          color: @bluePrimary;
          border:unset;
        });

        &:hover, &:focus {
          color: @bluePrimary;
          border:unset;
        }
      }
    }
  }
}

.adspot-dropdown {
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  height: 38px;
  .with-theme({
    border: 1px solid @vars[neutral-200-to-neutral-dark-200] !important;
  });

  &:hover {
    .with-theme({
      background: @vars[neutral-50-to-neutral-dark-100];
    });
  }

  &:has(.ant-dropdown-open) {
    .with-theme({
      border: 1px solid @bluePrimary;
      background: @vars[neutral-50-to-neutral-dark-100];
      box-shadow: none !important;
    });
  }
}

.old-main-filter-dropdown {
  padding: 6px 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: @border-radius;
  transition: all .3s ease;
  cursor: pointer;
  text-wrap: nowrap;
  .with-theme({
    color: @vars[neutral-800-to-200];
    border: 1px solid @vars[neutral-200-to-neutral-dark-200];
  });

  @media @viewport-mobile {
    width: 100%;
    position: relative;
  }

  svg {
    transition: all .3s ease;
  }

  &:hover {
    border: 1px solid @bluePrimary;
  }

  &:has(.ant-dropdown-open) {
    .with-theme({
      border: 1px solid @bluePrimary;
      box-shadow: 0 0 0 2px rgba(34, 90, 234, .2);
    });

    svg:not(.icon.icon-database-search):not(.icon.icon-empty-warning):not(.attention-outline-icon) {

      &:last-of-type {
        transform: rotateZ(180deg);
      }
    }
  }

  .old-main-filter-dropdown-inner {

    @media @viewport-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg {
      margin-left: 8px;

      @media @viewport-mobile {
        margin-left: 0;
      }
    }
  }


  &.product {
    .old-main-filter-dropdown-content-wrapper {
      width: 706px !important;
      left: 0 !important;

      @media @viewport-mobile {
        width: 100% !important;
        position: relative;
      }
    }
  }

  &-content-wrapper {
    top: 47px !important;
    left: -1px !important;
    cursor: default;
    width: 567px;
    height: 242px;
    border-radius: @border-radius;
    padding: 12px;
    .with-theme({
      background: @vars[color-white-to-dark];
      border: 1px solid @vars[neutral-100-to-800];
    });

    @media @viewport-mobile {
      width: 100% !important;
      height: auto;
    }
  }

  .old-calendar-dropdown-content-wrapper {
    width: 752px;
    height: 616px;
    right: -120px;
    left: unset !important;

    &.products{
      right: -145px;
    }

    &.adspot-dropdown{
      right: unset;
    }

    @media @viewport-mobile {
      width: 100%;
      height: 504px;
      left: 0 !important;

      .old-main-filter-dropdown-content-footer {
        margin-top: 360px !important;
      }

      .results {
        display: none;
      }
    }

    .ant-spin-nested-loading > div > .ant-spin {
     z-index: 4000;
    }

    .ant-spin-blur {
      opacity: 1;
    }

    .old-dropdown-date-wrapper {
      padding: 0 !important;
      border: none !important;
      width: unset !important;
      height: unset !important;

      .old-dropdown-database-wrapper-inner {
        top: 44px !important;
        z-index: 10000;

        .old-dropdown-database-list-item {
          border-radius: @border-radius;

          &:hover .ant-checkbox-inner {
            border: 1.5px solid @bluePrimary !important;
          }
        }

        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-family: Inter,sans-serif;
          font-weight: 400;
          min-height: 24px;
          line-height: 20px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });

          .ant-checkbox {
            top: 0;

            &-checked {
              .ant-checkbox-inner {
                border: 1.5px solid @bluePrimary !important;
                background: @bluePrimary !important;
              }
            }

            .ant-checkbox-inner {
              width: 20px;
              height: 20px;
              border-radius: @border-radius;
              .with-theme({
                border: 1.5px solid @vars[neutral-200-to-700];
                background-color: @vars[color-white-to-dark];
              });

              &:after {
                content: url("../../../../Images/checkbox-mark.svg");
                left: 50%;
                top: 50%;
                font-size: 0;
                border: none;
                width: 16px;
                height: 16px;
                transform: scale(1) translate(-50%, -50%);
                position: absolute;
              }
            }
          }
        }

      }

      .ant-dropdown-trigger.dropdown-database {

        & > svg {
          transition: all .3s ease;
          path {
            fill: @Neutral500;
          }
        }

        & > svg:last-of-type {
          transform: rotate(0deg) !important;
        }

        &.ant-dropdown-open {
          & > svg {
            path {
              fill: @bluePrimary;
            }
          }
        }
      }

      .ant-picker-panel button > svg {
        transform: rotate(0deg) !important;
        path {
          fill: @Neutral500;
        }
      }

      .old-dropdown-date-button-wrapper {
        margin-top: 0;
        gap: 0;
      }
    }
  }

  .languages-dropdown-content-wrapper {
    width: 216px;
    max-height: 298px;
    height: unset;
    padding: 7px;
    overflow-y: hidden;
    left: -44px !important;

    @media @viewport-mobile {
      width: 100%;
      left: 0 !important;
    }

    .ant-input-affix-wrapper.dropdown-search.input {
      .with-theme({
        border: 1px solid @vars[neutral-100-to-800];
      });

      svg path {
        fill: @Neutral500;
      }

      &:hover {
        box-shadow: none !important;
        border-color: @bluePrimary !important;
      }

      &:focus, &:focus-within {
        box-shadow: 0 0 0 2px rgba(34, 90, 234, .2) !important;
        border-color: @bluePrimary !important;

        svg path {
          fill: @bluePrimary;
        }
      }

      input {
        background-color: transparent !important;
        caret-color: @bluePrimary;
        .with-theme({
          color: @vars[neutral-800-to-200];
        });

        &::placeholder {
          .with-theme({
            color: @vars[neutral-300-to-700];
          })
        }
      }
    }

    .old-dropdown-database-list {
      margin-top: 0;
      max-height: 181px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        .with-theme({
          background: @vars[neutral-200-to-neutral-dark-200];
        });
      }
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }

      .old-dropdown-database-list-item.test-item {
        border-radius: @border-radius;
      }
    }

    .button-apply {
      width: 100%;
    }
  }

  &-content {

    .ant-spin-nested-loading {
      overflow: visible;
    }

    &-title {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });

      svg {
        margin-left: 4px;
      }

      .dropdown-bar-tooltip {
        max-width: 242px;

        .ant-tooltip-inner {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: @white;
        }
      }
    }

    &-bars-wrapper {
      margin-top: 24px;
      width: calc(100% - 12px);
      margin-left: 6px;

      .bars {
        display: flex;
        align-items: flex-end;
        justify-content: space-evenly;

        .bar {
          background: @bluePrimary;
          width: 12px;

          &.inactive {
            .with-theme({
              background: @vars[neutral-200-to-800];
            })
          }
        }
      }

      .ant-slider {
        margin-top: 0;
        padding-top: 0;
      }

      .ant-slider-rail {
        .with-theme({
          background-color: @vars[blue-100-to-900];
        })
      }

      .ant-slider-handle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: @bluePrimary;
        margin-top: -11px;
        transform-style: preserve-3d;

        &:focus {
          box-shadow: none;
          border: none;
        }

        &:after {
          display: none;
        }
      }
    }

    .slider-legend {
      font-size: 12px;
      line-height: 14px;
      font-family: 'Inter', sans-serif;
      min-width: 40px;
      text-align: center;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-top: 0;
      }
    }

    .min-max-input-wrapper {
      margin-top: 16px;
      margin-bottom: 0;

      .ant-input-affix-wrapper {
        margin-left: 0;

        @media @viewport-mobile {
          input {
            font-size: 12px;
          }
        }
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media @viewport-mobile {
        flex-direction: column;
        gap: 12px;
      }

      .results {
        border-radius: @border-radius;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: @bluePrimary;
        padding: 10px 12px;
        .with-theme({
          background: @vars[blue-100-to-900];
        });

        @media @viewport-mobile {
          width: 100%;
        }
      }
    }

    &-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      @media @viewport-mobile {
        width: 100%;
      }

      .button-apply {
        width: 117px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        transition: all .3s ease;
        border-radius: @border-radius;
        box-shadow: none;
        outline: none;
        border: none;
        cursor: pointer;
        background: @bluePrimary;
        color: @white !important;

        &-disabled {
          cursor: default;
          color: @Neutral500 !important;
          .with-theme({
            background: @vars[neutral-100-to-800] !important;
          });
        }

        &:hover, &:focus {
          background: @blueDepressed;
        }
      }

      .button-cancel {
        background: transparent;
        width: 87px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        transition: all .3s ease;
        border-radius: @border-radius;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        .with-theme({
          color: @vars[neutral-800-to-200];
          border: 1px solid @vars[neutral-100-to-800];
        });

        &:hover, &:focus {
          border: 1px solid @bluePrimary;
          color: @bluePrimary;
        }
      }
    }
  }
}

