@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.fadspot-page-overview-advertisers-wrapper {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 10px;

  @media @viewport-mobile {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    margin: 0;
  }
  .overview-advertiser-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 12px;
  
    .with-theme({
      border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      box-shadow: @vars[box-shadow-btn-secondary-without-border];
      background-color: @vars[white-to-neutral-dark-50];
    });
  
    @media @viewport-mobile {
      width: 100% !important;
      margin-bottom: 4px;
  
      &:last-child {
        margin: 0;
      }
    }
  
    &:has(.overflowing) {
      &:before {
        position: absolute;
        left: 20px;
        z-index: 10;
        top: 5px;
        width: max-content;
        max-width: 150px;
        white-space: break-spaces;
        border-radius: 6px;
        padding: 5px;
        font-size: 12px;
        line-height: 12px;
        background: @Neutral800;
        color: @white;
        text-align: left;
        content: attr(data-text);
      }
    }
  
    .overview-advertiser-logo {
      width: 40px;
      height: 40px;
  
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 50%;
      }
    }
  
    .overview-advertiser-content {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      width: calc(100% - 48px);
    }
  
    .overview-advertiser-title {
      display: flex;
      align-items: center;
  
      span {
        max-width: calc(100% - 20px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .with-theme({
          color: @vars[color-dark-to-white];
        });
      }
  
      svg {
        margin-left: 4px;
      }
    }
  
    .overview-advertiser-value {
      margin-top: 2px;
      font-weight: 400;
  
      .with-theme({
        color: @vars[neutral-600-to-neutral-dark-500];
      });
    }
  }
}
