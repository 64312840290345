@import '../../../styles/theme/theme';

.product-details-page {
  height: 100dvh;
  max-width: calc(100vw - 72px);
  margin: 0 auto !important;
  padding-bottom: 28px !important;

  @media @viewport-mobile {
    max-width: 100vw;
  }

  &-main-info {

    &-wrapper {
      padding: 24px 32px 0;
      display: flex;
      flex-direction: row;
      gap: 24px;

      @media @viewport-mobile {
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 16px 0;
        width: 100%;
        gap: 16px;
      }
    }

    &-text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      width: calc(100% - 385px - 24px);

      @media @viewport-mobile {
        width: 100%;
      }
    }
  }

  &-block {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 32px;

    @media @viewport-mobile {
      margin: 32px 0;
      flex-direction: column;
      align-items: flex-start;
    }

    & > .product-details-page-block-wrapper {
      margin: 0 !important;
    }

    &-wrapper {
      margin: 32px;
      border-radius: 16px;
      .with-theme({
        background: @vars[white-to-neutral-dark];
        border: 1px solid @vars[neutral-200-to-neutral-dark-200];
      });

      @media @viewport-mobile {
        margin: 32px 16px;
        padding: 0;
        border: none !important;
      }

      &.saturation-wrapper {
        width: 403px;
        height: 365px;

        @media @viewport-mobile {
          width: 100%;
          padding: 0 16px;
          height: auto;
        }
      }

      &.suppliers-wrapper {
        @media @viewport-mobile {
          margin: 32px 0;
        }
      }

      &.interests-wrapper {
        @media @viewport-mobile {
          margin: 32px 0;
        }
      }

      &.competitors-wrapper {
        width: calc(100% - 427px);
        height: 365px;

        @media @viewport-mobile {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .scroll-to-top {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all .3s ease;
    color: @bluePrimary;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;

    &:hover {
      color: @blueDepressed;
    }
  }

  .link.skeleton {
    display: block;
  }
}
