@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';

.sales-tracker-top-table-item {
  margin-bottom: 16px;
  border-radius: @border-radius;

  &:first-of-type {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px 0px 60px;
    
    @media @viewport-mobile {
      flex-direction: column;
      padding: 16px;
    }

    .total-info {
      display: flex;
      align-items: center;

      @media @viewport-mobile {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 12px;
        margin-bottom: 12px;
      }

      &-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .title {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          .with-theme({
            color: @vars[neutral-600-to-neutral-dark-500];
          });
        }

        .value {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });
          transition: all .3s ease;

          &.clickable {
            cursor: pointer;
            color: @bluePrimary;

            &:hover {
              color: @blueDepressed;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 16px;

      @media @viewport-mobile {
        width: 100%;
        justify-content: start;
      }

      .sales-tracker-top-table-button {
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(.sales-tracker-top-table-button-icon) {
          svg {
            width: 17px;
            height: 17px;
            margin-right: 8px;
            g{
              path{
                stroke: @white;
              }
            }
          }
        }
        padding: 10px 12px;
        height: 40px;
        border-radius: 10px;
        border: none;
        color: @white;
        background: @bluePrimary;
        outline: none;
        cursor: pointer;
        transition: all .3s ease;

        &:hover, &:focus {
          background: @blueDepressed;
          border: none;
          outline: none;
        }

        &-active {
          background: @redPrimary;

          &:hover, &:focus {
            background: @redDepressed;
            border: none;
            outline: none;
          }
        }

        &-icon {
          padding: 0 7px;
          height: 40px;
          .with-theme({
            background: @vars[color-white-to-dark];
            border: 1px solid @vars[neutral-100-to-800];
          });

          &[disabled] {
            .with-theme({
              background: @vars[neutral-100-to-800];
            });
            svg {
              path:first-of-type {
                fill: @Neutral500;
              }
            }
          }

          &.dark {
            svg {
              path:first-of-type {
                fill: @white;
              }
              path {
                fill: @dark;
              }
            }
            &[disabled] {
              svg {
                path:first-of-type {
                  fill: @Neutral700;
                }
              }
            }
          }
        }
        span{
          font-size: 14px;
          font-family: 'Inter', sans-serif;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.3px;
        }
        @media @viewport-mobile {
          width: 100%;
        }
      }

      .sales-tracker-top-table-toggle{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  .top-performing-products-wrapper {
    padding: 16px 24px;
    font-family: 'Inter', sans-serif;
    font-style: normal;

    @media @viewport-mobile {
      padding: 16px;
    }

    .top-performing-products-title {
      font-weight: 500;
      text-align: left;
      font-size: 16px;
      line-height: 20px;
      .with-theme({
        color: @vars[neutral-800-to-200];
      });
    }

    .top-performing-products {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      margin-top: 16px;
      gap: 12px;

      @media @viewport-mobile {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
      }

      .top-performing-product {
        display: flex;
        align-items: center;
        width: 33%;
        padding: 12px;
        border-radius: 12px;

        .with-theme({
          border: 1px solid @vars[neutral-200-to-neutral-dark-200];
        });

        @media @viewport-mobile {
          width: 100%;
        }

        .title-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          & > a, & > span {
            display: block;
            max-width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: none;
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            .with-theme({
              color: @vars[neutral-800-to-200];
            });

            &.overflowing {
              &:before {
                position: absolute;
                left: 0;
                top: 40px;
                width: max-content;
                max-width: 240px;
                white-space: break-spaces;
                border-radius: 6px;
                padding: 16px;
                font-size: 12px;
                line-height: 16px;
                background: @Neutral800;
                color: @white;
                text-align: left;
                content: attr(data-text);
                display: none;
                z-index: 100;
              }

              &:hover:before {
                display: block;
              }
            }
            @media @viewport-mobile {
              max-width: 220px;
            }
          }

          .price-info {
            margin-top: 4px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 19.6px;
            color: @bluePrimary;
            &:hover {
              color: @blueDepressed;
            }

            .price-info-title{
              margin-right: 3px;
              .with-theme({
                color: @vars[neutral-600-to-neutral-dark-500];
              });
              &:hover {
                .with-theme({
                  color: @vars[neutral-600-to-neutral-dark-500];
                });
              }
            }
            .price-info-value{
              .with-theme({
                color: @vars[neutral-800-to-100];
              });
              &:hover {
                .with-theme({
                  color: @vars[neutral-800-to-100];
                });
              }
            }

            @media @viewport-mobile {
              text-wrap: nowrap;
              font-size: 12px;
            }
          }
        }

        .image-wrapper {
          position: relative;
          width: 40px;
          min-width: 40px;
          height: 40px;
          border-radius: @border-radius;
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          .with-theme({
            background: @vars[color-white-to-dark];
            border: 1px solid @vars[neutral-100-to-800];
          });

          @media @viewport-mobile {
            margin-right: 6px;
          }

          &-hidden {
            &:after {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              border-radius: @border-radius;
              content: '';
              backdrop-filter: blur(4px);
              background: rgba(255,255,255, .1);
            }
          }

          .product-image-quantity {
            z-index: 2;
            position: absolute;
            left: 32px;
            top: -4px;
            padding: 2px;
            color: @Neutral500;
            min-width: 40px;
            height: 20px;
            border-radius: @border-radius;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            .with-theme({
              border: 1px solid @vars[neutral-100-to-800];
              background: @vars[color-white-to-dark];
            });

            @media @viewport-mobile {
              top: -10px;
              left: 30px;
            }
          }

          & > img {
            border-radius: @border-radius;
            width: fit-content;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  &-chart {
    padding: 24px 32px 0px 60px;
    position: relative;
    min-height: 275px;

    @media @viewport-mobile {
      padding: 16px;
    }

    .hidden-chart {
      position: relative;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      z-index: 1;

      .empty-block.performance {
        height: 275px;
      }

      &:before {
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: @border-radius;
        content: '';
        backdrop-filter: blur(4px);
        background: rgba(255,255,255, .1);
      }

      &-text {
        position: absolute;
        left: 0;
        top: 84px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 3;
        transform-style: preserve-3d;
        transform: translateZ(3px);

        @media @viewport-mobile {
          top: 50%;
          text-align: center;
          transform: translate3d(0, -50%, 3px);
        }
      }

      &-status {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: @Neutral500;
      }

      &-title {
        margin-top: 2px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        .with-theme({
          color: @vars[neutral-800-to-200];
        })
      }

      &-button {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
        height: 40px;
        border-radius: @border-radius;
        border: none;
        color: @white;
        background: @bluePrimary;
        outline: none;
        cursor: pointer;
        transition: all .3s ease;

        &:hover, &:focus {
          background: @blueDepressed;
          border: none;
          outline: none;
        }
      }
    }
  }
}

.expanded-content{
  @media @viewport-mobile {
    max-width: 100vw;
    position: sticky;
    left: 0px;
    overflow: hidden;
  }
}