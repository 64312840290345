@import '../../../../../styles/theme/theme';
@import '../../../../../styles/base/ui-kit';

.sales-tracker-top-table-wrapper {
  border: none !important;

  .product-database-table-header {
    padding-top: 7px;
    padding-bottom: 7px;
    border-top-left-radius: @border-radius;
    border-top-right-radius: @border-radius;
    margin-bottom: -8px;
    .with-theme({
      border: 1px solid @vars[neutral-100-to-800];
      border-bottom:  1px solid @vars[neutral-200-to-neutral-dark-200]!important;
    });
  }
}

.sales-tracker-top-table-body {
  padding: 0;
  max-height: calc(100dvh - 136px);
  overflow-y: auto;

  @media @viewport-mobile {
    overflow-y: auto;
  }

  .sales-tracker-top-table-body-empty {
    display: flex;
    align-items: center;
    width: fit-content;
    min-height: 479px;
    margin: 0 auto;

    @media @viewport-mobile {
      margin-top: 0;
      min-height: 240px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        font-family: 'Inter', sans-serif;
        font-style: normal;

        &:first-of-type {
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
          .with-theme({
            color: @vars[neutral-800-to-200];
          });
        }

        &:last-of-type {
          margin-top: 8px;
          color: @Neutral500;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .empty-image_icon {
      width: 96px;
      height: 96px;
      border-radius: @border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      .with-theme({
        background: @vars[yellow-200-to-800];
      });

      svg path {
        fill: @yellowPrimary;
      }
    }
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    .with-theme({
      background: @vars[neutral-200-to-neutral-dark-200];
    });
  }
  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }
}
