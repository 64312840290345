@import '../../../styles/theme/theme';
@import '../../../styles/base/ui-kit';

.product-database-page.competitors-page {

  .sales-tracker-top-page-header {

    .title-wrapper-icon {
      border: 1px solid rgba(160, 116, 1, 0.50);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.00) 100%), #F6B60E;
      box-shadow: 0px 20px 20px -10px rgba(112, 6, 6, 0.04), 0px 10px 10px -5px rgba(112, 6, 6, 0.04), 0px 5px 5px -2.5px rgba(112, 6, 6, 0.04), 0px 2.5px 2.5px -1.25px rgba(112, 6, 6, 0.04), 0px 0.833px 0.833px -0.417px rgba(112, 6, 6, 0.04), 0px 0px 0px 0.833px rgba(112, 6, 6, 0.04);
    }
  }

  .competitors-filter-wrapper {
    padding: 0 32px 0;
    max-height: 0;
    transition: all .3s ease;
    overflow: hidden;

    @media @viewport-mobile {
      padding: 0 16px 0;
    }

    &.active {
      max-height: 900px;
      padding: 24px 32px 0;
      overflow: visible;

      @media @viewport-mobile {
        padding: 16px 16px 0;
        max-height: 1500px;
      }
    }
  }
  
  .limited-banner {
    margin: 0;
  }
}
