@import '../../../styles/theme/theme';
@import '../../../styles/base/animation';
@import '../../../styles/base/ui-kit';

.unpause-sub {
  &-button {
    width: 100%;
    height: 48px;
    background: @bluePrimary;
    color: @white;
    border: none !important;

    &:hover, &:focus, &:active {
      background: @blueDepressed !important;
      color: @white !important;
      border: none !important;
    }
  }
}
