@import '../../../../styles/theme/theme';
@import '../../../../styles/base/ui-kit';

.delete-filter-preset-wrapper {

  .delete-preset-divider {
    margin-top: 24px;
  }

  .delete-filter-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .delete-preset-button-cancel {
    background: transparent;
    transition: all .3s ease;
    padding: 16px;
    border-radius: 10px;
    border: none;
    .with-theme({
      color: @vars[neutral-800-to-100] !important;
      background: @vars[white-to-neutral-dark-50] !important;
      box-shadow: @vars[box-shadow-btn-secondary] !important;

      &:hover, &:focus {
        background: @vars[neutral-50-to-neutral-dark-100] !important;
      }
    });

    &--orange {
      color: @white !important;
      border: none !important;
      background: @yellowPrimary !important;

      &:hover {
        background: @yellowDepressed !important;
      }
    }

    &.close-error-modal {
      background: @bluePrimary;
      color: @white;
      border: none !important;

      &:hover {
        background: @blueDepressed;
        color: @white;
      }
    }
  }

  .delete-preset-button-apply {
    margin-left: 16px;
    border: none !important;
    background: @white;
    color: @redPrimary !important;
    border-radius: 10px;

    .with-theme({
      box-shadow: @vars[box-shadow-btn-secondary] !important;
    });

    &:hover, &:focus {
      .with-theme({
        box-shadow: @vars[box-shadow-negative] !important;
      });
    }
  }
}
